import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import { css } from '@emotion/core';
import { Overlay } from './Overlay';

// Generic component to render something relative to an element in fixed screen space
export const Dropdown = React.memo(
  ({
    className,
    alignRight = false,
    positionRight = false,
    positionLeft = false,
    alignTop = false,
    alignBottom = false,
    alignCenter = false,
    parentRef,
    distance = 0,
    onClose,
    base, // If clicking on this dropdown should close other dropdowns
    children,
  }) => {
    const [parentRect, setParentRect] = useState(null);
    const [internalPosition, setInternalPosition] = useState({ left: 0, top: 0 });
    const [position, setPosition] = useState(internalPosition);
    const [freezePosition, setFreezePosition] = useState(false);
    const [size, setSize] = useState({});
    const [windowSize, setWindowSize] = useState({});
    const [positionTop, setPositionTop] = useState(false);
    const [hidden, setHidden] = useState(false);
    const ref = useRef(null);
    const portalRef = useRef(null);

    useLayoutEffect(() => {
      if (!freezePosition) {
        setPosition(internalPosition);
      }
    }, [freezePosition, internalPosition]);

    const updateSize = useCallback(() => {
      const element = ref.current;

      if (element) {
        const size = {
          width: element.scrollWidth + element.offsetWidth - element.clientWidth,
          height: element.scrollHeight + element.offsetHeight - element.clientHeight,
        };
        setSize(size);
      }
    }, []);

    const updatePosition = useCallback(() => {
      if (!parentRef.current) {
        return;
      }
      const element = parentRef.current;
      if (!element.clientWidth) {
        setHidden(true);
        return;
      }
      setHidden(false);

      const parentRect = element.getBoundingClientRect();
      setParentRect(parentRect);

      const distanceTop = parentRect.top - distance;
      const distanceBottom = windowSize.height - parentRect.bottom - distance;
      setPositionTop(
        (alignTop &&
          (size.height ?? 0) < distanceTop &&
          !(alignBottom && (size.height ?? 0) < distanceBottom)) ||
          (!alignTop && !alignBottom && distanceTop > distanceBottom),
      );
    }, [alignBottom, alignTop, distance, parentRef, size.height, windowSize.height]);

    const onRef = useCallback(
      current => {
        ref.current = current;
        updateSize();
        requestAnimationFrame(() => {
          updatePosition();
        });
      },
      [updatePosition, updateSize],
    );

    useLayoutEffect(() => {
      const onMouseUp = () => {
        setFreezePosition(false);
        updatePosition();
      };

      const onMouseDown = () => {
        setFreezePosition(true);
      };

      window.addEventListener('mouseup', onMouseUp, false);
      window.addEventListener('mousedown', onMouseDown, false);

      return () => {
        window.removeEventListener('mouseup', onMouseUp, false);
        window.removeEventListener('mousedown', onMouseDown, false);
      };
    }, [updatePosition]);

    useEffect(() => {
      updatePosition();
    }, [
      alignBottom,
      alignTop,
      distance,
      parentRef,
      positionTop,
      size.height,
      size.width,
      updatePosition,
    ]);

    const [sizeObserver] = useState(new MutationObserver(() => updateSize()));
    const [positionObserver] = useState(new MutationObserver(() => updatePosition()));

    useEffect(() => {
      const onClick = e => {
        if (
          onClose &&
          !parentRef.current?.contains(e.target) &&
          !ref.current?.contains(e.target) &&
          !e.target.closest('[data-modal]')
        ) {
          onClose();
        }
      };

      document.addEventListener('click', onClick, true);

      return () => {
        document.removeEventListener('click', onClick, true);
      };
    }, [onClose, parentRef]);

    useLayoutEffect(() => {
      const element = portalRef.current;

      if (!base) {
        element.dataset.modal = true;
      }

      sizeObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      return () => {
        sizeObserver.disconnect();
      };
    }, [base, sizeObserver]);

    useLayoutEffect(() => {
      if (!parentRef.current) {
        return undefined;
      }

      positionObserver.observe(parentRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      return () => {
        positionObserver.disconnect();
      };
    }, [positionObserver, parentRef]);

    useLayoutEffect(() => {
      const onResize = () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowSize(size => {
          if (size.width !== width || size.height !== height) {
            return { width, height };
          }
          return size;
        });
      };
      onResize();

      window.addEventListener('resize', onResize);
      window.addEventListener('orientationchange', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('orientationchange', onResize);
      };
    }, []);

    useLayoutEffect(() => {
      if (!parentRect) {
        return;
      }
      setInternalPosition(internalPosition => {
        const element = ref.current;
        const boundingRect = element.getBoundingClientRect();
        const offsetTop = boundingRect.top - internalPosition.top;
        const offsetLeft = boundingRect.left - internalPosition.left;

        let top;
        if (size.height > windowSize.height) {
          top = -offsetTop;
        } else {
          if ((positionLeft || positionRight) && alignCenter) {
            // Align center of parent
            top = parentRect.top + parentRect.height / 2;
          } else {
            top = positionTop
              ? parentRect.top - (size.height ?? 0) - distance
              : parentRect.bottom + distance;
          }
          // Offset so that we are within the viewport
          top = Math.min(
            Math.max(top, -offsetTop),
            windowSize.height - (size.height ?? 0) - offsetTop,
          );
        }
        let left;
        if (size.width > windowSize.width) {
          left = -offsetLeft;
        } else {
          if (positionLeft) {
            // Position on the left side of parent
            left = parentRect.left - (size.width ?? 0) - distance;
          } else if (positionRight) {
            // Position on the right side of parent
            left = parentRect.right + distance;
          } else {
            if (alignCenter) {
              // Align center of parent
              left = parentRect.left + parentRect.width / 2;
            } else if (alignRight) {
              // Align right inside
              left = parentRect.right - (size.width ?? 0);
            } else {
              // Align left inside
              left = parentRect.left;
            }
          }
          // Offset so that we are within the viewport
          left = Math.min(
            Math.max(left, -offsetLeft),
            windowSize.width - (size.width ?? 0) - offsetLeft,
          );
        }
        if (internalPosition.top !== top || internalPosition.left !== left) {
          return { top, left };
        }
        return internalPosition;
      });
    }, [
      alignCenter,
      positionRight,
      alignRight,
      distance,
      parentRect,
      positionTop,
      size.height,
      size.width,
      windowSize.width,
      positionLeft,
      windowSize.height,
    ]);

    return (
      <Overlay ref={portalRef}>
        <div
          className={className}
          ref={onRef}
          css={css(
            parentRect &&
              css`
                position: fixed;
                top: ${position.top}px;
                left: ${position.left}px;
              `,
            alignCenter &&
              (((positionLeft || positionRight) &&
                css`
                  transform: translateY(-50%);
                `) ||
                css`
                  transform: translateX(-50%);
                `),
            css`
              min-width: ${(!alignCenter && parentRect?.width) || 0}px;
              width: max-content;
              max-height: ${windowSize.height}px;
              max-width: ${windowSize.width}px;
              overflow: hidden;
              overflow-y: auto;
            `,
            hidden &&
              css`
                display: none;
              `,
          )}>
          {children}
        </div>
      </Overlay>
    );
  },
);
