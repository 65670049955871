import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const FormInput = ({
  disabled,
  input,
  label,
  meta: { touched, error },
  type,
  autocomplete,
}) => (
  <div className="material-input">
    <input
      {...input}
      className={classNames({ filled: input.value && input.value !== 0 })}
      disabled={disabled}
      id="course-description-input"
      required
      type={type}
      autoComplete={autocomplete}
    />
    <label htmlFor="course-description-input">{label}</label>
    <span>
      {error && touched && <div className="material-input__messages--error">{error}</div>}
    </span>
  </div>
);

FormInput.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({}),
  label: PropTypes.string,
  ref: PropTypes.func,
  meta: PropTypes.shape({}),
  type: PropTypes.string,
  autocomplete: PropTypes.string,
};

FormInput.defaultProps = {
  disabled: false,
  input: {},
  label: '',
  meta: {},
  type: 'text',
  autocomplete: 'off',
};
