import React, { useContext, useRef, useEffect } from 'react';
import { SceneContext } from './SceneContext';
import { Overlay } from './Overlay';

export const SceneOverlay = ({ className, children }) => {
  const { sceneRef } = useContext(SceneContext);

  const overlayRef = useRef();

  useEffect(() => {
    let running = true;

    const update = () => {
      if (!running) {
        return;
      }

      const { top, left } = sceneRef.current.getBoundingClientRect();

      const overlay = overlayRef.current;

      const { style } = overlay;
      style.top = `${top}px`;
      style.left = `${left}px`;

      requestAnimationFrame(update);
    };
    requestAnimationFrame(update);

    return () => {
      running = false;
    };
  }, [sceneRef]);

  return (
    <Overlay className={className} ref={overlayRef}>
      {children}
    </Overlay>
  );
};
