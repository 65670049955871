import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviewMode, getSlide } from '../../../../stores/editor/selectors/course.selectors';
import { CreateItemModal } from '../CreateItemModal';
import { EditorContext } from '../../EditorContext';
import { SceneRenderer, SceneProvider } from '~/components/scene/ItemScene';
import { SceneContext } from '~/components/scene/SceneContext';
import { css } from '@emotion/core';
import { updateSlideData } from '~/stores/editor/actions/course.actions';
import { createPortal } from 'react-dom';
import { EditorActionButton } from '../LeftSidebar/components/EditorActionButton';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PictureSlideScene = ({ className, scale }) => {
  const {
    loadJson,
    saveJson,
    setScale,
    dirty,
    setDirty,
    editor,
    optimize,
    showHiddenItems,
    setShowHiddenItems,
  } = useContext(SceneContext);

  const slide = useSelector(getSlide);

  const [loaded, setLoaded] = useState(false);
  const saveEnabled = useRef(false);

  useLayoutEffect(() => {
    setLoaded(false);
    setDirty(false);
    saveEnabled.current = false;
  }, [slide.id, editor, setDirty]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && slide) {
      setLoaded(true);
      loadJson(slide);
      saveEnabled.current = true;
    }
  }, [loadJson, loaded, slide]);

  if (process.env && process.env.NODE_ENV !== 'production') {
    window.setData = data => loadJson(data);
  }

  useEffect(() => {
    if (dirty && loaded && editor && saveEnabled.current) {
      const data = saveJson();

      if (process.env && process.env.NODE_ENV !== 'production') {
        window.data = data;
      }

      dispatch(updateSlideData(data));
      setDirty(false);
    }
  }, [saveJson, dirty, setDirty, dispatch, editor, loaded]);

  useEffect(() => {
    setScale(scale);
  }, [scale, setScale]);

  useEffect(() => {
    if (!window.Mousetrap) {
      return undefined;
    }

    window.Mousetrap.bind(['command+alt+o', 'ctrl+alt+o'], optimize);

    return () => {
      window.Mousetrap.unbind(['command+alt+o', 'ctrl+alt+o']);
    };
  }, [editor, optimize]);

  const leftToolbarElement = document.querySelector('#left-toolbar-buttons');

  return (
    <>
      <SceneRenderer key="scene" className={className} />
      {leftToolbarElement &&
        createPortal(
          <EditorActionButton
            onClick={() => {
              setShowHiddenItems(toggle => !toggle);
            }}
            tooltip={showHiddenItems ? 'Ikke vis skjulte elementer' : 'Vis skjulte elementer'}>
            <FontAwesomeIcon icon={showHiddenItems ? faEyeSlash : faEye} />
          </EditorActionButton>,
          leftToolbarElement,
        )}
    </>
  );
};

export const PictureSlide = ({ className, scale = 1, editor }) => {
  const slide = useSelector(getSlide);

  const previewMode = useSelector(getPreviewMode);
  const isEditor = !previewMode && editor;

  const { setAddItemModalOpen, isAddItemModalOpen } = useContext(EditorContext);

  const onAddItemModalClose = useCallback(() => {
    setAddItemModalOpen(false);
  }, [setAddItemModalOpen]);

  if (!slide) {
    return null;
  }

  const { backgroundType, justifyContent } = slide;
  const dynamicLayout = backgroundType !== 'Free' && backgroundType !== undefined;

  return (
    <SceneProvider
      key={isEditor ? 'editor' : 'player'}
      editor={isEditor}
      dynamicLayout={dynamicLayout}>
      <div
        css={css(
          css`
            display: flex;
            align-content: center;
            width: 100%;
            height: 100%;
          `,
          dynamicLayout &&
            css`
              padding: 20px;
            `,
        )}>
        {(dynamicLayout && (
          <div
            css={css(css`
              width: 100%;
              max-width: ${800 * scale}px;
              margin: 0 auto;
            `)}>
            <PictureSlideScene
              css={css`
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: ${justifyContent || 'center'};
                overflow-x: hidden;
                overflow-y: auto;
              `}
              editor={isEditor}
              className={className}
              scale={scale}
            />
          </div>
        )) || <PictureSlideScene editor={isEditor} className={className} scale={scale} />}
      </div>

      <CreateItemModal open={isAddItemModalOpen} onClose={onAddItemModalClose} />
    </SceneProvider>
  );
};
