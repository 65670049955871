import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckboxSelect } from '~/components/CheckboxSelect';
import { RadioSelect } from '~/components/RadioSelect';

import { getFilePreviewFilePropType } from '~/utils/types';
import { DndSelect } from '~/components/DndSelect';

export class PicturesQuestionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswers: new Set(),
      resultSubmitted: false,
    };

    this.toggleAnswer = this.toggleAnswer.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
  }

  toggleAnswer(answerId) {
    const {
      data: { answers },
      setCollectedData,
      permitContinue,
    } = this.props;
    const selectedAnswers = new Set(this.state.selectedAnswers);
    selectedAnswers[selectedAnswers.has(answerId) ? 'delete' : 'add'](answerId);
    this.setState({ selectedAnswers });
    setCollectedData({ selectedAnswers: [...selectedAnswers] });
    if (selectedAnswers.size >= answers.filter(({ isCorrect }) => isCorrect).length) {
      permitContinue();
    }
  }

  selectAnswer(answerId) {
    const { setCollectedData, permitContinue } = this.props;
    const selectedAnswers = new Set([answerId]);
    this.setState({ selectedAnswers });
    setCollectedData({ selectedAnswers: [...selectedAnswers] });
    permitContinue();
  }

  render() {
    const {
      data: { answers, question, settings },
      isQuestionnaireVisible,
    } = this.props;
    const { selectedAnswers, resultSubmitted } = this.state;

    const isExactlyOneGoodAnswer = answers.filter(answer => answer.isCorrect).length === 1;

    return (
      <div className="player-questionnaire__container">
        <div className="player-questionnaire__question-container">{question}</div>
        {isQuestionnaireVisible &&
          (!settings.dndSelect ? (
            <div className="player-questionnaire__answers-container">
              {isExactlyOneGoodAnswer ? (
                <RadioSelect
                  disabled={resultSubmitted}
                  groupName="answer"
                  onChange={this.selectAnswer}
                  options={answers.map(({ id, image }) => ({ id, image }))}
                  selectedOptionId={[...selectedAnswers][0]}
                  selectingImages
                />
              ) : (
                <CheckboxSelect
                  checked={selectedAnswers}
                  disabled={resultSubmitted}
                  groupName="answer"
                  onChange={this.toggleAnswer}
                  options={answers.map(({ id, image }) => ({ id, image }))}
                  selectingImages
                />
              )}
            </div>
          ) : (
            <div className="player-questionnaire__dnd-container">
              <DndSelect
                checked={selectedAnswers}
                onChange={this.toggleAnswer}
                options={answers.map(({ id, image }) => ({ id, image }))}
              />
            </div>
          ))}
      </div>
    );
  }
}

PicturesQuestionnaire.propTypes = {
  data: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCorrect: PropTypes.bool.isRequired,
        image: getFilePreviewFilePropType(),
      }),
    ).isRequired,
    question: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      dndSelect: PropTypes.bool,
      pointsEnabled: PropTypes.bool.isRequired,
      pointsPerAnswer: PropTypes.number.isRequired,
      pointsMaxScore: PropTypes.number.isRequired,
    }).isRequired,
    texts: PropTypes.shape({
      textWhenGood: PropTypes.string.isRequired,
      textWhenWrong: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isQuestionnaireVisible: PropTypes.bool.isRequired,
  permitContinue: PropTypes.func.isRequired,
  setCollectedData: PropTypes.func.isRequired,
};
