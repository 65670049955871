import { css } from '@emotion/core';

export const settingsWrapper = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1c1c1e;
  color: white;
  width: 300px;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  z-index: 3000;
  font-size: 14px;

  h2 {
    font-size: 24px;
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const section = css`
  h3 {
    font-size: 12px;
    color: #8c8c8c;
    margin-bottom: 8 px;
    padding-left: 8px;
  }
  .content {
    background-color: #303030;
    padding: 8px 12px;
    border-radius: 10px;
    .item {
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 14px;
      border-bottom: 1px solid #3d3d3d;
      display: inline-block;
      width: 100%;
      .form-select {
        padding: 0;
        border: 0;
        .rw-dropdown-list-input {
          color: #ccc;
        }
        .rw-widget-picker {
          background: transparent;
        }
        .rw-i {
          color: #ccc;
        }
      }
      .material-input {
        margin: 0;
        input {
          padding: 0;
        }
      }
      &.last {
        border-bottom: 0;
      }
      .label {
        float: left;
        width: 50%;
      }
      .field {
        float: left;
        width: 50%;
      }
    }
  }
`;
