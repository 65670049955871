import React from 'react';
import { css } from '@emotion/core';

export const ItemPlaceholder = React.memo(({ width, height, outline, enabled }) => (
  <div
    css={css(
      css`
        display: ${enabled ? 'block' : 'none'};
        height: ${height}px;
        width: ${width}px;
        box-sizing: border-box;
      `,
      outline &&
        css`
          border: 2px dashed rgba(128, 227, 255, 0.4);
        `,
    )}
  />
));
