import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { number, required, positiveNumber } from '~/utils/validators';
import { formatNumberInput, parseNumberInput } from '~/utils/helpers';

import { FormInput } from '~/components/FormInput';

const pointsValidator = (value, allValues) =>
  allValues.pointsEnabled ? positiveNumber(value) : undefined;

const TimeBoundsSection = props => {
  const { handleSubmit } = props;
  return (
    <form
      className="questionnaire-section-editor__settings-container"
      autoComplete="off"
      onSubmit={handleSubmit}>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormInput}
          name="timeToReadQuestionSec"
          type="number"
          label="Time to read question [sec]"
          defaultValue={undefined}
          format={formatNumberInput}
          parse={parseNumberInput}
          validate={[required, number, pointsValidator]}
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormInput}
          name="timeToAnswerQuestionSec"
          type="number"
          label="Time to answer question [sec]"
          defaultValue={undefined}
          format={formatNumberInput}
          parse={parseNumberInput}
          validate={[required, number, pointsValidator]}
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
    </form>
  );
};

TimeBoundsSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const TimeBoundsSectionForm = reduxForm({
  form: 'speedCourseTimeBoundsSectionForm',
})(TimeBoundsSection);
