import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { getIdForNextElement } from '~/utils/common';
import { ContentEditable } from '~/components/ContentEditable';
import { css } from '@emotion/core';

const SortableItem = SortableElement(({ affectAnswer, answer, onAnswerDeleteClick }) => (
  <div
    className="questionnaire-section-editor__question-row"
    css={css`
      :hover .questionnaire-section-editor__question-action-buttons {
        display: block;
      }
    `}>
    <div className="questionnaire-section-editor__question-content">
      <div
        css={{
          borderWidth: 2,
          borderColor: answer.isCorrect ? 'green' : 'red',
          borderStyle: 'solid',
          padding: 20,
          backgroundColor: 'white',
          borderRadius: 5,
        }}
        onDoubleClick={() => affectAnswer(answer.id, { isCorrect: !answer.isCorrect })}>
        <div>
          <ContentEditable
            id="course-description-input"
            css={{
              borderWidth: 0,
              width: '100%',
            }}
            hover={false}
            caretPosition="end"
            editable
            content={answer.text}
            onChange={value => affectAnswer(answer.id, { text: value })}
          />
        </div>
      </div>
    </div>

    <div className="questionnaire-section-editor__question-action-buttons">
      <button
        className="btn btn-icon btn-negative"
        css={{
          backgroundColor: 'transparent',
          borderColor: 'transparent!important',
        }}
        onClick={() => onAnswerDeleteClick(answer.id)}>
        <i className="icon-trash small" />
      </button>
    </div>
  </div>
));

const SortableList = SortableContainer(({ affectAnswer, answers, onAnswerDeleteClick }) => (
  <div>
    {answers.map((answer, index) => (
      <SortableItem
        affectAnswer={affectAnswer}
        answer={answer}
        index={index}
        key={`sortable-key-${answer.id}`}
        onAnswerDeleteClick={onAnswerDeleteClick}
      />
    ))}
  </div>
));

export class TextAnswersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newAnswer: {
        id: getIdForNextElement(props.answers),
        text: '',
        isCorrect: true,
      },
    };

    this.onAnswerDeleteClick = this.onAnswerDeleteClick.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.affectAnswer = this.affectAnswer.bind(this);
  }

  onAnswerDeleteClick(answerId) {
    const { answers, setAnswers } = this.props;
    setAnswers(answers.filter(answer => answer.id !== answerId));
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { answers, setAnswers } = this.props;
    const reorderedAnswers = arrayMove(answers, oldIndex, newIndex);
    setAnswers(reorderedAnswers);
  }

  addAnswer() {
    const { answers, setAnswers } = this.props;
    const newAnswer = {
      id: getIdForNextElement(answers),
      isCorrect: true,
      text: 'Enter answer',
    };
    setAnswers([...answers, newAnswer]);
  }

  affectAnswer(answerId, data) {
    const { answers, setAnswers } = this.props;
    setAnswers(
      answers.map(answer => {
        if (answer.id !== answerId) return answer;
        return {
          ...answer,
          ...data,
        };
      }),
    );
  }

  render() {
    const { answers } = this.props;
    return (
      <div className="material-tabs__content">
        <div className="questionnaire-section-editor__questions-list">
          <div className="questionnaire-section-editor__questions-list">
            <SortableList
              affectAnswer={this.affectAnswer}
              answers={answers}
              onAnswerDeleteClick={this.onAnswerDeleteClick}
              onSortEnd={this.onSortEnd}
              pressDelay={200}
            />
          </div>
        </div>
        <div className="questionnaire-section-editor__actions-buttons-row">
          <button className="btn btn-blue" onClick={this.addAnswer}>
            Add answer
          </button>
        </div>
      </div>
    );
  }
}

TextAnswersTab.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCorrect: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
};
