import axios from 'axios';
import Cookies from 'js-cookie';
import { backendUrl } from '~/config';

class API {
  phceId = '';

  setAuthorizationCookie = token => {
    Cookies.set('identity_login_attempted', 0);
    Cookies.set('tg-visit', token);
  };

  configureRequests = ({ courseId }) => {
    this.courseId = courseId;
    if (process.env.AUTH_TOKEN) {
      this.setAuthorizationCookie(process.env.AUTH_TOKEN);
    }
    // axios.defaults.baseURL = backendUrl;
    axios.defaults.withCredentials = true;
    axios.defaults.crossdomain = true;
  };

  getEditorData = () =>
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/courses/nano/${this.courseId}`)
      .then(({ data }) => {
        data.courseData = {
          descriptionPage: {},
          resultsPage: {},
          ...data.courseData,
        };
        return data;
      });

  getPlayerData = courseId =>
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/player/data/${courseId}`).then(({ data }) => {
      console.log(data);
      return data;
    });

  getTemplateTypes = () =>
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/static/javascript/nano/templateTypes.json`, {
      baseURL: undefined,
      withCredentials: false,
    });

  postCourse = data =>
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/courses/nano/${this.courseId}`, {
      cid: this.courseId,
      data,
    });

  postEvent = (type, pageId, data) =>
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/player/${this.courseId}/event`, {
        type,
        pageId,
        phce: this.phceId,
        data,
      })
      .then(response => {
        const { phce } = response.data;
        if (phce !== undefined && phce.id !== undefined) {
          this.phceId = phce.id;
        }
        return response;
      });

  uploadFile = file => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('timestamp', Math.round(Date.now() / 1000));

    return axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/files/savemanagefiles/nano/${this.courseId}`,
        formData,
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      )
      .then(response => {
        const { data } = response;
        const [fileExt] = data.title.split('.').slice(-1);
        const videoExtensions = ['mp4'];
        const pictureExtensions = ['jpg', 'jpeg', 'png'];
        if (videoExtensions.includes(fileExt.toLowerCase())) {
          response.data.type = 'video';
          return response;
        }
        if (pictureExtensions.includes(fileExt.toLowerCase())) {
          response.data.type = 'image';
          return response;
        }
        throw new Error('unsupported file type');
      });
  };

  getConfig = target =>
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/templates/config/nano_${target}.json`)
      .then(response => {
        const recievedValidJSON = typeof response.data === 'object' && response.data !== null;
        if (recievedValidJSON) {
          return response.data;
        } else {
          throw new Error('Invalid JSOn for config file');
        }
      });
}

export default new API();
