import React, { useRef, useState, useContext, useCallback } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { SceneContext } from '../SceneContext';
import { Tooltip } from '../Tooltip';
import { ToolbarItemPicker } from './ToolbarItemPicker';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { ToolbarText } from './ToolbarText';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarAnchorEdit = ({
  id,
  item,
  itemDefinition: { disableAnchorResize },
  label,
  icon,
  hideLabel,
}) => {
  const { itemSelectMode, updateItem, getAbsolutePosition, setParent } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onSetParent = useCallback(
    parent => {
      setParent(id, parent);
    },
    [id, setParent],
  );

  const onSetXDelta = useCallback(
    x => {
      updateItem(id, {
        position: {
          xDelta: x,
        },
      });
    },
    [id, updateItem],
  );

  const onSetYDelta = useCallback(
    y => {
      updateItem(id, {
        position: {
          yDelta: y,
        },
      });
    },
    [id, updateItem],
  );

  const onSetWidthDelta = useCallback(
    width => {
      updateItem(id, {
        position: {
          widthDelta: width,
        },
      });
    },
    [id, updateItem],
  );

  const onSetHeightDelta = useCallback(
    height => {
      updateItem(id, {
        position: {
          heightDelta: height,
        },
      });
    },
    [id, updateItem],
  );

  const onSetX = useCallback(
    x => {
      updateItem(id, {
        position: {
          x: x,
        },
      });
    },
    [id, updateItem],
  );

  const onSetY = useCallback(
    y => {
      updateItem(id, {
        position: {
          y: y,
        },
      });
    },
    [id, updateItem],
  );

  const onSetWidth = useCallback(
    width => {
      updateItem(id, {
        position: {
          width: width,
        },
      });
    },
    [id, updateItem],
  );

  const onSetHeight = useCallback(
    height => {
      updateItem(id, {
        position: {
          height: height,
        },
      });
    },
    [id, updateItem],
  );

  const setAnchorToElementPosition = useCallback(() => {
    const { x, y, width, height } = getAbsolutePosition(id);
    const { width: parentWidth, height: parentHeight } = getAbsolutePosition(item.parent);

    updateItem(id, {
      position: {
        x: x / parentWidth,
        y: y / parentHeight,
        width: width / parentWidth,
        height: height / parentHeight,
        xDelta: 0,
        yDelta: 0,
        widthDelta: 0,
        heightDelta: 0,
      },
    });
  }, [getAbsolutePosition, id, item.parent, updateItem]);

  const setElementToAnchorPosition = useCallback(() => {
    updateItem(id, {
      position: {
        xDelta: 0,
        yDelta: 0,
        widthDelta: 0,
        heightDelta: 0,
      },
    });
  }, [id, updateItem]);

  const { parent } = item;

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <div css={css(dropdownContainerStyle)}>
            Sett parent:
            <ToolbarItemPicker label="Velg element" value={parent} setValue={onSetParent} />
            <br />
            <div
              css={css`
                flex: 1 1 auto;
                display: flex;
                flex-direction: row;
              `}>
              <div
                css={css`
                  flex: 0;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: auto;
                  border-right: 2px solid #64656d;
                  margin-right: 10px;
                  padding-right: 10px;
                `}>
                Sett offset:
                <ToolbarText
                  type="number"
                  value={item.position?.xDelta}
                  precision={2}
                  setValue={onSetXDelta}
                  label="X"
                  suffixLabel="px"
                />
                <ToolbarText
                  type="number"
                  value={item.position?.yDelta}
                  precision={2}
                  setValue={onSetYDelta}
                  label="Y"
                  suffixLabel="px"
                />
                <ToolbarText
                  type="number"
                  value={item.position?.widthDelta}
                  precision={2}
                  setValue={onSetWidthDelta}
                  label="Bredde"
                  suffixLabel="px"
                />
                <ToolbarText
                  type="number"
                  value={item.position?.heightDelta}
                  precision={2}
                  setValue={onSetHeightDelta}
                  label="Høyde"
                  suffixLabel="px"
                />
                &nbsp;
                <ToolbarButton onClick={setElementToAnchorPosition} label="Nullstill offset" />
              </div>
              <div
                css={css`
                  flex: 0;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: auto;
                `}>
                Sett relativ posisjon:
                <ToolbarText
                  type="number"
                  value={item.position?.x}
                  precision={2}
                  factor={100}
                  setValue={onSetX}
                  label="X"
                  suffixLabel={`% av ${parent ? 'parent' : 'skjerm'}`}
                />
                <ToolbarText
                  type="number"
                  value={item.position?.y}
                  precision={2}
                  factor={100}
                  setValue={onSetY}
                  label="Y"
                  suffixLabel={`% av ${parent ? 'parent' : 'skjerm'}`}
                />
                {!disableAnchorResize && (
                  <>
                    <ToolbarText
                      type="number"
                      value={item.position?.width}
                      precision={2}
                      factor={100}
                      setValue={onSetWidth}
                      label="Bredde"
                      suffixLabel={`% av ${parent ? 'parent' : 'skjerm'}`}
                    />
                    <ToolbarText
                      type="number"
                      value={item.position?.height}
                      precision={2}
                      factor={100}
                      setValue={onSetHeight}
                      label="Høyde"
                      suffixLabel={`% av ${parent ? 'parent' : 'skjerm'}`}
                    />
                    &nbsp;
                    <ToolbarButton
                      onClick={setAnchorToElementPosition}
                      label="Sett til nåværende posisjon"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Dropdown>
      )}
    </>
  );
};
