import React, { useRef, useContext, useState, useEffect } from 'react';
import { toolbarButtonStyle, dropdownBgStyle } from '../styles';
import { SceneContext } from '../SceneContext';
import { nanoid } from 'nanoid';
import { Dropdown } from '../Dropdown';
import { ActionSequenceEditor } from './ActionSequenceEditor';
import { Tooltip } from '../Tooltip';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';

export const ToolbarActionEdit = ({ icon, hideLabel, item, name, label, id, type }) => {
  const { actionSequences, updateItem, itemSelectMode } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const { events = [] } = item;
  const [actionSequence, setActionSequence] = useState(
    type === 'event'
      ? events[name]?.actionSequence
      : (Object.entries(actionSequences).find(([, { target }]) => target === id) || [])[0],
  );

  useEffect(() => {
    setActionSequence(
      actionSequence =>
        actionSequence ||
        (type === 'event'
          ? events[name]?.actionSequence
          : (Object.entries(actionSequences).find(([, { target }]) => target === id) || [])[0]),
    );
  }, [actionSequences, events, id, name, type]);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(open => {
            if (!open && !actionSequence) {
              const actionSequence = nanoid();
              if (type === 'event') {
                updateItem(id, {
                  events: { [name]: { actionSequence } },
                });
              }
              setActionSequence(actionSequence);
            }
            return !open;
          });
        }}
        css={toolbarButtonStyle}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        {type === 'action' ? (
          <FontAwesomeIcon icon={faAngleDown} />
        ) : (
          <div
            css={css`
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #585d6a;
              line-height: 20px;
              text-align: center;
            `}>
            {(actionSequence && actionSequences[actionSequence]?.actions.length) || '+'}
          </div>
        )}
      </div>
      {open && actionSequence && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <ActionSequenceEditor
            id={id}
            actionSequence={actionSequence}
            target={type === 'action' ? id : null}
            autorun={type === 'action'}
          />
        </Dropdown>
      )}
    </>
  );
};
