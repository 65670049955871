import { useContext, useCallback, useLayoutEffect, useMemo } from 'react';
import { SceneContext } from '../SceneContext';
import { itemDefinitions } from '../definitions/item-definitions';

export const useActions = (target, { loading = false } = {}) => {
  const {
    moving,
    setMoving,
    pathEditMode,
    selection,
    scale,
    editor,
    getItem,
    updateItem,
    setSelectedItem,
    triggerEvent,
    itemSelectMode,
    setItemSelectMode,
    getAbsolutePosition,
    itemRefs,
    itemStyle,
    getGroupId,
    dynamicLayout,
    moveLayer,
    getItemDefinitionProperty,
    items,
    setItemLoading,
  } = useContext(SceneContext);
  const id = (target && getGroupId(target)) || target;
  const item = getItem(target);

  const onMouseEnter = useCallback(() => {
    if (pathEditMode || moving) {
      return;
    }
    updateItem(id, { hover: true });
    // triggerEvent(id, 'mouseenter');
  }, [id, moving, pathEditMode, updateItem]);

  const onMouseLeave = useCallback(() => {
    if (pathEditMode || moving) {
      return;
    }
    updateItem(id, { hover: false });
    // triggerEvent(id, 'mouseleave');
  }, [id, moving, pathEditMode, updateItem]);

  const onClick = useCallback(
    e => {
      if (!editor) {
        if (triggerEvent(id, 'click')) {
          e.stopPropagation();
        }
      }
    },
    [editor, id, triggerEvent],
  );

  const keepAspectRatio = getItemDefinitionProperty(id, 'keepAspectRatio');
  const editable = getItemDefinitionProperty(id, 'editable');

  const editorMouseDown = useCallback(
    (eventType, anchor = false) => e => {
      if (!editor || pathEditMode) {
        return true;
      }
      const { type, position, parent, editing } = getItem(id);

      if (editing && eventType === 'move' && !anchor) {
        return true;
      }

      e.preventDefault();
      e.stopPropagation();

      const target = e.target;
      if (target.setCapture) {
        target.setCapture();
      }

      const { dynamicLayout: dynamicLayoutDefinition } = itemDefinitions[type] || {};
      const usingDynamicLayout = dynamicLayout && dynamicLayoutDefinition !== false;

      let moving = true;

      const initialPosition = { ...position };

      if (usingDynamicLayout) {
        initialPosition.x = 0;
        initialPosition.y = 0;
        initialPosition.width = 0;
        initialPosition.height = 0;

        const { x, y, width, height } = getAbsolutePosition(id);

        initialPosition.xDelta = x;
        initialPosition.yDelta = y;
        initialPosition.widthDelta = width;
        initialPosition.heightDelta = height;
      }
      const itemPosition = { ...initialPosition };

      const { width: parentWidth, height: parentHeight } = getAbsolutePosition(parent) || {};
      const { pageX: initialPageX, pageY: initialPageY } = e;

      let nextIndex;

      const onMouseMove = e => {
        const { editing } = getItem(id);

        if (editing && eventType === 'move' && !anchor) {
          moving = false;
        }

        if (!moving) {
          return true;
        }

        e.preventDefault();
        e.stopPropagation();

        const { pageX, pageY } = e;
        const deltaX = (pageX - initialPageX) / scale;
        const deltaY = (pageY - initialPageY) / scale;

        const aspectRatio = itemPosition.widthDelta / itemPosition.heightDelta || 1;

        if (eventType === 'move') {
          if (anchor) {
            itemPosition.x = initialPosition.x + deltaX / parentWidth;
            itemPosition.y = initialPosition.y + deltaY / parentHeight;
          } else {
            itemPosition.xDelta = initialPosition.xDelta + deltaX;
            itemPosition.yDelta = initialPosition.yDelta + deltaY;
          }
        }
        if (eventType === 'wResize' || eventType === 'nwResize' || eventType === 'swResize') {
          if (anchor) {
            const delta = Math.min(
              Math.max(deltaX / parentWidth, -initialPosition.x),
              initialPosition.width,
            );
            itemPosition.x = initialPosition.x + delta;
            itemPosition.width = initialPosition.width - delta;
          } else {
            const delta = Math.min(
              initialPosition.widthDelta + initialPosition.width * parentWidth,
              deltaX,
            );
            itemPosition.xDelta = initialPosition.xDelta + deltaX;
            itemPosition.widthDelta = initialPosition.widthDelta - delta;
          }
        }
        if (eventType === 'eResize' || eventType === 'neResize' || eventType === 'seResize') {
          if (anchor) {
            itemPosition.width = Math.min(
              Math.max(initialPosition.width + deltaX / parentWidth, -itemPosition.x),
              1 - itemPosition.x,
            );
          } else {
            itemPosition.widthDelta = initialPosition.widthDelta + deltaX;
          }
        }
        if (eventType === 'nResize' || eventType === 'nwResize' || eventType === 'neResize') {
          if (anchor) {
            const delta = Math.min(
              Math.max(deltaY / parentHeight, -initialPosition.y),
              initialPosition.height,
            );
            itemPosition.y = initialPosition.y + delta;
            itemPosition.height = initialPosition.height - delta;
          } else {
            const delta = Math.min(
              initialPosition.heightDelta + initialPosition.height * parentHeight,
              deltaY,
            );
            itemPosition.yDelta = initialPosition.yDelta + deltaY;
            itemPosition.heightDelta = initialPosition.heightDelta - delta;
          }
        }
        if (eventType === 'sResize' || eventType === 'swResize' || eventType === 'seResize') {
          if (anchor) {
            itemPosition.height = Math.min(
              Math.max(initialPosition.height + deltaY / parentHeight, -itemPosition.y),
              1 - itemPosition.y,
            );
          } else {
            itemPosition.heightDelta = initialPosition.heightDelta + deltaY;
          }
        }

        if (keepAspectRatio) {
          if (
            eventType === 'wResize' ||
            eventType === 'eResize' ||
            (eventType !== 'nResize' &&
              eventType !== 'sResize' &&
              itemPosition.widthDelta > itemPosition.heightDelta)
          ) {
            itemPosition.heightDelta = itemPosition.widthDelta * (1 / aspectRatio);
          } else {
            itemPosition.widthDelta = itemPosition.heightDelta * aspectRatio;
          }
        }

        itemPosition.width = Math.max(0, itemPosition.width);
        itemPosition.height = Math.max(0, itemPosition.height);
        itemPosition.widthDelta = Math.max(
          usingDynamicLayout ? 0 : -itemPosition.width * parentWidth,
          itemPosition.widthDelta,
        );
        itemPosition.heightDelta = Math.max(
          usingDynamicLayout ? 0 : -itemPosition.height * parentHeight,
          itemPosition.heightDelta,
        );

        if (!selection.current[id]) {
          selection.current[id] = {};
        }

        selection.current[id].x = itemPosition.x - initialPosition.x;
        selection.current[id].y = itemPosition.y - initialPosition.y;
        selection.current[id].xDelta = itemPosition.xDelta - initialPosition.xDelta;
        selection.current[id].yDelta = itemPosition.yDelta - initialPosition.yDelta;
        selection.current[id].width = itemPosition.width - initialPosition.width;
        selection.current[id].height = itemPosition.height - initialPosition.height;
        selection.current[id].widthDelta = itemPosition.widthDelta - initialPosition.widthDelta;
        selection.current[id].heightDelta = itemPosition.heightDelta - initialPosition.heightDelta;

        let moveIndex;
        let currentIndex;
        if (usingDynamicLayout) {
          currentIndex = items.findIndex(({ id: otherId }) => id === otherId);
          moveIndex = items.findIndex(({ id: otherId }) => {
            if (id === otherId) {
              return false;
            }
            const position = getAbsolutePosition(otherId);
            return (
              itemPosition.yDelta + itemPosition.heightDelta / 2 < position.y + position.height / 2
            );
          });
          nextIndex = moveIndex !== -1 ? Math.max(moveIndex - 2, -2) + 1 : items.length - 1;
          if (nextIndex === currentIndex) {
            nextIndex = undefined;
          }
        }

        setMoving(moving => ({
          id: id,
          position: itemPosition,
          showDropzone: eventType === 'move' && !anchor,
          index: nextIndex !== undefined ? nextIndex - 1 : undefined,
          showPlaceholder: moving?.showPlaceholder,
        }));

        if (nextIndex !== undefined && moveIndex > currentIndex - 1) {
          nextIndex -= 1;
        }

        return false;
      };
      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove, false);
        window.removeEventListener('mouseup', onMouseUp, false);
        moving = false;
        delete selection.current[id];
        setMoving(null);
        if (!usingDynamicLayout) {
          updateItem(id, { position: { ...itemPosition } });
        } else {
          updateItem(id, {
            position: {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              widthDelta: itemPosition.widthDelta,
              heightDelta: itemPosition.heightDelta,
            },
          });
        }
        if (eventType === 'move' && !anchor && usingDynamicLayout && nextIndex !== undefined) {
          moveLayer(id, nextIndex + 1);
        }
        if (target.releaseCapture) {
          target.releaseCapture();
        }
      };
      window.addEventListener('mousemove', onMouseMove, false);
      window.addEventListener('mouseup', onMouseUp, false);

      return false;
    },
    [
      dynamicLayout,
      editor,
      getAbsolutePosition,
      getItem,
      id,
      items,
      keepAspectRatio,
      moveLayer,
      pathEditMode,
      scale,
      selection,
      setMoving,
      updateItem,
    ],
  );

  const selectionOnMouseDown = useMemo(
    () => ({
      move: editorMouseDown('move'),
      nwResize: editorMouseDown('nwResize'),
      nResize: editorMouseDown('nResize'),
      neResize: editorMouseDown('neResize'),
      swResize: editorMouseDown('swResize'),
      sResize: editorMouseDown('sResize'),
      seResize: editorMouseDown('seResize'),
      wResize: editorMouseDown('wResize'),
      eResize: editorMouseDown('eResize'),
    }),
    [editorMouseDown],
  );

  const anchorOnMouseDown = useMemo(
    () => ({
      move: editorMouseDown('move', true),
      nwResize: editorMouseDown('nwResize', true),
      nResize: editorMouseDown('nResize', true),
      neResize: editorMouseDown('neResize', true),
      swResize: editorMouseDown('swResize', true),
      sResize: editorMouseDown('sResize', true),
      seResize: editorMouseDown('seResize', true),
      wResize: editorMouseDown('wResize', true),
      eResize: editorMouseDown('eResize', true),
    }),
    [editorMouseDown],
  );

  const onMouseDown = useCallback(
    e => {
      if (!editor || pathEditMode) {
        return;
      }
      if (itemSelectMode?.callback) {
        itemSelectMode.callback(id);
        setItemSelectMode(null);
      } else {
        setSelectedItem(id);
        selectionOnMouseDown.move(e);
      }
      e.stopPropagation();
    },
    [
      editor,
      id,
      itemSelectMode,
      pathEditMode,
      selectionOnMouseDown,
      setItemSelectMode,
      setSelectedItem,
    ],
  );

  const onDoubleClick = useCallback(() => {
    if (editable) {
      updateItem(id, { editing: true });
    }
  }, [editable, id, updateItem]);

  useLayoutEffect(() => {
    const oldStyle = {};
    const modifiedStyles = new Set();

    let running = true;

    const frame = () => {
      if (!running) {
        return;
      }

      const element = itemRefs[id];
      const animatedStyle = itemStyle.current[id];
      if (element && animatedStyle) {
        const { style } = element;

        const modifiedNow = new Set(Object.keys(animatedStyle));
        modifiedNow.forEach(key => {
          if (oldStyle[key] === undefined) {
            oldStyle[key] = style[key];
          }
          style[key] = animatedStyle[key];
          modifiedStyles.add(key);
          delete animatedStyle[key];
        });

        modifiedStyles.forEach((key, index, set) => {
          if (!modifiedNow.has(key) && oldStyle[key] !== undefined) {
            style[key] = oldStyle[key];
            oldStyle[key] = undefined;
            set.delete(key);
          }
        });
      }

      requestAnimationFrame(frame);
    };
    frame();

    return () => {
      running = false;
    };
  }, [id, itemRefs, itemStyle]);

  const hasEventHandlers = pathEditMode?.id !== `${id}_targetPosition`;

  useLayoutEffect(() => {
    setItemLoading(loading, target);
  }, [loading, setItemLoading, target]);

  const hasClickEvent = item?.events && item.events['click'];

  return {
    onMouseEnter: hasEventHandlers ? onMouseEnter : undefined,
    onMouseLeave: hasEventHandlers ? onMouseLeave : undefined,
    onClick: hasEventHandlers ? onClick : undefined,
    onMouseDown: hasEventHandlers ? onMouseDown : undefined,
    onDoubleClick: hasEventHandlers ? onDoubleClick : undefined,
    clickable: !editor && !!hasClickEvent,
    selectionOnMouseDown,
    anchorOnMouseDown,
  };
};
