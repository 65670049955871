// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

import { highlightOnKeyboardFocusMixinExtra } from '~/styles/utils/utils';

export const PopupWrapper = styled(motion.dialog)`
  position: fixed;
  bottom: 10%;
  background: white;
  z-index: 10000;
  border: none;
  border-radius: 12px;
  padding: 5em 0;
  width: 100%;
  width: 80vw;
  max-width: 500px;
  user-select: none;
  padding-top: 3em;
  padding-bottom: 0;

  :hover {
    cursor: pointer;
  }
`;

export const PopupBackdrop = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  background: rgba(0.5, 0.5, 0.5, 0.5);
`;

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  position: fixed;
  z-index: 1001;
`;

export const DragHandle = styled.div`
  background: white;
  width: 10%;
  top: -5em;
  height: 6px;
  width: 25%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const Button = styled.button`
  width: 100%;
  background: white;

  border: none;
  border-bottom: 1px solid gray;
  padding: 1em;

  ${highlightOnKeyboardFocusMixinExtra};

  &:last-of-type {
    border-bottom: none;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 2em;
  padding-bottom: 3em;
`;
