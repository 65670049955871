// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/filename-case */
import React from 'react';

export const BottomStatusBar = ({
  pageNumber,
  pagesCount,
  isContinueEnabled,
  continueButtonText,
  handleContinue,
}) => (
  <div className="player-course-page__nav-bar">
    <div className="player-course-page__progress-bar-container">
      <div
        className="player-course-page__progress-bar-column"
        style={{ width: `${(pageNumber / pagesCount) * 100}%` }}
      />
      <div className="player-course-page__progress-bar-text">
        {pageNumber} / {pagesCount}
      </div>
    </div>
    <div className="player-course-page__continue-button">
      <button
        className="btn btn-continue"
        disabled={!isContinueEnabled}
        onClick={handleContinue}>
        {continueButtonText}
      </button>
    </div>
  </div>
);
