import { useLayoutEffect, useState, useRef } from 'react';

export const useFrameSequence = ({
  frames,
  initialFrame = 0,
  framerate = 1,
  playing: initialPlaying,
  onFrame,
  onInterpolate,
}) => {
  const [playing, setPlaying] = useState(initialPlaying);
  const { current } = useRef({ currentFrame: null, startTime: null });

  const frameCount = frames?.length || 0;

  useLayoutEffect(() => {
    setPlaying(initialPlaying);
  }, [current, initialPlaying]);

  useLayoutEffect(() => {
    if (playing) {
      current.startTime = Date.now();
      current.currentFrame = null;
    } else {
      current.startTime = null;
    }
  }, [current, playing]);

  useLayoutEffect(() => {
    if (!frameCount) {
      return;
    }

    let running = true;

    const frame = () => {
      if (!running) {
        return;
      }
      if (playing && current.startTime) {
        const now = Date.now();
        const t = ((now - current.startTime) / 1000) * framerate;
        const frameIncrement = Math.floor(t);
        if (frameIncrement > 0) {
          current.startTime += (frameIncrement * 1000) / framerate;
          current.currentFrame =
            ((current.currentFrame || initialFrame) + frameIncrement) % frameCount;

          if (onFrame) {
            onFrame(frames[current.currentFrame], current.currentFrame);
          }
        }
        if (onInterpolate) {
          onInterpolate(
            frames[current.currentFrame],
            frames[(current.currentFrame + 1) % frameCount],
            t % 1,
            current.currentFrame,
          );
        }
      } else if (current.currentFrame === null) {
        current.currentFrame = initialFrame;
        if (onFrame) {
          onFrame(frames[current.currentFrame], current.currentFrame);
        }
        if (onInterpolate) {
          onInterpolate(
            frames[current.currentFrame],
            frames[(current.currentFrame + 1) % frameCount],
            0,
            current.currentFrame,
          );
        }
      }

      requestAnimationFrame(frame);
    };
    requestAnimationFrame(frame);

    return () => {
      running = false;
    };
  }, [current, framerate, frames, frameCount, initialFrame, onFrame, playing, onInterpolate]);

  return { playing, setPlaying };
};
