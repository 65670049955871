import {
  PAGE_ID_DESCRIPTION,
  PAGE_ID_LOADING,
  PAGE_ID_SPEED_COURSE,
  PAGE_ID_RESULTS,
  PAGE_ID_FINISHED,
  PAGE_ID_NO_PAGES,
} from '~/utils/course';
import { getDictionary } from '~/utils/dictionary';

export const getPage = (state, params = {}) => {
  if (!state.course.data) {
    return undefined;
  }
  const { pageId = state.course.data.activePageId } = params;
  return state.course.data.pages.find(page => page.id === pageId);
};

export const getPages = state => {
  if (!state.course.data) {
    return undefined;
  }
  return state.course.data.pages;
};

export const getResultsPage = state => {
  if (!state.course.data) {
    return undefined;
  }
  return state.course.data.resultsPage;
};

export const getPageCollectedData = (state, { pageId }) => {
  if (pageId === PAGE_ID_DESCRIPTION) {
    return null;
  }
  return state.course.collectedData[pageId !== undefined ? pageId : state.course.data.activePageId];
};

export const getPageComponentCollectedData = (state, { pageId, componentDataKey }) => {
  if (pageId === PAGE_ID_DESCRIPTION) {
    return null;
  }
  return state.course.data[pageId][componentDataKey];
};

export const getSpeedCoursePagesCollectedData = state =>
  state.course.data.pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE) !== -1
    ? state.course.data.pages
        .slice(
          state.course.data.pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE),
          state.course.data.pages.length,
        )
        .reduce(
          (pagesCollectedData, page) => ({
            ...pagesCollectedData,
            [page.id]: state.course.collectedData[page.id],
          }),
          {},
        )
    : undefined;

export const getActivePage = state =>
  state.course.data &&
  state.course.data.pages.find(page => page.id === state.course.data.activePageId);

export const getActivePageIndex = state =>
  state.course.data &&
  state.course.data.pages.findIndex(page => page.id === state.course.data.activePageId);

export const getAllSlides = state => getActivePage(state)?.pictureslides?.slides || [];

export const getSlide = state =>
  getAllSlides(state).find(slide => slide.id === state.course.data?.activeSlideId);

export const getSpeedCourseIndex = state =>
  state.course.data && state.course.data.pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE);

export const getWinningStreak = state => state.course.winningStreak;

export const getActiveSlideIndex = state =>
  getAllSlides(state).findIndex(({ id }) => id === getSlide(state)?.id);

export const getActivePageTimeToReadQuestionSec = state => {
  if (!state.course.data) {
    return undefined;
  }
  const activePage = getActivePage(state);

  if (!activePage || !(activePage.questionnaire || activePage.picturesQuestionnaire)) {
    return undefined;
  }

  if (activePage.questionnaire && activePage.questionnaire.timeBounds.customTimeBounds) {
    return activePage.questionnaire.timeBounds.timeToReadQuestionSec;
  }

  if (
    activePage.picturesQuestionnaire &&
    activePage.picturesQuestionnaire.timeBounds.customTimeBounds
  ) {
    return activePage.picturesQuestionnaire.timeBounds.timeToReadQuestionSec;
  }

  return state.course.data.speedCourseSettings.timeToReadQuestionSec;
};

export const getActivePageTimeToAnswerQuestionSec = state => {
  if (!state.course.data) {
    return undefined;
  }
  const activePage = getActivePage(state);

  if (!activePage || !(activePage.questionnaire || activePage.picturesQuestionnaire)) {
    return undefined;
  }

  if (activePage.questionnaire && activePage.questionnaire.timeBounds.customTimeBounds) {
    return activePage.questionnaire.timeBounds.timeToAnswerQuestionSec;
  }

  if (
    activePage.picturesQuestionnaire &&
    activePage.picturesQuestionnaire.timeBounds.customTimeBounds
  ) {
    return activePage.picturesQuestionnaire.timeBounds.timeToAnswerQuestionSec;
  }

  return state.course.data.speedCourseSettings.timeToAnswerQuestionSec;
};

const getQuestionnaireResult = ({
  answers = [],
  selectedAnswers = new Set(),
  texts = {},
  redirections = {},
}) =>
  answers
    .map(answer => answer.isCorrect === selectedAnswers.has(answer.id))
    .every(result => result === true)
    ? {
        isCorrect: true,
        header: getDictionary().ANSWER_CORRECT,
        text: texts.textWhenGood,
        nextPageId: redirections.onSuccessPageId,
      }
    : {
        isCorrect: false,
        header: getDictionary().ANSWER_WRONG,
        text: texts.textWhenWrong,
        nextPageId: redirections.onFailurePageId,
      };

export const getPageQuestionnairesResults = (state, params = {}) => {
  if (!state.course.data) {
    return {};
  }
  const { pageId = state.course.data.activePageId } = params;
  if ([PAGE_ID_LOADING, PAGE_ID_DESCRIPTION].includes(pageId)) {
    return null;
  }
  return state.course.data[pageId]
    ? Object.entries(state.course.data[pageId])
        .map(([componentDataKey, { selectedAnswers }]) => ({
          componentDataKey,
          selectedAnswers: new Set(selectedAnswers),
          ...getPage(state, { pageId })[componentDataKey],
        }))
        .reduce(
          (questionnairesResults, { componentDataKey, ...questionnaireResultParams }) => ({
            ...questionnairesResults,
            [componentDataKey]: getQuestionnaireResult(questionnaireResultParams),
          }),
          {},
        )
    : {};
};

export const getNextPageId = state => {
  if (!state.course.data) {
    return undefined;
  }
  const {
    course: {
      data: { pages = [], activePageId, resultsPage },
    },
  } = state;

  const firstPage = pages.length ? pages[0].id : PAGE_ID_NO_PAGES;
  switch (activePageId) {
    case PAGE_ID_LOADING:
    case PAGE_ID_DESCRIPTION:
      return firstPage;

    case PAGE_ID_RESULTS:
    case PAGE_ID_NO_PAGES:
      return PAGE_ID_FINISHED;

    case pages[pages.length - 1].id:
      return resultsPage.disabled ? PAGE_ID_FINISHED : PAGE_ID_RESULTS;

    case undefined: // course ended
      return undefined;

    default: {
      const pageQuestionnairesResults = Object.entries(getPageQuestionnairesResults(state)).map(
        ([, result]) => result,
      );
      const id =
        pageQuestionnairesResults.length && pageQuestionnairesResults[0].nextPageId
          ? pageQuestionnairesResults[0].nextPageId
          : pages[getActivePageIndex(state) + 1].id;
      return id;
    }
  }
};

export const getPrevPageId = state => {
  if (!state.course.data) {
    return undefined;
  }
  const {
    course: {
      data: { pages = [], activePageId, descriptionPage },
    },
  } = state;

  const firstPage = pages.length ? pages[0].id : PAGE_ID_NO_PAGES;

  const currPageIndex = getActivePageIndex(state);

  // if we are at the frist page, go to the description page if we have any
  if (currPageIndex === 0) {
    return descriptionPage.disabled ? firstPage : PAGE_ID_DESCRIPTION;
  }

  // if we are the description page or loading, no need to go back
  if (activePageId === PAGE_ID_LOADING || activePageId === PAGE_ID_DESCRIPTION) {
    return firstPage;
  }

  // course ended
  if (activePageId === undefined) {
    return undefined;
  }

  if (!pages[currPageIndex - 1]) {
    return undefined;
  }

  return pages[currPageIndex - 1].id;
};

export const getActivePageInitTimestamp = state => {
  if (
    state.course.data &&
    [PAGE_ID_LOADING, PAGE_ID_DESCRIPTION].includes(state.course.data.activePageId)
  ) {
    return null;
  }
  return state.course.data
    ? state.course.pagesInitTimestamps[state.course.data.activePageId]
    : null;
};

export const getRedirectPageId = state => {
  const {
    course: {
      data: { pages, activePageId },
    },
  } = state;

  const selectedPage = pages.find(page => page.id === activePageId);
  if (selectedPage) {
    return selectedPage.redirectPageId;
  }
  return null;
};

export const isSpeedCoursePage = (pageId, { pages }) => {
  if ((!pageId && pageId !== 0) || !Array.isArray(pages)) {
    throw new Error('invalid arguments');
  }
  const pageIndex = pages.findIndex(page => page.id === pageId);
  if (pageIndex === -1) {
    return false;
  }
  const speedCourseIntroPageIndex = pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE);
  if (speedCourseIntroPageIndex === -1) {
    return false;
  }
  return speedCourseIntroPageIndex <= pageIndex;
};
