import React from 'react';
import PropTypes from 'prop-types';
import { getDictionary } from '~/utils/dictionary';
import { FilePreview } from '~/modules/Player/components/FilePreview';
import { getFilePreviewFilePropType } from '~/utils/types';
import { highlightOnKeyboardFocusMixinExtra } from '~/styles/utils/utils';
import { css } from '@emotion/core';
import { CloseButton } from '../components/CoursePage/TopStatusBar/styles';

export const CourseCover = ({ cover, description, onContinueClick, title, isSpeedCourseCover }) => (
  <div className="player-course-details__container">
    {cover && (
      <div className="player-course-details__cover">
        <FilePreview cover file={cover} />
      </div>
    )}
    <div className="player-course-details__content-wrapper">
      <div className="player-course-details__content-container">
        <div className="player-course-details__title">{title}</div>
        <div className="player-course-details__subtitle">{description}</div>
      </div>
    </div>
    <CloseButton
      onClick={onContinueClick}
      css={css`
        ${highlightOnKeyboardFocusMixinExtra};
        border-radius: 89px;
        align-self: center;
        margin-bottom: 4em;
        font-size: 1.1em;
        padding: 1.3em 2em;
        margin-top: 0;
        width: auto;
      `}>
      {getDictionary()[isSpeedCourseCover ? 'PLAYER_START_SPEED_COURSE' : 'PLAYER_START']}
    </CloseButton>
  </div>
);

CourseCover.propTypes = {
  cover: getFilePreviewFilePropType(),
  description: PropTypes.string,
  onContinueClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  isSpeedCourseCover: PropTypes.bool,
};

CourseCover.defaultProps = {
  title: '',
  description: '',
  cover: undefined,
  isSpeedCourseCover: false,
};
