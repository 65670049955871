export const alignOptions = [
  {
    text: 'Topp venstrestilt',
    value: 'top left',
  },
  {
    text: 'Topp midtstilt',
    value: 'top center',
  },
  {
    text: 'Topp høyrestilt',
    value: 'top right',
  },
  {
    text: 'Venstrestilt',
    value: 'center left',
  },
  {
    text: 'Midtstilt',
    value: 'center',
  },
  {
    text: 'Høyrestilt',
    value: 'center right',
  },
  {
    text: 'Bunn venstrestilt',
    value: 'bottom left',
  },
  {
    text: 'Bunn midtstilt',
    value: 'bottom center',
  },
  {
    text: 'Bunn høyrestilt',
    value: 'bottom right',
  },
];
