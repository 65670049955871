import { css } from '@emotion/core';
import { darken } from 'polished';

export const wrapper = css`
  display: flex;
  flex: 0 0 auto;
  left: 30px;
  top: 60px;
  position: fixed;
  flex-direction: column;
`;

export const actionBtn = css(`
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
    align-items: center;
    color: white;
    background-color: #40424C;
    height: 48px;
    width: 48px;
    font-size: 1em;
    border-radius: 16px;
    &:hover {
      background-color: ${darken(0.1, '#40424C')
    };
  `,
);

export const actionBtnActive = css`
 &:hover {
    background-color:#007AFF;
 }
 background-color: blue;
`;

export const actionBtnSmall = css`
  height: 20px;
  width: 20px;
  font-size: 1.5em;
  border-radius: 50%;
`;

export const actionBtnMain = css`
  background-color: '#0090E7';
  :hover {
    background-color: ${darken(0.1, '#0090E7')};
  }
`;


export const menuRowWrapper = css({
  position: 'absolute',
  width: 100,
  marginLeft: 150,
  height: 50,
  borderWidth: 1,
});

export const menuBtn = css({
  width: 36,
  height: 36,
  paddingTop: 7,
  marginTop: 7,
  marginLeft: 10,
  fontSize: 14,
  textAlign: 'center',
  backgroundColor: '#007AFF',
  borderRadius: 40,
});
