import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { acceptedImageFormats } from '~/config';
import { getFilePreviewFilePropType } from '~/utils/types';
import { FilePreview } from '~/modules/Editor/components/FilePreview';

export class Picture extends Component {
  constructor(props) {
    super(props);
    this.uploadPicture = this.uploadPicture.bind(this);
  }

  uploadPicture([file]) {
    this.props.uploadPicture(file);
  }

  render() {
    const {
      data: { file, isVisible },
    } = this.props;
    if (!isVisible) {
      return false;
    }
    return (
      <div className="picture-editor__container">
        <div className="picture-editor__image-container">
          {(!file && (
            <Dropzone
              className="picture-editor__dropzone"
              onDrop={this.uploadPicture}
              accept={acceptedImageFormats.map(ext => `image/${ext}`).join(', ')}>
              {() => 'Drop your file or click here to upload.'}
            </Dropzone>
          )) ||
            (file === 'UPLOADING' && (
              <div className="picture-editor__image-file-status">Uploading picture...</div>
            )) || (
              <div className="picture-editor__image">
                <FilePreview
                  file={file}
                  onDelete={() => this.props.deletePicture()}
                  asElement
                  height={300}
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

Picture.propTypes = {
  data: PropTypes.shape({
    file: getFilePreviewFilePropType(),
  }).isRequired,
  deletePicture: PropTypes.func.isRequired,
  uploadPicture: PropTypes.func.isRequired,
};
