import React, { useContext, useCallback, useMemo } from 'react';
import { toolbarButtonStyle } from '../styles';
import { SceneContext } from '../SceneContext';
import { faEyeDropper, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import randomColor from 'randomcolor';
import { ToolbarButton } from './ToolbarButton';
import { maxStringLength } from '../../../utils/max-string-length';
import { useHighlightEditorItems } from '../hooks/use-highlight-editor-items';

export const ToolbarItemPicker = ({ value, label, setValue }) => {
  const { setItemSelectMode, getItem } = useContext(SceneContext);

  const onClick = useCallback(() => {
    setItemSelectMode({ callback: id => setValue(id) });
  }, [setItemSelectMode, setValue]);

  const selectedItemName = getItem(value)?.name;

  const hightlightedItems = useMemo(() => [value], [value]);
  useHighlightEditorItems(hightlightedItems);

  const onClear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  return (
    <div
      css={css`
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
      `}>
      <div
        role="button"
        tabIndex="0"
        onClick={onClick}
        css={css(
          toolbarButtonStyle,
          css`
            flex: 1 1 auto;
          `,
        )}>
        <div
          css={css`
            flex: 1 1 auto;
            margin-right: 1em;
          `}>
          <div>{label}</div>
          {value && (
            <div
              css={css`
                font-size: 0.85em;
                color: ${randomColor({ luminosity: 'light', seed: value })};
              `}>
              {selectedItemName || `${maxStringLength(6, value)}`}
            </div>
          )}
        </div>
        <div
          css={css`
            flex: 0 0 auto;
            margin-left: auto;
          `}>
          <FontAwesomeIcon icon={faEyeDropper} />
        </div>
      </div>
      {value && <ToolbarButton icon={faTimes} onClick={onClear} />}
    </div>
  );
};
