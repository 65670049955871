import React from 'react';
import PropTypes from 'prop-types';
import { getDictionary } from '~/utils/dictionary';
import { Wrapper, Header, Description, CloseButton, ContentWrapper } from './styles/courseresults';
import { TopStatusBar } from '../components/CoursePage/TopStatusBar/TopStatusBar';
import { css } from '@emotion/core';
import { topStatusBarStyle } from './styles/coursepage';
import { highlightOnKeyboardFocusMixinExtra } from '~/styles/utils/utils';

export const CourseResults = ({ onFinishClick }) => (
  <Wrapper>
    <TopStatusBar
      noCloseButton
      finishedMode
      css={css`
        ${topStatusBarStyle};
      `}
      colorScheme={'light'}></TopStatusBar>

    <ContentWrapper>
      <Header>{getDictionary().COURSE_RESULT}</Header>
      <Description>{getDictionary().PLAYER_FINALIZE}</Description>
      <CloseButton
        onClick={onFinishClick}
        css={css`
          ${highlightOnKeyboardFocusMixinExtra};
        `}>
        {getDictionary().CLOSE}
      </CloseButton>
    </ContentWrapper>
  </Wrapper>
);

CourseResults.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  result: PropTypes.shape({
    text: PropTypes.string,
  }),
};

CourseResults.defaultProps = {
  result: {},
};
