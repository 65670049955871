import React, { useEffect, useContext, useRef, useCallback } from 'react';
import { css } from '@emotion/core';
import { useActions } from '../hooks/use-actions';
import { usePosition } from '../hooks/use-position';
import { SceneContext } from '../SceneContext';
import { ContentEditable } from '../../../components/ContentEditable';
import { maxStringLength } from '../../../utils/max-string-length';
import { sizeOptions } from '../fields/size-options';
import { flexAlignOptions } from './TextElement';

export const InfoModal = ({
  className,
  id,
  item: {
    editing,
    data: {
      text,
      bold,
      italic,
      size,
      padding,
      align = 'left',
      color,
      bgColor,
      unorderedList,
      showCloseButton,
    },
  },
}) => {
  const { editor, itemRefs, updateItem } = useContext(SceneContext);
  const { style } = usePosition(id);
  const { onClick, onMouseEnter, onMouseLeave, onMouseDown, onDoubleClick } = useActions(id);
  const init = useRef(false);

  const textboxRef = useRef(null);

  const onChange = useCallback(
    value => {
      const data = {
        name: `Infoboks: ${maxStringLength(16, value.split('\n')[0])}`,
        data: { text: value },
      };
      updateItem(id, data);
    },
    [id, updateItem],
  );

  useEffect(() => {
    // Reset text if it gets deselected with no text so we don't lose it
    //   alternatively we could just remove the element from the scene
    if (editor && !editing && !text) {
      const text = 'Sample Text';
      updateItem(id, {
        name: text,
        data: {
          text,
        },
      });
    }
  }, [editing, editor, id, text, updateItem]);

  useEffect(() => {
    if (editing && itemRefs[id]) {
      itemRefs[id].focus();
      if (!init.current) {
        // Select all text when created with {editing: true}.
        // Remove if-statement to always select all text when editing.
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(itemRefs[id]);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
    init.current = true;
  }, [editing, id, itemRefs]);

  const close = useCallback(() => {
    updateItem(id, { hidden: true });
  }, [updateItem, id]);

  return (
    <div
      className={className}
      ref={current => {
        itemRefs[id] = current;
      }}
      css={css(style, {
        overflow: 'visible',
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}>
      <div
        css={css(
          css`
            display: flex;
            max-height: 100%;
            flex-direction: column;
            border-radius: 10px;
            padding: 20px;
          `,
          bgColor &&
            css`
              background: ${bgColor};
            `,
        )}>
        <div
          css={css`
            overflow: auto;
          `}>
          <ContentEditable
            className={className}
            editable={editing}
            editing={editing}
            multiline
            css={css(
              css`
                display: flex;
                overflow: visible;
                justify-content: ${flexAlignOptions[align]};
                line-height: 1;
                font-size: ${(sizeOptions.find(({ value }) => value === size) ?? sizeOptions[1])
                  ?.size};
                text-align: ${align};
              `,
              padding &&
                css`
                  padding: ${padding.map(p => p + 'px ')};
                `,
              color &&
                css`
                  color: ${color};
                `,
              bold &&
                css`
                  font-weight: bold;
                `,
              italic &&
                css`
                  font-style: italic;
                `,
            )}
            ref={textboxRef}
            onChange={onChange}
            unorderedList={unorderedList}
            content={text}
            tabIndex={editor ? '0' : '-1'}
            hover={false}
          />
        </div>
        {showCloseButton && (
          <div
            css={css`
              display: flex;
              padding-top: 20px;
            `}>
            <div
              css={css`
                margin: 0 auto;
                padding: 10px 16px;
                border-radius: 5px;
                background: #cc3059;
                color: #fff;
                cursor: pointer;
                font-weight: bold;

                :hover {
                  background: #c9224e;
                }
              `}
              role="button"
              tabIndex="0"
              onClick={close}>
              Lukk
            </div>
          </div>
        )}
      </div>
      <div
        css={css`
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 0;
          width: 1.25em;
          height: 1.25em;
          transform: translate(50%, -50%);
          line-height: 0;
          background: #ddd none repeat scroll 0% 0%;
          border: 2px solid #fff;
          border-radius: 50%;
          text-align: center;
          color: #444;
          font-size: 1.5em;
          cursor: pointer;
        `}
        role="button"
        tabIndex="0"
        onClick={close}>
        &times;
      </div>
    </div>
  );
};
