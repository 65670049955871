import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCoursePagePropType } from '~/utils/types';
import { Sections } from '~/modules/Player/components/Sections';
import { PointsColumn } from '~/modules/Player/components/PointsColumn';

export class SpeedCoursePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentOfTimeToAnswerLeft: 100,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    this.timerIntervalID = setInterval(() => {
      const { pageInitTimestamp, timeToReadQuestionSec, timeToAnswerQuestionSec } = this.props;
      const elapsedTimeOnPageMs = Number(Date.now()) - pageInitTimestamp;
      let percentOfTimeToAnswerLeft = 0;
      if (elapsedTimeOnPageMs < timeToReadQuestionSec * 1000) {
        percentOfTimeToAnswerLeft = 100;
      } else if (elapsedTimeOnPageMs < (timeToReadQuestionSec + timeToAnswerQuestionSec) * 1000) {
        const timeLeftToAnswerMs =
          timeToAnswerQuestionSec * 1000 - (elapsedTimeOnPageMs - timeToReadQuestionSec * 1000);
        const timeForAnsweringQuestionMs = timeToAnswerQuestionSec * 1000;
        percentOfTimeToAnswerLeft = (timeLeftToAnswerMs / timeForAnsweringQuestionMs) * 100;
      }
      this.setState({
        percentOfTimeToAnswerLeft,
      });
    }, 50);
  }

  componentWillUnmount() {
    clearInterval(this.timerIntervalID);
  }

  handleContinue() {
    const { onContinueClick } = this.props;

    onContinueClick();
  }

  render() {
    const { page, pageNumber, pagesCount, winningStreak, setSectionCollectedData } = this.props;

    const { percentOfTimeToAnswerLeft } = this.state;

    return (
      <div className="player-course-page__wrapper">
        <div className="player-course-page__content-container">
          <div className="player-course-page__content-wrapper">
            <div className="player-course-page__content">
              <div className="player-course-page__points-bar">
                <PointsColumn winningStreak={winningStreak} progress={percentOfTimeToAnswerLeft} />
              </div>
              <div className="player-course-page__sections">
                {page.title && <div className="player-course-page__title">{page.title}</div>}
                <Sections
                  key={page.id}
                  page={page}
                  setSectionCollectedData={setSectionCollectedData}
                  isQuestionnaireVisible={percentOfTimeToAnswerLeft < 100}
                  onSectionsPermitContinue={() => {
                    setTimeout(() => {
                      // waiting for questionnairesResults to appear in store
                      this.handleContinue();
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="player-course-page__nav-bar">
          <div className="player-course-page__progress-bar-container">
            <div
              className="player-course-page__progress-bar-column"
              style={{ width: `${(pageNumber / pagesCount) * 100}%` }}
            />
            <div className="player-course-page__progress-bar-text">
              {pageNumber} / {pagesCount}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SpeedCoursePage.propTypes = {
  page: getCoursePagePropType().isRequired,
  pageNumber: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  winningStreak: PropTypes.number.isRequired,
  pageInitTimestamp: PropTypes.number.isRequired,
  timeToReadQuestionSec: PropTypes.number,
  timeToAnswerQuestionSec: PropTypes.number,
  setSectionCollectedData: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
};

SpeedCoursePage.defaultProps = {
  timeToReadQuestionSec: 2,
  timeToAnswerQuestionSec: 10,
};
