import React, { useContext, useState, useEffect } from 'react';
import { SceneContext } from './SceneContext';
import { toolbarButtons } from './definitions/toolbar-buttons';
import { itemDefinitions } from './definitions/item-definitions';
import { FieldContext } from './FieldContext';

export const ToolbarFields = ({
  fields: toolbarFields,
  id, // Item ID (optional if not relevant)
  getValue: getFieldValue,
  setValue: setFieldValue,
  showAdvanced = false,
  omit,
  context: propsContext,
}) => {
  const context = useContext(SceneContext);

  const [hotkeys, setHotkeys] = useState({});
  const [fields, setFields] = useState(null);

  useEffect(() => {
    const hotkeys = {};

    const item = context?.getItem(id);
    const commonContext = { item, id };
    if (context) {
      Object.assign(commonContext, context);
    }
    if (propsContext) {
      Object.assign(commonContext, propsContext);
    }

    const fieldMap = {};

    const fields = toolbarFields.map(field => {
      const newField = { ...commonContext, id, field, fields: fieldMap };

      const setValue = value => {
        if (field.setValue) {
          return field.setValue(value, newField);
        }
        return value;
      };

      newField.setValue = value => {
        const formattedValue = setValue(value);
        if (setFieldValue) {
          setFieldValue(formattedValue, newField);
        }
      };

      const getValue = value => {
        if (field.getValue) {
          return field.getValue(value, newField);
        }
        return value;
      };

      const fieldValue = getValue(getFieldValue(newField));
      newField.value = fieldValue !== undefined ? fieldValue : field.defaultValue;

      newField.onClick = () => {
        if (field.onClick) {
          field.onClick(newField);
        }
      };

      if (field.hotkey) {
        hotkeys[field.hotkey] = newField.onClick;
      }

      if (field.name) {
        fieldMap[field.name] = newField;
      }
      newField.fields = fieldMap;

      return newField;
    }, {});

    commonContext.fields = fieldMap;

    fields.forEach(newField => {
      newField.icon =
        typeof newField.field.icon === 'function'
          ? newField.field.icon(newField)
          : newField.field.icon;
      newField.label =
        typeof newField.field.label === 'function'
          ? newField.field.label(newField)
          : newField.field.label;
      newField.suffixLabel =
        typeof newField.field.suffixLabel === 'function'
          ? newField.field.suffixLabel(newField)
          : newField.field.suffixLabel;
    });

    setFields(fields);
    setHotkeys(hotkeys);
  }, [context, getFieldValue, id, propsContext, setFieldValue, toolbarFields]);

  useEffect(() => {
    if (!window.Mousetrap) {
      return undefined;
    }

    Object.entries(hotkeys).forEach(([hotkey, onClick]) => {
      window.Mousetrap.bind(hotkey, () => onClick());
    });

    return () => {
      Object.keys(hotkeys).forEach(hotkey => {
        window.Mousetrap.unbind(hotkey);
      });
    };
  }, [hotkeys]);

  return (
    fields?.map((field, index) => {
      const {
        value,
        setValue,
        onClick,
        fields,
        label,
        icon,
        field: {
          hideLabel,
          type,
          visible,
          name,
          options,
          advanced,
          suffixLabel,
          precision,
          step,
          factor,
          minValue,
          maxValue,
        },
      } = field;

      const Component = toolbarButtons[type]?.component;

      const item = context?.getItem(id);
      if (!Component || (!showAdvanced && advanced) || omit?.includes(name)) {
        return null;
      }

      const itemDefinition = itemDefinitions[item?.type];

      if (visible === false || (typeof visible === 'function' && !visible(field))) {
        return null;
      }

      return (
        <FieldContext.Provider key={name ? `_${name}` : index} value={field}>
          <Component
            id={id}
            type={type}
            icon={icon}
            label={label}
            suffixLabel={suffixLabel}
            precision={precision}
            step={step}
            factor={factor}
            minValue={minValue}
            maxValue={maxValue}
            hideLabel={hideLabel}
            options={options}
            onClick={onClick}
            item={item}
            itemDefinition={itemDefinition}
            name={name}
            value={value}
            setValue={setValue}
            fields={fields}
          />
        </FieldContext.Provider>
      );
    }) || null
  );
};
