import { svgPathProperties as SvgPathProperties } from 'svg-path-properties';
import { easingFunctions } from '../../../utils/easing-functions';
import { directionOptions } from '../fields/direction-options';
import { fadeOptions } from '../fields/fade-options';
import {
  faEyeSlash,
  faEye,
  faRoute,
  faGhost,
  faTachometerAltSlow,
  faUndo,
  faEdit,
  faStop,
  faPlay,
  faRepeat,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';

const showAction = (show, context) => ({
  onCreate: show && (({ target, updateItem }) => target && updateItem(target, { hidden: true })),
  onRemove: show && (({ target, updateItem }) => target && updateItem(target, { hidden: false })),
  label: ({ fadeIn, slideIn, direction, template, target }) => {
    const showHide = show ? 'Vis element' : 'Skjul element';

    if (template) {
      if (target) {
        return 'Visnings-effekt';
      }
      return showHide;
    }

    const inOut = show ? 'inn' : 'ut';
    const fromTo = show ? 'fra' : 'til';
    const slideDirection =
      directionOptions.find(({ value }) => value === direction)?.text.toLowerCase() || '';

    return (
      (slideIn && direction && `Slide ${inOut} ${fromTo} ${slideDirection}`) ||
      (fadeIn && `Fade ${inOut}`) ||
      `${showHide}`
    );
  },
  fields: [
    {
      name: 'target',
      label: 'Velg element',
      type: 'target',
    },
    {
      icon: faGhost,
      name: 'fadeIn',
      label: 'Fade-effekt',
      type: 'boolean',
      defaultValue: true,
    },
    {
      icon: faRoute,
      name: 'slideIn',
      label: `Slide-${show ? 'inn' : 'ut'} effekt`,
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'direction',
      label: `Slide inn ${show ? 'fra' : 'mot'}`,
      visible: ({
        fields: {
          slideIn: { value },
        },
      }) => value,
      type: 'select',
      options: directionOptions,
      defaultValue: 'left',
    },
    {
      name: 'duration',
      label: 'Effekt varighet',
      visible: ({
        fields: {
          fadeIn: { value: fadeIn },
          slideIn: { value: slideIn },
        },
      }) => fadeIn || slideIn,
      defaultValue: 1000,
      type: 'time',
    },
    {
      name: 'individualElements',
      label: 'Animer punkter hver for seg',
      visible: ({
        fields: {
          target: { value: target },
          fadeIn: { value: fadeIn },
          slideIn: { value: slideIn },
        },
      }) => context.getItem(target)?.data?.unorderedList && (fadeIn || slideIn),
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'durationPerElement',
      label: 'Effekt varighet per punkt',
      visible: ({
        fields: {
          target: { value: target },
          fadeIn: { value: fadeIn },
          slideIn: { value: slideIn },
          individualElements: { value: individualElements },
        },
      }) =>
        context.getItem(target)?.data?.unorderedList && (fadeIn || slideIn) && individualElements,
      defaultValue: 1000,
      type: 'time',
    },
    ...(show
      ? [
          {
            icon: ({ item }) => (item.hidden ? faEyeSlash : faEye),
            name: 'hideItem',
            label: `Element starter skjult`,
            type: 'boolean',
            getValue: (value, { item }) => item.hidden,
            setValue: (hidden, { id }) => {
              context.updateItem(id, { hidden });
              return hidden;
            },
          },
        ]
      : []),
  ],
  allowAutorun: show,
  process: ({
    next,
    id,
    data: { target, direction, duration, fadeIn, slideIn, individualElements, durationPerElement },
  }) => {
    if (duration && (fadeIn || slideIn)) {
      const item = context.getItem(target);
      const animateIndividualElements =
        individualElements && item?.data?.unorderedList && durationPerElement;
      const listElements =
        animateIndividualElements && context.itemRefs[target].querySelectorAll('li');
      const totalDuration = animateIndividualElements
        ? Math.max(durationPerElement * listElements.length + 1, duration)
        : duration;

      let destination;
      let fromPosition;
      if (slideIn && direction) {
        destination = context.getAbsolutePosition(target);
        fromPosition = { ...destination };
        if (direction === 'left') {
          fromPosition.x = -fromPosition.width * (fadeIn ? 0.5 : 1);
        } else if (direction === 'right') {
          fromPosition.x = context.sceneSize.width - fromPosition.width * (fadeIn ? 0.5 : 1);
        } else if (direction === 'top') {
          fromPosition.y = -fromPosition.height * (fadeIn ? 0.5 : 1);
        } else if (direction === 'bottom') {
          fromPosition.y = context.sceneSize.height - fromPosition.height * (fadeIn ? 0.5 : 1);
        } else {
          console.error('Invalid direction specified', direction);
        }
      }

      const animateFrame = seek => {
        const t = Math.min(seek / duration, 1);
        const tAtIndex =
          animateIndividualElements &&
          (index =>
            Math.min(
              Math.max(
                (seek -
                  index * (duration / (listElements.length + 1)) -
                  Math.max(duration / (listElements.length + 1) - durationPerElement, 0)) /
                  durationPerElement,
                0,
              ),
              1,
            ));

        const style = { visibility: 'visible' };

        if (slideIn && direction) {
          const positionOffset = {
            x: (fromPosition.x - destination.x) * (show ? 1 - t : t),
            y: (fromPosition.y - destination.y) * (show ? 1 - t : t),
          };
          if (animateIndividualElements) {
            listElements.forEach((element, index) => {
              const t = tAtIndex(index);
              const positionOffset = {
                x: (fromPosition.x - destination.x) * (show ? 1 - t : t),
                y: (fromPosition.y - destination.y) * (show ? 1 - t : t),
              };
              const { style } = element;
              style.transform = `translate3d(${positionOffset.x}px, ${positionOffset.y}px, 0)`;
            });
          } else {
            style.transform = `translate3d(${positionOffset.x}px, ${positionOffset.y}px, 0)`;
          }

          const animateCallback = context.onItemAnimateCallback.current[target];
          if (animateCallback) {
            const { previousPoint, callback } = animateCallback;
            if (previousPoint && callback) {
              callback({
                x: positionOffset.x,
                y: positionOffset.y,
                xDelta: positionOffset.x - previousPoint.x,
                yDelta: positionOffset.y - previousPoint.y,
              });
            }
            animateCallback.previousPoint = positionOffset;
          }
        }

        if (fadeIn) {
          if (animateIndividualElements) {
            listElements.forEach((element, index) => {
              const t = tAtIndex(index);
              const { style } = element;
              style.opacity = show ? t : 1 - t;
            });
          } else {
            style.opacity = show ? t : 1 - t;
          }
        }

        context.animateStyle(style, target);
      };

      let playing = true;
      let startTime;
      const frame = () => {
        if (!playing || !context.runningSequences.current.has(id)) {
          return;
        }
        if (context.loading) {
          requestAnimationFrame(frame);
          return;
        }
        if (!startTime) {
          startTime = Date.now();
        }

        const now = Date.now();
        const seek = now - startTime;

        animateFrame(seek);

        if (seek <= totalDuration) {
          requestAnimationFrame(frame);
        } else {
          context.updateItem(target, { hidden: !show });
          playing = false;
          next();
        }
      };

      requestAnimationFrame(frame);
    } else {
      context.updateItem(target, { hidden: !show });
      next();
    }
  },
});

export const actionDefinitions = context => ({
  show: showAction(true, context),
  hide: showAction(false, context),
  animatePath: {
    label: 'Animasjon',
    fields: [
      {
        icon: faCheck,
        label: 'Ferdig',
        type: 'button',
        visible: ({ pathEditMode }) => pathEditMode && !pathEditMode.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(null);
        },
      },
      {
        name: 'target',
        label: 'Velg element',
        type: 'target',
      },
      {
        name: 'path',
        label: ({ value }) => `${value ? 'Endre' : 'Velg'} animasjonsbane`,
        type: 'path',
      },
      {
        icon: faEdit,
        label: 'Rediger valgt animasjonsbane',
        type: 'button',
        visible: ({
          pathEditMode,
          fields: {
            path: { value },
          },
        }) => value && !pathEditMode,
        onClick: ({ setPathEditMode, id, actionSequence, actionSequenceIndex }) => {
          setPathEditMode({
            id,
            actionSequence,
            actionSequenceIndex,
          });
        },
      },
      {
        icon: faPlay,
        label: 'Forhåndsvis',
        type: 'button',
        visible: ({
          pathEditMode,
          fields: {
            path: { value },
          },
        }) => value && pathEditMode && !pathEditMode.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(pathEditMode => ({
            ...pathEditMode,
            preview: true,
          }));
        },
      },
      {
        icon: faStop,
        label: 'Avslutt forhåndsvisning',
        type: 'button',
        visible: ({ pathEditMode }) => pathEditMode?.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(pathEditMode => ({
            ...pathEditMode,
            preview: false,
          }));
        },
      },
      {
        name: 'easing',
        type: 'select',
        icon: faTachometerAltSlow,
        options: [
          {
            text: 'Konstant hastighet',
            value: 'linear',
          },
          {
            text: 'Myk inn',
            value: 'easeIn',
          },
          {
            text: 'Myk ut',
            value: 'easeOut',
          },
          {
            text: 'Myk inn og ut',
            value: 'easeInOut',
          },
          {
            text: 'Frem og tilbake',
            value: 'pingPong',
          },
          {
            text: 'Myk frem og tilbake',
            value: 'pingPongSmooth',
          },
        ],
        defaultValue: 'linear',
      },
      {
        name: 'duration',
        label: 'Varighet',
        defaultValue: 3000,
        type: 'time',
      },
      {
        name: 'pathOffset',
        label: 'Startposisjon',
        defaultValue: 0,
        type: 'number',
        minValue: 0,
        maxValue: 1,
        factor: 100,
        suffixLabel: '%',
      },
      {
        name: 'loop',
        label: 'Loop',
        icon: faRepeat,
        type: 'boolean',
      },
      {
        name: 'reverse',
        label: 'Reverser',
        icon: faUndo,
        type: 'boolean',
      },
      {
        icon: faGhost,
        name: 'fadeIn',
        type: 'select',
        options: fadeOptions,
        defaultValue: null,
      },
      {
        name: 'animateAsync',
        advanced: true,
        label: 'Ikke vent til fullført',
        type: 'boolean',
      },
    ],
    allowAutorun: true,
    process: ({
      next,
      id,
      data: {
        target,
        path,
        easing = 'linear',
        pathOffset,
        duration,
        loop,
        reverse,
        fadeIn,
        animateAsync,
      },
    }) => {
      let playing = true;

      const pathProperties = new SvgPathProperties(path);
      const pathLength = pathProperties.getTotalLength();

      const animateFrame = seek => {
        const t = Math.min((seek + pathOffset * duration) / duration, 1);
        const currentLength =
          pathLength *
          (easingFunctions[easing] ?? easingFunctions.linear)((reverse ? 1 - t : t) % 1 || 0);
        const point = pathProperties.getPointAtLength(currentLength);

        const animateCallback = context.onItemAnimateCallback.current[target];
        if (animateCallback) {
          const { previousPoint, callback } = animateCallback;
          if (previousPoint && callback) {
            animateCallback.callback({
              x: point.x,
              y: point.y,
              xDelta: point.x - previousPoint.x,
              yDelta: point.y - previousPoint.y,
            });
          }
          animateCallback.previousPoint = point;
        }

        const style = { transform: `translate3d(${point.x}px, ${point.y}px, 0)` };
        if (fadeIn === 'fadeIn') {
          style.opacity = seek / duration;
        } else if (fadeIn === 'fadeOut') {
          style.opacity = 1 - seek / duration;
        }

        context.animateStyle(style, target);
      };

      let startTime;
      const frame = () => {
        if (!playing || !context.runningSequences.current.has(id)) {
          return;
        }
        if (context.loading) {
          requestAnimationFrame(frame);
          return;
        }
        if (!startTime) {
          startTime = Date.now();
        }

        const now = Date.now();
        const seek = now - startTime;

        animateFrame(seek);

        if (seek <= duration) {
          requestAnimationFrame(frame);
        } else if (loop) {
          startTime = now;
          requestAnimationFrame(frame);
        } else {
          playing = false;
          if (!animateAsync) {
            next();
          }
        }
      };
      requestAnimationFrame(frame);

      if (animateAsync) {
        next();
      }
    },
  },
  /*
  animatePosition: {
    label: 'Endre posisjon',
    fields: [
      {
        icon: faCheck,
        label: 'Ferdig',
        type: 'button',
        visible: ({ pathEditMode }) => pathEditMode && !pathEditMode.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(null);
        },
      },
      {
        name: 'target',
        label: 'Velg element',
        type: 'target',
      },
      {
        name: 'targetPosition',
        type: 'position',
        visible: false,
      },
      {
        icon: faEdit,
        label: 'Rediger målposisjon',
        type: 'button',
        onClick: ({ setPathEditMode, id, actionSequence, actionSequenceIndex }) => {
          setPathEditMode({
            id,
            actionSequence,
            actionSequenceIndex,
          });
        },
      },
      {
        name: 'path',
        label: ({ value }) => `${value ? 'Endre' : 'Velg'} animasjonsbane`,
        type: 'path',
      },
      {
        icon: faPlay,
        label: 'Forhåndsvis',
        type: 'button',
        visible: ({
          pathEditMode,
          fields: {
            path: { value },
          },
        }) => value && pathEditMode && !pathEditMode.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(pathEditMode => ({
            ...pathEditMode,
            preview: true,
          }));
        },
      },
      {
        icon: faStop,
        label: 'Avslutt forhåndsvisning',
        type: 'button',
        visible: ({ pathEditMode }) => pathEditMode?.preview,
        onClick: ({ setPathEditMode }) => {
          setPathEditMode(pathEditMode => ({
            ...pathEditMode,
            preview: false,
          }));
        },
      },
      {
        name: 'easing',
        type: 'select',
        icon: faTachometerAltSlow,
        options: [
          {
            text: 'Konstant hastighet',
            value: 'linear',
          },
          {
            text: 'Myk ut',
            value: 'easeOut',
          },
          {
            text: 'Myk inn',
            value: 'easeIn',
          },
          {
            text: 'Myk inn og ut',
            value: 'easeInOut',
          },
        ],
        defaultValue: 'linear',
      },
      {
        name: 'duration',
        label: 'Varighet',
        defaultValue: 3000,
        type: 'time',
      },
      {
        icon: faGhost,
        name: 'fadeIn',
        label: 'Fade inn',
        type: 'boolean',
      },
    ],
    allowAutorun: false,
    process: ({
      next,
      id,
      data: {
        target,
        path,
        easing = 'linear',
        pathOffset,
        duration,
        loop,
        reverse,
        fadeIn,
        animateAsync,
      },
    }) => {
      let playing = true;

      const pathProperties = new SvgPathProperties(path);
      const pathLength = pathProperties.getTotalLength();

      const animateFrame = seek => {
        if (!itemRefs[target]) {
          return;
        }

        const t = (seek + pathOffset * duration) / duration;
        const currentLength =
          pathLength *
          (easingFunctions[easing] ?? easingFunctions.linear)((reverse ? 1 - t : t) % 1 || 0);
        const point = pathProperties.getPointAtLength(currentLength);

        const animateCallback = onItemAnimateCallback.current[target];
        if (animateCallback) {
          const { previousPoint, callback } = animateCallback;
          if (previousPoint && callback) {
            animateCallback.callback({
              x: point.x,
              y: point.y,
              xDelta: point.x - previousPoint.x,
              yDelta: point.y - previousPoint.y,
            });
          }
          animateCallback.previousPoint = point;
        }

        const style = { transform: `translate3d(${point.x}px, ${point.y}px, 0)` };
        if (fadeIn === 'fadeIn') {
          style.opacity = seek / duration;
        } else if (fadeIn === 'fadeOut') {
          style.opacity = 1 - seek / duration;
        }

        animateStyle(style, target);
      };

      let startTime = Date.now();
      const frame = () => {
        if (!playing || !runningSequences.current.has(id)) {
          return;
        }

        const now = Date.now();
        const seek = now - startTime;

        animateFrame(seek);

        if (seek <= duration) {
          requestAnimationFrame(frame);
        } else if (loop) {
          startTime = now;
          requestAnimationFrame(frame);
        }
      };
      requestAnimationFrame(frame);

      setTimeout(() => {
        if (!loop) {
          playing = false;
        }
        if (!animateAsync) {
          next();
        }
      }, duration);

      if (animateAsync) {
        next();
      }
    },
  },
  */
  wait: {
    label: ({ duration }) =>
      duration
        ? `Vent ${Number((duration / 1000).toFixed(2))
            .toString()
            .replace('.', ',')} sekund${duration !== 1000 ? 'er' : ''}`
        : 'Vent x sekunder',
    fields: [
      {
        name: 'duration',
        label: 'Varighet',
        defaultValue: 1000,
        type: 'time',
      },
    ],
    allowAutorun: true,
    process: ({ id, next, data: { duration } }) => {
      let startTime;
      let playing = true;

      const frame = () => {
        if (!playing || !context.runningSequences.current.has(id)) {
          return;
        }
        if (context.loading) {
          requestAnimationFrame(frame);
          return;
        }
        if (!startTime) {
          startTime = Date.now();
        }

        const now = Date.now();
        const seek = now - startTime;

        if (seek <= duration) {
          requestAnimationFrame(frame);
        } else {
          playing = false;
          next();
        }
      };

      requestAnimationFrame(frame);
    },
  },
});
