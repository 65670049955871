export const directionOptions = [
  {
    text: 'Venstre',
    value: 'left',
  },
  {
    text: 'Høyre',
    value: 'right',
  },
  {
    text: 'Topp',
    value: 'top',
  },
  {
    text: 'Bunn',
    value: 'bottom',
  },
];
