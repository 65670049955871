/** @jsx jsx */
import { Container } from './styles';
import { ErrorBoundary } from 'react-error-boundary';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

export const Stack = ({ direction, justifyContent, alignItems, gap, children, ...props }) => {
  /**
   * Fallback error component
   * https://github.com/bvaughn/react-error-boundary
   */

  /**
   * @params {error, resetErrorBoundary}
   */
  const ErrorFallback = ({ error }) => (
    <div role="alert" css={{ backgroundColor: '#e53e3e' }}>
      <h1>
        <span role="img" aria-label="Collision">
          💥
        </span>
        CABOOM
        <span role="img" aria-label="Collision">
          💥
        </span>
      </h1>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      {/** <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Container
        direction={direction}
        justifyContent={justifyContent}
        alignItems={alignItems}
        gap={gap}
        {...props}>
        {children}
      </Container>
    </ErrorBoundary>
  );
};

Container.propTypes = {
  direction: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  gap: PropTypes.array || PropTypes.string,
};

Container.default = {
  direction: 'horizontal',
  justifyContent: 'initial',
  alignItems: 'initial',
  gap: null,
};
