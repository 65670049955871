import React, { useState, useContext, useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import { ToolbarText } from './ToolbarText';
import { AssetPreview } from './AssetPreview';
import { SceneContext } from '../SceneContext';
import { faSearch, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { SVGUniqueID } from 'react-svg-unique-id';
import { ToolbarButton } from './ToolbarButton';
import { onAssetUpload } from '../asset-upload';
import { assetDefinitions } from '../definitions/asset-definitions';

const PathPreview = ({ path }) => {
  const [strokeWidth, setStrokeWidth] = useState(null);
  const [viewBox, setViewBox] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;
    const { x, y, width, height } = element.getBBox();
    const size = Math.max(width, height);
    const svgSize = 120;
    const strokeWidth = (size / svgSize) * 2;
    const padding = strokeWidth * 1.5;

    setStrokeWidth(strokeWidth);
    setViewBox(`${x - padding} ${y - padding} ${width + padding * 2} ${height + padding * 2}`);
  }, [path]);

  return (
    <SVGUniqueID>
      <svg
        width="120"
        height="120"
        viewBox={viewBox}
        preserveAspectRatio="xMidYMid meet"
        css={css`
          flex: 0 0 auto;
        `}>
        <path
          stroke="#fff"
          strokeWidth={strokeWidth}
          fill="transparent"
          ref={ref}
          d={path}
          markerEnd="url(#arrow)"
        />
        <circle r={strokeWidth * 1.5} fill="#fff" stroke="transparent">
          <animateMotion dur="6s" repeatCount="indefinite" path={path} />
        </circle>
      </svg>
    </SVGUniqueID>
  );
};

export const AssetSelect = ({ type, setValue }) => {
  const [search, setSearch] = useState('');

  const { actionSequences } = useContext(SceneContext);

  const [assets, setAssets] = useState([]);
  const [onUpload, setOnUpload] = useState(undefined);

  useEffect(() => {
    if (!assetDefinitions[type]) {
      console.error('No asset definition for', type);
      return;
    }
    let assets = [];

    Object.entries(actionSequences).forEach(([id, { name, path }]) => {
      if (type === 'path' && path) {
        assets.push({
          key: id,
          name,
          value: path,
          preview: <PathPreview path={path} />,
        });
      }
    });

    assets = [
      ...assets,
      ...(assetDefinitions[type].defaultAssets?.map(asset => ({
        ...asset,
        preview: <PathPreview path={asset.value} />,
      })) || []),
    ];

    const existing = new Set();

    assets = assets.reduce((acc, asset) => {
      if (
        !existing.has(asset.value) &&
        (!search || asset.name?.toLowerCase().includes(search.toLowerCase()))
      ) {
        existing.add(asset.value);
        acc.push(asset);
      }
      return acc;
    }, []);

    setAssets(assets);
    setOnUpload(() => onAssetUpload(type, setValue));
  }, [actionSequences, search, setValue, type]);

  return (
    <div
      css={css`
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      `}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}>
        <ToolbarText icon={faSearch} value={search} setValue={setSearch} autoFocus />
        <ToolbarButton icon={faUpload} label="Last opp" onClick={onUpload} />
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fit, 120px);
          grid-gap: 10px;
          overflow-x: hidden;
          overflow-y: auto;
          overflow-y: overlay;
          max-height: 60vh;
        `}>
        {assets.map(asset => (
          <AssetPreview key={asset.key} asset={asset} setValue={setValue} />
        ))}
      </div>
    </div>
  );
};
