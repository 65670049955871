import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';

import SpeedIcon from '@material-ui/icons/AvTimer';
import DashboardIcon from '@material-ui/icons/Dashboard';

import {
  PAGE_ID_DESCRIPTION,
  PAGE_ID_FINISHED,
  PAGE_ID_LOADING,
  PAGE_ID_NO_PAGES,
  PAGE_ID_RESULTS,
  PAGE_ID_RUNTIME_ERROR,
  PAGE_ID_SPEED_COURSE,
  getTemplateTypeMiniatureUrl,
  SAVE_STATUS_UPLOADING,
  SAVE_STATUS_SAVED,
  SAVE_STATUS_FAILED,
  SAVE_STATUS_DIRTY,
} from '~/utils/course';
import { getCoursePagePropType, getFilePreviewFilePropType } from '~/utils/types';
import { connect } from 'react-redux';

import * as courseEditorActions from '~/stores/editor/actions/course.actions';
import { bindActionCreators } from 'redux';
import { createSlide } from './create-slide';

const PageDragHandle = SortableHandle(() => <i className="icon-reorder normal" />);

const SpeedCourseDragHandle = SortableHandle(({ activePageId, onPageClick, page, pageNumber }) => (
  <div
    className={classNames('sidebar__page-wrapper sidebar__speed-course-wrapper', {
      active: activePageId === page.id,
    })}
    onClick={onPageClick}
    tabIndex={pageNumber}
    style={{ paddingBottom: 20 }}
    role="button">
    <div className="sidebar__page-preview" role="button" tabIndex="0" style={{ paddingTop: 2 }}>
      <SpeedIcon style={{ color: '#d8d8d9' }} />
      <div style={{ position: 'absolute', top: 24, left: 1, fontSize: 9, color: 'gray' }}>
        Speed-course
      </div>
      <div className="sidebar__page-number">{pageNumber}</div>
      <div className="sidebar__page-drag-handle">
        <i className="icon-reorder normal"></i>
      </div>
    </div>
  </div>
));

const SortableSlideItem = SortableElement(
  ({ activeSlideId, onSlideClick, page, pageNumber /* , activePage */ }) => {
    const onClick = () => onSlideClick(activeSlideId);
    return (
      <div
        onClick={onClick}
        role="button"
        tabIndex={pageNumber}
        className={classNames('sidebar__page-wrapper', { active: activeSlideId === page.id })}>
        <div className="sidebar__page-preview">
          <FontAwesomeIcon icon={faLayerGroup} />
        </div>
      </div>
    );
  },
);

const SortableItem = SortableElement(
  ({
    activePageId,
    activeSlideId,
    onPageClick,
    onSlideClick,
    onSlideAdd,
    page,
    pageNumber,
    activePage,
  }) => {
    const onClick = () => onPageClick(page.id);
    const onSlide = id => onSlideClick(id);
    const addSlide = () => onSlideAdd(page.id);

    return page.id !== PAGE_ID_SPEED_COURSE ? (
      <>
        <div
          onClick={onClick}
          role="button"
          tabIndex={pageNumber}
          className={classNames('sidebar__page-wrapper', { active: activePageId === page.id })}>
          <div className="sidebar__page-preview">
            <div
              className="sidebar__page-icon"
              style={{
                backgroundImage: `url('${getTemplateTypeMiniatureUrl(page.templateTypeId)}')`,
              }}
            />
          </div>
          <div className="sidebar__page-details">
            <div className="sidebar__page-number">{pageNumber}</div>
            <div className="sidebar__page-text">
              {page.title && page.title.length >= 20 ? page.title.slice(0, 15) + '...' : page.title}
            </div>
          </div>
          <div className="sidebar__page-drag-handle">
            <PageDragHandle />
          </div>
        </div>
        {activePage &&
          page.id === activePageId &&
          activePage.templateTypeId === 'PICTURESLIDES-TEXT' && (
            <div className="sidebar__container__sub">
              <SortableSlideList
                activeSlideId={activeSlideId}
                onSlideClick={onSlide}
                activePage={activePage}
                pages={activePage.pictureslides.slides}
                useDragHandle
              />
              <div className="sidebar__add-new-slide">
                <button className="sidebar__add-page-button" onClick={addSlide} title="New nano">
                  <div className="shortcut">ctl + Ø</div>
                  Add Scene
                </button>
              </div>
            </div>
          )}
      </>
    ) : (
      <SpeedCourseDragHandle
        activePageId={activePageId}
        onClick={onPageClick}
        page={page}
        pageNumber={pageNumber}
      />
    );
  },
);

const SortableSlideList = SortableContainer(
  ({ activeSlideId, onSlideClick, pages, activePage }) => (
    <div className="sidebar__pages-list">
      {pages.map((page, index) => (
        <SortableSlideItem
          activeSlideId={activeSlideId}
          index={index}
          activePage={activePage}
          key={`item-${page.id}`}
          onSlideClick={() => onSlideClick(page.id)}
          page={page}
          pageNumber={index + 1}
          tabIndex={index + 1}
        />
      ))}
    </div>
  ),
);

const SortableList = SortableContainer(
  ({ activePageId, activeSlideId, onPageClick, onSlideClick, onSlideAdd, pages, activePage }) => (
    <div className="sidebar__pages-list">
      {pages.map((page, index) => (
        <SortableItem
          activePageId={activePageId}
          index={index}
          key={`item-${page.id}`}
          onPageClick={onPageClick}
          page={page}
          onSlideClick={onSlideClick}
          activeSlideId={activeSlideId}
          onSlideAdd={onSlideAdd}
          activePage={activePage}
          pageNumber={index + 1}
          tabIndex={index + 1}
        />
      ))}
    </div>
  ),
);

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.onAddPageClick = this.onAddPageClick.bind(this);
    this.onHomePageClick = this.onHomePageClick.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onSlideClick = this.onSlideClick.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onAddSlideClick = this.onAddSlideClick.bind(this);
  }

  onAddSlideClick() {
    const {
      updatePageData,
      setActiveSlideId,
      course: {
        data: { activePageId, pages },
      },
    } = this.props;
    const activePage = pages.filter(cp => cp.id === activePageId)[0];
    const data = activePage.pictureslides;

    const newSlide = createSlide();

    updatePageData({
      pictureslides: {
        ...data,
        slides: [...data.slides, newSlide],
      },
    });

    setActiveSlideId(newSlide.id);
  }

  onAddPageClick() {
    const { setAddPageModalOpen } = this.props;
    setAddPageModalOpen(true);
  }

  onHomePageClick() {
    this.props.setActivePageId(PAGE_ID_DESCRIPTION);
  }

  onPageClick(pageId) {
    this.props.setActivePageId(pageId);
  }

  onSlideClick(slideId) {
    this.props.setActiveSlideId(slideId);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const reorderedPages = arrayMove(this.props.course.data.pages, oldIndex, newIndex);
    this.props.reorderPages(reorderedPages);
    this.props.setActivePageId(reorderedPages[newIndex].id);
  }

  render() {
    if (!this.props.course.data) {
      return null;
    }

    const activePage = this.props.course.data.pages.filter(
      cp => cp.id === this.props.course.data.activePageId,
    )[0];
    return (
      <>
        <div className="sidebar__container">
          <div
            onClick={this.onHomePageClick}
            tabIndex={0}
            role="button"
            className={classNames('sidebar__page-wrapper', {
              active: this.props.course.data.activePageId === 'DESCRIPTION_PAGE',
            })}>
            <div className="sidebar__home-button-wrapper">
              <div className="sidebar__page-wrapper">
                <div className="sidebar__page-preview" role="button" tabIndex="0">
                  <div className="sidebar__page-icon">
                    <DashboardIcon style={{ color: 'black' }} />
                  </div>
                </div>
                <div className="sidebar__page-details">
                  <div className="sidebar__page-text">Dashboard</div>
                </div>
              </div>
              <div className="sidebar__page-details">
                <div className="sidebar__page-text">Dashboard</div>
              </div>
            </div>
          </div>
          <SortableList
            activePageId={this.props.course.data.activePageId}
            onPageClick={this.onPageClick}
            activePage={activePage}
            onSortEnd={this.onSortEnd}
            pages={this.props.course.data.pages}
            activeSlideId={this.props.course.data.activeSlideId}
            onSlideClick={this.onSlideClick}
            onSlideSortEnd={this.onSortEnd}
            onSlideAdd={this.onAddSlideClick}
          />
          <div className="sidebar__add-new-nano">
            <button
              className="sidebar__add-page-button"
              onClick={this.onAddPageClick}
              title="New nano">
              <div className="shortcut">ctl + Æ</div>
              Add Page
            </button>
          </div>
        </div>
        {activePage && false === true && activePage.templateTypeId === 'PICTURESLIDES-TEXT' && (
          <div className="sidebar__container__sub2">
            <SortableSlideList
              activeSlideId={this.props.course.data.activeSlideId}
              onSlideClick={this.onSlideClick}
              onSortEnd={this.onSortEnd}
              onAdd={this.addSlide}
              pages={activePage.pictureslides.slides}
            />
            <div className="sidebar__add-new-slide">
              <button
                className="sidebar__add-page-button"
                onClick={this.onAddSlideClick}
                title="New nano">
                <div className="shortcut">ctl + Æ</div>
                Add Scene
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

Sidebar.propTypes = {
  course: PropTypes.shape({
    data: PropTypes.shape({
      activePageId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([
          PAGE_ID_DESCRIPTION,
          PAGE_ID_FINISHED,
          PAGE_ID_LOADING,
          PAGE_ID_NO_PAGES,
          PAGE_ID_RESULTS,
          PAGE_ID_RUNTIME_ERROR,
          PAGE_ID_SPEED_COURSE,
        ]),
      ]),
      activeElement: PropTypes.shape({
        id: PropTypes.number,
        element: PropTypes.string,
      }),
      descriptionPage: PropTypes.shape({
        disabled: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
      }),
      resultsPage: PropTypes.shape({
        disabled: PropTypes.bool,
        textWhenPassed: PropTypes.string,
        textWhenFailed: PropTypes.string,
      }),
      speedCourseSettings: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
        pointsMaxScore: PropTypes.number,
        pointsPerAnswer: PropTypes.number,
        timeToReadQuestionSec: PropTypes.number,
        timeToAnswerQuestionSec: PropTypes.number,
      }),
      name: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(getCoursePagePropType()),
    }),
    id: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    saveStatus: PropTypes.oneOf([
      SAVE_STATUS_UPLOADING,
      SAVE_STATUS_SAVED,
      SAVE_STATUS_FAILED,
      SAVE_STATUS_DIRTY,
    ]),
  }).isRequired,
  activePageId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([
      PAGE_ID_DESCRIPTION,
      PAGE_ID_FINISHED,
      PAGE_ID_LOADING,
      PAGE_ID_NO_PAGES,
      PAGE_ID_RESULTS,
      PAGE_ID_RUNTIME_ERROR,
      PAGE_ID_SPEED_COURSE,
    ]),
  ]),
  addPage: PropTypes.func.isRequired,
  coursePages: PropTypes.arrayOf(getCoursePagePropType()),
  reorderPages: PropTypes.func.isRequired,
  setActivePageId: PropTypes.func.isRequired,
  setActiveSlideId: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  activePageId: undefined,
  activeSlideId: undefined,
};

const mapStateToProps = state => ({
  course: state.course,
});

const mapDispatchToProps = dispatch => bindActionCreators(courseEditorActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
