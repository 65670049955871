import styled from '@emotion/styled';
import { cover } from '~/utils/facepaint';

export const Header = styled('div')(
  {
    height: '100%',
    flexBasis: '100%', // fixes a bug in Chrome
  },
  props => ({
    backgroundColor: props.backgroundColor,
  }),
);

export const Question = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    '& h1': {
      color: 'white',
      fontSize: '3.25em',
      lineHeight: '1.05882353em',
      textAlign: 'center',
      padding: '0 10%',
      fontWeight: '600',
    },
    '& p': {
      fontSize: '1.5em',
      position: 'relative',
      top: '1.5em',
      padding: '0 2em',
    },
  },
  cover,
);

export const Description = styled('p')({
  color: 'white',
});
