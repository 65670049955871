import { SvgFigure } from '../scene-components/SvgFigure';
import { ActionDot } from '../scene-components/ActionDot';
import { TextElement } from '../scene-components/TextElement';
import { alignOptions } from '../fields/align-options';
import { InfoModal } from '../scene-components/InfoModal';
import { ImageElement } from '../scene-components/ImageElement';
import {
  faPencil,
  faCheck,
  faRedoAlt,
  faExpandArrowsAlt,
  faExpandWide,
  faTextSize,
  faExpand,
  faBold,
  faItalic,
  faListUl,
  faFont,
  faPalette,
  faCheese,
  faQuoteLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { optimizeSvg } from '../../../utils/optimize-svg';
import { sizeOptions } from '../fields/size-options';
import { createItem } from '../ItemScene';
import { nanoid } from 'nanoid';

export const itemDefinitions = {
  textElement: {
    label: 'Tekstelement',
    name: 'Sample Text',
    resizable: ({ dynamicLayout }) => !dynamicLayout,
    editable: true, // Enable double click to set editing prop to true, used for editing text.
    disableRename: true,
    position: {
      x: 0,
      y: 0,
      xDelta: 20,
      yDelta: 0,
      width: 1,
      height: 0.2,
      widthDelta: -40,
      heightDelta: 0,
    },
    fields: [
      {
        icon: faPencil,
        label: 'Rediger tekst',
        type: 'button',
        hideLabel: true,
        hotkey: 'enter',
        visible: ({ item: { editing } }) => !editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: true });
        },
      },
      {
        icon: faCheck,
        label: 'Ferdig',
        type: 'button',
        visible: ({ item: { editing } }) => editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: false });
        },
      },
      { type: 'separator' },
      {
        name: 'align',
        type: 'align',
        defaultValue: 'left',
      },
      {
        name: 'verticalAlign',
        type: 'verticalAlign',
        defaultValue: 'top',
        visible: ({ dynamicLayout }) => !dynamicLayout,
      },
      { type: 'separator' },
      {
        name: 'bold',
        icon: faBold,
        label: 'Fet',
        hideLabel: true,
        type: 'boolean',
      },
      {
        name: 'italic',
        icon: faItalic,
        label: 'Kursiv',
        hideLabel: true,
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'unorderedList',
        icon: faListUl,
        visible: ({ item: { editing } }) => !editing, // Cannot be changed while editing.
        label: 'Punktliste',
        hideLabel: true,
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'size',
        icon: faTextSize,
        label: 'Fontstørrelse',
        hideLabel: true,
        type: 'range',
        step: 1,
        minValue: 0,
        maxValue: sizeOptions.length - 1,
        defaultValue: 'm',
        getValue: value => sizeOptions.findIndex(({ value: sizeValue }) => sizeValue === value),
        setValue: index => sizeOptions[index].value,
      },
      // {
      //   name: 'family',
      //   icon: faTextSize,
      //   label: 'Font',
      //   hideLabel: true,
      //   type: 'family',
      //   step: 1,
      //   minValue: 0,
      //   maxValue: sizeOptions.length - 1,
      //   defaultValue: 'm',
      //   getValue: value => sizeOptions.findIndex(({ value: sizeValue }) => sizeValue === value),
      //   setValue: index => sizeOptions[index].value,
      // },
      {
        name: 'color',
        icon: faFont,
        label: 'Tekstfarge',
        hideLabel: true,
        defaultValue: '#000',
        type: 'color',
      },
      { type: 'separator' },
      {
        name: 'padding',
        icon: faExpand,
        label: 'Padding',
        hideLabel: true,
        defaultValue: [0, 0, 0, 0],
        type: 'padding',
      },
      {
        name: 'bgColor',
        icon: faPalette,
        label: 'Bakgrunnsfarge',
        hideLabel: true,
        defaultValue: 'rgba(0,0,0,0)',
        type: 'color',
      },
      {
        name: 'text',
        type: 'string',
        visible: false,
        defaultValue: 'Sample Text',
      },
    ],
    component: TextElement,
  },
  dot: {
    label: 'Klikkbart punkt',
    name: 'Punkt 1',
    resizable: true,
    editable: true,
    keepAspectRatio: ({
      item: {
        data: { type },
      },
    }) => type !== 'button',
    disableAnchorResize: ({
      item: {
        data: { type },
      },
    }) => type !== 'button',
    disableRename: true,
    dynamicLayout: false,
    position: {
      x: 0.5,
      y: 0.5,
      xDelta: 0,
      yDelta: 0,
      width: 0,
      height: 0,
      widthDelta: 40,
      heightDelta: 40,
    },
    fields: [
      {
        icon: faPencil,
        label: 'Rediger tekst',
        type: 'button',
        hideLabel: true,
        hotkey: 'enter',
        visible: ({ item: { editing } }) => !editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: true });
        },
      },
      {
        icon: faCheck,
        label: 'Ferdig',
        type: 'button',
        visible: ({ item: { editing } }) => editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: false });
        },
      },
      {
        name: 'type',
        label: 'Type',
        hideLabel: true,
        type: 'select',
        options: [
          {
            text: 'Punkt',
            value: 'dot',
          },
          {
            text: 'Knapp',
            value: 'button',
          },
        ],
        defaultValue: 'dot',
      },
      { type: 'separator' },
      {
        name: 'borderRadius',
        visible: ({
          item: {
            data: { type },
          },
        }) => type === 'button',
        icon: faCheese,
        label: 'Hjørneavrunding',
        hideLabel: true,
        defaultValue: 8,
        type: 'number',
      },
      {
        name: 'size',
        visible: ({
          item: {
            data: { type },
          },
        }) => type === 'button',
        icon: faTextSize,
        label: 'Fontstørrelse',
        hideLabel: true,
        type: 'range',
        step: 1,
        minValue: 0,
        maxValue: sizeOptions.length - 1,
        defaultValue: 'm',
        getValue: value => sizeOptions.findIndex(({ value: sizeValue }) => sizeValue === value),
        setValue: index => sizeOptions[index].value,
      },
      { type: 'separator' },
      {
        name: 'color',
        icon: faPalette,
        label: 'Bakgrunnsfarge',
        hideLabel: true,
        defaultValue: '#CF395D',
        type: 'color',
      },
      { type: 'separator' },
      {
        name: 'createModal',
        icon: faQuoteLeft,
        visible: ({ item, items }) =>
          !items.find(({ type, dependsOn }) => type === 'infoModal' && dependsOn === item.id),
        label: 'Vis infoboks ved klikk',
        onClick: ({ item, addItem, updateItem, createAction }) => {
          const infoModal = createItem('infoModal', {
            dependsOn: item.id,
            hidden: true,
          });
          addItem(infoModal);

          let actionSequence = item.events?.click?.actionSequence;
          if (!actionSequence) {
            actionSequence = nanoid();
            updateItem(item.id, { events: { click: { actionSequence } } });
          }

          createAction(actionSequence, 'show', { target: infoModal.id });
        },
        hideLabel: true,
        type: 'button',
      },
      {
        name: 'click',
        label: 'Sett Action ved klikk',
        type: 'event',
      },
      {
        name: 'text',
        type: 'string',
        visible: false,
        defaultValue: '1',
      },
    ],
    component: ActionDot,
  },
  svgFigure: {
    label: 'SVG figur',
    resizable: true,
    editable: false,
    position: {
      x: 0.25,
      y: 0.25,
      xDelta: 0,
      yDelta: 0,
      width: 0.5,
      height: 0.5,
      widthDelta: 0,
      heightDelta: 0,
    },
    fields: [
      {
        name: 'source',
        label: 'SVG kildekode',
        type: 'string',
        visible: false,
      },
      {
        name: 'align',
        type: 'select',
        options: alignOptions,
        defaultValue: 'center',
      },
      { type: 'separator' },
      {
        name: 'rotation',
        icon: faRedoAlt,
        label: 'Roter',
        defaultValue: 0,
        type: 'rotate',
        visible: ({ dynamicLayout }) => !dynamicLayout,
      },
      { type: 'separator' },
      {
        name: 'fill',
        icon: faExpandArrowsAlt,
        label: 'Fyll',
        type: 'boolean',
      },
      {
        name: 'stretch',
        icon: faExpandWide,
        label: 'Strekk',
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'click',
        label: 'Sett Action ved klikk',
        type: 'event',
      },
    ],
    optimize: async ({
      item: {
        id,
        data: { source },
      },
      updateItem,
    }) => {
      if (!source) {
        return;
      }
      const optimized = await optimizeSvg.optimize(source);
      updateItem(id, { data: { source: optimized.data } });
    },
    component: SvgFigure,
  },
  imageElement: {
    label: 'Bilde',
    resizable: true,
    editable: false,
    position: {
      x: 0.25,
      y: 0.25,
      xDelta: 0,
      yDelta: 0,
      width: 0.5,
      height: 0.5,
      widthDelta: 0,
      heightDelta: 0,
    },
    fields: [
      {
        name: 'source',
        label: 'Bilde URL',
        type: 'string',
        visible: false,
      },
      {
        name: 'align',
        type: 'select',
        options: alignOptions,
        defaultValue: 'center',
      },
      {
        name: 'rotation',
        icon: faRedoAlt,
        label: 'Roter',
        defaultValue: 0,
        type: 'rotate',
        visible: ({ dynamicLayout }) => !dynamicLayout,
      },
      {
        name: 'fill',
        icon: faExpandArrowsAlt,
        label: 'Fyll',
        type: 'boolean',
      },
      {
        name: 'stretch',
        icon: faExpandWide,
        label: 'Strekk',
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'padding',
        icon: faExpand,
        label: 'Padding',
        hideLabel: true,
        defaultValue: [0, 0, 0, 0],
        type: 'padding',
      },
      {
        name: 'click',
        label: 'Sett Action ved klikk',
        type: 'event',
      },
    ],
    component: ImageElement,
  },
  infoModal: {
    name: 'Infoboks',
    label: 'Infoboks',
    resizable: true,
    editable: true,
    dynamicLayout: false,
    position: {
      height: 0.4,
      heightDelta: 0,
      width: 0.4,
      widthDelta: 0,
      x: 0.3,
      xDelta: 0,
      y: 0.3,
      yDelta: 0,
    },
    fields: [
      {
        icon: faPencil,
        label: 'Rediger tekst',
        type: 'button',
        hideLabel: true,
        hotkey: 'enter',
        visible: ({ item: { editing } }) => !editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: true });
        },
      },
      {
        icon: faCheck,
        label: 'Ferdig',
        type: 'button',
        visible: ({ item: { editing } }) => editing,
        onClick: ({ id, updateItem }) => {
          updateItem(id, { editing: false });
        },
      },
      { type: 'separator' },
      {
        name: 'align',
        type: 'align',
        defaultValue: 'left',
      },
      { type: 'separator' },
      {
        name: 'bold',
        icon: faBold,
        label: 'Fet',
        hideLabel: true,
        type: 'boolean',
      },
      {
        name: 'italic',
        icon: faItalic,
        label: 'Kursiv',
        hideLabel: true,
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'unorderedList',
        icon: faListUl,
        visible: ({ item: { editing } }) => !editing, // Cannot be changed while editing.
        label: 'Punktliste',
        hideLabel: true,
        type: 'boolean',
      },
      { type: 'separator' },
      {
        name: 'size',
        icon: faTextSize,
        label: 'Fontstørrelse',
        hideLabel: true,
        type: 'range',
        step: 1,
        minValue: 0,
        maxValue: sizeOptions.length - 1,
        defaultValue: 'm',
        getValue: value => sizeOptions.findIndex(({ value: sizeValue }) => sizeValue === value),
        setValue: index => sizeOptions[index].value,
      },
      {
        name: 'color',
        icon: faFont,
        label: 'Tekstfarge',
        hideLabel: true,
        defaultValue: '#000',
        type: 'color',
      },
      { type: 'separator' },
      {
        name: 'padding',
        icon: faExpand,
        label: 'Padding',
        hideLabel: true,
        defaultValue: [0, 0, 0, 0],
        type: 'padding',
      },
      {
        name: 'bgColor',
        icon: faPalette,
        label: 'Bakgrunnsfarge',
        hideLabel: true,
        defaultValue: '#fff',
        type: 'color',
      },
      {
        name: 'text',
        type: 'string',
        visible: false,
        defaultValue: 'Sample Text',
      },
      {
        name: 'showCloseButton',
        label: 'Vis lukk-knapp',
        type: 'boolean',
      },
    ],
    component: InfoModal,
  },
};
