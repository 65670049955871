const PREFIX = '[Course]';

export const ADD_PAGE = `${PREFIX} ADD PAGE`;
export const DELETE_PAGE = `${PREFIX} DELETE PAGE`;
export const REORDER_PAGES = `${PREFIX} REORDER PAGES`;
export const SET_ACTIVE_PAGE_ID = `${PREFIX} SET ACTIVE PAGE ID`;
export const SET_ACTIVE_SLIDE_ID = `${PREFIX} SET ACTIVE SLIDE ID`;
export const SET_PAGE_DATA = `${PREFIX} UPDATE PAGE DATA`;
export const SET_SLIDE_DATA = `${PREFIX} UPDATE SLIDE DATA`;
export const PATCH_SPEED_COURSE_SETTINGS = `${PREFIX} UPDATE SPEED COURSE SETTINGS`;
export const SWITCH_SPEED_COURSE = `${PREFIX} ENABLE SPEED COURSE`;
export const TOGGLE_SETTINGS = `${PREFIX} TOGGLE SETTINGS`;
export const SCENE_START_PREVIEW = `${PREFIX} SCENE START PREVIEW`;
export const SCENE_END_PREVIEW = `${PREFIX} SCENE END PREVIEW`;

export const SET_COURSE_PAGE = `${PREFIX} SET COURSE PAGE`;
export const gotoPage = (payload = {}) => ({
  type: SET_COURSE_PAGE,
  payload,
});

export const sceneStartPreview = () => ({
  type: SCENE_START_PREVIEW,
});

export const sceneEndPreview = () => ({
  type: SCENE_END_PREVIEW,
});

export const toggleSettings = (payload = {}) => ({
  type: TOGGLE_SETTINGS,
  payload,
});

export const addPage = (payload = {}) => ({
  type: ADD_PAGE,
  payload,
});

export const deletePage = payload => ({
  type: DELETE_PAGE,
  payload,
});

export const reorderPages = (payload = []) => ({
  type: REORDER_PAGES,
  payload,
});

export const setActiveSlideId = payload => ({
  type: SET_ACTIVE_SLIDE_ID,
  payload,
});

export const setActivePageId = payload => ({
  type: SET_ACTIVE_PAGE_ID,
  payload,
});

export const SET_ACTIVE_ELEMENT_ID = `${PREFIX} SET ACTIVE ELEMENT ID`;
export const setActiveElementId = payload => ({
  type: SET_ACTIVE_ELEMENT_ID,
  payload,
});

export const updatePageData = payload => ({
  type: SET_PAGE_DATA,
  payload,
});

export const updateSlideData = payload => ({
  type: SET_SLIDE_DATA,
  payload,
});

export const patchSpeedCourseSettings = payload => ({
  type: PATCH_SPEED_COURSE_SETTINGS,
  payload,
});

export const switchSpeedCourse = () => ({
  type: SWITCH_SPEED_COURSE,
});

export const FETCH_COURSE_DATA = `${PREFIX} FETCH COURSE DATA`;
export const FETCH_COURSE_DATA_REQUEST = `${PREFIX} FETCH COURSE DATA REQUEST`;
export const FETCH_COURSE_DATA_SUCCESS = `${PREFIX} FETCH COURSE DATA SUCCESS`;
export const FETCH_COURSE_DATA_FAILURE = `${PREFIX} FETCH COURSE DATA FAILURE`;

export const fetchCourseData = () => ({
  type: FETCH_COURSE_DATA,
});

export const fetchCourseDataRequest = () => ({
  type: FETCH_COURSE_DATA_REQUEST,
});
export const fetchCourseDataSuccess = payload => ({
  type: FETCH_COURSE_DATA_SUCCESS,
  payload,
});
export const fetchCourseDataFailure = () => ({
  type: FETCH_COURSE_DATA_FAILURE,
});

export const SAVE_COURSE_DATA = `${PREFIX} SAVE COURSE DATA`;
export const SAVE_COURSE_DATA_REQUEST = `${PREFIX} SAVE COURSE DATA REQUEST`;
export const SAVE_COURSE_DATA_SUCCESS = `${PREFIX} SAVE COURSE DATA SUCCESS`;
export const SAVE_COURSE_DATA_FAILURE = `${PREFIX} SAVE COURSE DATA FAILURE`;

export const saveCourseData = () => ({
  type: SAVE_COURSE_DATA,
});

export const saveCourseDataRequest = () => ({
  type: SAVE_COURSE_DATA_REQUEST,
});

export const saveCourseDataSuccess = () => ({
  type: SAVE_COURSE_DATA_SUCCESS,
});

export const saveCourseDataFailure = () => ({
  type: SAVE_COURSE_DATA_FAILURE,
});

export const UPLOAD_CONTENT_FILE = `${PREFIX} UPLOAD CONTENT FILE`;
export const UPLOAD_CONTENT_FILE_REQUEST = `${PREFIX} UPLOAD CONTENT FILE REQUEST`;
export const UPLOAD_CONTENT_FILE_SUCCESS = `${PREFIX} UPLOAD CONTENT FILE SUCCESS`;
export const UPLOAD_CONTENT_FILE_FAILURE = `${PREFIX} UPLOAD CONTENT FILE FAILURE`;
export const DELETE_CONTENT_FILE = `${PREFIX} REMOVE CONTENT FILE`;

export const uploadContentFile = payload => ({
  type: UPLOAD_CONTENT_FILE,
  payload,
});

export const uploadContentFileRequest = () => ({
  type: UPLOAD_CONTENT_FILE_REQUEST,
});

export const uploadContentFileSuccess = payload => ({
  type: UPLOAD_CONTENT_FILE_SUCCESS,
  payload,
});

export const uploadContentFileFailure = () => ({
  type: UPLOAD_CONTENT_FILE_FAILURE,
});

export const deleteContentFile = () => ({
  type: DELETE_CONTENT_FILE,
});

export const UPLOAD_COVER_FILE = `${PREFIX} UPLOAD COVER FILE`;
export const UPLOAD_COVER_FILE_REQUEST = `${PREFIX} UPLOAD COVER FILE REQUEST`;
export const UPLOAD_COVER_FILE_SUCCESS = `${PREFIX} UPLOAD COVER FILE SUCCESS`;
export const UPLOAD_COVER_FILE_FAILURE = `${PREFIX} UPLOAD COVER FILE FAILURE`;
export const DELETE_COVER_FILE = `${PREFIX} REMOVE COVER FILE`;

export const uploadCoverFile = payload => ({
  type: UPLOAD_COVER_FILE,
  payload,
});

export const uploadCoverFileRequest = () => ({
  type: UPLOAD_COVER_FILE_REQUEST,
});

export const uploadCoverFileSuccess = payload => ({
  type: UPLOAD_COVER_FILE_SUCCESS,
  payload,
});

export const uploadCoverFileFailure = () => ({
  type: UPLOAD_COVER_FILE_FAILURE,
});

export const deleteCoverFile = () => ({
  type: DELETE_COVER_FILE,
});

export const UPLOAD_SPEED_COURSE_COVER_FILE = `${PREFIX} UPLOAD SPEED COURSE COVER FILE`;
export const UPLOAD_SPEED_COURSE_COVER_FILE_REQUEST = `${PREFIX} UPLOAD SPEED COURSE COVER FILE REQUEST`;
export const UPLOAD_SPEED_COURSE_COVER_FILE_SUCCESS = `${PREFIX} UPLOAD SPEED COURSE COVER FILE SUCCESS`;
export const UPLOAD_SPEED_COURSE_COVER_FILE_FAILURE = `${PREFIX} UPLOAD SPEED COURSE COVER FILE FAILURE`;
export const DELETE_SPEED_COURSE_COVER_FILE = `${PREFIX} REMOVE SPEED COURSE COVER FILE`;

export const uploadSpeedCourseCoverFile = payload => ({
  type: UPLOAD_SPEED_COURSE_COVER_FILE,
  payload,
});

export const uploadSpeedCourseCoverFileRequest = () => ({
  type: UPLOAD_SPEED_COURSE_COVER_FILE_REQUEST,
});

export const uploadSpeedCourseCoverFileSuccess = payload => ({
  type: UPLOAD_SPEED_COURSE_COVER_FILE_SUCCESS,
  payload,
});

export const uploadSpeedCourseCoverFileFailure = () => ({
  type: UPLOAD_SPEED_COURSE_COVER_FILE_FAILURE,
});

export const deleteSpeedCourseCoverFile = () => ({
  type: DELETE_SPEED_COURSE_COVER_FILE,
});

export const UPLOAD_PICTURE = `${PREFIX} UPLOAD PICTURE`;
export const UPLOAD_PICTURE_REQUEST = `${PREFIX} UPLOAD PICTURE REQUEST`;
export const UPLOAD_PICTURE_SUCCESS = `${PREFIX} UPLOAD PICTURE SUCCESS`;
export const UPLOAD_PICTURE_FAILURE = `${PREFIX} UPLOAD PICTURE FAILURE`;
export const DELETE_PICTURE = `${PREFIX} DELETE PICTURE`;

export const uploadPicture = payload => ({
  type: UPLOAD_PICTURE,
  payload,
});

export const uploadPictureRequest = payload => ({
  type: UPLOAD_PICTURE_REQUEST,
  payload,
});

export const uploadPictureSuccess = payload => ({
  type: UPLOAD_PICTURE_SUCCESS,
  payload,
});

export const uploadPictureFailure = payload => ({
  type: UPLOAD_PICTURE_FAILURE,
  payload,
});

export const deletePicture = payload => ({
  type: DELETE_PICTURE,
  payload,
});

export const UPLOAD_SLIDE_PICTURE = `${PREFIX} UPLOAD/SLIDE/PICTURE`;
export const UPLOAD_SLIDE_PICTURE_REQUEST = `${PREFIX}/UPLOAD/SLIDE/PICTURE/REQUEST`;
export const UPLOAD_SLIDE_PICTURE_SUCCESS = `${PREFIX}/UPLOAD/SLIDE/PICTURE/SUCCESS`;
export const UPLOAD_SLIDE_PICTURE_FAILURE = `${PREFIX}/UPLOAD/SLIDE/PICTURE/FAILURE`;
export const DELETE_SLIDE_PICTURE = `${PREFIX}/DELETE/SLIDE/PICTURE`;

export const uploadSlidePicture = payload => ({
  type: UPLOAD_SLIDE_PICTURE,
  payload,
});

export const uploadSlidePictureRequest = payload => ({
  type: UPLOAD_SLIDE_PICTURE_REQUEST,
  payload,
});

export const uploadSlidePictureSuccess = payload => ({
  type: UPLOAD_SLIDE_PICTURE_SUCCESS,
  payload,
});

export const uploadSlidePictureFailure = payload => ({
  type: UPLOAD_SLIDE_PICTURE_FAILURE,
  payload,
});

export const deleteSlidePicture = payload => ({
  type: DELETE_SLIDE_PICTURE,
  payload,
});

export const UPLOAD_VIDEO_SOURCE = `${PREFIX} UPLOAD VIDEO SOURCE`;
export const UPLOAD_VIDEO_SOURCE_REQUEST = `${PREFIX} UPLOAD VIDEO SOURCE REQUEST`;
export const UPLOAD_VIDEO_SOURCE_SUCCESS = `${PREFIX} UPLOAD VIDEO SOURCE SUCCESS`;
export const UPLOAD_VIDEO_SOURCE_FAILURE = `${PREFIX} UPLOAD VIDEO SOURCE FAILURE`;
export const DELETE_VIDEO_SOURCE = `${PREFIX} DELETE VIDEO SOURCE`;

export const uploadVideoSource = payload => ({
  type: UPLOAD_VIDEO_SOURCE,
  payload,
});

export const uploadVideoSourceRequest = payload => ({
  type: UPLOAD_VIDEO_SOURCE_REQUEST,
  payload,
});

export const uploadVideoSourceSuccess = payload => ({
  type: UPLOAD_VIDEO_SOURCE_SUCCESS,
  payload,
});

export const uploadVideoSourceFailure = payload => ({
  type: UPLOAD_VIDEO_SOURCE_FAILURE,
  payload,
});

export const deleteVideoSource = payload => ({
  type: DELETE_VIDEO_SOURCE,
  payload,
});

export const UPLOAD_VIDEO_POSTER = `${PREFIX} UPLOAD VIDEO POSTER`;
export const UPLOAD_VIDEO_POSTER_REQUEST = `${PREFIX} UPLOAD VIDEO POSTER REQUEST`;
export const UPLOAD_VIDEO_POSTER_SUCCESS = `${PREFIX} UPLOAD VIDEO POSTER SUCCESS`;
export const UPLOAD_VIDEO_POSTER_FAILURE = `${PREFIX} UPLOAD VIDEO POSTER FAILURE`;
export const DELETE_VIDEO_POSTER = `${PREFIX} DELETE VIDEO POSTER`;

export const uploadVideoPoster = payload => ({
  type: UPLOAD_VIDEO_POSTER,
  payload,
});

export const uploadVideoPosterRequest = payload => ({
  type: UPLOAD_VIDEO_POSTER_REQUEST,
  payload,
});

export const uploadVideoPosterSuccess = payload => ({
  type: UPLOAD_VIDEO_POSTER_SUCCESS,
  payload,
});

export const uploadVideoPosterFailure = payload => ({
  type: UPLOAD_VIDEO_POSTER_FAILURE,
  payload,
});

export const deleteVideoPoster = payload => ({
  type: DELETE_VIDEO_POSTER,
  payload,
});

export const UPLOAD_SLIDE_IMAGE = `${PREFIX} UPLOAD SLIDE IMAGE`;
export const UPLOAD_SLIDE_IMAGE_REQUEST = `${PREFIX} UPLOAD SLIDE IMAGE REQUEST`;
export const UPLOAD_SLIDE_IMAGE_SUCCESS = `${PREFIX} UPLOAD SLIDE IMAGE SUCCESS`;
export const UPLOAD_SLIDE_IMAGE_FAILURE = `${PREFIX} UPLOAD SLIDE IMAGE FAILURE`;
export const DELETE_SLIDE_IMAGE = `${PREFIX} DELETE SLIDE IMAGE`;

export const uploadSlideImage = payload => ({
  type: UPLOAD_SLIDE_IMAGE,
  payload,
});

export const uploadSlideImageRequest = payload => ({
  type: UPLOAD_SLIDE_IMAGE_REQUEST,
  payload,
});

export const uploadSlideImageSuccess = payload => ({
  type: UPLOAD_SLIDE_IMAGE_SUCCESS,
  payload,
});

export const uploadSlideImageFailure = payload => ({
  type: UPLOAD_SLIDE_IMAGE_FAILURE,
  payload,
});

export const deleteSlideImage = payload => ({
  type: DELETE_SLIDE_IMAGE,
  payload,
});

export const UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE = `${PREFIX} UPLOAD QUESTIONNAIRE ANSWER IMAGE`;
export const UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_REQUEST = `${PREFIX} UPLOAD QUESTIONNAIRE ANSWER IMAGE REQUEST`;
export const UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_SUCCESS = `${PREFIX} UPLOAD QUESTIONNAIRE ANSWER IMAGE SUCCESS`;
export const UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_FAILURE = `${PREFIX} UPLOAD QUESTIONNAIRE ANSWER IMAGE FAILURE`;
export const DELETE_QUESTIONNAIRE_ANSWER_IMAGE = `${PREFIX} DELETE QUESTIONNAIRE ANSWER IMAGE`;

export const uploadQuestionnaireAnswerImage = payload => ({
  type: UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE,
  payload,
});

export const uploadQuestionnaireAnswerImageRequest = payload => ({
  type: UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_REQUEST,
  payload,
});

export const uploadQuestionnaireAnswerImageSuccess = payload => ({
  type: UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_SUCCESS,
  payload,
});

export const uploadQuestionnaireAnswerImageFailure = payload => ({
  type: UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_FAILURE,
  payload,
});

export const deleteQuestionnaireAnswerImage = payload => ({
  type: DELETE_QUESTIONNAIRE_ANSWER_IMAGE,
  payload,
});

export const SET_DESCRIPTION_PAGE_DATA = `${PREFIX} SET DESCRIPTION PAGE SETTINGS`;
export const SET_SETTINGS_PAGE_DATA = `${PREFIX} SET SETTINGS PAGE SETTINGS`;
export const SET_RESULTS_PAGE_DATA = `${PREFIX} SET RESULTS PAGE SETTINGS`;

export const setSettingsPageData = payload => ({
  type: SET_SETTINGS_PAGE_DATA,
  payload,
});

export const setDescriptionPageData = payload => ({
  type: SET_DESCRIPTION_PAGE_DATA,
  payload,
});

export const setResultsPageData = payload => ({
  type: SET_RESULTS_PAGE_DATA,
  payload,
});

export const SET_SCREEN_SIZE = `${PREFIX} SET PAGE SIZE SETTINGS`;
export const setScreenSize = payload => ({
  type: SET_SCREEN_SIZE,
  payload,
});

export const ADD_ASSETS = `${PREFIX} ADD ASSETS`;
export const addAssets = payload => ({
  type: ADD_ASSETS,
  payload,
});
