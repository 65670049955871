import { css } from '@emotion/core';

import { rgba } from 'polished';

/**
 * * *********************************************************************************************
 * * Media Queries *******************************************************************************
 * * *********************************************************************************************
 */

const breakpoints = {
  small: 'min-width: 320px',
  medium: 'min-width: 751px',
  large: 'min-width: 1051px',
};

export const mq = {
  small: `@media(${breakpoints.small})`,
  medium: `@media(${breakpoints.medium})`,
  large: `@media(${breakpoints.large})`,
};

/**
 * Combine media queries using sizes
 * @param  {...any} args The sizes to combine, in the wanted order
 */
export const or = (...args) =>
  `${`@media${args.map(size => ` ${size.slice(6)},`).join('')}`.slice(0, -1)} `;

/**
 * * *********************************************************************************************
 * * Ratios **************************************************************************************
 * * *********************************************************************************************
 */

export const sizes = {
  ratio: {
    scaleFactor: {
      MINOR_SECOND: 1.067,
      MAJOR_SECOND: 1.125,
      MINOR_THIRD: 1.2,
      MAJOR_THIRD: 1.25,
      PERFECT_FOURTH: 1.333,
      AUGMENTED_FOURTH: 1.414,
      DIMENSIONAL_FIFTH: 1.414,
      PERFECT_FIFTH: 1.5,
      MINOR_SIXTH: 1.6,
      GOLDEN_SECTION: 1.618,
      MAJOR_SIXTH: 1.667,
      MINOR_SEVENTH: 1.778,
      MAJOR_SEVENTH: 1.875,
      OCTAVE: 2,
      MAJOR_TENTH: 2.5,
      MAJOR_ELEVENTH: 2.667,
      MAJOR_TWELFTH: 3,
      DOUBLE_OCTAVE: 4,
    },
    low: function () {
      return this.scaleFactor.MAJOR_THIRD;
    },
    medium: function () {
      return this.scaleFactor.PERFECT_FIFTH;
    },
    high: function () {
      return this.scaleFactor.MINOR_SEVENTH;
    },
  },
};

/**
 * * *********************************************************************************************
 * * Typography **********************************************************************************
 * * *********************************************************************************************
 */

export const measureCap = css({
  /**
   * The numbers of letters on a line can contribute to reader's fatigue,
   * so keep the line length to 45–75 characters per line.
   */
  maxWidth: '60ch',
});

/**
 * Returns font-size based on input values. Use the ratio enum to set ratio.
 * @param {number} base – Font base size.
 * @param {number} ratio – Proportion ratio.
 * @param {number} size – Relative size.
 */
export const getFontSize = (base, ratio, size) => {
  const fontSize = base * Math.pow(ratio, size);
  return Math.round(fontSize);
};

/**
 * All line heights should be even multiples of the baseline grid.
 *
 * Define baseline height, multiply by same ratio, then check
 * whether the result can be evenly divided by the grid.
 * If not, add or subtract the division remainder of the line height.
 */

/**
 * Absolute values are better than relative units for height.
 * Em square size does not necessarily equal to the bounding box
 * or content area of the font, which more accurately specifies how
 * much vertical space the font occupies.
 * * Obs! Relative units are font-size relative, not content-area relative.
 */

export const getLineHeight = (base, ratio, size, baselineGridSize) => {
  const lineHeight = Math.round(base * Math.pow(ratio, size));
  return lineHeight % baselineGridSize === 0
    ? lineHeight
    : lineHeight + (lineHeight % baselineGridSize);
};

export const generateTypeScale = (
  baseFontSize,
  fontRatio,
  baselineHeight,
  lineHeightRatio,
  baselineGridSize,
) => {
  const typeScale = [];
  for (let i = 1; i < 7; i++) {
    const fontSizeInPx = getFontSize(baseFontSize, fontRatio, i - 1),
      lineHeightInPx = getLineHeight(baselineHeight, lineHeightRatio, i - 1, baselineGridSize);
    const scale = {
      size: i,
      fontSizeInPx: fontSizeInPx + 'px',
      lineHeightInPx: lineHeightInPx + 'px',
      metrics: [fontSizeInPx, lineHeightInPx],
    };
    typeScale.push(scale);
  }

  return typeScale;
};

export const generateTypeScaleWithCSS = (
  baseFontSize,
  fontRatio,
  baselineHeight,
  lineHeightRatio,
  baselineGridSize,
) => {
  const typeScale = generateTypeScale(
    baseFontSize,
    fontRatio,
    baselineHeight,
    lineHeightRatio,
    baselineGridSize,
  );

  const scale0 = css({
    fontSize: typeScale[0].fontSizeInPx,
    lineHeight: typeScale[0].lineHeightInPx,
    marginBottom: `${typeScale[0].metrics[0] / 2}px`,
  });

  const scale1 = css({
    fontSize: typeScale[3].fontSizeInPx,
    lineHeight: typeScale[3].lineHeightInPx,
    marginBottom: `${typeScale[2].metrics[0] / 2}px`,
  });

  const scale2 = css({
    fontSize: typeScale[2].fontSizeInPx,
    lineHeight: typeScale[2].lineHeightInPx,
    marginBottom: `${typeScale[2].metrics[0] / 2}px`,
  });

  const scale3 = css({
    fontSize: typeScale[1].fontSizeInPx,
    lineHeight: typeScale[1].lineHeightInPx,
    marginBottom: `${typeScale[2].metrics[0] / 2}px`,
  });

  return [scale0, scale1, scale2, scale3];
};

/**
 * * *********************************************************************************************
 * * Mixins **********************************************************************************
 * * *********************************************************************************************
 */

// Displays a decorative border around the box, but does not change the size of the element
// and also does not handle pointer events(clicks etc)
export const highlightMixin = {
  content: `''`,
  position: 'absolute',
  display: 'block',
  top: -4,
  left: -4,
  right: -4,
  bottom: -4,
  /* Border-radius equal to its parent reders some wierd gaps, therefore this is set a bit higher*/
  borderRadius: 10,
  border: `4px solid ${rgba('#64D2FF', 1)}`,
  '-webkit-transition': 'border 0.25s ease',
  '-moz-transition': 'border 0.25s ease',
  transition: 'border 0.25s ease',
  zIndex: 9999,
  pointerEvents: 'none' /* Allow events to fall trough to*/,
};

export const cover = css({
  /**
   * * -moz-available
   * This doesn't work for 'height' in Firefox
   *
   * * -webkit-fill-available
   * This does work for 'height' in Chrome or Safari,
   * but Safari has a weird re-draw issue
   * (open and close dev-tools and you'll see).
   * */

  /** Changes: removed -webkit-fill-available from min-height  */
  minHeight: ['-moz-available'],
  flexBasis: '100%', // goes around a bug in Chrome
  height: '100%',
});
