import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import debounce from 'debounce';

import { FormInput } from '~/components/FormInput';

import { number, positiveNumber, required } from '~/utils/validators';
import { formatNumberInput, parseNumberInput } from '~/utils/helpers';

class PointsSection extends Component {
  constructor(props) {
    super(props);

    this.debounce = debounce;
  }

  pointsValidator(value, allValues) {
    return allValues.pointsEnabled ? positiveNumber(value) : undefined;
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="questionnaire-section-editor__settings-container" autoComplete="off">
        <div className="questionnaire-section-editor__settings-subsection">
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormInput}
              format={formatNumberInput}
              label="Points per correct answer"
              name="pointsPerAnswer"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              parse={parseNumberInput}
              type="number"
              validate={[required, number, this.pointsValidator]}
            />
          </div>
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormInput}
              format={formatNumberInput}
              label="Max score in points"
              name="pointsMaxScore"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              parse={parseNumberInput}
              type="number"
              validate={[required, number, this.pointsValidator]}
            />
          </div>
        </div>
      </form>
    );
  }
}

PointsSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const PointsSectionForm = reduxForm({
  form: 'speedCoursePointsSectionForm',
})(PointsSection);
