import React, { useRef, useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '~/components/scene/Tooltip';
import { ChromePicker } from 'react-color';
import { Dropdown } from '../Dropdown';

const getColorStyle = color => {
  const {
    hex,
    rgb: { r, g, b, a },
  } = color;
  return a === 1 ? hex : `rgba(${r},${g},${b},${a})`;
};

export const ToolbarColorPicker = ({ icon, hideLabel, label, value, setValue }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [changed, setChanged] = useState(value);

  useEffect(() => {
    if (changed) {
      setValue(internalValue);
      setChanged(false);
    }
  }, [changed, internalValue, setValue]);

  useLayoutEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [value]);

  const onChange = useCallback(color => {
    const style = getColorStyle(color);
    setInternalValue(style);
    setChanged(true);
  }, []);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(
          toolbarButtonStyle,
          css`
            position: relative;
          `,
        )}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && <>&nbsp;</>}
        {!hideLabel && label && <>{label}&nbsp;</>}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        <div
          css={css`
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
            position: relative;
            overflow: hidden;
          `}>
          <div
            css={css`
              inset: 0px;
              background: #fff
                url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')
                repeat scroll left center;
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
            `}
          />
          <div
            css={css`
              background-color: ${value};
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
            `}
          />
        </div>
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            setOpen(false);
          }}>
          <div css={css(dropdownContainerStyle)}>
            <ChromePicker color={internalValue} onChange={onChange} />
          </div>
        </Dropdown>
      )}
    </>
  );
};
