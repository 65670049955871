import React, { useRef, useCallback } from 'react';
import { toolbarButtonStyle } from '../styles';
import { Tooltip } from '../Tooltip';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ToolbarButton = ({ className, label, hideLabel, icon, onClick: onButtonClick }) => {
  const onClick = useCallback(
    e => {
      if (onButtonClick) {
        onButtonClick(e);
      }
    },
    [onButtonClick],
  );

  const ref = useRef(null);

  return (
    <div
      className={className}
      ref={ref}
      role="button"
      tabIndex="0"
      onClick={onClick}
      css={css(toolbarButtonStyle)}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {icon && !!label && !hideLabel && <>&nbsp;</>}
      {!hideLabel && label}
      {hideLabel && !!label && (
        <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
          {label}
        </Tooltip>
      )}
    </div>
  );
};
