import React, { useCallback } from 'react';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AssetPreview = ({
  asset: { name, image, preview, value } = {},
  add,
  onClick: onClickProp,
  setValue,
}) => {
  const onClick = useCallback(
    e => {
      if (setValue) {
        setValue(value);
      }
      if (onClickProp) {
        onClickProp(e);
      }
    },
    [onClickProp, setValue, value],
  );

  return (
    <div
      css={css`
        flex: 0 0 auto;
        border-radius: 10px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: background 0.1s ease-out;

        :focus {
          outline: 0;
        }

        :hover {
          background: #6a6e79;
        }
      `}
      onClick={onClick}
      role="button"
      tabIndex="0">
      {add ? (
        <div
          css={css`
            flex: 1 1 auto;
            display: flex;
          `}>
          <div
            css={css`
              margin: auto;
              display: block;
              color: #fff;
              font-size: 1.5em;
            `}>
            <FontAwesomeIcon icon={faUpload} />
          </div>
        </div>
      ) : (
        <>
          {(image && (
            <div
              css={css`
                flex: 1 1 auto;
                background-color: #fff;
                background-image: url(${image});
                background-position: center center;
                background-size: cover;
              `}
            />
          )) ||
            preview}
          <div
            css={css`
              flex: 0 0 auto;
              margin: 0;
              padding: 10px;
              line-height: 1.2;
              color: #fff;
            `}>
            {name}
          </div>
        </>
      )}
    </div>
  );
};
