import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background: linear-gradient(#565656 0%, #454545 59.11%, #3a3a3a 74.69%, #000 100%);
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Header = styled.h1``;
export const Description = styled.p``;
export const CloseButton = styled.button`
  border: none;
  background: white;
  color: black;
  padding: 1em;
  border-radius: 10px;
  margin-top: 1em;
`;
export const ContentWrapper = styled.main`
  margin-top: 5.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
