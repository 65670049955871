/** @jsx jsx */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select } from '~/components/Select';
import { Timer } from '../QuestionnaireTimer';
import { Header, Question, Description } from './styles';
import { Stack } from '../../layouts/Stack';
import { jsx, css } from '@emotion/core';
import { ActionButton, AnswerInstruction } from '~/components/Select/styles';
import { AnswerCommentPage } from './QuestionaireAnswerCommentPage/AnswerCommentPage';
import { highlightOnKeyboardFocusMixin } from '~/styles/utils/utils';

export class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswers: new Set(),
      resultSubmitted: false,
      timeRanOut: false,
      showAnswerCommentPage: null,
    };

    this.selectAnswer = this.selectAnswer.bind(this);
    this.getAnswerHighlight = this.getAnswerHighlight.bind(this);
    this.onTimeRunOut = this.onTimeRunOut.bind(this);
    this.getAllAnswersIsCorrect = this.getAllAnswersIsCorrect.bind(this);
    this.onActionButtonClick = this.onActionButtonClick.bind(this);
  }

  selectAnswer(answerId) {
    const prevSelectedAnswers = this.state.selectedAnswers;
    const { setCollectedData, isSpeedCourse } = this.props;
    let selectedAnswers = new Set(prevSelectedAnswers);
    if (this.getAnswerType() === 'MULTIPLE_CORRECT') {
      if (prevSelectedAnswers.has(answerId)) {
        selectedAnswers.delete(answerId);
      } else {
        selectedAnswers.add(answerId);
      }
    } else {
      selectedAnswers = new Set([answerId]);
    }
    this.setState({ selectedAnswers });
    setCollectedData({ selectedAnswers: [...selectedAnswers] });

    // for speedquiz go direct to  show answer-correctness/answer comment page
    if (isSpeedCourse) {
      this.onActionButtonClick();
    }

    // permitContinue();
  }

  getAnswerHighlight(answerId) {
    const {
      data: { answers },
      informAboutAnswerCorrectness,
    } = this.props;
    const { resultSubmitted, selectedAnswers } = this.state;
    if (!informAboutAnswerCorrectness || !resultSubmitted) {
      return '';
    }
    if (
      answers
        .filter(a => a.isCorrect)
        .map(a => a.id)
        .includes(answerId)
    ) {
      return 'correct';
    }

    if (
      selectedAnswers.has(answerId) &&
      answers
        .filter(a => !a.isCorrect)
        .map(a => a.id)
        .includes(answerId)
    ) {
      return 'incorrect';
    }
  }

  onTimeRunOut() {
    this.setState({ timeRanOut: true });
    this.onActionButtonClick();
  }

  getAnswerType() {
    const {
      data: { answers },
    } = this.props;
    return answers.filter(answer => answer.isCorrect).length === 1
      ? 'SINGLE_CORRECT'
      : 'MULTIPLE_CORRECT';
  }

  getAllAnswersIsCorrect() {
    const {
      data: { answers },
    } = this.props;
    const { selectedAnswers } = this.state;

    if (selectedAnswers.size === 0) {
      return false;
    }

    // all correct: the user has answered all of the correct options and none of the wrong ones
    return (
      answers.filter(a => a.isCorrect).every(a => selectedAnswers.has(a.id)) &&
      !answers.filter(a => !a.isCorrect).some(a => selectedAnswers.has(a.id))
    );
  }

  onActionButtonClick() {
    const {
      informAboutAnswerCorrectness,
      onGotoNext,
      isSpeedCourse,
      data: { texts },
    } = this.props;
    const { resultSubmitted } = this.state;
    if (!resultSubmitted) {
      this.setState({ resultSubmitted: true });
    }

    if (informAboutAnswerCorrectness && !resultSubmitted) {
      // show the status of the answers
      return;
    }

    const allAnswersIsCorrect = this.getAllAnswersIsCorrect();
    if (texts.textWhenGood && allAnswersIsCorrect) {
      this.setState({ showAnswerCommentPage: 'ANSWER_CORRECT' });
    } else if (texts.textWhenWrong && !allAnswersIsCorrect) {
      this.setState({ showAnswerCommentPage: 'ANSWER_INCORRECT' });
    } else {
      onGotoNext();
    }
  }

  render() {
    const {
      data: { answers, title, texts },
      isQuestionnaireVisible,
      onGotoNext,
      isSpeedCourse,
      questionaireDescription,
    } = this.props;
    const { selectedAnswers, resultSubmitted, timeRanOut, showAnswerCommentPage } = this.state;

    const answerType = this.getAnswerType();

    return (
      <Stack direction="vertical">
        {/** className="background" to change background with config file instead */}

        {showAnswerCommentPage && (
          <AnswerCommentPage
            className="answer-comment-page"
            background={showAnswerCommentPage === 'ANSWER_CORRECT' ? '#237804' : '#a8071a'}
            onClose={onGotoNext}
            text={
              showAnswerCommentPage === 'ANSWER_CORRECT' ? texts.textWhenGood : texts.textWhenWrong
            }></AnswerCommentPage>
        )}

        <Header backgroundColor="#007AFF">
          <Stack direction="vertical" justifyContent="center">
            <Question>
              <h1>{title}</h1>
              {questionaireDescription && <Description>{questionaireDescription}</Description>}
            </Question>
            <AnswerInstruction
              aria-label="Type svar"
              css={css`
                margin-bottom: ${isSpeedCourse ? null : '1em'};
              `}>
              {answerType === 'SINGLE_CORRECT' ? 'Velg et svar' : 'Velg flere svar'}
            </AnswerInstruction>
            <div css={{ marginTop: 'auto' }}>
              <Timer
                css={css`
                  display: ${isSpeedCourse ? 'block' : 'none'};
                `}
                running={!resultSubmitted && isSpeedCourse}
                onTimeRunOut={this.onTimeRunOut}
                duration={12000}
                points={1000}
              />
            </div>
          </Stack>
        </Header>

        <Stack
          direction="vertical"
          css={css`
            min-height: auto;
          `}>
          <Stack
            direction="vertical"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: '#EFEFF4', padding: '0 0.75em' }}>
            {isQuestionnaireVisible && (
              <div
                css={css`
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}>
                <Select
                  multiple={answerType === 'MULTIPLE_CORRECT'}
                  disabled={resultSubmitted}
                  groupName="answer"
                  onChange={this.selectAnswer}
                  options={answers.map(({ id, text }) => ({
                    id,
                    label: text,
                    answerStatus: this.getAnswerHighlight(id),
                  }))}
                  selected={
                    answerType === 'MULTIPLE_CORRECT' ? selectedAnswers : [...selectedAnswers][0]
                  }
                />
              </div>
            )}

            <ActionButton
              css={css`
                ${highlightOnKeyboardFocusMixin}
              `}
              background={resultSubmitted ? '#383940' : '#34C759'}
              onClick={this.onActionButtonClick}
              visible={selectedAnswers.size > 0 || timeRanOut}>
              {resultSubmitted ? 'Neste' : 'Svar'}
            </ActionButton>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

Questionnaire.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCorrect: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    settings: PropTypes.shape({
      pointsEnabled: PropTypes.bool.isRequired,
      pointsPerAnswer: PropTypes.number.isRequired,
      pointsMaxScore: PropTypes.number.isRequired,
    }).isRequired,
    texts: PropTypes.shape({
      textWhenGood: PropTypes.string.isRequired,
      textWhenWrong: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isQuestionnaireVisible: PropTypes.bool.isRequired,
  permitContinue: PropTypes.func.isRequired,
  setCollectedData: PropTypes.func.isRequired,
};
