import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckboxSelect } from '~/components/CheckboxSelect';
import { RadioSelect } from '~/components/RadioSelect';

export class QuestionnaireInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswers: new Set(),
      resultSubmitted: false,
    };

    this.toggleAnswer = this.toggleAnswer.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
  }

  toggleAnswer(answerId) {
    const {
      data: { answers },
      setCollectedData,
      permitContinue,
    } = this.props;
    const selectedAnswers = new Set(this.state.selectedAnswers);
    selectedAnswers[selectedAnswers.has(answerId) ? 'delete' : 'add'](answerId);
    this.setState({ selectedAnswers });
    setCollectedData({ selectedAnswers: [...selectedAnswers] });
    if (selectedAnswers.size >= answers.filter(({ isCorrect }) => isCorrect).length) {
      permitContinue();
    }
  }

  selectAnswer(answerId) {
    const { setCollectedData, permitContinue } = this.props;
    const selectedAnswers = new Set([answerId]);
    this.setState({ selectedAnswers });
    setCollectedData({ selectedAnswers: [...selectedAnswers] });
    permitContinue();
  }

  render() {
    const {
      data: { answers, question },
      isQuestionnaireVisible,
    } = this.props;
    const { selectedAnswers, resultSubmitted } = this.state;

    const isExactlyOneGoodAnswer = answers.filter(answer => answer.isCorrect).length === 1;

    return (
      <div className="player-questionnaire__container">
        <div className="player-questionnaire__question-container">{question}</div>
        <div className="player-questionnaire__answers-container">
          {isQuestionnaireVisible &&
            (isExactlyOneGoodAnswer ? (
              <RadioSelect
                disabled={resultSubmitted}
                groupName="answer"
                onChange={this.selectAnswer}
                options={answers.map(({ id, text }) => ({
                  id,
                  label: text,
                }))}
                selectedOptionId={[...selectedAnswers][0]}
              />
            ) : (
              <CheckboxSelect
                checked={selectedAnswers}
                disabled={resultSubmitted}
                groupName="answer"
                onChange={this.toggleAnswer}
                options={answers.map(({ id, text }) => ({
                  id,
                  label: text,
                }))}
              />
            ))}
        </div>
      </div>
    );
  }
}

QuestionnaireInput.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCorrect: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    settings: PropTypes.shape({
      pointsEnabled: PropTypes.bool.isRequired,
      pointsPerAnswer: PropTypes.number.isRequired,
      pointsMaxScore: PropTypes.number.isRequired,
    }).isRequired,
    texts: PropTypes.shape({
      textWhenGood: PropTypes.string.isRequired,
      textWhenWrong: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isQuestionnaireVisible: PropTypes.bool.isRequired,
  permitContinue: PropTypes.func.isRequired,
  setCollectedData: PropTypes.func.isRequired,
};
