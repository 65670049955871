export const getToolbarFieldValues = (fields, data = {}) =>
  fields
    .filter(({ name, type }) => name && data[name] === undefined && type !== 'event')
    .reduce(
      (acc, { name, defaultValue }) => ({
        ...acc,
        [name]: defaultValue,
      }),
      data,
    );
