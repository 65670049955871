import styled from '@emotion/styled';
import { rgba, linearGradient } from 'polished';

const colors = {
  red: '#FF453A',
  yellow: '#FFCC00',
  green: '#34C759',
  bg: '#EFEFF4',
  blue: '#007AFF',
  base: '#EBEBF5',
  yak: '#1C1C1E',
};

const scale = {
  size: [0, 4, 8, 12, 16, 20, 24, 32, 40],
};
const size = {
  height: 20,
};

const timeLeftGradient = {
  ...linearGradient({
    colorStops: [`${colors.red} 0%`, `${colors.yellow} 50%`, `${colors.green} 100%`],
    toDirection: 'to right',
    fallBack: colors.red,
  }),
};

export const Base = styled('div')({
  height: size.height,
  width: '100%',
  backgroundColor: rgba(colors.base, 0.5),
  borderTop: `1px solid ${rgba(colors.base, 0.25)}`,
  borderBottom: `1px solid ${rgba(colors.base, 0.25)}`,
  position: 'relative',
});

export const Mask = styled('div')({
  position: 'relative',
  width: '100%',
  height: size.height,
  overflow: 'hidden',
});

export const Bar = styled('div')(
  {
    position: 'absolute',
    height: size.height,
    width: '100vw',
  },
  timeLeftGradient,
);

export const TimeLeft = styled('div')({
  backgroundColor: colors.yak,
  color: 'white',
  fontFamily: ['monospace', 'Roboto Mono'],
  display: 'inline-block',
  paddingTop: scale.size[2],
  paddingBottom: scale.size[2],
  paddingLeft: scale.size[3],
  paddingRight: scale.size[3],
  fontSize: scale.size[4],
  height: 20,
  borderRadius: 9999,
  border: `1px solid ${rgba(colors.yak, 0.25)}`,
  minWidth: scale.size[6],
  marginBottom: scale.size[2],
  minHeight: scale.size[8],
});

export const Path = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '-webkit-align-items': 'flex-end',
  alignItems: 'flex-end',
  marginRight: 8,
});
