import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import {
  blue11color,
  boxshadow as boxShadow,
  red6color,
  red7color,
} from '~/styles/utils/variables.scss';

import {
  SAVE_STATUS_FAILED,
  SAVE_STATUS_SAVED,
  SAVE_STATUS_UPLOADING,
  SAVE_STATUS_DIRTY,
} from '~/utils/course';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSave: {
    backgroundColor: blue11color,
    boxShadow,
    '&:hover': {
      backgroundColor: blue11color,
    },
  },
  buttonError: {
    backgroundColor: red6color,
    boxShadow,
    '&:hover': {
      backgroundColor: red7color,
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    boxShadow,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CircularIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    this.props.onSaveClick();
  }

  render() {
    const { classes, saveStatus } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Button
            variant="fab"
            color="primary"
            className={classNames({
              [classes.buttonSave]: ![SAVE_STATUS_SAVED, SAVE_STATUS_FAILED].includes(saveStatus),
              [classes.buttonSuccess]: saveStatus === SAVE_STATUS_SAVED,
              [classes.buttonError]: saveStatus === SAVE_STATUS_FAILED,
            })}
            onClick={this.handleButtonClick}>
            {saveStatus === SAVE_STATUS_SAVED ? <CheckIcon /> : <SaveIcon />}
          </Button>
          {saveStatus === SAVE_STATUS_UPLOADING && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      </div>
    );
  }
}

CircularIntegration.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onSaveClick: PropTypes.func.isRequired,
  saveStatus: PropTypes.oneOf([
    SAVE_STATUS_UPLOADING,
    SAVE_STATUS_SAVED,
    SAVE_STATUS_FAILED,
    SAVE_STATUS_DIRTY,
  ]).isRequired,
};

export default withStyles(styles)(CircularIntegration);
