import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import { dimensions } from '~/utils/player-preview';

const getPreviewIframeSize = (iframeContainer, dimension) => ({
  width: Math.min(iframeContainer.offsetWidth, dimension.width),
  height: Math.min(iframeContainer.offsetHeight - 1, dimension.height), // substracting 1px for IE
});

export class PlayerPreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      activeDimensionIndex: 0,
    };
    this.mountNode = document.querySelector('#modal-root');
    this.previewUrl = `${process.env.PREVIEW_URL}${props.courseId}`;

    this.previewIframeRef = React.createRef();
    this.previewIframeContainerRef = React.createRef();
    this.setActiveDimensionIndex = this.setActiveDimensionIndex.bind(this);
    this.setPreviewIframeSize = this.setPreviewIframeSize.bind(this);
  }

  componentDidMount() {
    this.setPreviewIframeSize();
    window.addEventListener('resize', this.setPreviewIframeSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setPreviewIframeSize);
  }

  setPreviewIframeSize() {
    const previewIframe = this.previewIframeRef.current;
    const iframeContainer = this.previewIframeContainerRef.current;
    const dimension = dimensions[this.state.activeDimensionIndex];
    Object.assign(previewIframe, getPreviewIframeSize(iframeContainer, dimension));
  }

  setActiveDimensionIndex(index) {
    this.setState(
      {
        activeDimensionIndex: index,
      },
      this.setPreviewIframeSize,
    );
  }

  render() {
    const { onPlayerPreviewModalCloseClick } = this.props;
    const { activeDimensionIndex } = this.state;

    return (
      <Modal
        basic
        mountNode={this.mountNode}
        onClose={onPlayerPreviewModalCloseClick}
        open
        size="small"
        style={{
          width: '100%',
          height: '100%',
        }}>
        <div className="player-preview__wrapper">
          <div className="player-preview__container">
            <div className="player-preview__dimensions-container">
              {dimensions.map(({ iconClassName, isRotated, name }, index) => (
                <div
                  className={classNames({
                    'player-preview__dimension-button': true,
                    'player-preview__dimension-button--active': activeDimensionIndex === index,
                  })}
                  key={name}
                  onClick={() => this.setActiveDimensionIndex(index)}
                  role="button"
                  tabIndex={0}>
                  <div
                    className={classNames({
                      'player-preview__dimension-icon': true,
                      'player-preview__dimension-icon--rotated': isRotated,
                    })}>
                    <span className={iconClassName} />
                  </div>
                  <div className="player-preview__dimension-title">{name}</div>
                </div>
              ))}
            </div>
            <div className="player-preview__iframe-container" ref={this.previewIframeContainerRef}>
              <iframe
                className="player-preview__iframe-element"
                src={this.previewUrl}
                title="preview-iframe"
                ref={this.previewIframeRef}
              />
            </div>
            <div className="player-preview__action-buttons-container">
              <button className="btn btn-blue" onClick={onPlayerPreviewModalCloseClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

PlayerPreviewModal.propTypes = {
  onPlayerPreviewModalCloseClick: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired,
};
