import { all, call, put, take, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from '~/utils/request';

import { addStylesLink } from '~/utils/common';
import { setDictionary } from '~/utils/dictionary';

import {
  PAGE_ID_DESCRIPTION,
  PAGE_ID_SPEED_COURSE,
  PAGE_ID_FINISHED,
  setPagesTypes,
  getParsedCourseData,
} from '~/utils/course';

import {
  INITIALIZE_COURSE_PLAYER,
  fetchCourseDataRequest,
  fetchCourseDataSuccess,
  fetchCourseDataFailure,
  enterLoadingPage,
  COURSE_ENTER_PAGE,
  courseEnterPage,
  courseEnterPageRequest,
  courseEnterPageSuccess,
  courseEnterPageFailure,
  COURSE_LEAVE_PAGE,
  COURSE_LEAVE_PAGE_SUCCESS,
  courseLeavePage,
  courseLeavePageRequest,
  courseLeavePageSuccess,
  courseLeavePageFailure,
  COURSE_ON_CONTINUE_CLICK,
  COURSE_ON_GO_BACK_CLICK,
  COURSE_ON_EXIT_CLICK,
  COURSE_ON_CONTINUE_SLIDE_CLICK,
  COURSE_ON_GO_BACK_SLIDE_CLICK,
  setActiveSlideId,
} from '../actions/course.actions';

import {
  getNextPageId,
  getPageCollectedData,
  getSpeedCoursePagesCollectedData,
  getRedirectPageId,
  getActiveSlideIndex,
  getPrevPageId,
  getPages,
} from '../selectors/course.selectors';

import { getConfig } from '../sagas/config.sagas';
import { postMessage } from '~/utils/dom';
import { getAllSlides } from '~/stores/editor/selectors/course.selectors';

function* initializeCoursePlayer(action) {
  const courseId = action.payload;
  yield put(fetchCourseDataRequest());
  try {
    const [
      { courseData, stylesUrl, dictionary },
      {
        data: { templateTypes },
      },
    ] = yield all([yield call(API.getPlayerData, courseId), yield call(API.getTemplateTypes)]);

    if (dictionary) {
      setDictionary(dictionary);
    }
    setPagesTypes(templateTypes);
    if (stylesUrl !== undefined) {
      addStylesLink(stylesUrl); // If no url, no need to add.
    }

    yield put(fetchCourseDataSuccess(getParsedCourseData(courseData)));

    if (courseData.descriptionPage.disabled) {
      yield put(enterLoadingPage());
      yield call(API.postEvent, 'enter', PAGE_ID_DESCRIPTION);
      yield call(API.postEvent, 'leave', PAGE_ID_DESCRIPTION);
    }
    yield call(getConfig);
    postMessage('TAB_COURSE_LOADED', 'IFRAME_COURSE_LOADED');
    return yield put(courseEnterPage({ id: yield select(getNextPageId) }));
  } catch (error) {
    toast.error('Failed to fetch course data.');
    postMessage('TAB_COURSE_FAILED_TO_LOAD', 'IFRAME_COURSE_FAILED_TO_LOAD');
    return yield put(fetchCourseDataFailure(error));
  }
}

function* enterPage(action) {
  const { id } = action.payload;
  yield put(courseEnterPageRequest({ id }));
  try {
    yield API.postEvent('enter', id);
    yield put(courseEnterPageSuccess());
  } catch {
    toast.error('Failed to enter page.');
    yield put(courseEnterPageFailure());
  }
}

function* leavePage(action) {
  const { id, collectedData } = action.payload;
  yield put(courseLeavePageRequest());
  try {
    const response = yield API.postEvent('leave', id, collectedData);
    yield put(courseLeavePageSuccess(response.data));
  } catch (error) {
    toast.error('Failed to save page data.');
    yield put(courseLeavePageFailure());
    throw error;
  }
}

function* onContinueClick() {
  const {
    course: {
      data: { activePageId },
    },
  } = yield select();
  const nextPageId = yield select(getNextPageId);
  const redirectPageId = yield select(getRedirectPageId);
  const continuePageId = redirectPageId || nextPageId;

  if (activePageId === 'SPEED_COURSE') {
    const collectedData = yield select(getSpeedCoursePagesCollectedData);
    yield put(
      courseLeavePage({
        id: PAGE_ID_SPEED_COURSE,
        collectedData,
      }),
    );
  } else {
    const collectedData = yield select(getPageCollectedData, { pageId: activePageId });
    yield put(
      courseLeavePage({
        id: activePageId,
        collectedData,
      }),
    );
  }

  /*
  if ( isSpeedCoursePage(continuePageId, courseData) && continuePageId !== PAGE_ID_SPEED_COURSE) {
    const questionnairesResults = yield select(getPageQuestionnairesResults);
    const questionnairesResultsList = Object.entries(questionnairesResults).map(([, res]) => res);
    if (questionnairesResultsList.length) {
      if (questionnairesResultsList.every(({ isCorrect }) => isCorrect)) {
        yield put(courseIncrementWinningStreak());
      } else {
        yield put(courseDropWinningStreak());
      }
    }
    return yield put(enterSpeedCoursePage({ pageId: continuePageId }));
  }
  */

  yield take(COURSE_LEAVE_PAGE_SUCCESS);
  if (continuePageId === PAGE_ID_FINISHED) {
    postMessage('TAB_COURSE_FINISHED', 'IFRAME_COURSE_FINISHED');
  }

  return yield put(courseEnterPage({ id: continuePageId }));
}

function* onGoBackClick() {
  const {
    course: {
      data: { activePageId },
    },
  } = yield select();
  const prevPageId = yield select(getPrevPageId);

  const collectedData = yield select(getPageCollectedData, { pageId: activePageId });
  yield put(
    courseLeavePage({
      id: activePageId,
      collectedData,
    }),
  );

  yield take(COURSE_LEAVE_PAGE_SUCCESS);

  yield put(courseEnterPage({ id: prevPageId }));

  // if the page we are entering backwards is a page with slides, set the current slide index to the last slide
  const pages = yield select(getPages);
  const activePage = pages.find(p => p.id === prevPageId);
  if (activePage.templateTypeId === 'PICTURESLIDES-TEXT') {
    yield put(
      setActiveSlideId(
        activePage.pictureslides.slides[activePage.pictureslides.slides.length - 1]?.id,
      ),
    );
  }
}

function* courseOnContinueSlideClick() {
  const slides = yield select(getAllSlides);
  const slideIndex = yield select(getActiveSlideIndex);
  yield put(setActiveSlideId(slides[slideIndex + 1]?.id));
}

function* courseOnGoBackSlideClick() {
  const slides = yield select(getAllSlides);
  const slideIndex = yield select(getActiveSlideIndex);
  yield put(setActiveSlideId(slides[slideIndex - 1]?.id));
}

// eslint-disable-next-line require-yield
function* onExitClick() {
  postMessage('TAB_COURSE_CANCELLED', 'IFRAME_COURSE_CANCELLED');
}

export const sagas = [
  takeLatest(INITIALIZE_COURSE_PLAYER, initializeCoursePlayer),
  takeLatest(COURSE_ENTER_PAGE, enterPage),
  takeLatest(COURSE_LEAVE_PAGE, leavePage),
  takeLatest(COURSE_ON_CONTINUE_CLICK, onContinueClick),
  takeLatest(COURSE_ON_GO_BACK_CLICK, onGoBackClick),
  takeLatest(COURSE_ON_EXIT_CLICK, onExitClick),
  takeLatest(COURSE_ON_CONTINUE_SLIDE_CLICK, courseOnContinueSlideClick),
  takeLatest(COURSE_ON_GO_BACK_SLIDE_CLICK, courseOnGoBackSlideClick),
];
