import { nanoid } from 'nanoid';

export const getIdForNextElement = () => nanoid();

export const getUrlParam = name => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const getColor = data => {
  if (Object.keys(data).includes('r')) {
    if (Object.keys(data).includes('a')) {
      return `rgba(${data.r},${data.g},${data.b},${data.a})`;
    } else {
      return `rgb(${data.r},${data.g},${data.b})`;
    }
  } else {
    return data;
  }
};

export const addStylesLink = url => {
  const link = document.createElement('link');
  link.href = url;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  document.querySelectorAll('head')[0].append(link);
};
