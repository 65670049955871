import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormTextarea } from '~/components/FormTextarea';

const TextTab = props => {
  const { handleSubmit } = props;
  return (
    <form
      className="questionnaire-section-editor__settings-container"
      autoComplete="off"
      onSubmit={handleSubmit}>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormTextarea}
          label="Text when answered right"
          name="textWhenGood"
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
          type="text"
        />
      </div>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormTextarea}
          label="Text when answered wrong"
          name="textWhenWrong"
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
          type="text"
        />
      </div>
    </form>
  );
};

TextTab.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const TextTabForm = reduxForm({
  form: 'questionareSectionTextTabForm',
})(TextTab);
