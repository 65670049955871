import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';
import { createItem } from '~/components/scene/ItemScene';
import { faDotCircle, faImage, faFont, faShapes, faBoxAlt } from '@fortawesome/pro-solid-svg-icons';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onAssetUpload } from '../../../components/scene/asset-upload';
import { SceneContext } from '../../../components/scene/SceneContext';
import { BundleLoader } from '../../../components/scene/BundleLoader';

const ItemIconButton = ({ onClick, label, icon }) => (
  <div className="course-miniature__container" onClick={onClick} role="button" tabIndex="0">
    <div
      css={css`
        font-size: 4em;
      `}>
      <FontAwesomeIcon icon={icon} />
    </div>
    <div className="course-miniature__title">
      <span>{label}</span>
    </div>
  </div>
);

export const CreateItemModal = ({ open, onClose }) => {
  const { addItem, items } = useContext(SceneContext);

  const [creatableItems, setCreatableItems] = useState();
  const [bundle, setBundle] = useState(null);

  useEffect(() => {
    setCreatableItems([
      {
        id: 'textElement',
        icon: faFont,
        label: 'Tekst',
        onSelect: () => {
          const item = createItem('textElement', {
            name: 'Sample Text',
            selected: true,
            editing: true, // Start text editing mode on create. Remove this if you want to move the element first instead.
            data: { text: 'Sample Text' },
          });
          addItem(item);
        },
      },
      {
        id: 'upload-img',
        icon: faImage,
        label: 'Last opp bilde',
        onSelect: onAssetUpload('image', (result, name) => {
          const item = createItem('imageElement', { name, selected: true, data: { file: result } });

          addItem(item);
        }),
      },
      {
        id: 'upload-svg',
        icon: faShapes,
        label: 'Last opp SVG/vektorgrafikk',
        onSelect: onAssetUpload('svg', (result, name) => {
          const item = createItem('svgFigure', { name, selected: true, data: { source: result } });

          addItem(item);
        }),
      },
      // {
      //   id: 'infoBox',
      //   icon: faParagraph,
      //   label: 'Infoboks', // WIP
      //   onSelect: () => {
      //     const item = createItem('infoBox');
      //     addItem(item);
      //   },
      // },
      {
        id: 'dot',
        icon: faDotCircle,
        label: 'Klikkbar punkt/knapp',
        onSelect: () => {
          const newNumber = items.filter(({ type }) => type === 'dot').length + 1;
          const item = createItem('dot', {
            name: `Punkt ${newNumber}`,
            selected: true,
            editing: true, // Start text editing mode on create. Remove this if you want to move the element first instead.
            data: { text: `${newNumber}` },
          });
          addItem(item);
        },
      },
      {
        id: 'bundle',
        icon: faBoxAlt,
        label: 'Last opp bundle',
        onSelect: onAssetUpload('bundle', (result, name) => {
          setBundle({
            name,
            ...result,
          });
        }),
      },
    ]);
  }, [addItem, items]);

  const onOptionClick = useCallback(
    callback => () => {
      callback();
      onClose();
    },
    [onClose],
  );

  return (
    <>
      <Modal
        basic
        mountNode={document.querySelector('#modal-root')}
        onClose={onClose}
        open={open}
        size="small">
        <Header content="Add Item" />
        <Modal.Content className="course-miniatures__wrapper">
          <div className="course-miniatures__container">
            {creatableItems?.map(({ id, type, label, icon, onSelect }) => (
              <ItemIconButton
                key={id}
                type={type}
                label={label}
                icon={icon}
                onClick={onOptionClick(onSelect)}
              />
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions className="course-miniatures__action-buttons-container">
          <button className="btn btn-blue" onClick={onClose}>
            Cancel
          </button>
        </Modal.Actions>
      </Modal>
      {bundle && <BundleLoader bundle={bundle} onLoaded={() => setBundle(null)} />}
    </>
  );
};

CreateItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
