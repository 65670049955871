import {
  PAGE_ID_RUNTIME_ERROR,
  PAGE_STATUS_ENTERING,
  PAGE_STATUS_ACTIVE,
  PAGE_STATUS_LEAVING,
  PAGE_STATUS_INACTIVE,
} from '~/utils/course';

import {
  SET_ACTIVE_PAGE_ID,
  FETCH_COURSE_DATA_REQUEST,
  FETCH_COURSE_DATA_SUCCESS,
  FETCH_COURSE_DATA_FAILURE,
  COURSE_SET_PAGE_COMPONENT_COLLECTED_DATA,
  COURSE_ENTER_PAGE_REQUEST,
  COURSE_ENTER_PAGE_SUCCESS,
  COURSE_ENTER_PAGE_FAILURE,
  COURSE_LEAVE_PAGE_REQUEST,
  COURSE_LEAVE_PAGE_SUCCESS,
  COURSE_LEAVE_PAGE_FAILURE,
  RUNTIME_ERROR_CATCH,
  ENTER_SPEED_COURSE_PAGE,
  COURSE_INCREMENT_WINNING_STREAK,
  COURSE_DROP_WINNING_STREAK,
  SET_ACTIVE_SLIDE_ID,
} from '../actions/course.actions';

export const initialState = {
  data: undefined,
  id: undefined,
  isFetching: true,
  collectedData: {},
  winningStreak: 0,
  pagesInitTimestamps: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          activePageId: action.payload,
        },
      };

    case FETCH_COURSE_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_COURSE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case FETCH_COURSE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: undefined,
      };

    case COURSE_SET_PAGE_COMPONENT_COLLECTED_DATA:
      return {
        ...state,
        collectedData: {
          ...state.collectedData,
          [action.payload.pageId]: {
            ...(state.collectedData[action.payload.pageId] || {}),
            [action.payload.componentDataKey]: action.payload.collectedData,
          },
        },
      };

    case COURSE_LEAVE_PAGE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data.pages.map(page =>
            page.id === state.data.activePageId
              ? {
                  ...page,
                  status: PAGE_STATUS_LEAVING,
                }
              : page,
          ),
        },
      };

    case COURSE_LEAVE_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          result:
            action.payload && action.payload.result ? action.payload.result : state.data.result,
          pages: state.data.pages.map(page =>
            page.id === state.data.activePageId
              ? {
                  ...page,
                  status: PAGE_STATUS_INACTIVE,
                }
              : page,
          ),
          phceId:
            action.payload && action.payload.phceId ? action.payload.phceId : state.data.phceId,
        },
      };

    case COURSE_LEAVE_PAGE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          activePageId: PAGE_ID_RUNTIME_ERROR,
          pages: state.data.pages.map(page =>
            page.id === state.data.activePageId
              ? {
                  ...page,
                  status: PAGE_STATUS_INACTIVE,
                }
              : page,
          ),
          phceId:
            action.payload && action.payload.phceId ? action.payload.phceId : state.data.phceId,
        },
      };

    case SET_ACTIVE_SLIDE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          activeSlideId: action.payload,
        },
      };

    case COURSE_ENTER_PAGE_REQUEST:
      const targetPage = state.data.pages.find(({ id }) => id === action.payload.id);
      const slides = targetPage?.pictureslides?.slides;

      return {
        ...state,
        data: {
          ...state.data,
          activePageId: action.payload.id,
          activeSlideId: slides && slides[0]?.id,
          pages: state.data.pages.map(page =>
            page.id === action.payload.id
              ? {
                  ...page,
                  status: PAGE_STATUS_ENTERING,
                }
              : page,
          ),
        },
        pagesInitTimestamps: {
          ...state.pagesInitTimestamps,
          [state.data.activePageId]: null,
        },
      };

    case COURSE_ENTER_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data.pages.map(page =>
            page.id === state.data.activePageId
              ? {
                  ...page,
                  status: PAGE_STATUS_ACTIVE,
                }
              : page,
          ),
          phceId:
            action.payload && action.payload.phceId ? action.payload.phceId : state.data.phceId,
        },
        pagesInitTimestamps: {
          ...state.pagesInitTimestamps,
          [state.data.activePageId]: Number(new Date()),
        },
      };

    case COURSE_ENTER_PAGE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          activePageId: PAGE_ID_RUNTIME_ERROR,
          pages: state.data.pages.map(page =>
            page.id === state.data.activePageId
              ? {
                  ...page,
                  status: PAGE_STATUS_INACTIVE,
                }
              : page,
          ),
          phceId:
            action.payload && action.payload.phceId ? action.payload.phceId : state.data.phceId,
        },
      };

    case ENTER_SPEED_COURSE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          activePageId: action.payload.pageId,
          pages: state.data.pages.map(page =>
            page.id === action.payload
              ? {
                  ...page,
                  status: PAGE_STATUS_ENTERING,
                }
              : page,
          ),
        },
        pagesInitTimestamps: {
          ...state.pagesInitTimestamps,
          [action.payload.pageId]: Number(new Date()),
        },
      };

    case COURSE_INCREMENT_WINNING_STREAK:
      return {
        ...state,
        winningStreak: state.data.winningStreak + 1,
      };

    case COURSE_DROP_WINNING_STREAK:
      return {
        ...state,
        winningStreak: 0,
      };

    case RUNTIME_ERROR_CATCH:
      return {
        ...state,
        data: {
          ...state.data,
          activePageId: PAGE_ID_RUNTIME_ERROR,
        },
      };

    default:
      return state;
  }
};
