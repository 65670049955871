import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import {
  DilemmaPlayerWrapper,
  CardWrapper,
  Card,
  CardHtmlContent,
  CardHtmlContentWrapper,
  ActionButtonsWrapper,
  CardBottomGradient,
  NavigationButton,
  FlipCardButton,
  FlipCardIconWrapper,
  LeaveFullscreenButton,
} from './styles';
import { useSelector } from 'react-redux';
import { getConfigObject } from '~/stores/player/selectors/config.selectors';

const getBgColorForCard = (properties, side, hasLoadedImage, fallbackBack, fallbackFront) => {
  if (side === 'front') {
    return hasLoadedImage ? 'none' : properties.bgColor || fallbackFront;
  } else if (side === 'front_fullscreen') {
    return properties.bgColor || (properties.bgImage && `gray`) || fallbackFront;
  } else if (side === 'back') {
    return '#EFEFF4';
  }
  return 'none';
};

const getBgImageForCard = (properties, side) =>
  (side === 'front' && properties.bgImage && `url(${properties.bgImage.url})`) || 'none';

export const Dilemma = ({
  onGotoNext,
  onGotoPrev,
  data,
  properties,
  pageNumber,
  pagesCount,
  setCollectedData,
  resultsPage,
  alignQuestion = 'center',
}) => {
  console.log('results page', resultsPage);
  const [cardViewMode, setCardViewMode] = useState('front'); // one of: front, back, fullscreen_front
  const [frontImageHasLoaded, setFrontImageHasLoaded] = useState(false);

  const configObject = useSelector(getConfigObject);

  const dilemma = data;

  const gotoNextDilemma = () => {
    setCardViewMode('front');
    onGotoNext();
  };

  const gotoPrevDilemma = () => {
    setCardViewMode('front');
    onGotoPrev();
  };

  const flipCard = () => {
    setCardViewMode(old => (old === 'front' ? 'back' : 'front'));
    setCollectedData({ hasFlippedCard: true });
  };

  const enterFrontCardFullscreen = () => {
    if (cardViewMode !== 'front') {
      return;
    }
    setCardViewMode('front_fullscreen');
  };

  const leaveFrontCardFullscreen = () => {
    setCardViewMode('front');
  };

  /* If we have a background image We want to know if or when the image loads, so that we can show it instad of
  the fallback background color.
  This is so that animations are played correctly.
  */
  useEffect(() => {
    if (properties.bgImage) {
      const img = new Image();
      img.src = properties.bgImage.url;
      if (img.complete) {
        setFrontImageHasLoaded(true);
      } else {
        img.addEventListener('load', () => {
          setFrontImageHasLoaded(true);
        });
      }
    }
  }, [properties.bgImage]);

  const bgColorForCard = getBgColorForCard(
    properties,
    cardViewMode,
    frontImageHasLoaded,
    configObject.getProperty('components.dilemma.cardFrontFallbackBgColor'),
    configObject.getProperty('components.dilemma.cardFrontFallbackBgColor'),
  );

  const bgImageForCard = getBgImageForCard(properties, cardViewMode, frontImageHasLoaded);
  const cardHasImageBg = bgImageForCard !== 'none';

  const nextPageIsFinishedPage = pageNumber === pagesCount;

  console.log('properties', properties);

  return (
    <DilemmaPlayerWrapper className={`dilemma-player-wrapper ${cardViewMode}`}>
      <CardWrapper className={`card-wrapper ${cardViewMode}`}>
        <AnimatePresence initial={false}>
          <Card
            tabIndex={0}
            aria-live="assertive"
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                flipCard();
              }
            }}
            aria-label="Dilemma-kort, trykk enter for å snu"
            onClick={flipCard}
            key={cardViewMode}
            className={`card ${cardViewMode}`}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: '.30' }}
            backgroundImage={bgImageForCard && frontImageHasLoaded ? bgImageForCard : 'none'}
            backgroundColor={bgColorForCard}>
            {cardHasImageBg && (
              <CardBottomGradient
                className={`bottom-card-gradient ${cardViewMode}`}
                gradient="linear-gradient(#fff0, black)"
              />
            )}
            <CardHtmlContentWrapper
              className={`card-html-content-wrapper ${cardViewMode}`}
              role="button"
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  enterFrontCardFullscreen();
                }
              }}
              tabIndex="0">
              <CardHtmlContent
                className={`card-html-content ${cardViewMode}`}
                initial={
                  cardViewMode === 'front_fullscreen' ? { position: 'absolute', bottom: 0 } : {}
                }
                animate={cardViewMode === 'front_fullscreen' ? { top: '0px' } : {}}
                alignFront={alignQuestion}
                transition={{ ease: 'easeOut', duration: '0.4' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    cardViewMode === 'front' || cardViewMode === 'front_fullscreen'
                      ? dilemma.question.text
                      : (dilemma.explain && dilemma.explain.text) ||
                        '<p style="font-style: italic;">Her var det visst tomt...</p>',
                }}
              />
            </CardHtmlContentWrapper>
          </Card>
        </AnimatePresence>
      </CardWrapper>

      <ActionButtonsWrapper className={`action-buttons ${cardViewMode}`}>
        <NavigationButton
          className={`prev-card-button ${cardViewMode}`}
          type="button"
          onClick={gotoPrevDilemma}
          disabled={pageNumber === 1}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            css={css`
              margin-right: 1em;
            `}
          />
          Forrige
        </NavigationButton>

        <FlipCardButton
          className="flip-card-button"
          type="button"
          onClick={flipCard}
          title="Flipp kort"
          aria-label="Flipp kort">
          <AnimatePresence initial={false}>
            <FlipCardIconWrapper
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`flip-card-button-icon ${cardViewMode}`}
              key={cardViewMode + pageNumber}>
              <FontAwesomeIcon icon={cardViewMode === 'front' ? faEye : faEyeSlash} css={css``} />
            </FlipCardIconWrapper>
          </AnimatePresence>
        </FlipCardButton>

        <NavigationButton
          className={`next-card-button ${cardViewMode}`}
          type="button"
          onClick={gotoNextDilemma}>
          {nextPageIsFinishedPage && resultsPage.disabled ? 'Avslutt' : 'Neste'}
          <FontAwesomeIcon
            icon={faArrowRight}
            css={css`
              margin-left: 1em;
            `}
          />
        </NavigationButton>
      </ActionButtonsWrapper>

      {cardViewMode === 'front_fullscreen' && (
        <LeaveFullscreenButton
          className="leave-front-fullscreen-button"
          aria-label="Vis beskrivelse"
          type="button"
          onClick={leaveFrontCardFullscreen}>
          <FontAwesomeIcon icon={faTimes} />
        </LeaveFullscreenButton>
      )}
    </DilemmaPlayerWrapper>
  );
};
