import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Dropzone from 'react-dropzone';

import { acceptedImageFormats } from '~/config';
import { getIdForNextElement } from '~/utils/common';
import { FilePreview } from '~/modules/Editor/components/FilePreview';
import { getFilePreviewFilePropType } from '~/utils/types';

const DragHandle = SortableHandle(() => (
  <div className="questionnaire-section-editor__question-drag-handle">
    <i className="icon-reorder small" />
  </div>
));

const SortableItem = SortableElement(
  ({ affectAnswer, answer, onAnswerDeleteClick, deleteAnswerImage, uploadAnswerImage }) => (
    <div className="questionnaire-section-editor__question-row">
      <div className="questionnaire-section-editor__image-container">
        {(answer.image === 'UPLOADING' && 'Uploading...') ||
          (typeof answer.image === 'object' && (
            <div className="questionnaire-section-editor__image">
              <FilePreview
                file={answer.image}
                onDelete={() => deleteAnswerImage(answer.id)}
                cover
              />
            </div>
          )) || (
            <Dropzone
              className="questionnaire-section-editor__dropzone"
              onDrop={([file]) => uploadAnswerImage(answer.id, file)}
              accept={acceptedImageFormats.map(ext => `image/${ext}`).join(', ')}>
              {() => 'Drop file here, or click to select'}
            </Dropzone>
          )}
      </div>
      {answer.isCorrect ? (
        <div className="questionnaire-section-editor__question-is-correct">
          <button
            className="btn btn-icon btn-positive"
            onClick={() => affectAnswer(answer.id, { isCorrect: !answer.isCorrect })}>
            <i className="icon-done normal" />
          </button>
          <span>Correct</span>
        </div>
      ) : (
        <div className="questionnaire-section-editor__question-is-correct">
          <button
            className="btn btn-icon btn-negative"
            onClick={() => affectAnswer(answer.id, { isCorrect: !answer.isCorrect })}>
            <i className="icon-x normal normal" />
          </button>
          <span>Wrong</span>
        </div>
      )}
      <div className="questionnaire-section-editor__question-action-buttons">
        <button
          className="btn btn-icon btn-negative"
          onClick={() => onAnswerDeleteClick(answer.id)}>
          <i className="icon-trash small" />
        </button>
        <DragHandle />
      </div>
    </div>
  ),
);

const SortableList = SortableContainer(
  ({ affectAnswer, answers, onAnswerDeleteClick, deleteAnswerImage, uploadAnswerImage }) => (
    <div>
      {answers.map((answer, index) => (
        <SortableItem
          affectAnswer={affectAnswer}
          answer={answer}
          index={index}
          key={answer.id}
          onAnswerDeleteClick={onAnswerDeleteClick}
          deleteAnswerImage={deleteAnswerImage}
          uploadAnswerImage={uploadAnswerImage}
        />
      ))}
    </div>
  ),
);

export class PictureAnswersTab extends Component {
  constructor(props) {
    super(props);

    this.onAnswerDeleteClick = this.onAnswerDeleteClick.bind(this);
    this.addEmptyAnswer = this.addEmptyAnswer.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.affectAnswer = this.affectAnswer.bind(this);
  }

  onAnswerDeleteClick(answerId) {
    const { answers, setAnswers } = this.props;
    setAnswers(answers.filter(answer => answer.id !== answerId));
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { answers, setAnswers } = this.props;
    const reorderedAnswers = arrayMove(answers, oldIndex, newIndex);
    setAnswers(reorderedAnswers);
  }

  addEmptyAnswer(e) {
    e.preventDefault();
    const { answers } = this.props;
    this.props.setAnswers([
      ...answers,
      {
        id: getIdForNextElement(answers),
        isCorrect: true,
        image: undefined,
      },
    ]);
  }

  affectAnswer(answerId, data) {
    const { answers, setAnswers } = this.props;
    setAnswers(
      answers.map(answer => {
        if (answer.id !== answerId) return answer;
        return {
          ...answer,
          ...data,
        };
      }),
    );
  }

  render() {
    const { answers, deleteQuestionnaireAnswerImage, uploadQuestionnaireAnswerImage } = this.props;
    return (
      <div className="material-tabs__content">
        <div className="questionnaire-section-editor__questions-list">
          <div className="questionnaire-section-editor__questions-list">
            <SortableList
              affectAnswer={this.affectAnswer}
              answers={answers}
              onAnswerDeleteClick={this.onAnswerDeleteClick}
              onSortEnd={this.onSortEnd}
              deleteAnswerImage={deleteQuestionnaireAnswerImage}
              uploadAnswerImage={uploadQuestionnaireAnswerImage}
              useDragHandle
            />
          </div>
          <form onSubmit={this.addEmptyAnswer}>
            <div className="questionnaire-section-editor__actions-buttons-row">
              <button className="btn btn-blue">Add answer</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

PictureAnswersTab.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCorrect: PropTypes.bool.isRequired,
      image: getFilePreviewFilePropType(),
    }),
  ).isRequired,
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
  deleteQuestionnaireAnswerImage: PropTypes.func.isRequired,
  uploadQuestionnaireAnswerImage: PropTypes.func.isRequired,
};
