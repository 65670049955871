import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getFilePreviewFilePropType } from '~/utils/types';
import { FilePreview } from '~/modules/Player/components/FilePreview';

export class DndSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [...props.options],
      selected: [],
    };
    this.id2List = {
      droppable: 'items',
      droppable2: 'selected',
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.getList = this.getList.bind(this);
  }

  onDragEnd({ source, destination }) {
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const [movedItem] = this.getList(source.droppableId).splice(source.index, 1);
      this.props.onChange(movedItem.id);
    }
  }

  getList(id) {
    switch (id) {
      case 'droppable':
        return [...this.props.options];
      case 'droppable2':
        return this.props.options.filter(option => this.props.checked.has(option.id));
      default:
        return undefined;
    }
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(listProvided, listSnapshot) => (
            <div
              ref={listProvided.innerRef}
              className={classNames([
                'droppable__list',
                { 'droppable__list--dragging-over': listSnapshot.isDraggingOver },
              ])}>
              {this.getList('droppable').map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={this.props.checked.has(item.id)}>
                  {(itemProvided, itemSnapshot) => (
                    <div
                      ref={itemProvided.innerRef}
                      {...itemProvided.draggableProps}
                      {...itemProvided.dragHandleProps}
                      className={classNames([
                        'droppable__item',
                        { 'droppable__item--dragging': itemSnapshot.isDragging },
                      ])}>
                      <FilePreview
                        cover
                        file={item.image}
                        greyscale={this.props.checked.has(item.id)}
                      />
                      {this.props.checked.has(item.id) && (
                        <button
                          className="droppable__item-delete"
                          onClick={() => this.props.onChange(item.id)}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {listProvided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="droppable2" direction="horizontal">
          {(listProvided, listSnapshot) => (
            <div
              ref={listProvided.innerRef}
              className={classNames([
                'droppable__bucket',
                { 'droppable__bucket--dragging-over': listSnapshot.isDraggingOver },
              ])}>
              Drag here
              <div className="droppable__bucket-volume">{this.props.checked.size}</div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

DndSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: getFilePreviewFilePropType(),
    }),
  ).isRequired,
  checked: PropTypes.shape({
    has: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
