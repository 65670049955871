import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { backendUrl } from '~/config';
import { getFilePreviewFilePropType } from '~/utils/types';

export class Video extends Component {
  constructor(props) {
    super(props);
    this.videoTime = 0;
  }
  componentDidMount() {
    const video = document.querySelectorAll('video')[0];
    if (video) {
      video.addEventListener('seeking', () => {
        if (video.currentTime <= this.videoTime) return;
        video.currentTime = this.videoTime;
      });

      video.addEventListener('ended', () => {
        this.props.setCollectedData({ displayed: true });
        this.props.permitContinue();
      });

      setInterval(() => {
        if (video.currentTime - this.videoTime > 0.2) return; // prevent update when seeking
        this.videoTime = video.currentTime;
      }, 100);
    }
  }

  render() {
    const {
      data: { video },
    } = this.props;
    return (
      <>
        <div className="player-video__container">
          {video.file && video.file !== 'UPLOADING' ? (
            <video
              autoPlay={video.autoPlay}
              className="player-video__video"
              controls
              playsInline
              poster={video.poster ? `${backendUrl}${video.poster.url}` : ''}>
              <source src={`${backendUrl}${video.file.url}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="player-video__no-video-info">No video file provided.</div>
          )}
        </div>
        <div className="player-video__description-container">
          <div className="player-video__title">{video.meta.title}</div>
          <div className="player-video__subtitle">{video.meta.subtitle}</div>
        </div>
      </>
    );
  }
}

Video.propTypes = {
  data: PropTypes.shape({
    video: PropTypes.shape({
      file: getFilePreviewFilePropType(),
      autoPlay: PropTypes.bool,
      meta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
      }).isRequired,
      poster: getFilePreviewFilePropType(),
    }).isRequired,
  }).isRequired,
  setCollectedData: PropTypes.func.isRequired,
  permitContinue: PropTypes.func.isRequired,
};
