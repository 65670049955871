import React from 'react';
import * as styles from './styles';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopSideBar = ({ page, active = 1, showSettings, toggleSettings, setPage }) =>
  page ? (
    <div css={styles.wrapper}>
      {page.templateTypeId === 'TEXT-CONTENT' && (
        <div css={styles.pagesSection}>
          <div css={[styles.btn, active === 1 ? styles.btnActive : null]} />
        </div>
      )}
      {page.templateTypeId === 'TEXT-DILEMMA' && (
        <div css={styles.pagesSection}>
          <div
            css={[styles.btn, active === 1 ? styles.btnActive : null]}
            onClick={() => setPage(1)}
            role="button"
            tabIndex="0"
          />
          <div
            css={[styles.btn, active === 2 ? styles.btnActive : null]}
            onClick={() => setPage(2)}
            role="button"
            tabIndex="0"
          />
        </div>
      )}
      {['TEXT-PICTURESQUESTIONNAIRE', 'TEXT-QUESTIONNAIRE'].includes(page.templateTypeId) && (
        <div css={styles.pagesSection}>
          <div
            css={[styles.btn, active === 1 ? styles.btnActive : null]}
            onClick={() => setPage(1)}
            role="button"
            tabIndex="0"
          />
          <div
            css={[styles.btn, active === 2 ? styles.btnActive : null]}
            onClick={() => setPage(2)}
            role="button"
            tabIndex="0"
          />
          <div
            css={[styles.btn, active === 3 ? styles.btnActive : null]}
            onClick={() => setPage(3)}
            role="button"
            tabIndex="0"
          />
        </div>
      )}
      <div
        css={[styles.settingsBtn, showSettings ? styles.settingsBtnActive : null]}
        onClick={toggleSettings}
        role="button"
        tabIndex="0">
        <FontAwesomeIcon icon={faCog} style={{ fontSize: 12, marginTop: -4 }} />
      </div>
    </div>
  ) : null;
