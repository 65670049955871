import { css } from '@emotion/core';
import { mqMaxWidth } from '../../styles/facepaint';

export const topStatusBarStyle = css`
  left: 50%;
  transform: translateX(-50%);
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 80%;
  max-width: 80%;

  ${mqMaxWidth.small} {
    width: 100%;
    max-width: 100%;
  }
`;
