import styled from '@emotion/styled';
import { highlightMixin, measureCap, mq } from '~/utils/facepaint';
import { screenreaderOnlyMixin } from '~/styles/utils/utils';
import { rgba } from 'polished';

export const Box = styled('div')(
  {
    position: 'relative',
    borderRadius: 8,
    backgroundColor: '#fff',
    border: `1px solid ${rgba('#000', 0.25)}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [mq.small]: {
      fontSize: '1em',
    },
    [mq.medium]: {
      fontSize: '1.25em',
    },
    [mq.large]: {
      fontSize: '1.5em',
    },
    '&:hover::before, &.selected::before': {
      ...highlightMixin,
    },
    '&.correct::before': {
      ...highlightMixin,
      borderColor: '#32D74B',
    },
    '&.incorrect::before': {
      ...highlightMixin,
      borderColor: 'red',
    },
    '&:hover, & label:hover': {
      cursor: 'pointer',
    },
    '& input': {
      ...screenreaderOnlyMixin,
    },

    margin: '8px 0',
  },
  measureCap,
  props => ({
    backgroundColor: props.checked ? 'red' : null,
  }),
);

export const ActionButton = styled('button')(
  {
    border: 'none',
    position: 'relative',
    top: '1em',
    outline: 'none',
    padding: '0.68em 1.5em',
    color: 'white',
    borderRadius: '21px',
  },
  props => ({
    visibility: props.visible || props.visible === undefined ? 'visible' : 'hidden',
    backgroundColor: props.background,
    margin: props => (props.margin ? props.margin : 'unset'),
  }),
);

export const AnswerInstruction = styled('p')({
  color: '#EBEBF5',
  marginBottom: '0',
  textAlign: 'center',
});
