import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { backendUrl } from '~/config';
import { getFilePreviewFilePropType } from '~/utils/types';

export class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = { imageLoaded: false };
    this.image = React.createRef();
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  componentDidMount() {
    const img = this.image.current;
    if (img && img.complete) {
      this.handleImageLoaded();
    }
  }

  handleImageLoaded() {
    if (!this.state.imageLoaded) {
      this.setState({ imageLoaded: true });
      const img = this.image.current;
      const aspect = img.naturalWidth / img.naturalHeight;
      img.width = img.height * aspect;
    }
  }

  render() {
    const { asElement, cover, file, height, onDelete } = this.props;
    return file ? (
      <div className="file-preview__container">
        {(file === 'UPLOADING' && <div className="file-preview__no-file-info">Uploading...</div>) ||
          (file.url && (
            <div
              className={classNames({
                'file-preview__file-container': !asElement,
                'file-preview__file-as-element-container': asElement,
              })}>
              {(file.type === 'image' && !asElement && (
                <div
                  className={classNames([
                    'file-preview__image',
                    { 'file-preview__image--cover': cover },
                  ])}
                  style={{ backgroundImage: `url(${backendUrl}${file.url})` }}
                />
              )) ||
                (file.type === 'image' && asElement && (
                  <img
                    className="file-preview__image-as-element"
                    src={`${backendUrl}${file.url}`}
                    alt="preview"
                    height={height}
                    ref={this.image}
                    onLoad={this.handleImageLoaded}
                  />
                )) ||
                (file.type === 'video' && (
                  <video className="file-preview__video" controls style={{ height }}>
                    <source src={`${backendUrl}${file.url}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))}
              {onDelete && (
                <button
                  className="file-preview__delete"
                  onClick={onDelete}
                  ref={this.deleteButton}
                />
              )}
            </div>
          )) ||
          ''}
      </div>
    ) : (
      <div className="file-preview__container" />
    );
  }
}

FilePreview.propTypes = {
  asElement: PropTypes.bool,
  cover: PropTypes.bool,
  file: getFilePreviewFilePropType(),
  height: PropTypes.number,
  onDelete: PropTypes.func,
};

FilePreview.defaultProps = {
  asElement: false,
  cover: false,
  file: undefined,
  height: 200,
  onDelete: undefined,
};
