export const postMessage = (tabMessage, iframeMessage) => {
  if (window.frameElement) {
    // in frame
    window.parent.postMessage(iframeMessage, '*');
  } else if (window.opener) {
    // not in frame
    window.opener.postMessage(tabMessage, '*');
    window.close();
  } else {
    window.top.postMessage(iframeMessage, '*');
  }
};
