

import React, { useRef, useState, useCallback } from 'react';
import * as styles from '../styles';
import { Dropdown } from '../Dropdown';
import { Tooltip } from '../Tooltip';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { ToolbarText } from './ToolbarText';

export const ToolbarPadding = ({ value, setValue, label, icon, hideLabel }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const setPadding = useCallback((index, newValue) => {
    console.log('value', newValue)
    value[index] = newValue;
    setValue(value);
  }, [setValue, value]);



  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(styles.toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={styles.dropdownBgStyle}
          parentRef={ref}
          onClose={() => {
            setOpen(false);
          }}>
          <div css={css(styles.dropdownContainerStyle)}>
            <div css={css(styles.dropdownPaddingStyle)}>
              <ToolbarText
                type="number"
                value={value[0]}
                setValue={(value) => setPadding(0, value)}
              />
              <ToolbarText
                type="number"
                value={value[1]}
                setValue={(value) => setPadding(1, value)}
              />
              <ToolbarText
                type="number"
                value={value[2]}
                setValue={(value) => setPadding(2, value)}
              />
              <ToolbarText
                type="number"
                value={value[3]}
                setValue={(value) => setPadding(3, value)}
              />
            </div>
          </div>
        </Dropdown>
      )}
    </>
  );
};

