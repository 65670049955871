import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFilePreviewFilePropType } from '~/utils/types';
import { FilePreview } from '~/modules/Player/components/FilePreview';

export const CheckboxSelect = ({
  checked,
  disabled,
  groupName,
  onChange,
  options,
  selectingImages,
}) => (
  <div className={classNames({ 'radio-images-container': selectingImages })}>
    {options.map(option => (
      <div
        className={classNames({
          'checkbox-image': selectingImages,
          'md-checkbox': !selectingImages,
        })}
        key={option.id}>
        <input
          checked={checked.has(option.id)}
          disabled={disabled}
          id={`${groupName}-${option.id}`}
          name={`${groupName}-${option.id}`}
          onChange={() => onChange(option.id)}
          type="checkbox"
        />
        <label htmlFor={`${groupName}-${option.id}`}>
          {selectingImages ? (
            <div className="checkbox-image__file-preview-container">
              <FilePreview file={option.image} cover />
            </div>
          ) : (
            option.label
          )}
        </label>
      </div>
    ))}
  </div>
);

CheckboxSelect.propTypes = {
  checked: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: getFilePreviewFilePropType(),
      }),
    ]),
  ).isRequired,
  selectingImages: PropTypes.bool,
};

CheckboxSelect.defaultProps = {
  disabled: false,
  selectingImages: false,
};
