import {
  faEye,
  faEyeSlash,
  faCassetteTape,
  faTrashAlt,
  faRulerCombined,
  faBringForward,
  faSendBackward,
} from '@fortawesome/pro-solid-svg-icons';
import { itemDefinitions } from '../definitions/item-definitions';

export const commonItemFields = [
  { type: 'separator' },
  {
    icon: faRulerCombined,
    type: 'anchor',
    label: 'Rediger responsiv posisjonering',
    hideLabel: true,
    onClick: ({ id, removeItem }) => {
      removeItem(id);
    },
    visible: ({ dynamicLayout, item: { type } }) =>
      !dynamicLayout || itemDefinitions[type]?.dynamicLayout === false,
  },
  {
    icon: faBringForward,
    type: 'button',
    hotkey: ['ctrl+up', 'command+up'],
    label: 'Flytt fremover',
    hideLabel: true,
    onClick: ({ id, moveLayerForward }) => {
      moveLayerForward(id);
    },
    visible: ({ dynamicLayout, item: { type } }) =>
      !dynamicLayout || itemDefinitions[type]?.dynamicLayout === false,
  },
  {
    icon: faSendBackward,
    type: 'button',
    hotkey: ['ctrl+down', 'command+down'],
    label: 'Flytt bakover',
    hideLabel: true,
    onClick: ({ id, moveLayerBackward }) => {
      moveLayerBackward(id);
    },
    visible: ({ dynamicLayout, item: { type } }) =>
      !dynamicLayout || itemDefinitions[type]?.dynamicLayout === false,
  },
  {
    name: 'animatePath',
    icon: faCassetteTape,
    label: 'Animasjon',
    hideLabel: true,
    type: 'action',
  },
  // {
  //   icon: faObjectGroup,
  //   type: 'group',
  //   label: 'Grupper elementer',
  //   visible: false, // WIP
  //   hideLabel: true,
  // },
  { type: 'separator' },
  {
    icon: faEye,
    type: 'button',
    label: 'Skjul element',
    hideLabel: true,
    visible: ({ showHiddenItems, item: { hidden } }) => showHiddenItems && !hidden,
    onClick: ({ id, updateItem }) => {
      updateItem(id, { hidden: true });
    },
  },
  {
    icon: faEyeSlash,
    type: 'button',
    label: 'Vis element',
    hideLabel: true,
    visible: ({ showHiddenItems, item: { hidden } }) => showHiddenItems && hidden,
    onClick: ({ id, updateItem }) => {
      updateItem(id, { hidden: false });
    },
  },
  { type: 'separator' },
  {
    icon: faTrashAlt,
    type: 'button',
    label: 'Slett element',
    hideLabel: true,
    hotkey: 'delete',
    onClick: ({ id, removeItem }) => {
      removeItem(id);
    },
  },
];
