import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import debounce from 'debounce';

import { FormSwitch } from '~/components/FormSwitch';

import { positiveNumber } from '~/utils/validators';

class RandomizeSection extends Component {
  constructor(props) {
    super(props);

    this.debounce = debounce;
  }

  pointsValidator(value, allValues) {
    return allValues.pointsEnabled ? positiveNumber(value) : undefined;
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="questionnaire-section-editor__settings-container" autoComplete="off">
        <div className="questionnaire-section-editor__settings-subsection">
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormSwitch}
              label="Random"
              name="useRandomize"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              type="boolean"
            />
          </div>
        </div>
      </form>
    );
  }
}

RandomizeSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const RandomizeSectionForm = reduxForm({
  form: 'speedCourseRandomizeSectionForm',
})(RandomizeSection);
