import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { getDictionary } from '~/utils/dictionary';

export const PlayerResultModal = ({ onContinueClick, questionnaireResult }) => (
  <Modal
    basic
    mountNode={document.querySelector('#modal-root')}
    open
    size="small"
    className="questionnaire-result__modal">
    <Modal.Content>
      <div className="questionnaire-result__container">
        <div className={'questionnaire-result__header'}>{questionnaireResult.header}</div>
        <div className="questionnaire-result__text">{questionnaireResult.text}</div>
        <div className="questionnaire-result__nav-buttons">
          <button
            className="btn btn-continue"
            onClick={() => {
              onContinueClick();
            }}>
            {getDictionary().CLOSE}
          </button>
        </div>
      </div>
    </Modal.Content>
  </Modal>
);

PlayerResultModal.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  questionnaireResult: PropTypes.shape({
    isCorrect: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};
