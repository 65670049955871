import React from 'react';
import { Wrapper, SlideWrapper, NavigationButtonsWrapper, NavigationButton } from './styles';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { PictureSlide } from '~/modules/Editor/components/PictureSlides/PictureSlide';
import { getSlide } from '~/stores/player/selectors/course.selectors';
import { useSelector } from 'react-redux';

/* Known as Content Slides in the editor

Displays slides from a picture-slides-page.
A slide is text and image-content in a linear fashion.
The first text-element is considered the header.
*/
export const PictureSlides = ({
  data: { slides },
  courseType,
  onGotoNext,
  onGotoPrev,
  slideNumber,
  pageNumber,
}) => {
  const backNotAllowed = pageNumber === 1 && slideNumber === 1;
  const slide = useSelector(getSlide);

  const onClickNext = evt => {
    if (evt) {
      evt.stopPropagation();
    }
    onGotoNext(true);
  };

  const onClickPrev = evt => {
    if (evt) {
      evt.stopPropagation();
    }
    onGotoPrev(true);
  };
  const onClickSlide = evt => {
    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const clickedAtXPos = evt.clientX;
    if (clickedAtXPos < viewportWidth / 2) {
      if (!backNotAllowed) {
        onClickPrev();
      }
    } else {
      onClickNext();
    }
  };
  const customCss = {};
  if (slide) {
    customCss.backgroundColor = slide.bgColor;
    if (slide.bgImage) {
      customCss.backgroundImage = `url('${slide.bgImage.url}')`;
      customCss.backgroundSize = 'cover';
    }
  }

  return slide ? (
    <Wrapper className="pictureslides-wrapper" onClick={onClickSlide}>
      <SlideWrapper css={customCss} className="slide-wrapper">
        <PictureSlide />
        {courseType === '2' && (
          <div
            css={{
              width: '100%',
              position: 'absolute',
              bottom: 20,
              height: 200,
              display: 'flex',
              margin: '0 auto',
              justifyContent: 'center'
            }}
          >
            {slides.map((page_slide, i) => (
              <div
                css={{
                  width: 300,
                  padding: 20,
                  cursor: 'pointer',
                  height: '100%',
                  marginRight: 4,
                  color:(page_slide.id === slide.id ? 'white' : 'black'),
                  backgroundColor:(page_slide.id === slide.id ? 'black' : 'transparent'),
                }}
              >
                <h2>Side</h2>
                <h3>{i + 1}</h3>
              </div>
            ))}
          </div>
        ) || (
          <NavigationButtonsWrapper>
            <NavigationButton
              disabled={backNotAllowed}
              className="prev-button"
              type="button"
              onClick={onClickPrev}
              title="Forrige">
              <FontAwesomeIcon
                icon={faArrowLeft}
                css={css`
                margin-left: 1em;
              `}
              />
            </NavigationButton>
            <NavigationButton
              className="next-button"
              type="button"
              onClick={onClickNext}
              title="Neste">
              <FontAwesomeIcon
                icon={faArrowRight}
                css={css`
                margin-left: 1em;
              `}
              />
            </NavigationButton>
          </NavigationButtonsWrapper>
        )}
      </SlideWrapper>
    </Wrapper>
  ) : null;
};
