import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash, faImage, faPalette } from '@fortawesome/pro-solid-svg-icons';
import Dropzone from 'react-dropzone';
import { SketchPicker } from 'react-color';
import React from 'react';

export const SlideBackground = ({
  activeSlide,
  deleteSlideImage,
  handleSettingChange,
  handleChange,
  uploadSlideImage,
  uploadPicture,
  showColor,
  deleteImage,
  bgTypes,
  sectionClick,
  openSections,
  showColorAction,
  acceptedImageFormats,
}) => (
  <div
    className={
      'settings__container__section' +
      (openSections.filter(s => s === 'background-background').length === 0 ? ' collapse' : '')
    }>
    <div className="content settings__container__background">
      <div
        css={{
          marginTop: 10,
          marginBottom: 10,
        }}>
        <FontAwesomeIcon icon={faImage} style={{ marginRight: 10 }} />
        Background image
      </div>
      {typeof activeSlide.image === 'object' ||
        (typeof activeSlide.bgImage === 'object' && (
          <div
            css={{
              marginLeft: 26,
            }}
            className="remove"
            onClick={() => {
              deleteImage(typeof activeSlide.image === 'object' ? activeSlide.id : null);
            }}>
            <FontAwesomeIcon icon={faTrash} /> Remove
          </div>
        )) ||
        activeSlide.bgImage === 'UPLOADING' ||
        (activeSlide.image === 'UPLOADING' && (
          <div className="picture-slides-editor__slide-image-dropzone">uploading...</div>
        )) || (
          <div
            className="add-new"
            css={{
              marginLeft: 20,
              borderWidth: 1,
              borderStyle: 'dotted',
              padding: 5,
              borderRadius: 5,
              textAlign: 'center',
              cursor: 'pointer',
            }}>
            <Dropzone
              onDrop={([file]) => uploadPicture(file)}
              accept={acceptedImageFormats.map(ext => `image/${ext}`).join(', ') + ',video/mp4'}
              className="picture-slides-editor__slide-image-dropzone">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Add file</p>
                </div>
              )}
            </Dropzone>
          </div>
        )}
      <div className="spacer" />
      <div className="settings__container__color-picker">
        <div
          css={{
            marginTop: 20,
          }}>
          <FontAwesomeIcon icon={faPalette} style={{ marginRight: 10 }} />
          Color
        </div>
        <div
          css={{
            width: '100%',
            height: 61,
            padding: 10,
            marginLeft: 30,
            marginTop: 4,
            marginBottom: 16,
            position: 'relative',
            paddingLeft: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {[
            '#007AFF',
            '#FF3B30',
            '#FFCC00',
            '#34C759',
            '#FF2D55',
            '#5856D6',
            '#FBB54C',
            '#010236',
            '#B21A5E',
            '#E2E6EF',
            '#203E51',
            '#50A3EF',
          ].map(color => (
            <div
              key={`color-${color.replace('#', '')}`}
              css={{
                position: 'relative',
                marginBottom: '1em',
              }}>
              <div
                css={{
                  marginRight: 16,
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                  backgroundColor: [color],
                }}
                onClick={() => handleChange(color)}
              />
              {activeSlide.bgColor && activeSlide.bgColor === color && (
                <div
                  css={{
                    marginRight: 10,
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    borderWidth: 2,
                    borderRadius: 30,
                    borderStyle: 'solid',
                    marginTop: '-25px',
                    marginLeft: '-5px',
                    borderColor: [color],
                    zIndex: 10,
                  }}
                  onClick={() => handleChange('')}
                />
              )}
            </div>
          ))}
        </div>
        <div
          className="picker-placement"
          style={{ backgroundColor: activeSlide.bgcolor }}
          onClick={() => showColorAction(!showColor)}></div>

        {activeSlide.bgcolor && (
          <div
            className="settings__container__color-picker__bg__remove"
            onClick={() => handleChange('bgColor', '')}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}

        {showColor && (
          <div className="settings__container__color-picker__bg__popup">
            <SketchPicker
              disableAlpha={true}
              onChange={color => handleChange('bgColor', color)}
              color={activeSlide.bgcolor}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);
