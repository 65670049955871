import { combineReducers, applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { all as allSagas } from 'redux-saga/effects';

import { reducer as formReducer } from 'redux-form';
import { course as courseReducer } from './reducers/course.reducer';
import { config as configReducer } from './reducers/config.reducer';

import { sagas as courseSagas } from './sagas/course.sagas';
import { sagas as configSagas } from './sagas/config.sagas';

function* rootSaga() {
  yield allSagas([...courseSagas, ...configSagas]);
}
export const configureStore = initialState => {
  const reducers = combineReducers({
    course: courseReducer,
    form: formReducer,
    config: configReducer,
  });

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware];

  let enhancer;
  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  } else {
    enhancer = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(reducers, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};
