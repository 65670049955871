import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCoursePagePropType, getFilePreviewFilePropType } from '~/utils/types';

import { TextInputAnswersTab } from './Tabs/TextInputAnswersTab';
import { TextTabForm } from '../Questionnaire/Tabs/TextTab';
import { RedirectionsTabForm } from '../Questionnaire/Tabs/RedirectionsTab';
import { FormSelect } from '~/components/FormSelect';
import Slider from 'rc-slider';

const userInputTypes = {
  'User input': 'user-input',
  'Multiple choice': 'multiple-choice',
  'Single choice': 'single-choice',
  Scale: 'scale',
};
const userScaleTypes = {
  Stars: 'stars',
  Hearts: 'hearts',
  'Thumbs up': 'thumbs-up',
};

export class QuestionnaireInput extends Component {
  constructor(props) {
    super(props);

    this.tabs = ['QUESTIONS', 'MESSAGES', 'REDIRECTIONS'];

    this.state = {
      activeTab: this.tabs[0],
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.setAnswers = this.setAnswers.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.setTexts = this.setTexts.bind(this);
    this.setInput = this.setInput.bind(this);
    this.setRedirections = this.setRedirections.bind(this);
  }

  setActiveTab(tabName) {
    this.setState({
      activeTab: tabName,
    });
  }

  setAnswers(answers) {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      answers,
    });
  }

  setQuestion = ({ target: { value: question } }) => {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      question,
    });
  };

  setSettings(settings) {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      settings,
    });
  }

  setInput(input) {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      input,
    });
  }

  setTexts(texts) {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      texts,
    });
  }

  setRedirections(redirections) {
    const { data } = this.props;
    this.props.onValueUpdate({
      ...data,
      redirections: {
        onSuccessPageId: redirections.onSuccessPage ? redirections.onSuccessPage.id : null,
        onFailurePageId: redirections.onFailurePage ? redirections.onFailurePage.id : null,
      },
    });
  }

  render() {
    const { activeTab } = this.state;
    const {
      data: { question, answers, input, texts, redirections },
      pages,
    } = this.props;
    const redirectionsPages = [{ title: 'default' }, ...pages];

    return (
      <div className="questionnaire-section-editor__container">
        <FormSelect
          name="backgroundType"
          defaultValue={'User input'}
          input={{
            value: Object.keys(userInputTypes).filter(key => userInputTypes[key] === input.type)[0],
            onChange: value => {
              this.setInput({ type: userInputTypes[value] });
            },
          }}
          meta={{ touched: false, error: false }}
          data={Object.keys(userInputTypes)}
          label="Input type"
        />
        {input && input.type === 'scale' && (
          <>
            <FormSelect
              name="backgroundType"
              defaultValue={'Stars'}
              input={{
                value: Object.keys(userScaleTypes).filter(
                  key => userScaleTypes[key] === input.scaletype,
                )[0],
                onChange: value => {
                  this.setInput({
                    type: input.type,
                    range: input.range,
                    scaletype: userScaleTypes[value],
                  });
                },
              }}
              meta={{ touched: false, error: false }}
              data={Object.keys(userScaleTypes)}
              label="Input type"
            />
            <div style={{ width: '300px', paddingLeft: 14, marginTop: 10 }}>
              <div style={{ paddingBottom: 10 }}>
                Steps:&nbsp;&nbsp;
                {input.range}
              </div>
              <Slider
                max={10}
                min={2}
                step={1}
                value={input.range}
                onChange={value =>
                  this.setInput({
                    type: input.type,
                    range: value,
                    scaletype: input.scaletype,
                  })
                }
              />
            </div>
          </>
        )}
        {input &&
          (input.type === 'single-choice' || input.type === 'multiple-choice') &&
          {
            QUESTIONS: (
              <TextInputAnswersTab
                addAnswer={this.addAnswer}
                answers={answers}
                question={question}
                reorderAnswers={this.reorderAnswers}
                setAnswers={this.setAnswers}
                setQuestion={this.setQuestion}
              />
            ),
            MESSAGES: (
              <TextTabForm
                initialValues={{
                  ...texts,
                }}
                onSubmit={this.setTexts}
              />
            ),
            REDIRECTIONS: (
              <RedirectionsTabForm
                initialValues={{
                  onSuccessPage:
                    redirections &&
                    redirectionsPages.find(page => page.id === redirections.onSuccessPageId),
                  onFailurePage:
                    redirections &&
                    redirectionsPages.find(page => page.id === redirections.onFailurePageId),
                }}
                pages={redirectionsPages}
                onSubmit={this.setRedirections}
              />
            ),
          }[activeTab]}
      </div>
    );
  }
}

QuestionnaireInput.propTypes = {
  data: PropTypes.shape({
    input: PropTypes.shape({
      type: PropTypes.number,
      range: PropTypes.number,
      rangetype: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCorrect: PropTypes.bool.isRequired,
        image: getFilePreviewFilePropType(),
        text: PropTypes.string,
      }),
    ).isRequired,
    question: PropTypes.string.isRequired,
    texts: PropTypes.shape({
      textWhenGood: PropTypes.string.isRequired,
      textWhenWrong: PropTypes.string.isRequired,
    }),
    redirections: PropTypes.shape({
      onSuccessPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onFailurePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  pages: PropTypes.arrayOf(getCoursePagePropType()).isRequired,
  onValueUpdate: PropTypes.func.isRequired,
};
