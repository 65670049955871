import React, { useRef, forwardRef, useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

export const Overlay = React.memo(
  forwardRef(({ className, children }, ref) => {
    const portalRef = useRef(document.createElement('div'));

    useEffect(() => {
      const overlay = portalRef.current;

      const { style } = overlay;
      style.position = 'fixed';
      style.overflow = 'visible';
      style.margin = 0;
      style.padding = 0;

      document.body.append(overlay);

      return () => {
        overlay.remove();
      };
    }, []);

    useLayoutEffect(() => {
      portalRef.current.className = className ?? '';
    }, [className]);

    useLayoutEffect(() => {
      if (typeof ref === 'function') {
        ref(portalRef.current);
      } else if (ref) {
        ref.current = portalRef.current;
      }
    }, [ref]);

    return children && portalRef.current && createPortal(children, portalRef.current);
  }),
);
