import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import { acceptedImageFormats, acceptedVideoFormats } from '~/config';
import { getCoursePagePropType, getFilePreviewFilePropType } from '~/utils/types';
import { getRedirectPageId, getRedirectPages } from '~/utils/course';
import { FormInput } from '~/components/FormInput';
import { FormSwitch } from '~/components/FormSwitch';
import { FilePreview } from '~/modules/Editor/components/FilePreview';
import { RedirectForm as Redirections } from '~/modules/Editor/components/Redirect/Redirect';

export class Video extends Component {
  constructor(props) {
    super(props);

    this.tabs = ['VIDEO', 'REDIRECTIONS'];

    this.state = {
      activeTab: this.tabs[0],
    };

    this.onAutoplayChange = this.onAutoplayChange.bind(this);
    this.onMetaChange = this.onMetaChange.bind(this);
    this.uploadVideoSource = this.uploadVideoSource.bind(this);
    this.uploadVideoPoster = this.uploadVideoPoster.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  onAutoplayChange(value) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      video: {
        ...data.video,
        autoPlay: value,
      },
    });
  }

  onMetaChange(meta) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      video: {
        ...data.video,
        meta: {
          ...data.video.meta,
          ...meta,
        },
      },
    });
  }

  setActiveTab(tabName) {
    this.setState({
      activeTab: tabName,
    });
  }

  uploadVideoSource([file]) {
    this.props.uploadVideoSource(file);
  }

  uploadVideoPoster([file]) {
    this.props.uploadVideoPoster(file);
  }

  render() {
    const { activeTab } = this.state;

    const {
      page,
      pages,
      data: { video },
      onPageDataChange,
    } = this.props;

    return (
      <div className="video-editor__container">
        <div className="material-tabs__container">
          <div className="material-tabs__header">
            <div
              className={classNames('material-tabs__tab-title', { active: activeTab === 'VIDEO' })}
              onClick={() => this.setActiveTab('VIDEO')}
              role="button"
              tabIndex={0}>
              Video
            </div>
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'REDIRECTIONS',
              })}
              onClick={() => this.setActiveTab('REDIRECTIONS')}
              role="button"
              tabIndex={0}>
              Redirections
            </div>
          </div>

          <div className="material-tabs__content">
            {
              {
                VIDEO: (
                  <div className="video-editor__container">
                    <h4>File</h4>
                    <div className="video-editor__video-container">
                      {(!video.file && (
                        <Dropzone
                          className="video-editor__dropzone"
                          onDrop={this.uploadVideoSource}
                          accept={acceptedVideoFormats.map(ext => `video/${ext}`).join(', ')}>
                          {() => 'No video added yet. Drop your files or click here to upload.'}
                        </Dropzone>
                      )) ||
                        (video.file === 'UPLOADING' && (
                          <div className="video-editor__video-file-status">Uploading video...</div>
                        )) || (
                          <div className="video-editor__video">
                            <FilePreview
                              file={video.file}
                              onDelete={() => this.props.deleteVideoSource()}
                              asElement
                              height={240}
                            />
                          </div>
                        )}
                    </div>
                    <h4>Poster</h4>
                    <div className="video-editor__poster-container">
                      {(!video.poster && (
                        <Dropzone
                          className="video-editor__dropzone"
                          onDrop={this.uploadVideoPoster}
                          accept={acceptedImageFormats.map(ext => `image/${ext}`).join(', ')}>
                          {() => 'No poster added yet. Drop your file or click here to upload.'}
                        </Dropzone>
                      )) ||
                        (video.poster === 'UPLOADING' && (
                          <div className="video-editor__poster-file-status">
                            Uploading poster...
                          </div>
                        )) || (
                          <div className="video-editor__poster">
                            <FilePreview
                              file={video.poster}
                              onDelete={() => this.props.deleteVideoPoster()}
                              asElement
                              height={200}
                            />
                          </div>
                        )}
                    </div>
                    <div className="video-editor__input-container">
                      <FormSwitch
                        input={{
                          value: video.autoPlay,
                          onChange: () => {
                            this.onAutoplayChange(!video.autoPlay);
                          },
                        }}
                        label="Autoplay"
                      />
                    </div>
                    <div className="video-editor__input-container">
                      <FormInput
                        input={{
                          value: video.meta.title,
                          onChange: ({ target: { value } }) => {
                            this.onMetaChange({ title: value });
                          },
                        }}
                        label="Title"
                      />
                    </div>
                    <div className="video-editor__input-container">
                      <FormInput
                        input={{
                          value: video.meta.subtitle,
                          onChange: ({ target: { value } }) => {
                            this.onMetaChange({ subtitle: value });
                          },
                        }}
                        label="Subtitle"
                      />
                    </div>
                  </div>
                ),
                REDIRECTIONS: (
                  <Redirections
                    initialValues={{
                      redirectPage: getRedirectPageId(page, pages),
                    }}
                    pages={getRedirectPages(page, pages)}
                    handleChange={({ id }) => onPageDataChange({ redirectPageId: id })}
                  />
                ),
              }[activeTab]
            }
          </div>
        </div>
      </div>
    );
  }
}

Video.propTypes = {
  data: PropTypes.shape({
    video: PropTypes.shape({
      autoPlay: PropTypes.bool,
      file: getFilePreviewFilePropType(),
      meta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  page: getCoursePagePropType().isRequired,
  pages: PropTypes.arrayOf(getCoursePagePropType()).isRequired,
  onPageDataChange: PropTypes.func.isRequired,
  onValueUpdate: PropTypes.func.isRequired,
  deleteVideoSource: PropTypes.func.isRequired,
  uploadVideoSource: PropTypes.func.isRequired,
  deleteVideoPoster: PropTypes.func.isRequired,
  uploadVideoPoster: PropTypes.func.isRequired,
};
