import React, { useRef, useState, useContext, useCallback, useEffect } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { SceneContext } from '../SceneContext';
import { Tooltip } from '../Tooltip';
import { ToolbarItemPicker } from './ToolbarItemPicker';
import { faAngleDown, faTimes, faObjectUngroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarGroupEdit = ({ id, label, icon, hideLabel }) => {
  const {
    itemSelectMode,
    setParent,
    setSelectedItem,
    groupItems,
    ungroupItems,
    getChildren,
    getGroupId,
  } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [children, setChildren] = useState([]);

  useEffect(() => {
    const groupId = getGroupId(id);
    const children = getChildren(groupId);
    setChildren(children);
  }, [getChildren, getGroupId, id]);

  const onItemSelect = useCallback(
    target => {
      groupItems(target, id);
    },
    [groupItems, id],
  );

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <div css={css(dropdownContainerStyle)}>
            {!!children.length && (
              <>
                {children.map(({ id, name }) => (
                  <div
                    key={id}
                    css={css`
                      flex: 1 1 auto;
                      display: flex;
                      flex-direction: row;
                    `}>
                    <ToolbarButton
                      css={css`
                        flex: 1 1 auto;
                      `}
                      label={name}
                      onClick={() => setSelectedItem(id, true)}
                    />
                    <ToolbarButton
                      css={css`
                        flex: 0 0 auto;
                      `}
                      icon={faTimes}
                      onClick={() => setParent(id, null)}
                    />
                  </div>
                ))}
                <br />
              </>
            )}
            Grupper med element:
            <ToolbarItemPicker label="Velg element" value={null} setValue={onItemSelect} />
            {!!children.length && (
              <>
                <br />
                <ToolbarButton
                  icon={faObjectUngroup}
                  label="Fjern gruppering av elementer"
                  onClick={() => ungroupItems(id)}
                />
              </>
            )}
          </div>
        </Dropdown>
      )}
    </>
  );
};
