import React, { useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { Tooltip } from '../Tooltip';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { Slider } from '~/modules/Editor/components/Slider';

export const ToolbarSlider = ({
  label,
  icon,
  hideLabel,
  value,
  step,
  setValue,
  minValue,
  maxValue,
}) => {
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [changed, setChanged] = useState(false);

  useLayoutEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [maxValue, minValue, value]);

  useEffect(() => {
    if (!changed) {
      return;
    }
    setValue(internalValue);
    setChanged(false);
  }, [setValue, changed, internalValue]);

  const onChange = useCallback(value => {
    setInternalValue(value);
    setChanged(true);
  }, []);

  const ref = useRef(null);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            setOpen(false);
          }}>
          <div css={css(dropdownContainerStyle)}>
            <Slider
              value={internalValue}
              minValue={minValue}
              maxValue={maxValue}
              step={step}
              length={160}
              onChange={onChange}
              vertical
            />
          </div>
        </Dropdown>
      )}
    </>
  );
};
