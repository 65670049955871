import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPageSections, getPlayerSectionComponent } from '~/utils/course';
import { getCoursePagePropType } from '~/utils/types';

const getPreventingContinueSet = templateTypeId =>
  new Set(
    getPageSections(templateTypeId)
      .filter(section => section.preventContinue)
      .map(section => section.dataKey),
  );

export class Sections extends Component {
  constructor(props) {
    super(props);
    this.preventingContinueSet = getPreventingContinueSet(props.page.templateTypeId);

    this.handleSectionPermitContinue = this.handleSectionPermitContinue.bind(this);
    this.handleSectionsPermitContinue = this.handleSectionsPermitContinue.bind(this);
  }

  componentDidMount() {
    const { onSectionsPermitContinue } = this.props;
    if (this.preventingContinueSet.size === 0) {
      onSectionsPermitContinue();
    }
  }

  handleSectionsPermitContinue() {
    const { onSectionsPermitContinue } = this.props;
    onSectionsPermitContinue();
  }

  handleSectionPermitContinue(sectionDataKey) {
    if (this.preventingContinueSet.has(sectionDataKey)) {
      this.preventingContinueSet.delete(sectionDataKey);
    }
    if (this.preventingContinueSet.size === 0) {
      this.handleSectionsPermitContinue();
    }
  }

  render() {
    const {
      page,
      setSectionCollectedData,
      isQuestionnaireVisible,
      onGotoNext,
      onGotoPrev,
      pageNumber,
      pagesCount,
      courseType,
      onGotoNextPictureslide,
      onGotoPrevPictureslide,
      slideNumber,
      pagesAndSubpagesCount,
      resultsPage,
      course,
      isSpeedCourse,
      informAboutAnswerCorrectness,
    } = this.props;

    return getPageSections(page.templateTypeId).map(section => {
      const SectionComponent = getPlayerSectionComponent(section.componentName);
      const data = {
        title: this.props.page.title,
        ...page[section.dataKey],
      };

      return (
        <React.Fragment key={`page_${page.id}-section_${section.dataKey}`}>
          <SectionComponent
            onGotoNext={onGotoNext}
            onGotoPrev={onGotoPrev}
            properties={page}
            resultsPage={resultsPage}
            pageNumber={pageNumber}
            pagesCount={pagesCount}
            courseType={courseType}
            questionaireDescription={page.text && page.text.paragraphValue}
            pagesAndSubpagesCount={pagesAndSubpagesCount}
            informAboutAnswerCorrectness={informAboutAnswerCorrectness}
            isSpeedCourse={isSpeedCourse}
            onGotoNextPictureslide={onGotoNextPictureslide}
            onGotoPrevPictureslide={onGotoPrevPictureslide}
            slideNumber={slideNumber}
            data={data}
            course={course}
            isQuestionnaireVisible={isQuestionnaireVisible}
            setCollectedData={collectedData => {
              setSectionCollectedData({
                collectedData,
                componentDataKey: section.dataKey,
              });
            }}
            permitContinue={() => {
              this.handleSectionPermitContinue(section.dataKey);
            }}
          />
        </React.Fragment>
      );
    });
  }
}

Sections.propTypes = {
  page: getCoursePagePropType().isRequired,
  isQuestionnaireVisible: PropTypes.bool,
  onSectionsPermitContinue: PropTypes.func.isRequired,
  setSectionCollectedData: PropTypes.func.isRequired,
  onGotoNext: PropTypes.func,
  onGotoPrev: PropTypes.func,
  handlePictureSlideContinue: PropTypes.func.isRequired,
  handlePictureSlideGoBack: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  pagesAndSubpagesCount: PropTypes.number.isRequired,
  slideNumber: PropTypes.number.isRequired,
  informAboutAnswerCorrectness: PropTypes.bool.isRequired,
};

Sections.defaultProps = {
  isQuestionnaireVisible: true,
  onGotoNext: null,
  onGotoPrev: null,
};
