import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import { css } from '@emotion/core';
import { useActions } from '../hooks/use-actions';
import { usePosition } from '../hooks/use-position';
import { SceneContext } from '../SceneContext';
import { useFrameSequence } from '../hooks/use-frame-sequence';
import { useAnimationCallback } from '../hooks/use-animation-callback';
import { createSvgElement } from '../../../utils/create-svg-element';
import { MorphSvg } from '../../../utils/morph-svg';

export const SvgFigure = ({
  className,
  id,
  item: {
    data: {
      align = 'center',
      source,
      fill,
      stretch,
      rotation,
      frameSequence,
      framerate,
      flipWithAnimation = false,
      flipWithAnimationReverse = false,
      morphFrames = false,
      morphQuality = 4,
    },
  },
}) => {
  const { editor, itemRefs, dynamicLayout } = useContext(SceneContext);
  const [svgElements, setSvgElements] = useState([]);
  const [svgInterpolate, setSvgInterpolate] = useState([]);
  const [svgViewbox, setSvgViewbox] = useState(null);
  const loading = !svgElements.length;

  const { onMouseEnter, onMouseLeave, onClick, onMouseDown, onDoubleClick, clickable } = useActions(
    id,
    {
      loading,
    },
  );

  const setActiveIndex = useCallback(
    index => {
      svgElements.forEach((svgElement, elementIndex) => {
        const { style } = svgElement;

        style.display = elementIndex === index ? 'block' : 'none';
      });
    },
    [svgElements],
  );

  useFrameSequence({
    frames: svgElements,
    framerate: framerate,
    onFrame: (frame, index) => {
      setActiveIndex(index);
    },
    onInterpolate: (from, to, t, index) => {
      if (morphFrames && svgElements.length > 1 && svgInterpolate[index]) {
        svgInterpolate[index](t);
      }
    },
    playing: !editor,
  });

  const flip = useRef(false);

  const flipOnAnimate = useCallback(
    ({ xDelta }) => {
      if (flipWithAnimation) {
        const rotationTransform = dynamicLayout ? '' : `rotate(${rotation}deg)`;
        if (Math.abs(xDelta) > 0.005) {
          flip.current = xDelta > 0;
          if (flipWithAnimationReverse) {
            flip.current = !flip.current;
          }
        }
        const scale = flip.current ? 1 : -1;
        const scaleTransform = `scaleX(${scale})`;
        const transform = `${rotationTransform} ${scaleTransform}`;

        svgElements.forEach(svgElement => {
          const { style } = svgElement;

          style.transform = transform;
        });
      }
    },
    [dynamicLayout, flipWithAnimation, flipWithAnimationReverse, rotation, svgElements],
  );

  useAnimationCallback(flipOnAnimate, id);

  useEffect(() => {
    const svgSources = frameSequence || [source];
    const element = itemRefs[id];
    setSvgElements(svgElements => {
      if (svgElements.length) {
        return svgElements;
      }

      while (element.firstChild) {
        element.firstChild.remove();
      }

      return svgSources.map((source, index) => {
        const svgElement = createSvgElement(source);
        if (!svgElement) {
          console.error('Invalid SVG source', source);
          return undefined;
        }
        element.append(svgElement);
        const viewBoxBase = svgElement.viewBox.baseVal;
        if (viewBoxBase) {
          const { x, y, width, height } = viewBoxBase;
          setSvgViewbox(viewBox => viewBox || { x, y, width, height });
        }

        const { style } = svgElement;
        style.display = index === 0 ? 'block' : 'none';

        return svgElement;
      });
    });
  }, [itemRefs, id, frameSequence, source, setActiveIndex]);

  useEffect(() => {
    setSvgInterpolate(svgInterpolate => {
      if (svgInterpolate) {
        svgInterpolate.forEach(interpolate => interpolate(0));
      }
      return morphFrames && svgElements.length > 1
        ? svgElements.map((svgElement, index) =>
            MorphSvg(svgElement, svgElements[(index + 1) % svgElements.length], morphQuality),
          )
        : [];
    });
  }, [svgElements, morphQuality, morphFrames]);

  useEffect(() => {
    svgElements.forEach(element => {
      element.childNodes.forEach(node => {
        node.addEventListener('click', onClick);
        node.addEventListener('dblclick', onDoubleClick);
        node.addEventListener('mousedown', onMouseDown);
        node.addEventListener('mouseenter', onMouseEnter);
        node.addEventListener('mouseleave', onMouseLeave);
      });
    });
    return () => {
      svgElements.forEach(element => {
        element.childNodes.forEach(node => {
          node.removeEventListener('click', onClick);
          node.removeEventListener('dblclick', onDoubleClick);
          node.removeEventListener('mousedown', onMouseDown);
          node.removeEventListener('mouseenter', onMouseEnter);
          node.removeEventListener('mouseleave', onMouseLeave);
        });
      });
    };
  }, [onClick, onDoubleClick, onMouseDown, onMouseEnter, onMouseLeave, svgElements]);

  const { style, width, height } = usePosition(id);

  useLayoutEffect(() => {
    svgElements.forEach(svgElement => {
      svgElement.setAttribute('width', width);
      svgElement.setAttribute('height', height);
      svgElement.setAttribute('preserveAspectRatio', 'none');

      if (svgViewbox) {
        const scaleX = svgViewbox.width / width;
        const scaleY = svgViewbox.height / height;
        const scale = (fill ? Math.min : Math.max)(scaleX, scaleY) || 1;
        const viewboxWidth = width * ((stretch && scaleX) || scale);
        const viewboxHeight = height * ((stretch && scaleY) || scale);
        const viewboxX =
          (align.includes('left') && '0') ||
          (align.includes('right') && svgViewbox.width - viewboxWidth) ||
          svgViewbox.x - (viewboxWidth - svgViewbox.width) / 2;
        const viewboxY =
          (align.includes('top') && '0') ||
          (align.includes('bottom') && svgViewbox.height - viewboxHeight) ||
          svgViewbox.y - (viewboxHeight - svgViewbox.height) / 2;

        svgElement.setAttribute(
          'viewBox',
          `${viewboxX} ${viewboxY} ${viewboxWidth} ${viewboxHeight}`,
        );
      }
    });
  }, [align, width, height, svgViewbox, fill, stretch, svgElements]);

  useLayoutEffect(() => {
    svgElements.forEach(svgElement => {
      const { style } = svgElement;

      style.transform = dynamicLayout ? '' : `rotate(${rotation}deg)`;
    });
  }, [rotation, dynamicLayout, svgElements]);

  return (
    <div
      className={className}
      ref={current => {
        itemRefs[id] = current;
      }}
      css={css(
        style,
        css`
          pointer-events: none;

          svg {
            pointer-events: none;

            * {
              pointer-events: visiblePainted;
            }
          }
        `,
        clickable &&
          css`
            svg * {
              cursor: pointer;
            }
          `,
      )}
    />
  );
};
