import React, { useCallback, useLayoutEffect, forwardRef, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  editable: PropTypes.bool,
  unorderedList: PropTypes.bool,
  editing: PropTypes.bool,
  focus: PropTypes.bool,
  hover: PropTypes.bool,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  sanitise: PropTypes.bool,
  // The element to make contenteditable.
  // Takes an element string ('div', 'span', 'h1') or a styled component
  tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ref: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onPaste: PropTypes.func,
  onChange: PropTypes.func,
};

const inlineTags = new Set(['A', 'SPAN']);

const getTextContent = element => {
  if (element.nodeType === Node.ELEMENT_NODE) {
    return [...element.childNodes]
      .filter(node => node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE)
      .map(node => {
        const newline = node.nodeType === Node.ELEMENT_NODE && !inlineTags.has(node.tagName);
        return [getTextContent(node), newline];
      })
      .map(([value, newline], index, arr) => {
        if (newline && index < arr.length - 1) {
          return `${value}\n`;
        }
        return value;
      })
      .join('');
  }
  if (element.nodeType === Node.TEXT_NODE) {
    return element.textContent;
  }
  return '';
};

window.getTextContent = getTextContent;

export const ContentEditable = forwardRef(
  (
    {
      className,
      content,
      editable,
      focus,
      hover,
      maxLength,
      multiline,
      sanitise,
      editing,
      tagName: Element,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      onKeyDown: onKeyDownProp,
      onKeyUp: onKeyUpProp,
      onPaste: onPasteProp,
      onChange,
      unorderedList,
      ...props
    },
    forwardedRef,
  ) => {
    const ref = useRef(null);
    const [changed, setChanged] = useState(false);

    const sanitiseValue = useCallback(
      value => {
        if (!sanitise) {
          return value;
        }

        if (typeof sanitise === 'function') {
          return sanitise(value);
        }

        let nextValue = value
          // Normalise whitespace
          .replace(/[ \u00A0\u2000-\u200B\u2028\u2029\u202E\u202F\u3000]/g, ' ');
        if (!multiline) {
          nextValue = nextValue.replace(/\n/g, ' ').trim();
        }

        return nextValue.slice(0, Math.max(0, maxLength));
      },
      [maxLength, sanitise, multiline],
    );

    const [value, setValue] = useState(content);

    useLayoutEffect(() => {
      setValue(content);
      setChanged(false);
    }, [content]);

    useLayoutEffect(() => {
      if (onChange && changed) {
        onChange(value);
        setChanged(false);
      }
    }, [value, onChange, changed]);

    useLayoutEffect(() => {
      if (!editing) {
        if (unorderedList) {
          ref.current.innerHTML = '';
          const ulNode = document.createElement('ul');
          const points = value.replace('\r\n', '\n').split('\n');
          points.forEach(point => {
            const liNode = document.createElement('li');
            liNode.textContent = point;
            ulNode.append(liNode);
          });
          ref.current.append(ulNode);
        } else {
          ref.current.textContent = value;
        }
      }
    }, [value, editing, unorderedList]);

    const onRef = useCallback(
      current => {
        ref.current = current;
        if (typeof forwardedRef === 'function') {
          forwardedRef(current);
        } else if (forwardedRef) {
          forwardedRef.current = current;
        }
      },
      [forwardedRef],
    );

    useLayoutEffect(() => {
      if (focus && ref && editable) {
        ref.current.focus();
      }
    }, [editable, focus]);

    const onFocus = useCallback(
      ev => {
        if (onFocusProp) {
          onFocusProp(ev, value);
        }
      },
      [onFocusProp, value],
    );

    const onBlur = useCallback(
      ev => {
        if (onBlurProp) {
          onBlurProp(ev, value);
        }
      },
      [onBlurProp, value],
    );

    const onInput = useCallback(() => {
      const value = sanitiseValue(getTextContent(ref.current));

      setValue(value);
      setChanged(true);
    }, [sanitiseValue]);

    const onKeyDown = useCallback(
      ev => {
        if (onKeyDownProp) {
          onKeyDownProp(ev, getTextContent(ref.current));
        }
      },
      [onKeyDownProp],
    );

    const onKeyPress = useCallback(
      e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (multiline) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);

            range.deleteContents();

            let ulNode = ref.current.querySelector('ul');
            if (unorderedList) {
              if (!ulNode) {
                ulNode = document.createElement('ul');
                ref.current.append(ulNode);
              }
              const currentLi = (range.endContainer.nodeType === Node.ELEMENT_NODE
                ? range.endContainer
                : range.endContainer.parentElement
              ).closest('li');
              const liNode = document.createElement('li');
              liNode.textContent = range.endContainer.textContent.slice(range.endOffset);
              range.endContainer.textContent = range.endContainer.textContent.slice(
                0,
                range.endOffset,
              );
              if (currentLi.tagName === 'LI' && currentLi.nextSibling) {
                ulNode.insertBefore(liNode, currentLi.nextSibling);
              } else {
                ulNode.append(liNode);
              }
              range.setStart(liNode, 0);
              range.collapse(true);
            } else {
              const breakNode = document.createTextNode('\n');
              range.insertNode(breakNode);
              range.setStartAfter(breakNode);
              range.collapse(true);
            }
            selection.removeAllRanges();
            selection.addRange(range);

            const value = sanitiseValue(getTextContent(ref.current));

            setValue(value);
            setChanged(true);
          }
        }
      },
      [multiline, sanitiseValue, unorderedList],
    );

    const onKeyUp = useCallback(
      ev => {
        if (onKeyUpProp) {
          onKeyUpProp(ev, getTextContent(ref.current));
        }
      },
      [onKeyUpProp],
    );

    const onPaste = useCallback(
      ev => {
        ev.preventDefault();

        let pastedValue = ev.clipboardData.getData('text/html');
        if (pastedValue) {
          const pasteContainer = document.createElement('div');
          pasteContainer.innerHTML = pastedValue;
          let fragmentEndIndex = undefined;
          [...pasteContainer.childNodes].forEach((node, index, nodes) => {
            if (node.nodeType === Node.COMMENT_NODE) {
              if (node.textContent === 'StartFragment') {
                const fragmentStartIndex = index;
                nodes.slice(0, fragmentStartIndex).forEach(node => node.remove());
                return;
              }
              if (node.textContent === 'EndFragment') {
                fragmentEndIndex = index;
              }
            }
            if (fragmentEndIndex !== undefined && index >= fragmentEndIndex) {
              node.remove();
            }
          });
          pastedValue = getTextContent(pasteContainer.querySelector('body') || pasteContainer);
        } else {
          pastedValue = ev.clipboardData.getData('text');
        }

        if (pastedValue) {
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);

          range.deleteContents();

          let ulNode = ref.current.querySelector('ul');
          if (unorderedList) {
            if (!ulNode) {
              ulNode = document.createElement('ul');
              ref.current.append(ulNode);
            }
            const points = pastedValue.replace('\r\n', '\n').split('\n');
            let liNode;
            points.forEach((point, index) => {
              if (index === 0 && range.endContainer.closest('li')) {
                const textNode = document.createTextNode(point);
                range.insertNode(textNode);
                return;
              }
              liNode = document.createElement('li');
              liNode.textContent = point;
              const currentLi = (range.endContainer.nodeType === Node.ELEMENT_NODE
                ? range.endContainer
                : range.endContainer.parentElement
              ).closest('li');
              if (currentLi && currentLi.nextSibling) {
                ulNode.insertBefore(liNode, currentLi.nextSibling);
              } else {
                ulNode.append(liNode);
              }
            });
            if (liNode) {
              range.setStartAfter(liNode);
              range.collapse(true);
            }
          } else {
            const textNode = document.createTextNode(pastedValue);
            range.insertNode(textNode);
            range.collapse(false);
          }

          const value = sanitiseValue(getTextContent(ref.current));

          setValue(value);
          setChanged(true);

          if (onPasteProp) {
            onPasteProp(value);
          }
        }
      },
      [onPasteProp, sanitiseValue, unorderedList],
    );

    return (
      <Element
        {...props}
        ref={onRef}
        className={className}
        css={css(
          css`
            display: inline-block;
            border-radius: 4px;
            outline: 0px solid transparent;
            border: 1px solid transparent;
            white-space: pre-wrap;
            box-sizing: border-box;
          `,
          hover &&
            css`
              :hover,
              :focus {
                border: 1px solid #1592e6;
              }
            `,
        )}
        contentEditable={editable}
        onFocus={onFocus}
        onBlur={onBlur}
        onInput={onInput}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
      />
    );
  },
);

ContentEditable.propTypes = propTypes;
ContentEditable.defaultProps = {
  content: '',
  editable: true,
  hover: true,
  focus: false,
  maxLength: Infinity,
  multiline: false,
  sanitise: true,
  tagName: 'div',
  ref: null,
  onFocus: null,
  onBlur: null,
  onKeyDown: null,
  onKeyUp: null,
  onPaste: null,
  onChange: null,
};
