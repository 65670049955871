let dictionary = {
  PLAYER_START: 'Start Course',
  PLAYER_START_SPEED_COURSE: 'Start Speed Quiz',
  PLAYER_CONTINUE: 'Continue',
  PLAYER_FINALIZE: 'Finalize',
  COURSE_RESULT: 'Result',
  ANSWER_CORRECT: 'Correct',
  ANSWER_WRONG: 'Wrong',
  CLOSE: 'Close',
  SPEED_COURSE: 'Speed Course',
};

export const setDictionary = newDictionary => {
  dictionary = newDictionary;
  dictionary['PLAYER_START_SPEED_COURSE'] = 'Kjør på!';
};

export const getDictionary = () => dictionary;
