// Based on https://github.com/nfejzic/svg-morpher

import { interpolate } from 'flubber';
import { createSvgElement } from './create-svg-element';

// Some SVGs have more paths than the others. This function makes sure that
// the number of paths on two SVGs be the same, otherwise some pieces are
// going to be missing!
const equalizePathCount = (from, to) => {
  const { parentSVG: fromSVG, paths: fromPaths } = from;
  const { parentSVG: toSVG, paths: toPaths } = to;

  if (fromPaths.length !== toPaths.length) {
    // determine which SVG has more paths
    const hasLessPaths = fromPaths.length < toPaths.length;

    // find out how many paths are needed and store into diff variable
    const diff = hasLessPaths
      ? toPaths.length - fromPaths.length
      : fromPaths.length - toPaths.length;

    // insert a path into SVG with fewer paths, and repeat 'diff' many times
    for (let i = 0; i < diff; i++) {
      // create new Path element
      const elemPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      // the path to copy is going to be the last path of the SVG with fewer
      // paths
      const copyPath = hasLessPaths ? fromPaths[fromPaths.length - 1] : toPaths[toPaths.length - 1];
      // copy the path values - d element and store it into newly created Path
      elemPath.setAttributeNS(null, 'd', copyPath.getAttribute('d'));

      // append Path to SVG element with fewer paths
      if (hasLessPaths) {
        const fromEl = fromSVG.querySelectorAll('g');
        fromEl.item(fromEl.length - 1).append(elemPath);
        fromPaths.push(elemPath);
      } else {
        const toEl = toSVG.querySelectorAll('g');
        toEl.item(toEl.length - 1).append(elemPath);
        toPaths.push(elemPath);
      }
    }
  }
};

export const MorphSvg = (element, toSvg, quality) => {
  const targetSvgElement = typeof toSvg === 'string' ? createSvgElement(toSvg) : toSvg;

  const fromPaths = [...element.querySelectorAll('path')];
  const toPaths = [...targetSvgElement.querySelectorAll('path')];

  equalizePathCount(
    { parentSVG: element, paths: fromPaths },
    { parentSVG: targetSvgElement, paths: toPaths },
  );

  const pathInterpolate = fromPaths.map((fromPath, index) => {
    const from = fromPath.getAttribute('d');
    const to = toPaths[index].getAttribute('d');
    try {
      return interpolate(from, to, {
        maxSegmentLength: quality,
      });
    } catch (error) {
      console.error(error, 'From path:', from, 'To path:', to);
      return null;
    }
  });

  return t => {
    fromPaths.forEach((fromPath, index) => {
      const inerpolatePath = pathInterpolate[index];
      if (inerpolatePath !== null) {
        const d = inerpolatePath(t);
        fromPath.setAttributeNS(null, 'd', d);
      }
    });
  };
};
