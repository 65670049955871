import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import 'react-quill/dist/quill.bubble.css'; // ES6

export class Content extends Component {
  constructor(props) {
    super(props);
    this.insertImage = this.insertImage.bind(this);
    this.showImageHandler = this.showImageHandler.bind(this);
    this.onContentValue = this.onContentChange.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.hideImageHandler = this.hideImageHandler.bind(this);
    const {
      data: { htmlValue },
    } = this.props;

    this.state = {
      editorState: htmlValue,
      selectImage: false,
    };
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  onEditorStateChange = value => {
    this.setState({ editorState: value });
    this.props.onValueUpdate({
      ...this.props.data,
      htmlValue: value,
    });
  };

  onContentChange({ target: { value: htmlValue } }) {
    this.props.onValueUpdate({
      ...this.props.data,
      htmlValue,
    });
  }

  hideImageHandler() {
    this.setState({ selectImage: false });
  }

  showImageHandler() {
    const {
      data: { files },
    } = this.props;
    if (files.length === 0) {
      this.imageHandler();
    }
    this.setState({ selectImage: true });
  }

  insertImage(place) {
    const { data } = this.props;
    const insert_file = data.files[place];
    const range = this.quill.getEditor().getSelection(true);

    this.hideImageHandler();
    this.quill.getEditor().insertEmbed(range.index, 'image', insert_file.url);
  }

  imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const { uploadContentFile } = this.props;

      const file = input.files[0];
      uploadContentFile(file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      this.quill.insertEmbed(
        range.index,
        'image',
        `${window.location.origin}/images/loaders/placeholder.gif`,
      );

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      // Remove placeholder image
      this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      this.quill.insertEmbed(range.index, 'image', 'res');
    });
  }

  render() {
    const {
      data: { files },
    } = this.props;

    const { editorState, selectImage } = this.state;
    return (
      <div className="content-section-editor__container">
        {selectImage && (
          <div className="content-section-editor__imagepicker">
            <div className="content-section-editor__imagepicker__list">
              {files &&
                files.map((f, i) => (
                  <div key={f.id} onClick={() => this.insertImage(i)}>
                    {f.title}
                  </div>
                ))}
            </div>
            <div className="content-section-editor__imagepicker__add"></div>
            <button onClick={() => this.imageHandler()}>Add new image</button>
          </div>
        )}
        <ReactQuill
          ref={el => {
            this.quill = el;
          }}
          theme="bubble"
          value={editorState}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
              handlers: {
                image: this.showImageHandler,
              },
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

Content.propTypes = {
  data: PropTypes.shape({
    htmlValue: PropTypes.string.isRequired,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ),
  onValueUpdate: PropTypes.func.isRequired,
};
