import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCoursePagePropType, getFilePreviewFilePropType } from '~/utils/types';

import { TextAnswersTab } from './Tabs/TextAnswersTab';
import { PictureAnswersTab } from './Tabs/PictureAnswersTab';
import { SettingsTabForm } from './Tabs/SettingsTab';
import { TextTabForm } from './Tabs/TextTab';
import { RedirectionsTabForm } from './Tabs/RedirectionsTab';
import { TimeBoundsTabForm } from './Tabs/TimeBoundsTab';
import { ContentEditable } from '~/components/ContentEditable';

const textWhenGoodPlaceholder = 'Tekst når riktig';
const textWhenWrongPlaceholder = 'Tekst når feil';

export class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.tabs = ['QUESTIONS', 'SETTINGS', 'MESSAGES', 'REDIRECTIONS', 'TIME_BOUNDS'];

    this.state = {
      activeTab: this.tabs[0],
      renderItem: 'settings',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.setAnswers = this.setAnswers.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.setSettings = this.setSettings.bind(this);
    this.setTexts = this.setTexts.bind(this);
    this.setRedirections = this.setRedirections.bind(this);
    this.setTimeBounds = this.setTimeBounds.bind(this);
  }

  setActiveTab(tabName) {
    this.setState({
      activeTab: tabName,
    });
  }

  setAnswers(answers) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      answers,
    });
  }

  setQuestion = ({ target: { value: question } }) => {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      question,
    });
  };

  setSettings(settings) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      settings,
    });
  }

  setTexts(text) {
    const { data, activeSection, onValueUpdate } = this.props;
    const texts = data.texts;
    if (activeSection === 2) {
      if (text !== textWhenGoodPlaceholder) {
        texts.textWhenGood = text;
      }
    } else {
      if (text !== textWhenWrongPlaceholder) {
        texts.textWhenWrong = text;
      }
    }
    onValueUpdate({
      ...data,
      texts,
    });
  }

  setRedirections(redirections) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      redirections: {
        onSuccessPageId: redirections.onSuccessPage ? redirections.onSuccessPage.id : null,
        onFailurePageId: redirections.onFailurePage ? redirections.onFailurePage.id : null,
      },
    });
  }

  setTimeBounds(timeBounds) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      timeBounds,
    });
  }

  render() {
    // const { activeTab, renderItem } = this.state;
    const {
      data: { question, answers, texts /* , redirections, timeBounds, settings */ },
      // pages,
      isPicturesQuestionnaire,
      deleteQuestionnaireAnswerImage,
      uploadQuestionnaireAnswerImage,
      activeSection,
    } = this.props;

    // const redirectionsPages = [{ title: 'default' }, ...pages];
    if (activeSection !== 1) {
      return (
        <div className="questionnaire-section-editor__container">
          <div>
            <ContentEditable
              tagName="h1"
              className="title"
              content={
                activeSection === 2
                  ? texts.textWhenGood || textWhenGoodPlaceholder
                  : texts.textWhenWrong || textWhenWrongPlaceholder
              }
              editable={true}
              onChange={value => this.setTexts(value)}
              multiline={true}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="questionnaire-section-editor__container">
        {isPicturesQuestionnaire ? (
          <PictureAnswersTab
            addAnswer={this.addAnswer}
            answers={answers}
            question={question}
            reorderAnswers={this.reorderAnswers}
            setAnswers={this.setAnswers}
            setQuestion={this.setQuestion}
            deleteQuestionnaireAnswerImage={deleteQuestionnaireAnswerImage}
            uploadQuestionnaireAnswerImage={uploadQuestionnaireAnswerImage}
          />
        ) : (
          <TextAnswersTab
            addAnswer={this.addAnswer}
            answers={answers}
            question={question}
            reorderAnswers={this.reorderAnswers}
            setAnswers={this.setAnswers}
            setQuestion={this.setQuestion}
          />
        )}
      </div>
    );
  }

  render_OLD() {
    const { activeTab /* , renderItem */ } = this.state;
    const {
      data: { question, answers, settings, texts, redirections, timeBounds },
      pages,
      isPicturesQuestionnaire,
      deleteQuestionnaireAnswerImage,
      uploadQuestionnaireAnswerImage,
    } = this.props;
    const redirectionsPages = [{ title: 'default' }, ...pages];

    return (
      <div className="questionnaire-section-editor__container">
        <div className="material-tabs__container">
          <div className="material-tabs__header">
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'QUESTIONS',
              })}
              onClick={() => this.setActiveTab('QUESTIONS')}
              role="button"
              tabIndex={0}>
              Questions
            </div>
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'MESSAGES',
              })}
              onClick={() => this.setActiveTab('MESSAGES')}
              role="button"
              tabIndex={0}>
              Messages
            </div>
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'REDIRECTIONS',
              })}
              onClick={() => this.setActiveTab('REDIRECTIONS')}
              role="button"
              tabIndex={0}>
              Redirections
            </div>
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'SETTINGS',
              })}
              onClick={() => this.setActiveTab('SETTINGS')}
              role="button"
              tabIndex={0}>
              {isPicturesQuestionnaire ? 'Settings' : 'Points'}
            </div>
            <div
              className={classNames('material-tabs__tab-title', {
                active: activeTab === 'TIME_BOUNDS',
              })}
              onClick={() => this.setActiveTab('TIME_BOUNDS')}
              role="button"
              tabIndex={0}>
              Time Bounds
            </div>
          </div>
          {
            {
              QUESTIONS: isPicturesQuestionnaire ? (
                <PictureAnswersTab
                  addAnswer={this.addAnswer}
                  answers={answers}
                  question={question}
                  reorderAnswers={this.reorderAnswers}
                  setAnswers={this.setAnswers}
                  setQuestion={this.setQuestion}
                  deleteQuestionnaireAnswerImage={deleteQuestionnaireAnswerImage}
                  uploadQuestionnaireAnswerImage={uploadQuestionnaireAnswerImage}
                />
              ) : (
                <TextAnswersTab
                  addAnswer={this.addAnswer}
                  answers={answers}
                  question={question}
                  reorderAnswers={this.reorderAnswers}
                  setAnswers={this.setAnswers}
                  setQuestion={this.setQuestion}
                />
              ),
              SETTINGS: (
                <SettingsTabForm
                  initialValues={{
                    ...settings,
                  }}
                  onSubmit={this.setSettings}
                  isPicturesQuestionnaireSettings={isPicturesQuestionnaire}
                />
              ),
              MESSAGES: (
                <TextTabForm
                  initialValues={{
                    ...texts,
                  }}
                  onSubmit={this.setTexts}
                />
              ),
              REDIRECTIONS: (
                <RedirectionsTabForm
                  initialValues={{
                    onSuccessPage:
                      redirections &&
                      redirectionsPages.find(page => page.id === redirections.onSuccessPageId),
                    onFailurePage:
                      redirections &&
                      redirectionsPages.find(page => page.id === redirections.onFailurePageId),
                  }}
                  pages={redirectionsPages}
                  onSubmit={this.setRedirections}
                />
              ),
              TIME_BOUNDS: (
                <TimeBoundsTabForm
                  initialValues={{
                    ...timeBounds,
                  }}
                  onSubmit={this.setTimeBounds}
                />
              ),
            }[activeTab]
          }
        </div>
      </div>
    );
  }
}

Questionnaire.propTypes = {
  data: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCorrect: PropTypes.bool.isRequired,
        image: getFilePreviewFilePropType(),
        text: PropTypes.string,
      }),
    ).isRequired,
    question: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      dndSelect: PropTypes.bool,
      pointsEnabled: PropTypes.bool.isRequired,
      pointsMaxScore: PropTypes.number,
      pointsPerAnswer: PropTypes.number,
    }),
    texts: PropTypes.shape({
      textWhenGood: PropTypes.string.isRequired,
      textWhenWrong: PropTypes.string.isRequired,
    }),
    redirections: PropTypes.shape({
      onSuccessPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onFailurePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    timeBounds: PropTypes.shape({
      customTimeBounds: PropTypes.bool,
      timeToReadQuestionSec: PropTypes.number,
      timeToAnswerQuestionSec: PropTypes.number,
    }),
  }).isRequired,
  pages: PropTypes.arrayOf(getCoursePagePropType()).isRequired,
  isPicturesQuestionnaire: PropTypes.bool.isRequired,
  onValueUpdate: PropTypes.func.isRequired,
  deleteQuestionnaireAnswerImage: PropTypes.func.isRequired,
  uploadQuestionnaireAnswerImage: PropTypes.func.isRequired,
};
