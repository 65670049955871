/* Returns a config-object with data and methods
for accessing that data.
*/
export const getConfigObject = ({ config }) => ({
  data: config.config.data,
  error: config.config.error,
  isFetching: config.config.isFetching,

  /* Get a property using dot syntax.
    If there is no data or the final property or any properties along the way dosent exist,
    undefined is returned
    */
  getProperty: key => {
    if (!config.config.data) {
      return undefined;
    }
    const { data } = config.config;
    const keyParts = key.split('.');
    let curr = data;

    for (let i = 0; i < keyParts.length; i += 1) {
      const keyPart = keyParts[i];
      if (curr[keyPart] === undefined) {
        return undefined;
      }

      curr = curr[keyPart];
    }

    return curr;
  },

  getRoutes: () => Object.getOwnPropertyNames(config.routes),
  hasRoute: route => config.routes[route] !== undefined,
});

export const getConfig = ({ config }) => config;
export const getHasLoadedConfig = ({ config }) =>
  !!(!config.config.isFetching && !config.config.error && config.config.data);
