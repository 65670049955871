import { useContext, useEffect } from 'react';
import { SceneContext } from '../SceneContext';

const increment = i => (Number.isInteger(i) && i + 1) || 1;
const decrement = i => (Number.isInteger(i) && i - 1) || null;

export const useHighlightEditorItems = (items, enabled = true) => {
  const { setEditorHighlightedItems } = useContext(SceneContext);

  useEffect(() => {
    if (!items?.length || !enabled) {
      return;
    }
    setEditorHighlightedItems(highlightedItems => {
      items.forEach(item => {
        if (item) {
          highlightedItems[item] = increment(highlightedItems[item]);
        }
      });
      return { ...highlightedItems };
    });

    return () => {
      setEditorHighlightedItems(highlightedItems => {
        items.forEach(item => {
          if (item) {
            highlightedItems[item] = decrement(highlightedItems[item]);
          }
        });
        return { ...highlightedItems };
      });
    };
  }, [items, setEditorHighlightedItems, enabled]);
};
