import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'semantic-ui-react';

import { acceptedImageFormats, acceptedVideoFormats } from '~/config';

import { getFilePreviewFilePropType } from '~/utils/types';

import { CoverSectionForm } from './forms/CoverSectionForm';
import { PointsSectionForm } from './forms/PointsSectionForm';
import { QuestionPoolSectionForm } from './forms/QuestionPoolSectionForm';
import { RandomizeSectionForm } from './forms/RandomizeSectionForm';
import { TimeBoundsSectionForm } from './forms/TimeBoundsSectionForm';
import { FilePreview } from '../FilePreview';

export const SpeedCourseSettings = ({
  speedCourseSettings: {
    title,
    description,
    cover,
    useRandomize,
    useQuestionPool,
    questionPoolSize,
    pointsPerAnswer,
    pointsMaxScore,
    timeToReadQuestionSec,
    timeToAnswerQuestionSec,
  },
  patchSpeedCourseSettings,
  uploadCoverFile,
  deleteCoverFile,
}) => (
  <div className="course-sections-editors__container">
    <div className="speed-course-settings__container">
      <h3>Cover</h3>
      <div className="speed-course-settings__section">
        <CoverSectionForm
          initialValues={{
            title,
            description,
          }}
          onSubmit={patchSpeedCourseSettings}
        />
        {(typeof cover === 'object' && (
          <div className="speed-course-settings__dropzone-container">
            <div className="speed-course-settings__dropzone-image">
              <FilePreview file={cover} asElement height={240} />
            </div>
            <div className="speed-course-settings__dropzone-buttons">
              <Button color="red" onClick={() => deleteCoverFile()}>
                Remove Cover
              </Button>
            </div>
          </div>
        )) ||
          (cover === 'UPLOADING' && (
            <div className="speed-course-settings__dropzone-container">
              <div className="speed-course-settings__cover-file-status">
                <p>Uploading...</p>
              </div>
            </div>
          )) || (
            <div className="speed-course-settings__dropzone-container">
              <Dropzone
                onDrop={([file]) => uploadCoverFile({ file })}
                accept={[
                  ...acceptedImageFormats.map(ext => `image/${ext}`),
                  ...acceptedVideoFormats.map(ext => `video/${ext}`),
                ].join(', ')}
                className="speed-course-settings__cover-file-status">
                {() => <p>Drop your files or click here to upload</p>}
              </Dropzone>
            </div>
          )}
      </div>
      <div className="speed-course-settings__section">
        <h3>Question pool</h3>
        <QuestionPoolSectionForm
          initialValues={{
            useQuestionPool,
            questionPoolSize,
          }}
          onSubmit={patchSpeedCourseSettings}
        />
      </div>
      <div className="speed-course-settings__section">
        <h3>Randomize questions</h3>
        <RandomizeSectionForm
          initialValues={{
            useRandomize,
          }}
          onSubmit={patchSpeedCourseSettings}
        />
      </div>
      <div className="speed-course-settings__section">
        <h3>Points</h3>
        <PointsSectionForm
          initialValues={{
            pointsMaxScore,
            pointsPerAnswer,
          }}
          onSubmit={patchSpeedCourseSettings}
        />
      </div>
      <div className="speed-course-settings__section">
        <h3>Time Bounds</h3>
        <TimeBoundsSectionForm
          initialValues={{
            timeToReadQuestionSec,
            timeToAnswerQuestionSec,
          }}
          onSubmit={patchSpeedCourseSettings}
        />
      </div>
    </div>
  </div>
);

SpeedCourseSettings.propTypes = {
  speedCourseSettings: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cover: getFilePreviewFilePropType(),
    pointsMaxScore: PropTypes.number,
    pointsPerAnswer: PropTypes.number,
    timeToReadQuestionSec: PropTypes.number,
    timeToAnswerQuestionSec: PropTypes.number,
  }).isRequired,
  patchSpeedCourseSettings: PropTypes.func.isRequired,
  uploadCoverFile: PropTypes.func.isRequired,
  deleteCoverFile: PropTypes.func.isRequired,
};
