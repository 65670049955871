export const required = value => {
  switch (value) {
    case undefined:
    case null:
    case '':
      return 'This field is required';
    default:
      return undefined;
  }
};

export const email = value =>
  value && !/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

export const number = value =>
  Number.isNaN(Number.parseInt(value, 10)) ? 'Invalid number' : undefined;

export const positiveNumber = value =>
  Number.parseInt(value, 10) <= 0 ? 'The number must be positive' : undefined;
