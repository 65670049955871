import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { mqMaxWidth } from '~/modules/Player/styles/facepaint';

export const Wrapper = styled.section`
  background: transparent;
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 2em 0;
  top: 0;
`;

export const ProgressBar = styled.div`
  display: flex;
  height: 0.25em;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 1em;

  order: 1;

  ${mqMaxWidth.small} {
    order: 2;
  }
`;

export const ProgressItemGrowShrink = styled(motion.div)`
  transform-origin: left;
  flex-grow: 1;
  border-radius: 10px;
`;

export const ProgressItemWrapper = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  background-color: ${props =>
    props.colorscheme === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(60,60,60,0.15)'};
  margin-right: 0.5em;
  overflow: visible;
`;

export const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  background-color: rgba(235, 235, 245, 0.6);
  margin-top: 0.67em;
  margin-right: 0.8em;
  justify-content: center;
  display: flex;
  align-items: center;

  border: 1px solid rgba(255, 255, 255, 0.25);

  order: 2;
  align-self: flex-end;
  ${mqMaxWidth.small} {
    order: 1;
    position: relative;
    top: -1em;
    margin-top: 0;

    margin-right: 2.8em;
    margin-bottom: 0.44em;
  }
`;
