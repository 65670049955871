import React, { useRef, useState, useContext, useCallback } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { SceneContext } from '../SceneContext';
import { Tooltip } from '../Tooltip';
import { faAngleDown, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { ToolbarText } from './ToolbarText';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarRotate = ({ value, setValue, label, icon, hideLabel }) => {
  const { itemSelectMode } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const decrement = useCallback(() => {
    setValue(((value || 0) - 22.5) % 360);
  }, [setValue, value]);

  const increment = useCallback(() => {
    setValue(((value || 0) + 22.5) % 360);
  }, [setValue, value]);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={css(
            dropdownBgStyle,
            css`
              width: 120px;
            `,
          )}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <div css={css(dropdownContainerStyle)}>
            <ToolbarText
              type="number"
              value={value % 360}
              setValue={setValue}
              suffixLabel={<>&deg;</>}
            />
            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}>
              <ToolbarButton
                css={css`
                  flex: 1 1 auto;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
                icon={faMinus}
                onClick={decrement}
              />
              <ToolbarButton
                css={css`
                  flex: 1 1 auto;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
                icon={faPlus}
                onClick={increment}
              />
            </div>
          </div>
        </Dropdown>
      )}
    </>
  );
};
