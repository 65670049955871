/* eslint-disable no-console */
import React, { Component } from 'react';

import { EditorActionButton } from '~/modules/Editor/components/LeftSidebar/components/EditorActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPlus, faStop } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import * as styles from './styles';
import { getIdForNextElement } from '~/utils/common';
import { getPageSections, PAGE_ID_DESCRIPTION } from '~/utils/course';
import { bindActionCreators } from 'redux';
import * as courseEditorActions from '~/stores/editor/actions/course.actions';
import PropTypes from 'prop-types';

class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openToolbar: -1,
    };
    this.doAction = this.doAction.bind(this);
    this.setToolbarOpen = this.setToolbarOpen.bind(this);
    this.onSectionDataChange = this.onSectionDataChange.bind(this);
  }

  onSectionDataChange(sectionData, sectionDataKey) {
    const { updatePageData } = this.props;
    console.log('sectionData', sectionData);
    console.log('sectionDataKey', sectionDataKey);
    updatePageData({
      [sectionDataKey]: sectionData,
    });
  }

  addAnswer(answers) {
    const { course } = this.props;
    const newAnswer = {
      id: getIdForNextElement(answers),
      isCorrect: true,
      text: 'Enter answer',
    };
    const activePage = course.data.pages.filter(cp => cp.id === course.data.activePageId)[0];
    console.log(newAnswer);
    const new_answers = [...answers, newAnswer];
    activePage.questionnaire.answers = new_answers;
    console.log('new_answers');
    console.log(new_answers);
    this.onSectionDataChange(
      {
        ...activePage.questionnaire,
      },
      'questionnaire',
    );
  }

  setAnswers(answers) {
    const { data, onValueUpdate } = this.props;
    onValueUpdate({
      ...data,
      answers,
    });
  }

  doAction = (action, item) => {
    const { course } = this.props;
    const activePage = course.data.pages.filter(cp => cp.id === course.data.activePageId)[0];
    const data = activePage;
    if (action === 'show') {
      activePage[item.dataKey].isVisible = true;
      this.onSectionDataChange({
        ...data,
        item,
      });
    } else if (action === 'hide') {
      activePage[item.dataKey].isVisible = false;
      this.onSectionDataChange({
        ...data,
        item,
      });
    } else if (action === 'addQuestion') {
      this.addAnswer(activePage.questionnaire.answers);
    }
    console.log(activePage[item.dataKey]);
  };

  setToolbarOpen = toolbar => {
    this.setState({ openToolbar: toolbar });
  };

  render() {
    const {
      course,
      previewMode,
      sceneEndPreview,
      setAddItemModalOpen,
      sceneStartPreview,
    } = this.props;

    const { openToolbar } = this.state;
    if (!course.data || course.data?.activePageId === PAGE_ID_DESCRIPTION) {
      return null;
    }

    const activePage = course.data.pages.filter(cp => cp.id === course.data.activePageId)[0];

    if (!activePage) {
      return null;
    }
    const sections = getPageSections(activePage.templateTypeId);
    // const previewMode = useSelector(getPreviewMode);

    return (
      <div css={styles.wrapper}>
        {activePage.templateTypeId === 'PICTURESLIDES-TEXT' && (
          <EditorActionButton
            main
            onClick={() => setAddItemModalOpen(true)}
            tooltip="Legg til nytt element">
            <FontAwesomeIcon icon={faPlus} />
          </EditorActionButton>
        )}
        {sections.map(
          (item, i) =>
            ['text', 'questionnaire', 'content'].includes(item.dataKey) && (
              <EditorActionButton
                key={item.dataKey}
                onClick={() => this.setToolbarOpen(i !== openToolbar ? i : -1)}
                isToolbarOpen={i === openToolbar}
                section={item}
                onAction={(action, item) => {
                  this.doAction(action, item);
                }}
                value={activePage[item.dataKey]}
              />
            ),
        )}
        <EditorActionButton
          onClick={() => {
            if (previewMode) {
              sceneEndPreview();
            } else {
              sceneStartPreview();
            }
          }}
          tooltip={previewMode ? 'Stop forhåndsvisning' : 'Forhåndsvis'}>
          <FontAwesomeIcon icon={previewMode ? faStop : faPlay} />
        </EditorActionButton>
        <div id="left-toolbar-buttons" />
      </div>
    );
  }
}

LeftSidebar.propTypes = {
  updatePageData: PropTypes.func.isRequired,
  sceneEndPreview: PropTypes.func.isRequired,
  sceneStartPreview: PropTypes.func.isRequired,
  setAddItemModalOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  course: state.course,
  previewMode: state.course.previewMode,
});

const mapDispatchToProps = dispatch => bindActionCreators(courseEditorActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
/* eslint-enable no-console */
