import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormSelect } from '~/components/FormSelect';

const Redirect = props => {
  const { dropUp, pages, handleChange } = props;
  return (
    <div className="redirections-section-editor__container">
      <form
        className="redurections-section-editor__settings-container"
        autoComplete="off"
        onSubmit={handleChange}>
        <div className="redirections-section-editor__settings-select">
          <Field
            component={FormSelect}
            name="redirectPage"
            data={pages}
            defaultValue={null}
            textField={page => page.title || page.id || 'No redirect'}
            label="Redirect to"
            onChange={redirectPageData => {
              setTimeout(() => handleChange(redirectPageData));
            }}
            dropUp={dropUp}
          />
        </div>
      </form>
    </div>
  );
};

Redirect.propTypes = {
  dropUp: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

Redirect.defaultProps = {
  dropUp: false,
};

export const RedirectForm = reduxForm({
  form: 'coursePageRedirectsForm',
})(Redirect);
