export const dimensions = [
  {
    iconClassName: 'icon-phone',
    isRotated: false,
    name: 'Mobile Vertical',
    width: 380,
    height: 640,
  },
  {
    iconClassName: 'icon-phone',
    isRotated: true,
    name: 'Mobile Horizontal',
    width: 640,
    height: 380,
  },
  {
    iconClassName: 'icon-tablet',
    isRotated: false,
    name: 'Tablet Vertical',
    width: 640,
    height: 960,
  },
  {
    iconClassName: 'icon-tablet',
    isRotated: true,
    name: 'Tablet Horizontal',
    width: 960,
    height: 640,
  },
  {
    iconClassName: 'icon-laptop',
    isRotated: false,
    name: 'desktop',
    width: 1000,
    height: 800,
  },
];
