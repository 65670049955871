import {
  PAGE_ID_SPEED_COURSE,
  SAVE_STATUS_FAILED,
  SAVE_STATUS_SAVED,
  SAVE_STATUS_UPLOADING,
  SAVE_STATUS_DIRTY,
} from '~/utils/course';

import {
  ADD_PAGE,
  DELETE_PAGE,
  REORDER_PAGES,
  SET_ACTIVE_PAGE_ID,
  SET_ACTIVE_ELEMENT_ID,
  FETCH_COURSE_DATA_REQUEST,
  FETCH_COURSE_DATA_SUCCESS,
  FETCH_COURSE_DATA_FAILURE,
  SAVE_COURSE_DATA_REQUEST,
  SAVE_COURSE_DATA_SUCCESS,
  SAVE_COURSE_DATA_FAILURE,
  UPLOAD_CONTENT_FILE_REQUEST,
  UPLOAD_CONTENT_FILE_SUCCESS,
  UPLOAD_CONTENT_FILE_FAILURE,
  DELETE_CONTENT_FILE,
  UPLOAD_COVER_FILE_REQUEST,
  UPLOAD_COVER_FILE_SUCCESS,
  UPLOAD_COVER_FILE_FAILURE,
  UPLOAD_SPEED_COURSE_COVER_FILE_REQUEST,
  UPLOAD_SPEED_COURSE_COVER_FILE_SUCCESS,
  UPLOAD_SPEED_COURSE_COVER_FILE_FAILURE,
  DELETE_SPEED_COURSE_COVER_FILE,
  DELETE_COVER_FILE,
  SET_ACTIVE_SLIDE_ID,
  UPLOAD_PICTURE_REQUEST,
  UPLOAD_PICTURE_SUCCESS,
  UPLOAD_PICTURE_FAILURE,
  DELETE_PICTURE,
  UPLOAD_VIDEO_SOURCE_REQUEST,
  UPLOAD_VIDEO_SOURCE_SUCCESS,
  UPLOAD_VIDEO_SOURCE_FAILURE,
  DELETE_VIDEO_SOURCE,
  UPLOAD_VIDEO_POSTER_REQUEST,
  UPLOAD_VIDEO_POSTER_SUCCESS,
  UPLOAD_VIDEO_POSTER_FAILURE,
  SET_SCREEN_SIZE,
  DELETE_VIDEO_POSTER,
  UPLOAD_SLIDE_IMAGE_REQUEST,
  UPLOAD_SLIDE_IMAGE_SUCCESS,
  UPLOAD_SLIDE_IMAGE_FAILURE,
  DELETE_SLIDE_IMAGE,
  UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_REQUEST,
  UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_SUCCESS,
  UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_FAILURE,
  DELETE_QUESTIONNAIRE_ANSWER_IMAGE,
  SET_PAGE_DATA,
  SET_SLIDE_DATA,
  SET_COURSE_PAGE,
  PATCH_SPEED_COURSE_SETTINGS,
  SET_DESCRIPTION_PAGE_DATA,
  SET_SETTINGS_PAGE_DATA,
  SET_RESULTS_PAGE_DATA,
  SWITCH_SPEED_COURSE,
  TOGGLE_SETTINGS,
  SCENE_START_PREVIEW,
  SCENE_END_PREVIEW,
  ADD_ASSETS,
} from '../actions/course.actions';

export const initialState = {
  data: undefined,
  id: undefined,
  showSettings: false,
  showPage: 1,
  pageSize: 4,
  isFetching: true,
  previewMode: false,
  assets: [],
};

export const course = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          pages: [...state.data.pages, action.payload],
          pagesCount: state.data.pages.length + 1,
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case TOGGLE_SETTINGS:
      return {
        ...state,
        showSettings: action.payload,
      };
    case SET_COURSE_PAGE:
      return {
        ...state,
        showPage: action.payload,
      };
    case SCENE_START_PREVIEW:
      return {
        ...state,
        previewMode: true,
      };
    case SCENE_END_PREVIEW:
      return {
        ...state,
        previewMode: false,
      };
    case DELETE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.filter(page => page.id !== action.payload),
          pagesCount: state.data.pages.filter(page => page.id !== action.payload).length,
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case REORDER_PAGES:
      return {
        ...state,
        data: {
          ...state.data,
          pages: action.payload,
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_ACTIVE_PAGE_ID:
      const activePage = state.data.pages.filter(p => p.id === action.payload)[0];
      if (activePage && activePage.templateTypeId === 'PICTURESLIDES-TEXT') {
        if (activePage.pictureslides?.slides.length > 0) {
          return {
            ...state,
            data: {
              ...state.data,
              activePageId: action.payload,
              activeSlideId: activePage.pictureslides?.slides[0].id,
            },
            showPage: 1,
            saveStatus: SAVE_STATUS_DIRTY,
          };
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          activePageId: action.payload,
        },
        showPage: 1,
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_ACTIVE_SLIDE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          activeSlideId: action.payload,
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_ACTIVE_ELEMENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          activeElementId: action.payload,
        },
      };

    case SET_PAGE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page => {
            if (page.id !== state.data.activePageId) return page;
            return {
              ...page,
              ...action.payload,
            };
          }),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case SET_SLIDE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page => {
            if (page.id !== state.data.activePageId) return page;
            return {
              ...page,
              pictureslides: {
                ...(page.pictureslides || {}),
                slides:
                  page.pictureslides?.slides.map(slide =>
                    slide.id === state.data.activeSlideId
                      ? {
                          ...slide,
                          ...action.payload,
                        }
                      : slide,
                  ) || [],
              },
            };
          }),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case PATCH_SPEED_COURSE_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          speedCourseSettings: {
            ...state.data.speedCourseSettings,
            ...action.payload,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SWITCH_SPEED_COURSE:
      return {
        ...state,
        data: {
          ...state.data,
          pages:
            state.data.pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE) === -1
              ? [...state.data.pages, { id: PAGE_ID_SPEED_COURSE }]
              : state.data.pages.filter(page => page.id !== PAGE_ID_SPEED_COURSE),
          pagesCount: state.data.pages.length + 1,
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case FETCH_COURSE_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: undefined,
        saveStatus: SAVE_STATUS_SAVED,
      };

    case FETCH_COURSE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case FETCH_COURSE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: undefined,
      };

    case SAVE_COURSE_DATA_REQUEST:
      return {
        ...state,
        saveStatus: SAVE_STATUS_UPLOADING,
      };

    case SAVE_COURSE_DATA_SUCCESS:
      return {
        ...state,
        saveStatus: SAVE_STATUS_SAVED,
      };

    case SAVE_COURSE_DATA_FAILURE:
      return {
        ...state,
        saveStatus: SAVE_STATUS_FAILED,
      };

    case UPLOAD_CONTENT_FILE_REQUEST:
      return {
        ...state,
      };

    case SET_SCREEN_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    case UPLOAD_CONTENT_FILE_SUCCESS:
      const active_page = state.data.pages.filter(page => page.id === action.payload.pageId)[0];
      const new_files = active_page[action.payload.componentDataKey].files;
      new_files.push(action.payload.data);
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    files: new_files,
                  },
                },
          ),
        },
      };

    case UPLOAD_CONTENT_FILE_FAILURE:
    case DELETE_CONTENT_FILE:
      return {
        ...state,
      };

    case UPLOAD_COVER_FILE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          descriptionPage: {
            ...state.data.descriptionPage,
            cover: 'UPLOADING',
          },
        },
      };

    case UPLOAD_COVER_FILE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          descriptionPage: {
            ...state.data.descriptionPage,
            cover: action.payload,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case UPLOAD_COVER_FILE_FAILURE:
    case DELETE_COVER_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          descriptionPage: {
            ...state.data.descriptionPage,
            cover: undefined,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_SPEED_COURSE_COVER_FILE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          speedCourseSettings: {
            ...state.data.speedCourseSettings,
            cover: 'UPLOADING',
          },
        },
      };

    case UPLOAD_SPEED_COURSE_COVER_FILE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          speedCourseSettings: {
            ...state.data.speedCourseSettings,
            cover: action.payload,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case UPLOAD_SPEED_COURSE_COVER_FILE_FAILURE:
    case DELETE_SPEED_COURSE_COVER_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          speedCourseSettings: {
            ...state.data.speedCourseSettings,
            cover: undefined,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case UPLOAD_VIDEO_SOURCE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      file: 'UPLOADING',
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_VIDEO_SOURCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      file: action.payload.data,
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_VIDEO_SOURCE_FAILURE:
    case DELETE_VIDEO_SOURCE:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      file: undefined,
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case UPLOAD_PICTURE_REQUEST:
      if (action.payload.componentDataKey !== null) {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    [action.payload.componentDataKey]: {
                      ...page[action.payload.componentDataKey],
                      file: 'UPLOADING',
                    },
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    bgImage: 'UPLOADING',
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }
    case UPLOAD_PICTURE_SUCCESS:
      if (action.payload.componentDataKey !== null) {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    [action.payload.componentDataKey]: {
                      ...page[action.payload.componentDataKey],
                      file: action.payload.data,
                    },
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    bgImage: action.payload.data,
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }
    case UPLOAD_PICTURE_FAILURE:
    case DELETE_PICTURE:
      if (action.payload.componentDataKey !== null) {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    [action.payload.componentDataKey]: {
                      ...page[action.payload.componentDataKey],
                      file: undefined,
                    },
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    bgImage: undefined,
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }
    case UPLOAD_VIDEO_POSTER_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      poster: 'UPLOADING',
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_VIDEO_POSTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      poster: action.payload.data,
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_VIDEO_POSTER_FAILURE:
    case DELETE_VIDEO_POSTER:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    video: {
                      ...page[action.payload.componentDataKey].video,
                      poster: undefined,
                    },
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case UPLOAD_SLIDE_IMAGE_REQUEST:
      if (action.payload.bg) {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    slides: page[action.payload.componentDataKey].slides.map(slide =>
                      slide.id !== action.payload.slideId
                        ? slide
                        : {
                          ...slide,
                          bgImage: 'UPLOADING',
                        },
                    ),
                  },
                },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    slides: page[action.payload.componentDataKey].slides.map(slide =>
                      slide.id !== action.payload.slideId
                        ? slide
                        : {
                          ...slide,
                          image: 'UPLOADING',
                        },
                    ),
                  },
                },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }

    case UPLOAD_SLIDE_IMAGE_SUCCESS:
      console.log('UPLOAD_SLIDE_IMAGE_SUCCESS' , action.payload);
      if (action.payload.bg) {
        console.log('set it here...')
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    slides: page[action.payload.componentDataKey].slides.map(slide =>
                      slide.id !== action.payload.slideId
                        ? slide
                        : {
                          ...slide,
                          bgImage: action.payload.data,
                        },
                    ),
                  },
                },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    slides: page[action.payload.componentDataKey].slides.map(slide =>
                      slide.id !== action.payload.slideId
                        ? slide
                        : {
                          ...slide,
                          image: action.payload.data,
                        },
                    ),
                  },
                },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }
    case UPLOAD_SLIDE_IMAGE_FAILURE:
    case DELETE_SLIDE_IMAGE:
      if (action.payload.bg) {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    slides: page[action.payload.componentDataKey].slides.map(slide =>
                      slide.id !== action.payload.slideId
                        ? slide
                        : {
                          ...slide,
                          bgImage: undefined,
                        },
                    ),
                  },
                },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }else {
        return {
          ...state,
          data: {
            ...state.data,
            pages: state.data?.pages.map(page =>
              page.id !== action.payload.pageId
                ? page
                : {
                    ...page,
                    [action.payload.componentDataKey]: {
                      ...page[action.payload.componentDataKey],
                      slides: page[action.payload.componentDataKey].slides.map(slide =>
                        slide.id !== action.payload.slideId
                          ? slide
                          : {
                              ...slide,
                              image: undefined,
                            },
                      ),
                    },
                  },
            ),
          },
          saveStatus: SAVE_STATUS_DIRTY,
        };
      }
    case UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    answers: page[action.payload.componentDataKey].answers.map(answer =>
                      answer.id !== action.payload.answerId
                        ? answer
                        : {
                            ...answer,
                            image: 'UPLOADING',
                          },
                    ),
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    answers: page[action.payload.componentDataKey].answers.map(answer =>
                      answer.id !== action.payload.answerId
                        ? answer
                        : {
                            ...answer,
                            image: action.payload.data,
                          },
                    ),
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };
    case UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE_FAILURE:
    case DELETE_QUESTIONNAIRE_ANSWER_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          pages: state.data?.pages.map(page =>
            page.id !== action.payload.pageId
              ? page
              : {
                  ...page,
                  [action.payload.componentDataKey]: {
                    ...page[action.payload.componentDataKey],
                    answers: page[action.payload.componentDataKey].answers.map(answer =>
                      answer.id !== action.payload.answerId
                        ? answer
                        : {
                            ...answer,
                            image: undefined,
                          },
                    ),
                  },
                },
          ),
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_SETTINGS_PAGE_DATA:
      let pageSize = state.pageSize;
      if (action.payload.pageSizing === '2') {
        pageSize = 4;
      } else if (action.payload.pageSizing === '3') {
        pageSize = 0;
      }
      return {
        ...state,
        data: {
          ...state.data,
          settings: {
            ...state.data.settings,
            ...action.payload,
          },
        },
        pageSize: pageSize,
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_DESCRIPTION_PAGE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          descriptionPage: {
            ...state.data.descriptionPage,
            ...action.payload,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case SET_RESULTS_PAGE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          resultsPage: {
            ...state.data.resultsPage,
            ...action.payload,
          },
        },
        saveStatus: SAVE_STATUS_DIRTY,
      };

    case ADD_ASSETS:
      return {
        ...state,
        assets: [...state.assets, action.payload],
      };

    default:
      return state;
  }
};
