import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { getIdForNextElement } from '~/utils/common';

// const DragHandle = SortableHandle(() => (
//   <div className="questionnaire-section-editor__question-drag-handle">
//     <i className="icon-reorder small" />
//   </div>
// ));

const SortableItem = SortableElement(({ affectAnswer, answer, onAnswerDeleteClick }) => (
  <div className="questionnaire-section-editor__question-row">
    <div className="questionnaire-section-editor__question-content">
      <div className="material-input material-input--required">
        <input
          id="course-description-input"
          placeholder="Answer..."
          required
          type="text"
          autoComplete="off"
          value={answer.text}
          onChange={event => affectAnswer(answer.id, { text: event.target.value })}
        />
      </div>
    </div>

    <div className="questionnaire-section-editor__question-action-buttons">
      <button className="btn btn-icon btn-negative" onClick={() => onAnswerDeleteClick(answer.id)}>
        <i className="icon-trash small" />
      </button>
    </div>
  </div>
));

const SortableList = SortableContainer(({ affectAnswer, answers, onAnswerDeleteClick }) => (
  <div>
    {answers.map((answer, index) => (
      <SortableItem
        affectAnswer={affectAnswer}
        answer={answer}
        index={index}
        key={answer.id}
        onAnswerDeleteClick={onAnswerDeleteClick}
      />
    ))}
  </div>
));

export class TextInputAnswersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newAnswer: {
        id: getIdForNextElement(props.answers),
        text: '',
        isCorrect: true,
      },
    };

    this.onAnswerDeleteClick = this.onAnswerDeleteClick.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.affectAnswer = this.affectAnswer.bind(this);
  }

  onAnswerDeleteClick(answerId) {
    const { answers, setAnswers } = this.props;
    setAnswers(answers.filter(answer => answer.id !== answerId));
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { answers, setAnswers } = this.props;
    const reorderedAnswers = arrayMove(answers, oldIndex, newIndex);
    setAnswers(reorderedAnswers);
  }

  addAnswer() {
    const { answers, setAnswers } = this.props;
    const newAnswer = {
      id: getIdForNextElement(answers),
      isCorrect: true,
      text: 'Enter answer',
    };
    setAnswers([...answers, newAnswer]);
  }

  affectAnswer(answerId, data) {
    const { answers, setAnswers } = this.props;
    setAnswers(
      answers.map(answer => {
        if (answer.id !== answerId) return answer;
        return {
          ...answer,
          ...data,
        };
      }),
    );
  }

  render() {
    const { answers } = this.props;
    return (
      <div className="material-tabs__content">
        <div className="questionnaire-section-editor__questions-list">
          <div className="questionnaire-section-editor__questions-list">
            <SortableList
              affectAnswer={this.affectAnswer}
              answers={answers}
              onAnswerDeleteClick={this.onAnswerDeleteClick}
              onSortEnd={this.onSortEnd}
              useDragHandle
            />
          </div>
        </div>
        <div className="questionnaire-section-editor__actions-buttons-row">
          <button className="btn btn-blue" onClick={this.addAnswer}>
            Add answer
          </button>
        </div>
      </div>
    );
  }
}

TextInputAnswersTab.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCorrect: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
};
