/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { FullscreenModal, Text } from './styles';
import { css, jsx } from '@emotion/core';
import { ActionButton } from '~/components/Select/styles';
import { highlightOnKeyboardFocusMixin } from '~/styles/utils/utils';

export const AnswerCommentPage = ({ text, background, onClose }) => (
  <FullscreenModal open background={background}>
    <Text className="text">{text}</Text>
    <ActionButton
      className="close-button"
      background="#383940"
      onClick={onClose}
      css={css`
        ${highlightOnKeyboardFocusMixin}
      `}>
      Neste
    </ActionButton>
  </FullscreenModal>
);
