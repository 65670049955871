import { useContext, useState, useCallback, useLayoutEffect, useRef } from 'react';
import { SceneContext } from '../SceneContext';
import { itemDefinitions } from '../definitions/item-definitions';

export const usePosition = id => {
  const { getItem, dynamicLayout, getAbsolutePosition, pathEditMode } = useContext(SceneContext);

  const item = getItem(id);
  const { dynamicLayout: dynamicLayoutDefinition } = itemDefinitions[item?.type] || {};
  const usingDynamicLayout = dynamicLayout && dynamicLayoutDefinition !== false;

  const createPositionObject = useCallback(
    id => {
      const absolutePosition =
        pathEditMode && pathEditMode.id === `${id}_targetPosition`
          ? getAbsolutePosition(id, pathEditMode.targetPosition)
          : getAbsolutePosition(id);

      return {
        ...absolutePosition,
        dynamicLayout: usingDynamicLayout,
        style: !usingDynamicLayout
          ? {
              position: 'absolute',
              left: `${absolutePosition.x}px`,
              top: `${absolutePosition.y}px`,
              width: `${absolutePosition.width}px`,
              height: `${absolutePosition.height}px`,
            }
          : {
              position: 'relative',
              width: '100%',
              height: absolutePosition ? `${absolutePosition.height}px` : 'auto',
            },
      };
    },
    [getAbsolutePosition, pathEditMode, usingDynamicLayout],
  );

  const init = useRef(false);
  const [position, setPosition] = useState(createPositionObject(id));

  const itemPosition = item?.position;
  const itemParent = item?.parent;
  useLayoutEffect(() => {
    if (init.current) {
      setPosition(createPositionObject(id));
    }
    init.current = true;
  }, [createPositionObject, id, itemPosition, itemParent]);

  return position;
};
