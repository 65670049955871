import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getCoursePagePropType } from '~/utils/types';
import { getDictionary } from '~/utils/dictionary';
import { Sections } from '~/modules/Player/components/Sections';
import { PlayerResultModal } from '~/modules/Player/components/PlayerResultModal';
import {
  getPlayerShouldDisplayTopStatusBar,
  getPlayerShouldDisplayBottomStatusBar,
  // getTopStatusBarVariant,
} from '~/utils/course';
import { BottomStatusBar } from '../components/CoursePage/BottomStatusBar/BottomStatusBar';
import { TopStatusBar } from '../components/CoursePage/TopStatusBar/TopStatusBar';
import { lightOrDark } from '~/styles/utils/utils';
import { css } from '@emotion/core';
import { topStatusBarStyle } from './styles/coursepage';
import { getSlide } from '~/stores/player/selectors/course.selectors';
import { useSelector } from 'react-redux';
import { getAllSlides } from '~/stores/editor/selectors/course.selectors';

export const CoursePage = ({
  pages,
  page,
  courseType,
  onContinueSlideClick,
  pagesCount,
  setSectionCollectedData,
  pagesAndSubpagesCount,
  resultsPage,
  informAboutAnswerCorrectness,
  activePageIndex,
  pageNumber,
  onExitClick,
  slideNumber,
  onContinueClick,
  onGoBackClick,
  prevPageId,
  onGoBackSlideClick,
  pageQuestionnairesResults,
}) => {
  const slides = useSelector(getAllSlides);
  const slide = useSelector(getSlide);

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [questionnairesResultsList, setQuestionnairesResultsList] = useState([]);
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);

  const preloadImages = useCallback(() => {
    pages
      .filter(p => p.templateTypeId === 'PICTURESLIDES-TEXT')
      .forEach(page =>
        page.pictureslides?.slides.forEach(slide =>
          slide.items
            ?.filter(i => i.type === 'imageElement')
            .forEach(imageELem => {
              const img = new Image();
              img.src = imageELem.data.file.url;
            }),
        ),
      );
  }, [pages]);

  useEffect(() => {
    preloadImages();
  }, [preloadImages]);

  const handleSectionsPermitContinue = useCallback(() => {
    setIsContinueEnabled(true);
  }, []);

  const handleContinue = useCallback((force) => {
    // if we are currently viewing a page with multiple slides,
    // go to the next slide if any, else go to the next page
    if (!force && courseType === '2') {
      return true;
    }
    if (page.templateTypeId === 'PICTURESLIDES-TEXT' && slideNumber < slides.length) {
      onContinueSlideClick();
    } else {
      const questionnairesResultsList = Object.values(pageQuestionnairesResults);
      setQuestionnairesResultsList(questionnairesResultsList);
      if (questionnairesResultsList.length) {
        setIsResultsModalOpen(true);
      } else {
        setIsContinueEnabled(false);
        setIsResultsModalOpen(false);
        onContinueClick();
      }
    }
  }, [
    onContinueClick,
    onContinueSlideClick,
    slides.length,
    page.templateTypeId,
    pageQuestionnairesResults,
    slideNumber,
  ]);

  const handleGoBack = useCallback((force) => {
    // if we are currently viewing a page with multiple slides,
    // go back if there are any slides behind, othervise go to the previous page
    if (!force && courseType === '2') {
      return true;
    }
    const templateTypeIdsAllowedToGoBack = ['TEXT-DILEMMA', 'PICTURESLIDES-TEXT'];
    if (!templateTypeIdsAllowedToGoBack.includes(page.templateTypeId)) {
      return;
    }

    if (page.templateTypeId === 'PICTURESLIDES-TEXT' && slideNumber > 1) {
      onGoBackSlideClick();
    } else {
      onGoBackClick();
    }
  }, [onGoBackClick, onGoBackSlideClick, page.templateTypeId, slideNumber]);

  const handleResultModalContinue = useCallback(() => {
    setQuestionnairesResultsList([...questionnairesResultsList]);
    if (questionnairesResultsList.length) {
      setIsResultsModalOpen(true);
    } else {
      setIsContinueEnabled(false);
      setIsResultsModalOpen(false);
      onContinueClick();
    }
  }, [onContinueClick, questionnairesResultsList]);

  const getPageNumberIncludingSubpages = useCallback(() => {
    let cnt = 0;

    pages.slice(0, pageNumber - 1).map(p => {
      if (p.templateTypeId === 'PICTURESLIDES-TEXT') {
        cnt += p.pictureslides?.slides.length;
      } else {
        cnt += 1;
      }
    });

    return cnt + slideNumber;
  }, [pageNumber, pages, slideNumber]);

  const getPageBgColor = useCallback(() => {
    switch (page.templateTypeId) {
      case 'PICTURESLIDES-TEXT':
        return slide?.bgColor || '#ffffff';
      case 'TEXT-DILEMMA':
        return '#ffffff';
      case 'TEXT-QUESTIONNAIRE':
        return '#007AFF';
      default:
        return page.bgColor;
    }
  }, [page.bgColor, page.templateTypeId, slide]);

  const getPageIsSpeedCourse = useCallback(() => {
    if (
      page.templateTypeId === 'TEXT-QUESTIONNAIRE' &&
      activePageIndex > pages.findIndex(page => page.templateTypeId === 'SPEED_COURSE')
    ) {
      return true;
    }
    return false;
  }, [activePageIndex, page.templateTypeId, pages]);

  const showTopStatusBar = useMemo(() => (courseType === '2' ? false : getPlayerShouldDisplayTopStatusBar(page.templateTypeId)), [
    page.templateTypeId,
  ]);
  const showBottomStatusBar = useMemo(
    () => getPlayerShouldDisplayBottomStatusBar(page.templateTypeId),
    [page.templateTypeId],
  );
  // const topStatusBarVariant = useMemo(() => getTopStatusBarVariant(page.templateTypeId), [page.templateTypeId]);

  const pageNumberWithSubpages = getPageNumberIncludingSubpages();
  const pageBgColor = getPageBgColor();

  return (
    <>
      {showTopStatusBar && (
        <TopStatusBar
          pageNumber={pageNumberWithSubpages}
          css={css`
            ${topStatusBarStyle}
          `}
          pagesCount={pagesAndSubpagesCount}
          colorScheme={
            (pageBgColor && lightOrDark(pageBgColor) === 'dark' ? 'light' : 'dark') || 'dark'
          }
          handleExit={onExitClick}
        />
      )}
      <Sections
        key={page.id}
        page={page}
        // pictureSlideIdx={currPictureSlideIdx}
        onGotoNext={handleContinue}
        onGotoPrev={handleGoBack}
        slideNumber={slideNumber}
        courseType={courseType}
        resultsPage={resultsPage}
        isSpeedCourse={getPageIsSpeedCourse()}
        informAboutAnswerCorrectness={informAboutAnswerCorrectness}
        pageNumber={pageNumber}
        pagesCount={pagesCount}
        pagesAndSubpagesCount={pagesAndSubpagesCount}
        setSectionCollectedData={setSectionCollectedData}
        onSectionsPermitContinue={handleSectionsPermitContinue}
      />
      {isResultsModalOpen && questionnairesResultsList.length && (
        <PlayerResultModal
          onContinueClick={() => {
            handleResultModalContinue();
          }}
          questionnaireResult={questionnairesResultsList[0]}
          key={questionnairesResultsList[0]}
        />
      )}

      {showBottomStatusBar && (
        <BottomStatusBar
          pageNumber={pageNumberWithSubpages}
          pagesCount={pagesAndSubpagesCount}
          isContinueEnabled={isContinueEnabled}
          handleContinue={() => {
            console.log('aaa')
            return handleContinue(true);
          }}
          continueButtonText={getDictionary().PLAYER_CONTINUE}
        />
      )}
    </>
  );
};

CoursePage.propTypes = {
  page: getCoursePagePropType().isRequired,
  pageNumber: PropTypes.number.isRequired,
  slideNumber: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  pictureSlidesCount: PropTypes.number.isRequired,
  pagesAndSubpagesCount: PropTypes.number.isRequired,
  setSectionCollectedData: PropTypes.func.isRequired,
  pageQuestionnairesResults: PropTypes.shape({}).isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
  activePageIndex: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prevPageId: PropTypes.number.isRequired,
  onNextSlideClick: PropTypes.func.isRequired,
  onGoBackSlideClick: PropTypes.func.isRequired,
  resultsPage: PropTypes.shape({}).isRequired,
  informAboutAnswerCorrectness: PropTypes.bool.isRequired,
};
