import React, { useRef, useState, useCallback, useEffect } from 'react';
import { flexAlignOptions } from '../scene/scene-components/TextElement';
import { Dropdown } from '../scene/Dropdown';
import { toolbarStyle } from '../scene/styles';
import { ToolbarFields } from '../scene/ToolbarFields';
import { ContentEditable } from '.';
import { getToolbarFieldValues } from '../scene/utils/get-toolbar-field-values';
import { css } from '@emotion/core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { sizeOptions } from '~/components/scene/fields/size-options';

export const TextElement = ({
  tagName,
  className,
  content,
  context,
  useToolbar,
  canDelete,
  editable,
  onChange,
  multiline,
  data,
  onRemove,
  getValue,
  setValue,
  ...props
}) => {
  const ref = useRef(null);

  const [showToolbar, setShowToolbar] = useState(false);

  const openToolbar = useCallback(() => {
    setShowToolbar(true);
  }, []);

  const closeToolbar = useCallback(() => {
    setShowToolbar(false);
  }, []);

  const [fields, setFields] = useState([]);

  useEffect(() => {
    const deleteElement = [
      {
        icon: faTrashAlt,
        type: 'button',
        label: 'Slett element',
        hideLabel: true,
        hotkey: 'delete',
        onClick: () => {
          onRemove();
        },
      },
    ];

    setFields(useToolbar ? (canDelete ? useToolbar.concat(deleteElement) : useToolbar) : []);
  }, [canDelete, useToolbar, onRemove]);

  const { align, verticalAlign, bold, italic, color, bgColor, size, } = getToolbarFieldValues(
    fields,
    data,
  );

  return (
    <>
      <ContentEditable
        ref={ref}
        css={css(
          css`
            display: flex;
            justify-content: ${flexAlignOptions[align]};
            align-items: ${flexAlignOptions[verticalAlign]};
            font-weight: ${bold ? 'bold' : 'normal'};
            color: ${color ? color : ''};
          `,
          bgColor &&
            css`
              background-color: ${bgColor};
            `,
          italic &&
            css`
              font-style: italic;
            `,
          size &&
            css`
              font-size: ${(sizeOptions.find(({ value }) => value === size) ?? sizeOptions[1])
                ?.size};
              line-height: 120%;
            `,
        )}
        tagName={tagName}
        className={className}
        content={content}
        editable={editable}
        onChange={onChange}
        multiline={multiline}
        onFocus={openToolbar}
        {...props}
      />
      {fields && showToolbar && (
        <Dropdown parentRef={ref} distance={10} alignCenter alignTop base onClose={closeToolbar}>
          <div css={toolbarStyle}>
            <ToolbarFields
              fields={fields}
              context={context}
              getValue={getValue}
              setValue={setValue}
            />
          </div>
        </Dropdown>
      )}
    </>
  );
};
