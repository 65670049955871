import { PAGE_ID_SPEED_COURSE } from '~/utils/course';

export const getAllPages = state => state.course.data?.pages;

export const getPage = state =>
  getAllPages(state).find(page => page.id === state.course.data?.activePageId);

export const getAllSlides = state => getPage(state)?.pictureslides?.slides || [];

export const getSlide = state =>
  getAllSlides(state).find(slide => slide.id === state.course.data?.activeSlideId);

export const isSpeedCoursePage = (pageId, { pages }) => {
  if ((!pageId && pageId !== 0) || !Array.isArray(pages)) {
    throw new Error('invalid arguments');
  }
  const pageIndex = pages.findIndex(page => page.id === pageId);
  if (pageIndex === -1) {
    return false;
  }
  const speedCourseIntroPageIndex = pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE);
  if (speedCourseIntroPageIndex === -1) {
    return false;
  }
  return speedCourseIntroPageIndex <= pageIndex;
};

export const getPreviewMode = ({ course: { previewMode } }) => previewMode;

export const getPageSize = ({ course: { pageSize } }) => pageSize;

export const getPageType = ({ course }) => {
  if (course.data && course.data.settings) {
    return course.data.settings.pageType;
  }
  return '1';
};

export const getShouldUseDynamicSize = ({ course: { data } }) => data?.activePageId === -1;

export const getAssets = ({ course: { assets } }) => assets;
