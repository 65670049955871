import React from 'react';
import styled from '@emotion/styled';
import { EditorMain } from './views/EditorMain';
import 'mousetrap';

export const Editor = () => (
  <EditorFrame>
    <EditorMain />
  </EditorFrame>
);

const EditorFrame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
