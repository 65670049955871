import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import API from '~/utils/request';

import { setPagesTypes, getParsedCourseData } from '~/utils/course';

import {
  FETCH_COURSE_DATA,
  fetchCourseDataRequest,
  fetchCourseDataSuccess,
  fetchCourseDataFailure,
  SAVE_COURSE_DATA,
  saveCourseDataRequest,
  saveCourseDataSuccess,
  saveCourseDataFailure,
  UPLOAD_COVER_FILE,
  uploadCoverFileRequest,
  uploadCoverFileSuccess,
  uploadCoverFileFailure,
  UPLOAD_SPEED_COURSE_COVER_FILE,
  uploadSpeedCourseCoverFileRequest,
  uploadSpeedCourseCoverFileSuccess,
  uploadSpeedCourseCoverFileFailure,
  UPLOAD_PICTURE,
  uploadPictureRequest,
  uploadPictureSuccess,
  uploadPictureFailure,
  UPLOAD_CONTENT_FILE,
  uploadContentFileRequest,
  uploadContentFileSuccess,
  uploadContentFileFailure,
  UPLOAD_VIDEO_SOURCE,
  uploadVideoSourceRequest,
  uploadVideoSourceSuccess,
  uploadVideoSourceFailure,
  UPLOAD_VIDEO_POSTER,
  uploadVideoPosterRequest,
  uploadVideoPosterSuccess,
  uploadVideoPosterFailure,
  UPLOAD_SLIDE_IMAGE,
  uploadSlideImageRequest,
  uploadSlideImageSuccess,
  uploadSlideImageFailure,
  UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE,
  uploadQuestionnaireAnswerImageRequest,
  uploadQuestionnaireAnswerImageSuccess,
  uploadQuestionnaireAnswerImageFailure,
} from '../actions/course.actions';

function* fetchCourseData() {
  yield put(fetchCourseDataRequest());

  try {
    const [
      { courseData },
      {
        data: { templateTypes },
      },
    ] = yield all([yield call(API.getEditorData), yield call(API.getTemplateTypes)]);

    setPagesTypes(templateTypes);
    yield put(fetchCourseDataSuccess(getParsedCourseData(courseData)));
  } catch (error) {
    toast.error('Failed to fetch course data.');
    yield put(fetchCourseDataFailure(error));
  }
}

function* saveCourseData() {
  const {
    course: { data },
  } = yield select();
  yield put(saveCourseDataRequest());

  try {
    yield call(API.postCourse, data);
    yield put(saveCourseDataSuccess());
  } catch (error) {
    toast.error('Failed to save course data.');
    yield put(saveCourseDataFailure(error));
  }
}

function* uploadCoverFile(action) {
  const { file } = action.payload;
  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadCoverFileRequest());

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadCoverFileSuccess(data));
  } catch (error) {
    toast.error('Failed to upload course cover.');
    yield put(uploadCoverFileFailure(error));
  }
}

function* uploadSpeedCourseCoverFile(action) {
  const { file } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadSpeedCourseCoverFileRequest());

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadSpeedCourseCoverFileSuccess(data));
  } catch (error) {
    toast.error('Failed to upload speed course cover.');
    yield put(uploadSpeedCourseCoverFileFailure(error));
  }
}

function* uploadPicture(action) {
  const { file, ...fileDescriptor } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadPictureRequest(fileDescriptor));

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadPictureSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload picture.');
    yield put(uploadPictureFailure(fileDescriptor));
  }
}

function* uploadContentFile(action) {
  const { file, ...fileDescriptor } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadContentFileRequest(fileDescriptor));

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadContentFileSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload video.');
    yield put(uploadContentFileFailure(fileDescriptor));
  }
}

function* uploadVideoSource(action) {
  const { file, ...fileDescriptor } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadVideoSourceRequest(fileDescriptor));

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadVideoSourceSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload video.');
    yield put(uploadVideoSourceFailure(fileDescriptor));
  }
}

function* uploadVideoPoster(action) {
  const { file, ...fileDescriptor } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadVideoPosterRequest(fileDescriptor));

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadVideoPosterSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload video poster.');
    yield put(uploadVideoPosterFailure(fileDescriptor));
  }
}

function* uploadSlideImage(action) {
  const { file, ...fileDescriptor } = action.payload;
  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadSlideImageRequest(fileDescriptor));
    const { data } = yield call(API.uploadFile, file);
    yield put(uploadSlideImageSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload slide picture.');
    yield put(uploadSlideImageFailure(fileDescriptor));
  }
}

function* uploadQuestionnaireAnswerImage(action) {
  const { file, ...fileDescriptor } = action.payload;

  try {
    if (!file) {
      toast.error('Wrong file provided.');
      throw new Error('Wrong file provided.');
    }
    if (Math.floor(file.size / (1024 * 1024)) >= 20) {
      toast.error('Max file size is 20MB.');
      throw new Error('Max file size is 20MB.');
    }
    yield put(uploadQuestionnaireAnswerImageRequest(fileDescriptor));

    const { data } = yield call(API.uploadFile, file);
    yield put(uploadQuestionnaireAnswerImageSuccess({ data, ...fileDescriptor }));
  } catch {
    toast.error('Failed to upload questionnaire picture.');
    yield put(uploadQuestionnaireAnswerImageFailure(fileDescriptor));
  }
}

export const sagas = [
  takeLatest(FETCH_COURSE_DATA, fetchCourseData),
  takeLatest(SAVE_COURSE_DATA, saveCourseData),
  takeLatest(UPLOAD_COVER_FILE, uploadCoverFile),
  takeLatest(UPLOAD_CONTENT_FILE, uploadContentFile),
  takeLatest(UPLOAD_SPEED_COURSE_COVER_FILE, uploadSpeedCourseCoverFile),
  takeLatest(UPLOAD_PICTURE, uploadPicture),
  takeLatest(UPLOAD_VIDEO_SOURCE, uploadVideoSource),
  takeLatest(UPLOAD_VIDEO_POSTER, uploadVideoPoster),
  takeLatest(UPLOAD_SLIDE_IMAGE, uploadSlideImage),
  takeLatest(UPLOAD_QUESTIONNAIRE_ANSWER_IMAGE, uploadQuestionnaireAnswerImage),
];
