import { jsx, css } from '@emotion/core';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

export const FullscreenModal = styled.modal`
  width: 100%;
  height: 100%;
  position: fixed;
  background: red;
  background: ${props => props.background};
  z-index: 10001;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 4em;
`;

export const Text = styled.p`
  font-size: 2.75em;
`;
