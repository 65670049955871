import React, { useRef } from 'react';
import { css } from '@emotion/core';
import { Tooltip } from '../../../../../components/scene/Tooltip';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as styles from '../styles';


export const EditorActionButton = ({
  section,
  main = false,
  small = false,
  tooltip,
  onClick,
  onAction,
  isToolbarOpen,
  value,
  key,
  children,
  ...props
}) => {
  const ref = useRef(null);
  return (
    <div
      onClick={onClick}
      ref={ref}
      css={css(
        styles.actionBtn,
        isToolbarOpen ? styles.actionBtnActive : null,
        main ? styles.actionBtnMain : null,
        small ? styles.actionBtnSmall : null,

      )}
      {...props}>
      {section && <div>{section.componentName.slice(0, 1)}</div>}
      {tooltip && (
        <Tooltip parentRef={ref} onHover distance={12} positionRight alignCenter>
          {tooltip}
        </Tooltip>
      )}
      {children}

      {isToolbarOpen && (
        <>
          {section.componentName === 'TEXT COMPONENT' && (
            <div css={styles.menuRowWrapper}>
              {value.isVisible && (
                <div
                  css={styles.menuBtn}
                  onClick={() => onAction('hide', section)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              ) || (
                <div
                  css={styles.menuBtn}
                  onClick={() => onAction('show', section)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              )}
            </div>
          )}

          {section.componentName === 'QUESTIONNAIRE COMPONENT' && (
            <div css={styles.menuRowWrapper}>
              <div
                css={styles.menuBtn}
                onClick={() => onAction('addQuestion', section)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          )}

        </>
      )}

    </div>
  );
};
