import { put, takeLatest, call } from 'redux-saga/effects';
import * as configActions from '../actions/config.actions';
import API from '~/utils/request';

export function* getConfig() {
  yield put(configActions.configGetConfigRequest());
  try {
    const config = yield call(API.getConfig, 'player');
    try {
      yield put(
        configActions.configGetConfigSuccess({
          config,
        }),
      );
    } catch {
      yield put(configActions.configGetConfigFailure({ error: {} }));
    }
  } catch (error) {
    yield put(configActions.configGetConfigFailure({ error }));
  }
}

export const sagas = [takeLatest(configActions.CONFIG_GET_CONFIG, getConfig)];
