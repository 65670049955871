import styled from '@emotion/styled';
import { cover } from '~/utils/facepaint';

const direction = val => {
  const directionEnum = {
    COLUMN: 'column',
    ROW: 'row',
  };

  switch (val) {
    case 'column':
    case 'vertical':
      return directionEnum.COLUMN;

    case 'row':
    case 'horizontal':
      return directionEnum.ROW;

    default:
      return null;
  }
};

const gap = val => {
  if (Array.isArray(val)) {
    switch (val.length) {
      case 1:
        return `${val[0]}px`;
      case 2:
        return `${val[0]}px ${val[1]}px`;
      case 4:
        return `${val[0]}px ${val[1]}px ${val[3]}px ${val[4]}px`;
      default:
        throw new Error(`${val} is not a valid value.`);
    }
  }

  return val;
};

export const Container = styled('div')(
  {
    label: 'Stack',
    display: 'flex',
  },
  props => {
    console.log(props);

    return {
      flexDirection: direction(props.direction),
      justifyContent: props.justifyContent,
      alignItems: props.alignItems,
      '> *': {
        margin: gap(props.gap),
      },
    };
  },
  cover,
);
