import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

export const FormTextarea = ({ disabled, input, label, meta: { touched, error } }) => (
  <div className="material-input">
    <TextareaAutosize
      {...input}
      id="course-description-input"
      required
      rows={1}
      type="textarea"
      disabled={disabled}
    />
    <label htmlFor="course-description-input">{label}</label>
    <span>
      {touched && error && <div className="material-input__messages--error">{error}</div>}
    </span>
  </div>
);

FormTextarea.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({}),
  label: PropTypes.string,
  meta: PropTypes.shape({}),
};

FormTextarea.defaultProps = {
  disabled: false,
  input: {},
  label: '',
  meta: {},
};
