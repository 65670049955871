import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react';
import { toolbarFieldStyle, toolbarInputStyle } from '../styles';
import { Tooltip } from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';

// Text field with dynamic width, that can also handle numbers
export const ToolbarText = ({
  icon,
  hideLabel,
  label,
  value,
  type,
  setValue,
  suffixLabel,
  precision = null,
  factor: factorProp = 1,
  minValue = -Infinity,
  maxValue = Infinity,
  autoFocus,
}) => {
  const isNumeric = type === 'time' || type === 'number';
  const factor = type === 'time' ? 1 / 1000 : factorProp;
  const [internalValue, setInternalValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [changed, setChanged] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const formatValue = useCallback(
    value => {
      let inputValue;

      if (value === '' || value === null || value === undefined) {
        inputValue = '';
      } else if (isNumeric) {
        inputValue =
          Math.min(Math.max(Number.parseFloat(value) / factor, minValue), maxValue) * factor || 0;

        if (precision != null) {
          inputValue = inputValue.toFixed(precision);
        }

        inputValue = Number(inputValue)
          .toLocaleString('fullwide', { useGrouping: false })
          .replace('.', ',')
          .replace('−', '-');
      } else {
        inputValue = value?.toString() || '';
      }

      return inputValue;
    },
    [factor, isNumeric, maxValue, minValue, precision],
  );

  useLayoutEffect(() => {
    const internalValue = isNumeric ? Number.parseFloat(value) * factor || 0 : value;
    setInternalValue(internalValue);
    setInputValue(formatValue(internalValue));
    setChanged(false);
  }, [factor, formatValue, isNumeric, type, value]);

  useEffect(() => {
    if (!changed) {
      return;
    }
    if (typeof internalValue === 'number') {
      const value = Math.min(Math.max(internalValue / factor, minValue), maxValue);
      setValue(value);
    } else {
      setValue(internalValue);
    }
    setChanged(false);
  }, [type, setValue, internalValue, changed, factor, minValue, maxValue]);

  useEffect(() => {
    const element = inputRef.current;
    if (!element) {
      return;
    }
    const { style } = element;
    style.width = `${inputValue.length + 2}ch`;
  }, [inputValue]);

  return (
    <label ref={ref} css={css(toolbarFieldStyle)}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {icon && !!label && !hideLabel && <>&nbsp;</>}
      {!hideLabel && label}
      {hideLabel && !!label && (
        <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
          {label}
        </Tooltip>
      )}
      {!!(label || icon) && <>&nbsp;</>}
      <input
        ref={inputRef}
        css={css(
          toolbarInputStyle,
          isNumeric &&
            css`
              text-align: right;
            `,
        )}
        type="text"
        value={inputValue}
        onChange={e => {
          if (isNumeric) {
            const newInputValue = e.target.value.replace('.', ',').replace('−', '-');
            setInputValue(newInputValue);
            const newValue = Number.parseFloat(newInputValue.replace(',', '.'));
            if (newValue === '' || !Number.isNaN(newValue)) {
              setInternalValue(newValue);
              setChanged(true);
            }
          } else {
            setChanged(true);
            setInternalValue(e.target.value);
          }
        }}
        onFocus={({ target }) => {
          target?.select();
        }}
        autoFocus={autoFocus}
      />
      {(type === 'time' && <>&nbsp;sekund{inputValue !== '1' ? 'er' : ''}</>) ||
        (suffixLabel && <>&nbsp;{suffixLabel}</>)}
    </label>
  );
};
