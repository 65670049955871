/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import { Bar, Base, Mask, TimeLeft, Path } from './styles';

// Usage <Timer duration={<DURATION OF QUIZ>} running={<BEGIN>}/>
export const Timer = ({ duration = 4000, running = false, points, onTimeRunOut, ...props }) => {
  const [startTime, setStartTime] = useState(Date.now());
  const maskElement = useRef(null);
  const baseElement = useRef(null);
  const timeLeft = useRef(0);
  const pathElement = useRef(null);

  useEffect(() => {
    setStartTime(Date.now());
  }, [running]);

  useEffect(() => {
    let active = true;

    const update = () => {
      if (!active || !running) return;

      const currentTime = Date.now() - startTime;
      const progress = 1 - currentTime / duration;

      if (maskElement.current) {
        const { style } = maskElement.current;

        style.width = `${progress * 100}%`;
        style.backgroundSize = `${100 + progress * 100}%, contain`;
        style.borderBottomRightRadius = `9999px `;
        style.borderTopRightRadius = `9999px`;
      }

      const rightValueFromMaskElement = maskElement.current.getBoundingClientRect().right;

      if (timeLeft.current) {
        // console.log(progress);
        // For going down by 10 each time
        // timeLeft.current.textContent = `+${Math.ceil((Math.round(progress * 1000) + 1) / 10) * 10}`;
        if (Number.isInteger(points)) {
          progress >= 0.001
            ? (timeLeft.current.textContent = `+${Math.round(progress * points)}`)
            : (timeLeft.current.textContent = '0');
        } else {
          timeLeft.current.textContent = `${Math.abs(
            Math.ceil(Math.round(currentTime / 1000) - duration / 1000),
          )}s`;
        }

        pathElement.current.style.width = `${rightValueFromMaskElement}px`;
        if (rightValueFromMaskElement < 56) {
          pathElement.current.style.width = `calc(100% - 8px)`;
          pathElement.current.style.alignItems = `start`;
          pathElement.current.style.marginLeft = `8px`;
        }
      }

      if (progress < 0) {
        active = false;
        maskElement.current.style.display = 'none';
        if (onTimeRunOut) {
          onTimeRunOut();
        }
      }

      requestAnimationFrame(update);
    };

    if (running) {
      requestAnimationFrame(update);
    }

    return () => {
      active = false;
    };
  }, [duration, running, startTime]);

  return (
    <div {...props}>
      <Path ref={pathElement}>
        <TimeLeft>
          <label ref={timeLeft}>{duration / 1000} s</label>
        </TimeLeft>
      </Path>
      <Base ref={baseElement}>
        <Mask ref={maskElement}>
          <Bar />
        </Mask>
      </Base>
    </div>
  );
};
