export const textScale = ['0.75rem', '0.875rem', '1rem', '1.25rem', '2rem'];

export const space = [0, 4, 8, 12, 16, 20, 24, 32, 40];

export const nanoPlayerMaxSize = '800px';

const sizes = {
  small: 'min-width: 320px',
  medium: 'min-width: 751px',
  large: 'min-width: 1051px',
  nanoPlayer: `min-width: ${nanoPlayerMaxSize}`,
};

const sizesMaxWidth = {
  small: 'max-width: 320px',
  medium: 'max-width: 751px',
  large: 'max-width: 1051px',
  nanoPlayerMax: `max-width: ${nanoPlayerMaxSize}`,
};

export const mq = {
  small: `@media(${sizes.small})`,
  medium: `@media(${sizes.medium})`,
  large: `@media(${sizes.large})`,
  nanoPlayerMax: `@media(${sizes.nanoPlayer})`,
};

export const mqMaxWidth = {
  extraSmall: `@media(max-width: 530px)`,
  small: `@media(${sizesMaxWidth.medium})`,
  medium: `@media(${sizesMaxWidth.large})`,
  large: `@media(${10000})`,
};

/**
 * Combine media queries using sizes
 * @param  {...any} args The sizes to combine, in the wanted order
 */
export const or = (...args) =>
  `${`@media${args.map(size => ` ${size.slice(6)},`).join('')}`.slice(0, -1)} `;
