import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { nanoid } from 'nanoid';
import { AutoSizer } from 'react-virtualized';
import { css } from '@emotion/core';
import { SceneContext } from './SceneContext';
import { EditorItem } from './EditorItem';
import { itemDefinitions } from './definitions/item-definitions';
import { getToolbarFieldValues } from './utils/get-toolbar-field-values';
import { SceneSizeUpdater } from './SceneSizeUpdater';
import { actionDefinitions as actionDefinitionsFn } from './definitions/action-definitions';

export const createItem = (type, { data = {}, position = {}, name = null, ...values } = {}) => {
  if (!itemDefinitions[type]) {
    console.error('No item definition for type', type);
    return null;
  }
  const { position: defaultPosition, fields, name: defaultName } = itemDefinitions[type];
  const newItem = {
    ...values,
    name: name || defaultName,
    type,
    id: nanoid(),
    position: { ...defaultPosition, ...position },
    data: getToolbarFieldValues(fields, data),
  };

  return newItem;
};

export const SceneRenderer = ({ className }) => {
  const context = useContext(SceneContext);
  const {
    scale,
    items,
    setItems,
    editor,
    itemSelectMode,
    setItemSelectMode,
    deselectAll,
    showHiddenItems,
    editorHighlightedItems,
    dynamicLayout,
    sceneRef,
    sceneBackgroundRef,
    sceneSize,
    itemsLoading,
    actionSequences,
  } = context;

  useEffect(() => {
    if (editor) {
      const onEditorKeydown = e => {
        if (e.key === 'Escape') {
          if (itemSelectMode) {
            setItemSelectMode(null);
          } else {
            deselectAll();
          }
        }
      };

      window.addEventListener('keydown', onEditorKeydown);

      return () => {
        window.removeEventListener('keydown', onEditorKeydown);
      };
    }
    return undefined;
  }, [deselectAll, editor, itemSelectMode, setItemSelectMode, setItems]);

  useEffect(() => {
    const onClick = e => {
      if (
        !e.defaultPrevented &&
        (e.target === sceneBackgroundRef.current ||
          e.target.contains(sceneBackgroundRef.current)) &&
        !itemSelectMode
      ) {
        deselectAll();
      }
    };

    window.addEventListener('mousedown', onClick, true);

    return () => {
      window.removeEventListener('mousedown', onClick, true);
    };
  }, [deselectAll, editor, itemSelectMode, sceneBackgroundRef, setItems]);

  const [renderedItems, setRenderedItems] = useState(null);
  useEffect(() => {
    const newRenderedItems = items.map((item, index) => {
      if (!item) {
        return null;
      }

      const { id, type, hidden, selected } = item;

      if (type === 'group') {
        return null;
      }

      const itemDefinition = itemDefinitions[type];
      if (!itemDefinition) {
        console.error('No item definition for type', type);
        return null;
      }
      const Component = itemDefinition?.component || null;

      const style = {};
      if (
        hidden &&
        (!editor ||
          (!showHiddenItems &&
            !selected &&
            !editorHighlightedItems[id] &&
            !Object.values(actionSequences).find(
              seq => seq.target === id && seq.actions.find(action => action.type === 'show'),
            )))
      ) {
        style.visibility = 'hidden';
        style.pointerEvents = 'none';
      }

      return editor ? (
        <EditorItem
          key={`${id}_editor`}
          css={css(style)}
          id={id}
          component={Component}
          item={item}
          index={index}
        />
      ) : (
        Component && <Component key={`${id}_player`} css={css(style)} id={id} item={item} />
      );
    });

    if (!itemsLoading.current.size) {
      setRenderedItems(newRenderedItems);
    }
  }, [actionSequences, editor, editorHighlightedItems, items, itemsLoading, showHiddenItems]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div
          ref={sceneRef}
          css={css`
            display: block;
            float: left;
            width: ${width}px;
            height: ${height}px;
            overflow: ${editor ? 'visible' : 'hidden'};
            position: relative;
          `}>
          {sceneSize ? (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: ${width / scale}px;
                height: ${height / scale}px;
                transform-origin: top left;
                transform: scale(${scale});
                overflow: ${dynamicLayout ? 'visible' : 'hidden'};

                svg {
                  image-rendering: crisp-edges;
                }
              `}>
              <div ref={sceneBackgroundRef} className={className}>
                {renderedItems}
              </div>
            </div>
          ) : null}
          <SceneSizeUpdater width={width} height={height} />
        </div>
      )}
    </AutoSizer>
  );
};

export const SceneProvider = ({ editor = false, dynamicLayout = false, children }) => {
  const contextRef = useRef({});
  const context = contextRef.current;

  context.editor = editor;
  context.dynamicLayout = dynamicLayout;

  const [items, setItems] = useState([]);
  context.items = items;
  context.setItems = setItems;

  const [actionSequences, setActionSequences] = useState([]);
  context.actionSequences = actionSequences;
  context.setActionSequences = setActionSequences;

  const selection = useRef({});
  context.selection = selection;

  const [dirty, setDirty] = useState(false);
  context.dirty = dirty;
  context.setDirty = setDirty;
  const [scale, setScale] = useState(1);
  context.scale = scale;
  context.setScale = setScale;

  const [showHiddenItems, setShowHiddenItems] = useState(false);
  context.showHiddenItems = showHiddenItems;
  context.setShowHiddenItems = setShowHiddenItems;

  /*
   * Set a one-time callback for when an item in the editor is clicked
   * Toolbars/overlays should be temporarily hidden while this mode is active
   * Used by ToolbarItemPicker
   */
  const [itemSelectMode, setItemSelectMode] = useState(null);
  context.itemSelectMode = itemSelectMode;
  context.setItemSelectMode = setItemSelectMode;

  /*
   * Works similarly to itemSelectMode, but is used for editing an SVG path.
   */
  const [pathEditMode, setPathEditMode] = useState(null);
  context.pathEditMode = pathEditMode;
  context.setPathEditMode = setPathEditMode;

  /*
   * Create a coloured outline around items that are related for the current editor selection
   * Used by ToolbarItemPicker and ActionDot (clickable point/button)
   */
  const [editorHighlightedItems, setEditorHighlightedItems] = useState({});
  context.editorHighlightedItems = editorHighlightedItems;
  context.setEditorHighlightedItems = setEditorHighlightedItems;

  const [moving, setMoving] = useState(null);
  context.moving = moving;
  context.setMoving = setMoving;

  const itemsLoading = useRef(new Set());
  context.itemsLoading = itemsLoading;

  const [loading, setLoading] = useState(null);
  context.loading = loading;

  const setItemLoading = useCallback((loading, id) => {
    if (loading) {
      itemsLoading.current.add(id);
    } else if (itemsLoading.current.has(id)) {
      itemsLoading.current.delete(id);
    }
    setLoading(!!itemsLoading.current.size);
  }, []);
  context.setItemLoading = setItemLoading;

  const sceneRef = useRef(null);
  context.sceneRef = sceneRef;
  const sceneBackgroundRef = useRef(null);
  context.sceneBackgroundRef = sceneBackgroundRef;

  const onItemAnimateCallback = useRef({});
  context.onItemAnimateCallback = onItemAnimateCallback;

  const setOnItemAnimate = useCallback((callback, id) => {
    if (!onItemAnimateCallback.current[id]) {
      onItemAnimateCallback.current[id] = {};
    }
    onItemAnimateCallback.current[id].callback = callback;
  }, []);
  context.setOnItemAnimate = setOnItemAnimate;

  const removeItem = useCallback(
    removeId => {
      setItems(items =>
        items.filter(({ id, dependsOn }) => id !== removeId && dependsOn !== removeId),
      );
      setDirty(true);
    },
    [setItems],
  );
  context.removeItem = removeItem;

  const updateItem = useCallback(
    (updateId, { position = {}, data = {}, events = {}, ...update }) => {
      setItems(items => {
        const itemIndex = items.findIndex(({ id }) => id === updateId);

        if (itemIndex === -1) {
          console.error('No item with ID', updateId);
          return items;
        }
        const item = items[itemIndex];
        item.position = { ...(item.position || {}), ...position };
        item.data = { ...(item.data || {}), ...data };
        item.events = { ...(item.events || {}), ...events };
        Object.assign(item, update);
        return [...items];
      });
      setDirty(true);
    },
    [setItems],
  );
  context.updateItem = updateItem;

  const runningSequences = useRef(new Set());
  context.runningSequences = runningSequences;

  const stopActionSequence = useCallback(
    id => {
      runningSequences.current.delete(id);
    },
    [runningSequences],
  );
  context.stopActionSequence = stopActionSequence;

  const [itemRefs] = useState({});
  context.itemRefs = itemRefs;

  const itemStyle = useRef({});
  context.itemStyle = itemStyle;

  const getItem = useCallback(id => context.items.find(({ id: itemId }) => itemId === id), [
    context,
  ]);
  context.getItem = getItem;

  const animateStyle = useCallback((style, id) => {
    itemStyle.current[id] = itemStyle.current[id] ?? {};
    Object.assign(itemStyle.current[id], style);
  }, []);
  context.animateStyle = animateStyle;

  /*
   * Actions to be run when triggered by an event or autorun when the scene starts
   * The process function will be executed
   */
  const [actionDefinitions] = useState(actionDefinitionsFn(context));
  context.actionDefinitions = actionDefinitions;

  const processSequence = useCallback(
    ({ id, actions }, index = 0) => {
      const sequenceActionData = actions[index];
      const actionType = sequenceActionData.type;
      const action = actionDefinitions[sequenceActionData.type];

      const next = () => {
        if (index + 1 < actions.length && runningSequences.current.has(id)) {
          processSequence({ id, actions }, index + 1);
        } else {
          stopActionSequence(id);
        }
      };

      if (!action) {
        console.warn('No action definition for', actionType);
        next();
        return;
      }

      let item;
      if (sequenceActionData.target) {
        item = getItem(sequenceActionData.target);
      }

      action.process({ next, id, item, data: sequenceActionData });
    },
    [actionDefinitions, getItem, stopActionSequence],
  );

  const createSequence = useCallback(
    ({ id, actions }) => {
      if (runningSequences.current.has(id)) {
        return;
      }

      runningSequences.current.add(id);

      processSequence({ id, actions });
    },
    [runningSequences, processSequence],
  );

  const runActionSequence = useCallback(
    id => {
      createSequence({ ...actionSequences[id], id });
    },
    [actionSequences, createSequence],
  );
  context.runActionSequence = runActionSequence;

  const [sceneSize, setSceneSize] = useState(null);
  context.sceneSize = sceneSize;
  context.setSceneSize = setSceneSize;

  useEffect(() => {
    if (!sceneSize) {
      return;
    }
    runningSequences.current.forEach(id => {
      if (!actionSequences[id]) {
        stopActionSequence(id);
      }
    });
    Object.entries(actionSequences).forEach(([id, { actions, autorun }]) => {
      if (editor) {
        stopActionSequence(id);
        return;
      }
      if (autorun) {
        createSequence({ id, actions });
      }
    });
  }, [actionSequences, createSequence, editor, stopActionSequence, sceneSize]);

  const updateAction = useCallback(
    (actionSequence, index, data) => {
      setActionSequences(seq => ({
        ...seq,
        [actionSequence]: {
          ...(seq[actionSequence] ?? {}),
          actions: (seq[actionSequence]?.actions ?? []).map((action, seqIndex) =>
            seqIndex !== index
              ? action
              : {
                  ...action,
                  ...data,
                },
          ),
        },
      }));
      setDirty(true);
    },
    [setActionSequences, setDirty],
  );
  context.updateAction = updateAction;

  const createAction = useCallback(
    (actionSequence, type, data = {}, actionSequenceData = {}) => {
      if (!actionDefinitions[type]) {
        console.error('No action definition for type', type);
        return -1;
      }
      setActionSequences(seq => ({
        ...seq,
        [actionSequence]: {
          ...(seq[actionSequence] ?? {}),
          ...actionSequenceData,
          actions: [
            ...(seq[actionSequence]?.actions ?? []),
            {
              ...getToolbarFieldValues(actionDefinitions[type].fields, data),
              type,
            },
          ],
        },
      }));
      setDirty(true);
      return context.actionSequences[actionSequence]?.actions.length || 0;
    },
    [actionDefinitions, context],
  );
  context.createAction = createAction;

  const loadJson = useCallback(
    jsonData => {
      let data = jsonData;
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      setItems(
        data.items?.map(item => ({
          ...item,
          data: item.data ?? {},
          position: item.position ?? {},
        })) ?? [],
      );
      setActionSequences(data.actionSequences ?? {});
      setDirty(false);
    },
    [setActionSequences, setItems],
  );
  context.loadJson = loadJson;

  const saveJson = useCallback(
    () => ({
      items: items.map(item => ({
        ...item,
        hover: false,
        selected: false,
        editing: false,
      })),
      actionSequences,
    }),
    [actionSequences, items],
  );
  context.saveJson = saveJson;

  const getItemDefinitionProperty = useCallback(
    (id, property) => {
      const item = getItem(id);
      if (!item) {
        return null;
      }
      const itemDefinition = itemDefinitions[item.type];
      if (!itemDefinition) {
        console.error('No item definition for type', item.type);
        return null;
      }
      const value = itemDefinition[property];

      const context = {
        ...contextRef.current,
        item,
      };

      return typeof value === 'function' ? value(context) : value;
    },
    [contextRef, getItem],
  );
  context.getItemDefinitionProperty = getItemDefinitionProperty;

  const getAbsolutePosition = useCallback(
    (id, positionOverride = {}) => {
      const item = getItem(id);

      const { dynamicLayout: dynamicLayoutDefinition } = itemDefinitions[item?.type] || {};
      const usingDynamicLayout = dynamicLayout && dynamicLayoutDefinition !== false;

      if (!item) {
        return { x: 0, y: 0, width: sceneSize?.width || 0, height: sceneSize?.height || 0 };
      }

      const { position, parent } = item;

      const { x, y, xDelta, yDelta, width, height, widthDelta, heightDelta } = {
        ...position,
        ...positionOverride,
      };

      const {
        x: parentX,
        y: parentY,
        width: parentWidth,
        height: parentHeight,
      } = getAbsolutePosition(parent);

      if (usingDynamicLayout) {
        const { top, left, height: itemHeight } = itemRefs[id]?.getBoundingClientRect() || {};
        const { top: sceneTop, left: sceneLeft } = sceneRef.current?.getBoundingClientRect() || {};

        const newX = (left - sceneLeft) / scale;
        const newY = (top - sceneTop) / scale;
        const newWidth = sceneSize.width || widthDelta + width * parentWidth;
        const newHeight =
          (getItemDefinitionProperty(id, 'resizable') === false &&
            itemHeight &&
            itemHeight / scale) ||
          heightDelta + height * parentHeight;

        Object.assign(item.position, {
          x: 0,
          y: 0,
          xDelta: newX,
          yDelta: newY,
          width: 0,
          height: 0,
          widthDelta: newWidth,
          heightDelta: newHeight,
        });
        return {
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
        };
      }

      return {
        x: parentX + xDelta + x * parentWidth,
        y: parentY + yDelta + y * parentHeight,
        width: widthDelta + width * parentWidth,
        height: heightDelta + height * parentHeight,
        anchorX: parentX + x * parentWidth,
        anchorY: parentY + y * parentHeight,
        anchorWidth: width * parentWidth,
        anchorHeight: height * parentHeight,
      };
    },
    [dynamicLayout, getItem, getItemDefinitionProperty, itemRefs, scale, sceneSize],
  );
  context.getAbsolutePosition = getAbsolutePosition;

  const getOverlappingItems = useCallback(
    (id, { firstMatch = false, getLayersBefore = false, getLayersAfter = false }) => {
      const itemIndex = items.findIndex(({ id: itemId }) => itemId === id);

      const getAllLayers = getLayersBefore === getLayersAfter;

      const itemsToTest = getAllLayers
        ? items
        : (getLayersAfter && items.slice(itemIndex + 1, items.length)) ||
          items.slice(0, itemIndex).reverse();

      const position = getAbsolutePosition(id);

      let resultFound = false;
      let result = [];
      itemsToTest.forEach((otherItem, index) => {
        if (firstMatch && resultFound) {
          return;
        }

        const otherPosition = getAbsolutePosition(otherItem.id);

        const isOverlapping =
          position.x <= otherPosition.x + otherPosition.width &&
          otherPosition.x <= position.x + position.width &&
          position.y <= otherPosition.y + otherPosition.height &&
          otherPosition.y <= position.y + position.height;

        if (isOverlapping) {
          const value = {
            index: getLayersAfter ? itemIndex + index + 1 : itemIndex - (index + 1),
            item: otherItem,
          };
          if (firstMatch) {
            result = value;
            resultFound = true;
          } else {
            result.push(value);
          }
        }
      });
      return result;
    },
    [getAbsolutePosition, items],
  );

  const moveLayer = useCallback(
    (id, index) => {
      setItems(items => {
        const newItems = dynamicLayout
          ? items.map(item => {
              const itemDefinition = itemDefinitions[item.type];
              if (!itemDefinition?.dynamicLayout) {
                return item;
              }
              const { left = 0, top = 0 } = itemRefs[item.id]?.getBoundingClientRect() || {};
              item.position = {
                ...item.position,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                xDelta: left,
                yDelta: top,
              };
              return item;
            })
          : items;
        const itemIndex = newItems.findIndex(({ id: itemId }) => itemId === id);
        newItems.splice(index, 0, newItems.splice(itemIndex, 1)[0]);
        return newItems;
      });
      setDirty(true);
    },
    [dynamicLayout, itemRefs],
  );
  context.moveLayer = moveLayer;

  const moveLayerForward = useCallback(
    id => {
      const { index } = getOverlappingItems(id, { firstMatch: true, getLayersAfter: true });
      if (index !== undefined) {
        moveLayer(id, index);
      }
    },
    [getOverlappingItems, moveLayer],
  );
  context.moveLayerForward = moveLayerForward;

  const moveLayerBackward = useCallback(
    id => {
      const { index } = getOverlappingItems(id, { firstMatch: true, getLayersBefore: true });
      if (index !== undefined) {
        moveLayer(id, index);
      }
    },
    [getOverlappingItems, moveLayer],
  );
  context.moveLayerBackward = moveLayerBackward;

  const deselectAll = useCallback(() => {
    setPathEditMode(null);
    setItemSelectMode(null);
    setItems(items =>
      items.map(item => {
        item.selected = false;
        item.editing = false;
        item.hover = false;
        return item;
      }),
    );
  }, [setItems]);
  context.deselectAll = deselectAll;

  useEffect(() => {
    if (!editor) {
      setPathEditMode(null);
      setItemSelectMode(null);
      setItems(items =>
        items.map(item => {
          item.selected = false;
          item.editing = false;
          item.hover = false;
          return item;
        }),
      );
    }
  }, [editor, setItems]);

  const triggerEvent = useCallback(
    (id, event) => {
      const item = getItem(id);
      if (!item.events || !item.events[event]) {
        return false;
      }
      const { actionSequence } = item.events[event];
      runActionSequence(actionSequence);
      return true;
    },
    [getItem, runActionSequence],
  );
  context.triggerEvent = triggerEvent;

  const getChildren = useCallback(id => items.filter(({ parent }) => id && parent === id), [items]);
  context.getChildren = getChildren;

  const setParent = useCallback(
    (id, parent) => {
      const item = getItem(id);
      if (!item) {
        return;
      }

      const { anchorX, anchorY, anchorWidth, anchorHeight } = getAbsolutePosition(id);
      const {
        x: parentX,
        y: parentY,
        width: parentWidth,
        height: parentHeight,
      } = getAbsolutePosition(parent);

      const newPosition = {
        ...item.position,
        x:
          (anchorX / sceneSize.width) * (sceneSize.width / parentWidth) -
          (parentX / sceneSize.width) * (sceneSize.width / parentWidth),
        y:
          (anchorY / sceneSize.height) * (sceneSize.height / parentHeight) -
          (parentY / sceneSize.height) * (sceneSize.height / parentHeight),
        width: (anchorWidth / sceneSize.width) * (sceneSize.width / parentWidth),
        height: (anchorHeight / sceneSize.height) * (sceneSize.height / parentHeight),
      };
      updateItem(id, { parent, position: newPosition });
    },
    [getAbsolutePosition, getItem, sceneSize, updateItem],
  );
  context.setParent = setParent;

  const getGroupId = useCallback(
    id => {
      const item = getItem(id);
      if (!item) {
        return null;
      }
      if (item.type === 'group') {
        return id;
      }

      let parent = id;
      const traversed = new Set();
      while (parent) {
        if (traversed.has(parent)) {
          console.error('Parent-reference loop to item', parent, 'from', id);
          return null;
        }
        const { parent: parentId, type: parentType } = getItem(parent);
        if (parentType === 'group') {
          return parent;
        }
        traversed.add(parent);
        parent = parentId;
      }
      return null;
    },
    [getItem],
  );
  context.getGroupId = getGroupId;

  const setSelectedItem = useCallback(
    (id, exact = false) => {
      const selectedGroup = exact ? null : getGroupId(id);
      setPathEditMode(null);
      setItems(items =>
        items.map(item => {
          const selected =
            id !== undefined && (selectedGroup ? item.id === selectedGroup : item.id === id);
          item.selected = selected;
          item.editing = item.editing && selected;

          return item;
        }),
      );
    },
    [getGroupId, setItems],
  );
  context.setSelectedItem = setSelectedItem;

  const addItem = useCallback(
    ({ data = {}, ...item }) => {
      setItems(items => [...items, { ...item, data }]);
      if (item.selected) {
        setSelectedItem(item.id);
      }
      setDirty(true);
    },
    [setItems, setSelectedItem],
  );
  context.addItem = addItem;

  const groupItems = useCallback(
    (id, parentId) => {
      let groupId = getGroupId(parentId);

      if (!groupId) {
        const { name, position, parent } = getItem(parentId);
        groupId = nanoid();
        addItem({
          id: groupId,
          name,
          position,
          parent,
          type: 'group',
          data: {},
        });

        updateItem(parentId, {
          parent: groupId,
          position: {
            x: 0,
            y: 0,
            xDelta: 0,
            yDelta: 0,
            width: 1,
            height: 1,
            widthDelta: 0,
            heightDelta: 0,
          },
        });
      }

      setParent(id, parentId);
    },
    [addItem, getGroupId, getItem, setParent, updateItem],
  );
  context.groupItems = groupItems;

  const ungroupItems = useCallback(
    id => {
      const groupId = getGroupId(id);
      if (!groupId) {
        return;
      }
      items.forEach(({ id, parent }) => {
        if (parent !== groupId) {
          return;
        }
        setParent(id, null);
      });
    },
    [getGroupId, items, setParent],
  );
  context.ungroupItems = ungroupItems;

  context.optimize = useCallback(() => {
    let actionSequences;
    setActionSequences(sequences => {
      // Remove actions that have invalid types and action sequences with no actions.
      actionSequences = Object.entries(sequences).reduce((acc, [id, seq]) => {
        const actions = seq.actions.filter(action => actionDefinitions[action.type]);
        if (actions.length) {
          acc[id] = {
            ...seq,
            actions,
          };
        }
        return acc;
      }, {});
      return actionSequences;
    });

    let items;
    setItems(oldItems => {
      // Remove items with invalid types and events with empty actionSequences.
      items = oldItems
        .filter(item => item.type === 'group' || itemDefinitions[item.type])
        .map(item => ({
          ...item,
          events:
            item.events &&
            Object.entries(item.events).reduce((acc, [event, eventData]) => {
              if (actionSequences[eventData.actionSequence]) {
                acc[event] = eventData;
              }
              return acc;
            }, {}),
        }));
      return items;
    });

    items.forEach(item => {
      const { type } = item;
      const { optimize } = itemDefinitions[type];
      if (optimize) {
        optimize({ ...contextRef.current, item });
      }
    });
  }, [actionDefinitions]);

  return <SceneContext.Provider value={{ ...context }}>{children}</SceneContext.Provider>;
};
