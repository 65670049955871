import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

// Displays a block of text
// This text is handled by the questionaire-component for questionaires
export const Paragraph = ({ data, properties }) => (
  <p
    css={css`
      display: ${data.isVisible && !properties.templateTypeID === 'TEXT-QUESTIONNAIRE'
        ? 'unset'
        : 'none'};
    `}>
    {data.paragraphValue}3489349834
  </p>
);

Paragraph.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
