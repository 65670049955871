import { css } from '@emotion/core';

export const toolbarFieldStyle = css`
  flex: 1 1 auto;
  padding: 4px 10px;
  white-space: nowrap;
  margin: 4px;
  display: flex;
  align-items: center;
  font-size: 1em;
  user-select: none;

  :focus {
    outline: 0;
  }
`;

export const toolbarButtonStyle = css(
  toolbarFieldStyle,
  css`
    flex: 0 0 auto;
    transition: background 0.1s ease-out;
    border-radius: 20px;
    cursor: pointer;
    color: #fff;

    :hover {
      background: rgba(255, 255, 255, 0.1);
    }
  `,
);

export const toolbarButtonWarnStyle = css(
  toolbarButtonStyle,
  css`
    :hover {
      background: #ff3b30;
    }
  `,
);

export const toolbarInputStyle = css`
  outline: 0;
  appearance: none;
  border: none;
  color: inherit;
  font-size: inherit;
  background: none;
  white-space: pre;
  color: #fff;
  flex: 1 1 auto;
  padding: 0 1ch;
  line-height: 1.5;
  user-select: auto;

  :hover,
  :focus,
  :active {
    background: rgba(255, 255, 255, 0.1);
  }
  :focus,
  *:focus {
    outline: 0;
  }
`;

export const toolbarStyle = css`
  height: 40px;
  width: auto;
  border-radius: 20px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  background: #4c5059;
  color: #fff;
  pointer-events: all;
`;

export const resizeHandleStyle = css`
  position: absolute;
  width: 8px;
  height: 8px;
  pointer-events: all;
  border-radius: 50%;

  :focus {
    outline: 0;
  }
`;
export const anchorHandleStyle = css`
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: all;
  border-radius: 0;

  :focus {
    outline: 0;
  }
`;

export const dropdownBgStyle = css`
  border-radius: 13px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
`;

export const dropdownPaddingStyle = css`
  display: flex;
  flex-direction: row;
`;
export const dropdownContainerStyle = css`
  padding: 8px 10px;
  border-radius: 13px;
  background: #585d6a;
  color: #fff;
  pointer-events: all;
`;

export const toolbarCircleButton = css`
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  background: #2d78e1;

  :hover {
    background: #5792e5;
  }
  :focus {
    outline: 0;
  }
`;
