import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const SliderBackground = styled.div(
  css`
    flex: 1 1 auto;
    margin: auto;
    position: relative;
    background: #a7a6e6;
    border-radius: 24px;

    &,
    & * {
      user-select: none;
    }
  `,
  ({ vertical, width = 24, length = 160 }) =>
    vertical
      ? css`
          height: ${length}px;
          width: ${width}px;
        `
      : css`
          width: ${length}px;
          height: ${width}px;
        `,
);

export const Knob = styled.div(
  css`
    background: #7f7ede;
    border-radius: 14px;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border: 1px solid #5856d6;
    transition: background 0.166s ease-out, transform 0.166s ease-out;

    :hover {
      background: #bbbfc7;
      transform: scale(1.1);
    }
  `,
  ({ width }) => css`
    height: ${width}px;
    width: ${width}px;
  `,
);
