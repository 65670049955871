import React, { useRef, useContext, useEffect, useMemo } from 'react';
import { css } from '@emotion/core';
import { SceneContext } from './SceneContext';
import { useActions } from './hooks/use-actions';
import { usePosition } from './hooks/use-position';
import { toolbarInputStyle, toolbarStyle } from './styles';
import { ToolbarFields } from './ToolbarFields';
import { transparentize } from 'polished';
import { ItemNameEdit } from './ItemNameEdit';
import { commonItemFields } from './fields/common-item-fields';
import { EditorSelection } from './EditorSelection';
import { Dropdown } from './Dropdown';
import { PathEditor } from './PathEditor';
import { SceneOverlay } from './SceneOverlay';
import { ItemPlaceholder } from './ItemPlaceholder';
import { randomColor } from 'randomcolor';
import { useHighlightEditorItems } from './hooks/use-highlight-editor-items';

/*
 * Draggable wrapper and toolbar for components.
 *
 * Items are draggable once selected. Selection is handled by onClick returned from useActions, see an existing component for examples.
 * Some text items have an "editing" mode which disables the ability to move, allowing the user to select and edit text.
 *
 * Attributes such as resizable, editable, keepAspectRatio are defined in itemDefinitions at the top of ItemScene.jsx.
 * Here fields for the toolbar are also accessible. Values assigned in the editor/toolbar become values in the data prop of the component.
 */
export const EditorItem = ({ className, component: Component, id, item, index }) => {
  const context = useContext(SceneContext);
  const {
    items,
    scale,
    selection,
    updateItem,
    itemSelectMode,
    pathEditMode,
    editorHighlightedItems,
    itemRefs,
    moving,
    getItemDefinitionProperty,
    setMoving,
    actionSequences,
  } = context;

  const { parent, selected, editing, hover } = item;

  const anchorRef = useRef(null);
  const selectionRef = useRef(null);
  const selectionBackgroundRef = useRef(null);

  const { selectionOnMouseDown, anchorOnMouseDown } = useActions(id);

  const movingId = moving?.id;

  const position = usePosition(id);
  const parentPosition = usePosition(parent);

  useEffect(() => {
    let running = true;

    setMoving(moving => moving && { ...moving, showPlaceholder: true });

    const originalStyles = [];
    const saveStyles = (index, element) => {
      if (!element || originalStyles[index]) {
        return;
      }
      originalStyles[index] = {
        position: element.style.position,
        left: element.style.left,
        top: element.style.top,
        width: element.style.width,
        height: element.style.height,
      };
    };
    const restoreStyles = (index, element) => {
      if (!element || !originalStyles[index]) {
        return;
      }
      const { style } = element;
      style.position = originalStyles[index].position;
      style.left = originalStyles[index].left;
      style.top = originalStyles[index].top;
      style.width = originalStyles[index].width;
      style.height = originalStyles[index].height;
    };

    const update = () => {
      if (!running) {
        restoreStyles(0, selectionBackgroundRef.current);
        restoreStyles(1, itemRefs[id]);
        restoreStyles(2, selectionRef.current);
        restoreStyles(3, anchorRef.current);
        return;
      }
      if (movingId === id) {
        saveStyles(0, selectionBackgroundRef.current);
        saveStyles(1, itemRefs[id]);
        saveStyles(2, selectionRef.current);
        saveStyles(3, anchorRef.current);

        const {
          x = 0,
          y = 0,
          xDelta = 0,
          yDelta = 0,
          width = 0,
          height = 0,
          widthDelta = 0,
          heightDelta = 0,
        } = selection.current[id] || {};

        const updatePositionStyle = (
          element,
          scale = 1,
          offsetX = 0,
          offsetY = 0,
          resize = true,
        ) => {
          if (!element) {
            return;
          }
          const { style } = element;
          style.position = 'absolute';
          style.left = `${(position.x + xDelta + x * parentPosition.width) * scale + offsetX}px`;
          style.top = `${(position.y + yDelta + y * parentPosition.height) * scale + offsetY}px`;
          if (resize) {
            style.width = `${
              (position.width + widthDelta + width * parentPosition.width) * scale
            }px`;
            style.height = `${
              (position.height + heightDelta + height * parentPosition.height) * scale
            }px`;
          }
        };

        updatePositionStyle(selectionBackgroundRef.current);
        updatePositionStyle(itemRefs[id]);
        updatePositionStyle(selectionRef.current, scale);

        if (anchorRef.current) {
          const { style } = anchorRef.current;
          style.position = 'absolute';
          style.left = `${(position.anchorX + x * parentPosition.width) * scale}px`;
          style.top = `${(position.anchorY + y * parentPosition.height) * scale}px`;
          style.width = `${(position.anchorWidth + width * parentPosition.width) * scale}px`;
          style.height = `${(position.anchorHeight + height * parentPosition.height) * scale}px`;
        }
      }

      requestAnimationFrame(update);
    };
    requestAnimationFrame(update);

    return () => {
      running = false;
    };
  }, [position, parentPosition, id, itemRefs, movingId, parent, scale, selection, setMoving]);

  const itemRef = useRef(itemRefs[id]);
  itemRef.current = itemRefs[id];

  const { events } = item;

  const linkedItemIds = useMemo(
    () =>
      actionSequences[events?.click?.actionSequence]?.actions.reduce((acc, action) => {
        if (!acc.includes(action.target) && action.target !== id) {
          acc.push(action.target);
        }
        return acc;
      }, []) || [],
    [actionSequences, events, id],
  );

  useHighlightEditorItems(linkedItemIds, selected);

  const fields = getItemDefinitionProperty(id, 'fields');
  const resizable = getItemDefinitionProperty(id, 'resizable');
  const disableRename = getItemDefinitionProperty(id, 'disableRename');
  const keepAspectRatio = getItemDefinitionProperty(id, 'keepAspectRatio');
  const disableAnchorResize = getItemDefinitionProperty(id, 'disableAnchorResize');

  return (
    <>
      <div
        ref={selectionBackgroundRef}
        css={css(
          css`
            display: none;
            overflow: hidden;
            position: absolute;
            left: ${position.x}px;
            top: ${position.y}px;
            width: ${position.width}px;
            height: ${position.height}px;
            background: ${!editorHighlightedItems[id] ? 'rgba(128, 227, 255, 0.14)' : 'none'};
            pointer-events: none;
          `,
          editorHighlightedItems[id] &&
            css`
              boxshadow: 0 0 0 ${2 / scale}px
                ${transparentize(0.2, randomColor({ luminosity: 'light', seed: id }))};
            `,
          !position.dynamicLayout &&
            (selected || editorHighlightedItems[id]) &&
            !itemSelectMode &&
            !pathEditMode &&
            css`
              display: block;
            `,
        )}
      />
      <ItemPlaceholder
        key="itemPlaceholder_top"
        width={moving?.position.widthDelta}
        height={moving?.position.heightDelta}
        outline
        enabled={
          position.dynamicLayout &&
          moving &&
          moving.showPlaceholder &&
          moving.showDropzone &&
          moving.id !== id &&
          moving.index === -2 &&
          index === 0
        }
      />
      {Component && (
        <Component
          className={className}
          css={css(
            !editing &&
              css`
                &,
                & * {
                  user-select: none;
                }
              `,
            itemSelectMode &&
              css`
                cursor: crosshair;
              `,
            pathEditMode &&
              pathEditMode?.id !== id &&
              css`
                filter: contrast(30%) grayscale(100%) brightness(150%);
              `,
          )}
          key={`${id}_item`}
          id={id}
          item={item}
        />
      )}
      {Component && pathEditMode && pathEditMode.id === id && pathEditMode.targetPosition && (
        <Component
          className={className}
          css={css`
            opacity: 0.6;

            &,
            & * {
              user-select: none;
            }
          `}
          key={`${id}_targetPosition`}
          id={`${id}_targetPosition`}
          item={item}
        />
      )}
      <ItemPlaceholder
        key="itemPlaceholder_bottom"
        width={moving?.position.widthDelta}
        height={moving?.position.heightDelta}
        outline={moving?.showDropzone}
        enabled={
          position.dynamicLayout &&
          moving &&
          moving.showPlaceholder &&
          ((moving.id === id && moving.index === undefined) ||
            moving.index === index - 1 ||
            (index === items.length - 1 && moving.index >= index - 1) ||
            (moving.id === id && moving.index === -1 && index === 0))
        }
      />
      <SceneOverlay>
        {selected &&
          ((pathEditMode && <PathEditor />) ||
            (!itemSelectMode && (
              <>
                <EditorSelection
                  id="selection"
                  ref={selectionRef}
                  x={position.x * scale}
                  y={position.y * scale}
                  width={position.width * scale}
                  height={position.height * scale}
                  onMouseDownEvents={selectionOnMouseDown}
                  resizeHorizontal={resizable !== false && !position.dynamicLayout}
                  resizeVertical={resizable !== false}
                  resizeCornersOnly={keepAspectRatio}
                  color="#80e3ff"
                />
                {!position.dynamicLayout && (
                  <EditorSelection
                    id="anchor"
                    ref={anchorRef}
                    x={position.anchorX * scale}
                    y={position.anchorY * scale}
                    width={position.anchorWidth * scale}
                    height={position.anchorHeight * scale}
                    onMouseDownEvents={anchorOnMouseDown}
                    color="#ff852e"
                    resizeHorizontal={!disableAnchorResize}
                    resizeVertical={!disableAnchorResize}
                    anchor
                  />
                )}
              </>
            )))}
        {selected && (
          <Dropdown
            id="toolbar"
            parentRef={itemRef}
            distance={10}
            alignCenter
            alignTop
            base
            css={css(
              css`
                display: flex;
                flex-direction: column;
                pointer-events: none;
              `,
              (itemSelectMode || pathEditMode || moving?.id === id) &&
                css`
                  display: none;
                `,
            )}>
            {!pathEditMode && !disableRename && (
              <ItemNameEdit
                css={css(
                  toolbarInputStyle,
                  css`
                    flex: 0;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 0.5em;
                    line-height: 1.5;
                    color: #4d5059;
                    pointer-events: all;
                  `,
                )}
                id={id}
              />
            )}
            {fields && (
              <div
                css={css(
                  toolbarStyle,
                  css`
                    pointer-events: all;
                  `,
                )}>
                <ToolbarFields
                  id={id}
                  fields={[...fields, ...commonItemFields]}
                  getValue={({ field: { name }, id, getItem }) => name && getItem(id).data[name]}
                  setValue={(value, { field: { name } }) => {
                    if (name) {
                      updateItem(id, { data: { [name]: value } });
                    }
                  }}
                />
              </div>
            )}
          </Dropdown>
        )}
        <div
          id="hover"
          css={css(
            {
              position: 'absolute',
              background: 'rgba(128, 227, 255, 0.14)',
              border: '1px solid rgba(128, 227, 255, 0.8)',
              boxSizing: 'border-box',
              pointerEvents: 'none',
              display: 'none',
            },
            (!selected || itemSelectMode) &&
              hover && {
                display: 'block',
                left: `${position.x * scale}px`,
                top: `${position.y * scale}px`,
                width: `${position.width * scale}px`,
                height: `${position.height * scale}px`,
              },
          )}
        />
      </SceneOverlay>
    </>
  );
};
