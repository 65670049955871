import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { SceneContext } from './SceneContext';
import { usePosition } from './hooks/use-position';
import { SVGUniqueID } from 'react-svg-unique-id';
import { css } from '@emotion/core';
import { svgPathProperties as SvgPathProperties } from 'svg-path-properties';
import { easingFunctions } from '../../utils/easing-functions';
import { ToolbarFields } from './ToolbarFields';
import { toolbarStyle } from './styles';
import { Dropdown } from './Dropdown';
import { EditorSelection } from './EditorSelection';
import SvgPath from 'svgpath';
import { SceneOverlay } from './SceneOverlay';

export const PathEditor = () => {
  const {
    pathEditMode,
    scale,
    actionSequences,
    setActionSequences,
    actionDefinitions,
    runActionSequence,
    stopActionSequence,
  } = useContext(SceneContext);
  const { id, preview, actionSequence, actionSequenceIndex } = pathEditMode;

  const itemPosition = usePosition(id);

  const [
    { fields, getValue, setValue, values: { path: previousPath, easing, pathOffset } = {} },
    setToolbarFields,
  ] = useState({});

  const updateAction = useCallback(
    data => {
      setActionSequences(seq => ({
        ...seq,
        [actionSequence]: {
          ...(seq[actionSequence] ?? {}),
          actions: (seq[actionSequence]?.actions ?? []).map((action, index) =>
            index !== actionSequenceIndex
              ? action
              : {
                  ...action,
                  ...data,
                },
          ),
        },
      }));
    },
    [actionSequence, actionSequenceIndex, setActionSequences],
  );

  useEffect(() => {
    const id = actionSequence;
    if (preview) {
      runActionSequence(id);
    } else {
      stopActionSequence(id);
    }
    return () => {
      stopActionSequence(id);
    };
  }, [preview, runActionSequence, actionSequence, stopActionSequence, previousPath]);

  useLayoutEffect(() => {
    if (!actionSequence || actionSequenceIndex === undefined) {
      return;
    }
    const actions = actionSequences[actionSequence]?.actions;
    const { type, ...values } = actions[actionSequenceIndex] || {};
    const { fields } = actionDefinitions[type];

    const getValue = ({ field: { name } }) => values[name];
    const setValue = (value, { field: { name } }) => {
      if (name && value !== values[name]) {
        updateAction({ [name]: value });
      }
    };

    setToolbarFields({ fields, getValue, setValue, values });
  }, [actionDefinitions, actionSequence, actionSequenceIndex, actionSequences, updateAction]);

  const [changed, setChanged] = useState(false);
  const [path, setPath] = useState(previousPath);

  useEffect(() => {
    if (changed) {
      updateAction({ path });
      setChanged(false);
    }
  }, [changed, path, updateAction]);

  useLayoutEffect(() => {
    setPath(previousPath);
    setChanged(false);
  }, [previousPath]);

  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const offsetRef = useRef(null);
  const selectionRef = useRef(null);

  const strokeWidth = 3;
  const padding = strokeWidth * 1.5;

  const position = useRef(null);

  const updatePosition = useCallback(
    path => {
      const pathElement = pathRef.current;
      if (!path) {
        return;
      }

      const pathProperties = new SvgPathProperties(path);
      const pathLength = pathProperties.getTotalLength();
      const currentLength =
        pathLength * (easingFunctions[easing] ?? easingFunctions.linear)(pathOffset % 1 || 0);

      const offset = pathProperties.getPointAtLength(currentLength);
      const viewBox = pathElement.getBBox();

      const newPosition = {
        x: (itemPosition.x + itemPosition.width / 2) * scale + (viewBox.x * scale - padding),
        y: (itemPosition.y + itemPosition.height / 2) * scale + (viewBox.y * scale - padding),
        width: viewBox.width * scale + padding * 2 || undefined,
        height: viewBox.height * scale + padding * 2 || undefined,
      };

      position.current = newPosition;

      svgRef.current.setAttribute(
        'viewBox',
        `${viewBox.x - padding / scale} ${viewBox.y - padding / scale} ${
          viewBox.width + (padding * 2) / scale
        } ${viewBox.height + (padding * 2) / scale}`,
      );

      offsetRef.current.setAttribute('cx', offset.x);
      offsetRef.current.setAttribute('cy', offset.y);

      const { style: svgStyle } = svgRef.current;
      svgStyle.left = `${newPosition.x}px`;
      svgStyle.top = `${newPosition.y}px`;
      svgStyle.width = `${newPosition.width}px`;
      svgStyle.height = `${newPosition.height}px`;

      const { style: selectionStyle } = selectionRef.current;
      selectionStyle.left = `${newPosition.x}px`;
      selectionStyle.top = `${newPosition.y}px`;
      selectionStyle.width = `${newPosition.width}px`;
      selectionStyle.height = `${newPosition.height}px`;
    },
    [
      easing,
      itemPosition.height,
      itemPosition.width,
      itemPosition.x,
      itemPosition.y,
      padding,
      pathOffset,
      scale,
    ],
  );

  const editorMouseDown = useCallback(
    eventType => e => {
      if (!position.current) {
        return true;
      }

      e.preventDefault();
      e.stopPropagation();

      const target = e.target;
      if (target.setCapture) {
        target.setCapture();
      }

      const newPosition = { ...position.current };
      const initialPosition = { ...newPosition };
      let newPath = path;
      const { pageX: initialPageX, pageY: initialPageY } = e;

      const onMouseMove = e => {
        e.preventDefault();
        e.stopPropagation();

        const { pageX, pageY } = e;
        const deltaX = (pageX - initialPageX) / scale;
        const deltaY = (pageY - initialPageY) / scale;

        if (eventType === 'move') {
          newPosition.x = initialPosition.x + deltaX;
          newPosition.y = initialPosition.y + deltaY;
        }
        if (eventType === 'wResize' || eventType === 'nwResize' || eventType === 'swResize') {
          newPosition.x = initialPosition.x + deltaX;
          newPosition.width = initialPosition.width - deltaX;
        }
        if (eventType === 'eResize' || eventType === 'neResize' || eventType === 'seResize') {
          newPosition.width = initialPosition.width + deltaX;
        }
        if (eventType === 'nResize' || eventType === 'nwResize' || eventType === 'neResize') {
          newPosition.y = initialPosition.y + deltaY;
          newPosition.height = initialPosition.height - deltaY;
        }
        if (eventType === 'sResize' || eventType === 'swResize' || eventType === 'seResize') {
          newPosition.height = initialPosition.height + deltaY;
        }

        const pathElement = pathRef.current;
        const svgPath = new SvgPath(path);
        svgPath.scale(
          newPosition.width / initialPosition.width,
          newPosition.height / initialPosition.height,
        );
        svgPath.translate(newPosition.x - initialPosition.x, newPosition.y - initialPosition.y);
        newPath = svgPath.toString();
        pathElement.setAttribute('d', newPath);

        requestAnimationFrame(() => updatePosition(newPath));

        return false;
      };
      const onMouseUp = () => {
        if (target.releaseCapture) {
          target.releaseCapture();
        }
        setPath(newPath);
        setChanged(true);
        updatePosition(newPath);
        window.removeEventListener('mousemove', onMouseMove, false);
        window.removeEventListener('mouseup', onMouseUp, false);
      };
      window.addEventListener('mousemove', onMouseMove, false);
      window.addEventListener('mouseup', onMouseUp, false);

      return false;
    },
    [path, position, scale, updatePosition],
  );

  const [selectionOnMouseDown, setSelectionOnMouseDown] = useState({});

  useLayoutEffect(() => {
    setSelectionOnMouseDown({
      move: editorMouseDown('move'),
      nwResize: editorMouseDown('nwResize'),
      nResize: editorMouseDown('nResize'),
      neResize: editorMouseDown('neResize'),
      swResize: editorMouseDown('swResize'),
      sResize: editorMouseDown('sResize'),
      seResize: editorMouseDown('seResize'),
      wResize: editorMouseDown('wResize'),
      eResize: editorMouseDown('eResize'),
    });
  }, [editorMouseDown]);

  useEffect(() => {
    requestAnimationFrame(() => {
      updatePosition(path);
    });
  }, [updatePosition, path]);

  return (
    <SceneOverlay>
      {!preview && (
        <div
          css={css`
            position: absolute;
            left: ${(itemPosition.x + itemPosition.width / 2) * scale - 4}px;
            top: ${(itemPosition.y + itemPosition.height / 2) * scale - 4}px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ff852e;
            opacity: 0.6;
            pointer-events: none;
            user-select: none;
          `}
        />
      )}
      <SVGUniqueID>
        <svg
          ref={svgRef}
          css={css(
            css`
              position: absolute;
            `,
            preview &&
              css`
                opacity: 0.2;
              `,
          )}
          preserveAspectRatio="none">
          <path
            ref={pathRef}
            stroke="#5b57d9"
            strokeWidth={strokeWidth}
            fill="transparent"
            d={path}
            markerEnd="url(#arrow)"
          />
          <circle
            ref={offsetRef}
            r={strokeWidth * 1.5}
            fill="#ff852e"
            stroke="transparent"></circle>
        </svg>
      </SVGUniqueID>
      <EditorSelection
        ref={selectionRef}
        onMouseDownEvents={selectionOnMouseDown}
        color="#80e3ff"
        resizeHorizontal
        resizeVertical
        move
      />
      {fields && (
        <Dropdown parentRef={svgRef} distance={10} alignCenter alignTop base>
          <div css={toolbarStyle}>
            <ToolbarFields
              id={id}
              fields={fields}
              getValue={getValue}
              setValue={setValue}
              omit={['target', 'path']}
            />
          </div>
        </Dropdown>
      )}
    </SceneOverlay>
  );
};
