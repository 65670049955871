import { ToolbarSeparator } from '../editor-toolbar/ToolbarSeparator';
import { ToolbarButton } from '../editor-toolbar/ToolbarButton';
import { ToolbarColorPicker } from '../editor-toolbar/ToolbarColorPicker';
import { ToolbarActionEdit } from '../editor-toolbar/ToolbarActionEdit';
import { ToolbarSelect } from '../editor-toolbar/ToolbarSelect';
import { ToolbarItemPicker } from '../editor-toolbar/ToolbarItemPicker';
import { ToolbarText } from '../editor-toolbar/ToolbarText';
import { ToolbarAnchorEdit } from '../editor-toolbar/ToolbarAnchorEdit';
import { ToolbarRotate } from '../editor-toolbar/ToolbarRotate';
import { ToolbarAssetSelect } from '../editor-toolbar/ToolbarAssetSelect';
import { ToolbarGroupEdit } from '../editor-toolbar/ToolbarGroupEdit';
import { ToolbarAlign } from '../editor-toolbar/ToolbarAlign';
import { ToolbarPadding } from '../editor-toolbar/ToolbarPadding';
import { ToolbarFontfamily } from '../editor-toolbar/ToolbarFontfamily';
import { ToolbarSlider } from '../editor-toolbar/ToolbarSlider';
import { ToolbarToggle } from '../editor-toolbar/ToolbarToggle';

export const toolbarButtons = {
  separator: {
    component: ToolbarSeparator,
  },
  button: {
    component: ToolbarButton,
  },
  color: {
    component: ToolbarColorPicker,
  },

  boolean: {
    component: ToolbarToggle,
  },
  select: {
    component: ToolbarSelect,
  },
  event: {
    component: ToolbarActionEdit,
  },
  action: {
    component: ToolbarActionEdit,
  },
  target: {
    component: ToolbarItemPicker,
  },
  string: {
    component: ToolbarText,
  },
  number: {
    component: ToolbarText,
  },
  time: {
    component: ToolbarText,
  },
  padding: {
    component: ToolbarPadding,
  },
  family: {
    component: ToolbarFontfamily,
  },
  anchor: {
    component: ToolbarAnchorEdit,
  },
  rotate: {
    component: ToolbarRotate,
  },
  path: {
    component: ToolbarAssetSelect,
  },
  group: {
    component: ToolbarGroupEdit,
  },
  align: {
    component: ToolbarAlign,
  },
  verticalAlign: {
    component: ToolbarAlign,
  },
  range: {
    component: ToolbarSlider,
  },
};
