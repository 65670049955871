import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormSelect } from '~/components/FormSelect';

const RedirectionsTab = props => {
  const { pages, handleSubmit } = props;
  return (
    <form
      className="questionnaire-section-editor__settings-container"
      autoComplete="off"
      onSubmit={handleSubmit}>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormSelect}
          name="onSuccessPage"
          data={pages}
          defaultValue={undefined}
          textField={page => page.title || page.id}
          label="Redirect on success"
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormSelect}
          name="onFailurePage"
          data={pages}
          defaultValue={undefined}
          textField={page => page.title || page.id}
          label="Redirect on failure"
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
    </form>
  );
};

RedirectionsTab.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const RedirectionsTabForm = reduxForm({
  form: 'questionareSectionRedirectionsTabForm',
})(RedirectionsTab);
