export const PUBLICATION_STATUS_NOT_PUBLISHED = 'NOT_PUBLISHED';
export const PUBLICATION_STATUS_PUBLISHED = 'PUBLISHED';

const publicationStatusMessages = {
  [PUBLICATION_STATUS_PUBLISHED]: 'Published',
  [PUBLICATION_STATUS_NOT_PUBLISHED]: 'Not Published',
};

export const getPublicationStatusMessage = publicationStatus =>
  publicationStatusMessages[publicationStatus];
