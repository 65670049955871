import React, { useContext, useState, useCallback } from 'react';
import {
  toolbarButtonStyle,
  toolbarCircleButton,
  dropdownContainerStyle,
  toolbarButtonWarnStyle,
} from '../styles';
import { SceneContext } from '../SceneContext';
import { ToolbarFields } from '../ToolbarFields';
import {
  faAngleLeft,
  faPencilAlt,
  faPlus,
  faGripLines,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { sortableElement, sortableContainer, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { randomColor } from 'randomcolor';
import { maxStringLength } from '../../../utils/max-string-length';
import { FieldContext } from '../FieldContext';

const SortableHandle = sortableHandle(() => (
  <div
    css={css`
      margin-right: 4px;
      color: #7a7c83;
      display: flex;
      align-items: center;
    `}>
    <FontAwesomeIcon icon={faGripLines} />
  </div>
));

const SortableItem = sortableElement(({ children, ...props }) => <div {...props}>{children}</div>);

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

export const ActionSequenceEditor = ({ id, actionSequence, target = null, autorun = false }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const context = useContext(SceneContext);
  const {
    actionSequences,
    setActionSequences,
    actionDefinitions,
    itemSelectMode,
    setDirty,
    createAction,
    updateAction,
    getItem,
  } = context;
  const fieldContext = useContext(FieldContext);

  const [creatingAction, setCreatingAction] = useState(false);
  const [showAdvanced] = useState(false);

  const createNewAction = useCallback(
    (type, data = {}) => {
      const actionDefinition = actionDefinitions[type];
      if (actionDefinition?.onCreate) {
        actionDefinition.onCreate({
          ...context,
          ...fieldContext,
          target,
          autorun,
        });
      }
      const index = createAction(actionSequence, type, data, { autorun, target });
      if (index !== -1) {
        setSelectedIndex(index);
        setCreatingAction(false);
      }
    },
    [actionDefinitions, createAction, actionSequence, autorun, target, context, fieldContext],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setActionSequences(seq => ({
        ...seq,
        [actionSequence]: {
          ...(seq[actionSequence] ?? {}),
          actions: arrayMove(seq[actionSequence].actions, oldIndex, newIndex),
        },
      }));
      setDirty(true);
    },
    [actionSequence, setActionSequences, setDirty],
  );

  const removeActionAtIndex = useCallback(
    index => {
      let action;
      setActionSequences(seq => {
        const actions = [...seq[actionSequence].actions];
        action = actions.splice(index, 1);

        return {
          ...seq,
          [actionSequence]: {
            ...(seq[actionSequence] ?? {}),
            actions,
          },
        };
      });
      setDirty(true);
      const actionDefinition = actionDefinitions[action.type];
      if (actionDefinition?.onRemove) {
        actionDefinition.onRemove({
          ...context,
          ...fieldContext,
          target,
          autorun,
        });
      }
    },
    [
      actionDefinitions,
      actionSequence,
      autorun,
      context,
      fieldContext,
      setActionSequences,
      setDirty,
      target,
    ],
  );

  const { type, ...values } = actionSequences[actionSequence]?.actions[selectedIndex] || {};
  const { label, fields } = actionDefinitions[type] || {};

  return (
    !itemSelectMode && (
      <div css={dropdownContainerStyle}>
        {(creatingAction && (
          <>
            <div
              css={css`
                font-weight: bold;
                margin-bottom: 1em;
                display: flex;
                flex-direction: row;
                align-items: center;
              `}>
              <div
                css={css(toolbarCircleButton)}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setCreatingAction(false);
                }}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
              Legg til ny Action
            </div>
            {Object.entries(actionDefinitions)
              .filter(
                ([, { allowAutorun, advanced }]) =>
                  (showAdvanced || !advanced) && (!autorun || allowAutorun),
              )
              .map(([id, { label, description }]) => (
                <div
                  key={id}
                  css={css(
                    toolbarButtonStyle,
                    css`
                      border-radius: 10px;
                      display: flex;
                      flex-direction: row;
                      padding: 4px 10px;
                      margin: 4px 0;
                    `,
                  )}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    createNewAction(id, { target });
                  }}>
                  <div
                    css={css`
                      flex: 1 1 auto;
                      margin-right: 1em;
                    `}>
                    <div>
                      {typeof label === 'function' ? label({ template: true, target }) : label}
                    </div>
                    {description && (
                      <div
                        css={css`
                          font-size: 0.85em;
                          color: #444;
                        `}>
                        {typeof description === 'function' ? description({}) : description}
                      </div>
                    )}
                  </div>
                  <div
                    css={css`
                      flex: 0 0 auto;
                      margin-left: auto;
                    `}>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              ))}
          </>
        )) ||
          (actionSequences[actionSequence]?.actions[selectedIndex] && (
            <div>
              <div
                css={css`
                  font-weight: bold;
                  margin-bottom: 1em;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}>
                <div
                  css={css(toolbarCircleButton)}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setSelectedIndex(null);
                  }}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                {typeof label === 'function'
                  ? label(
                      fields.reduce((acc, { name }) => ({ ...acc, [name]: values[name] }), {
                        template: false,
                      }),
                    )
                  : label}
              </div>
              <ToolbarFields
                id={id}
                fields={fields}
                getValue={({ field: { name } }) => values[name]}
                setValue={(value, { field: { name } }) => {
                  if (name && value !== values[name]) {
                    updateAction(actionSequence, selectedIndex, { [name]: value });
                  }
                }}
                omit={target ? ['target'] : undefined}
                context={{ actionSequence, actionSequenceIndex: selectedIndex }}
              />
              <div
                css={css(
                  toolbarButtonWarnStyle,
                  css`
                    flex: 0 0 auto;
                    margin-top: 1em;
                    margin-right: auto;
                    width: min-content;
                  `,
                )}
                role="button"
                tabIndex="0"
                onClick={() => {
                  removeActionAtIndex(selectedIndex);
                  setSelectedIndex(null);
                }}>
                <FontAwesomeIcon icon={faTrashAlt} />
                &nbsp;Slett Action
              </div>
            </div>
          )) || (
            <>
              <div
                css={css`
                  margin-bottom: 0.5em;
                  font-weight: bold;
                `}>
                Actions
              </div>
              <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis="y">
                <div>
                  {actionSequences[actionSequence]?.actions.map(({ type, ...values }, index) => {
                    const action = actionDefinitions[type];
                    if (!action) {
                      console.warn('No action definition for', type);
                      return null;
                    }
                    const { label, fields } = action;

                    return (
                      <SortableItem
                        key={index}
                        index={index}
                        css={css`
                          display: flex;
                          flex-direaction: row;
                        `}>
                        <SortableHandle />
                        <div
                          css={css(
                            toolbarButtonStyle,
                            css`
                              flex: 1 1 auto;
                              border-radius: 10px;
                              display: flex;
                              flex-direction: row;
                              padding: 4px 10px;
                              margin: 4px 0;
                            `,
                          )}
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            setSelectedIndex(index);
                          }}>
                          <div
                            css={css`
                              flex: 1 1 auto;
                              margin-right: 1em;
                            `}>
                            <div
                              css={css`
                                display: flex;
                                flex-direction: row;
                              `}>
                              <div
                                css={css`
                                  display: flex;
                                  justify-content: center;
                                  color: #ccc;
                                  font-size: 0.85em;
                                  margin-right: 0.5em;
                                `}>
                                {index + 1}
                              </div>
                              {typeof label === 'function'
                                ? label(
                                    fields.reduce(
                                      (acc, { name }) => ({ ...acc, [name]: values[name] }),
                                      { template: false },
                                    ),
                                  )
                                : label}
                            </div>
                            {!target && values.target && (
                              <div
                                css={css`
                                  font-size: 0.85em;
                                  color: ${randomColor({
                                    luminosity: 'light',
                                    seed: values.target,
                                  })};
                                `}>
                                {getItem(values.target)?.name ||
                                  `${maxStringLength(6, values.target)}`}
                              </div>
                            )}
                          </div>
                          <div
                            css={css`
                              flex: 0 0 auto;
                              margin-left: 0;
                            `}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </div>
                        </div>
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContainer>

              <div
                css={css(
                  toolbarButtonStyle,
                  css`
                    flex: 1 1 auto;
                    margin-top: 8px;
                    display: flex;
                    flex-direction: row;
                    color: #fff;
                    padding-right: 4px;

                    :hover > div {
                      background: #5792e5;
                    }
                  `,
                )}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setCreatingAction(true);
                }}>
                <div
                  css={css`
                    flex: 1 1 auto;
                    background: none !important;
                    margin-right: 1em;
                  `}>
                  Legg til ny Action
                </div>
                <div css={css(toolbarCircleButton)}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </>
          )}
      </div>
    )
  );
};
