import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { TextElement } from '~/components/ContentEditable/TextElement';
import { textElementFields } from '~/components/ContentEditable/text-element-fields';

export const Dilemma = ({ onValueUpdate, data, activeSection }) => {
  const [editorState, setEditorState] = useState(data.explain?.text);

  const onParagraphChange = useCallback(
    text => {
      onValueUpdate({
        ...data,
        question: {
          text,
          attr: { ...data.question.attr },
        },
      });
    },
    [data, onValueUpdate],
  );

  // const onExplainChange = useCallback(
  //   (ev, text) => {
  //     onValueUpdate({
  //       ...data,
  //       explain: { text },
  //     });
  //   },
  //   [data, onValueUpdate],
  // );

  // const handleEvent = useCallback((ev, value) => {
  //   ev.persist();
  //   console.log(ev.type, ev, value);
  // }, []);

  const onEditorStateChange = useCallback(
    value => {
      setEditorState(value);
      onValueUpdate({
        ...data,
        explain: {
          ...data.explain,
          text: value,
        },
      });
    },
    [data, onValueUpdate],
  );

  const getDataField = useCallback(
    ({ field: { name } }) =>
      data.question && data.question.attr ? data.question.attr[name] : undefined,
    [data],
  );

  const setDataField = useCallback(
    (value, { field: { name } }) =>
      onValueUpdate({
        ...data,
        question: { ...data.question, attr: { ...data.question.attr, [name]: value } },
      }),
    [onValueUpdate, data],
  );

  return (
    (activeSection === 2 && (
      <div className="text-section-editor__container">
        <ReactQuill
          className="quill-item"
          theme="bubble"
          value={editorState || 'Skriv inn beskrivelse'}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
              ],
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={onEditorStateChange}
        />
      </div>
    )) || (
      <div className="text-section-editor__container">
        <TextElement
          className="ingress"
          content={data.question?.text || 'Skriv inn paragraph'}
          onChange={onParagraphChange}
          caretPosition="end"
          editable
          multiline
          useToolbar={textElementFields}
          data={data.question?.attr}
          getValue={getDataField}
          setValue={setDataField}
        />
      </div>
    )
  );
};

Dilemma.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.shape({
      text: PropTypes.string.isRequired,
      attr: PropTypes.shape({}).isRequired,
    }).isRequired,
    explain: PropTypes.any.isRequired,
    questionData: PropTypes.shape({}),
  }).isRequired,
  activeSection: PropTypes.number.isRequired,
  onValueUpdate: PropTypes.func.isRequired,
};
