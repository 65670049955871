export const sizeOptions = [
  {
    text: 'Liten',
    value: 's',
    size: '1.0em',
  },
  {
    text: 'Middels',
    value: 'm',
    size: '1.5em',
  },
  {
    text: 'Stor',
    value: 'l',
    size: '2em',
  },
  {
    text: 'Ekstra stor',
    value: 'xl',
    size: '3em',
  },
  {
    text: 'Størst',
    value: 'xxl',
    size: '4em',
  },
];

export const fontSizes = {
  s: '1.1em',
  m: '1.5em',
  l: '2em',
  xl: '3em',
  xxl: '4em',
};
