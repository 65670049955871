import React, { useEffect, useState, useRef, useContext } from 'react';
import { SceneContext } from './SceneContext';
import { maxStringLength } from '../../utils/max-string-length';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -1.25em;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
`;

export const ItemNameEdit = ({ className, id, disabled }) => {
  const { getItem, updateItem } = useContext(SceneContext);
  const [value, setValue] = useState(getItem(id)?.name);
  const inputRef = useRef(null);

  useEffect(() => {
    const element = inputRef.current;

    element.textContent = getItem(id)?.name || `Uten navn (${maxStringLength(6, id)})`;
  }, [getItem, id]);

  useEffect(() => {
    updateItem(id, { name: value });
  }, [value, id, updateItem]);

  const [editorProps, setEditorProps] = useState({});

  useEffect(() => {
    if (!disabled) {
      setEditorProps({
        contentEditable: true,
        onFocus: ({ target }) => {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(target);
          selection.removeAllRanges();
          selection.addRange(range);
        },
        role: 'textbox',
        tabIndex: '0',
        onBlur: ({ target }) => {
          target.textContent = value || `Uten navn (${maxStringLength(6, id)})`;
        },
        onInput: ({ target }) => {
          setValue(target.textContent.replace(/\n/g, ' ').trim());
        },
        onKeyDown: e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        },
        onPaste: e => {
          e.preventDefault();
        },
      });
    } else {
      setEditorProps({});
    }
  }, [disabled, id, value]);

  return (
    <div
      className={className}
      css={css(
        !disabled &&
          css`
            flex: 0;
            display: flex;
            align-items: center;

            :hover ${Icon} {
              visibility: visible;
            }
          `,
      )}
      onClick={() => {
        inputRef.current.focus();
      }}
      role="button"
      tabIndex="-1">
      <div
        css={css`
          flex: 0;
          position: relative;
          margin: auto;
        `}>
        <div ref={inputRef} {...editorProps} />
        {!disabled && <Icon icon={faPencilAlt} />}
      </div>
    </div>
  );
};
