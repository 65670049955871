// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { mqMaxWidth } from '~/modules/Player/styles/facepaint';

export const cardBorderRadius = '24px';
const navigtionButtonColor = '#26252a';

export const DilemmaPlayerWrapper = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;

  background-color: ${props => props.backgroundColor};
  background-size: cover;
  background-position: center;

  ${mqMaxWidth.small} {
    background: none;
    &.front_fullscreen {
      background-color: ${props => props.backgroundColor};
    }
  }

  width: 100%;
  /*DEBUGmargin: 2em 0;*/
  padding: 1.5em;

  &.front_fullscreen {
    color: white;
  }

  @media and (max-width: 340px) {
    padding: 0.5em;
  }

  transition: background 0.5s;
`;

export const CardWrapper = styled.div`
  height: 100%;
  position: relative;

  width: 100%;
  margin: 2em 0;

  max-width: 1000px;
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;

  :hover {
    cursor: pointer;
  }

  ${mqMaxWidth.small} {
    margin-top: 5em;
    margin-bottom: 3em;
    height: 100%;
  }
`;

export const CardHtmlContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  border-bottom-left-radius: ${cardBorderRadius};
  border-bottom-right-radius: ${cardBorderRadius};
  padding: 2.45em 2em;
  line-height: initial;

  z-index: 1;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  &.back {
    ${mqMaxWidth.extraSmall} {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
`;

export const FlipCardButton = styled.button`
  border: none;
  background: none;
  position: relative;
  display: flex;
  align-content: center;
  align-items: inherit;
  justify-content: center;
  margin: 0 1.5em;

  @media and (max-width: 340px) {
    padding: 0.5em;
  }

  :focus,
  :focus:active {
    width: 2em;
    height: 2em;
    outline: 1px solid gray;
  }

  width: 127px; /*To have the same width for next and quit*/
`;

export const CardBottomGradient = styled(CardHtmlContentWrapper)`
  background: ${props => props.gradient};

  display: block;
  height: 30%;
  z-index: 0;
  position: absolute;
  bottom: 0;
  border-radius: inherit;

  &.front_fullscreen {
    display: none;
  }
`;

export const WrapperBottomGradient = styled(CardHtmlContentWrapper)`
  background: ${props => props.gradient};
  height: 10%;
  max-height: 100px;
  z-index: 0;
  position: absolute;
  bottom: 0;
  border-radius: 0;

  ${mqMaxWidth.small} {
    display: none;
  }
`;

export const WrapperBackgroundImg = styled(motion.div)`
  position: absolute;
  background-image: ${props => props.backgroundImage};
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  ${mqMaxWidth.small} {
    display: none;
  }
`;

export const Card = styled(motion.div)`
  background-image: ${props => props.backgroundImage};
  background-color: ${props => props.backgroundColor};
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;

  &.front {
    font-size: 1.5em;
  }

  width: 100%;
  border-radius: ${cardBorderRadius};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullscreen {
    ${mqMaxWidth.small} {
      border-radius: 0;
      position: fixed;
      top: 0;
    }
  }

  ${mqMaxWidth.small} {
    &.front_fullscreen {
      background: transparent;
    }
  }

  ${mqMaxWidth.large} {
    font-size: 1em;
    width: 100%;
    border-radius: ${cardBorderRadius};
    height: 100%;
    display: flex;
    justify-content: center;
  }

  transition: background 0.5s;
`;

export const NavigationButton = styled.button`
  border: none;
  background: none;
  white-space: nowrap;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};

  padding: 1em 1.5em;
  border-radius: 25px;

  :disabled {
    color: #ffffffcf;
  }

  :disabled:focus,
  :disabled:active:focus {
    outline: 1px solid gray;
  }

  &.back {
    color: ${navigtionButtonColor};

    :disabled {
      color: #26252a99;
    }
  }

  color: ${navigtionButtonColor};

  :disabled {
    color: gray;
  }

  :hover,
  :focus,
  :focus:active {
    background-color: #ebebf54d;
    outline: none;

    ${mqMaxWidth.small} {
      background-color: #ebebf5;
    }

    :disabled {
      background: none;
      cursor: not-allowed;
    }
  }

  @media (max-width: 350px) {
    padding: 1em;
  }
`;

export const FlipCardIconWrapper = styled(motion.div)`
  padding: 1em;
  border: 3px solid #e0e0e0;
  border-radius: 50%;
  position: absolute;
  color: white;

  :hover,
  :active,
  :active:focus {
    background-color: #ebebf54d;

    ${mqMaxWidth.small} {
      background-color: #ebebf5;
    }
  }

  &.back {
    color: ${navigtionButtonColor};
  }

  color: ${navigtionButtonColor};
`;

export const CardHtmlContent = styled(motion.div)`
  &.front {
    padding: 0;

    left: 50%;
    position: relative;
    transform: translateX(-50%);
    align-self: ${props => props.alignFront};

    ${mqMaxWidth.small} {
      font-size: 1em;
      align-self: end;
      font-size: 1em;
    }

    p {
      color: white;
      margin-top: 1.45em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }

    font-size: 1.4em;
  }

  &.front_fullscreen,
  &.back {
    font-size: 1.2em;
    overflow: auto;
    max-height: 100%;

    p {
      color: black;
      margin-top: 0;
      margin-bottom: 1.4em;
    }

    /*React quill inserts paragraphs with brs betwen paragraphs, we dont want to show those*/
    br:only-child {
      display: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: black;
      margin-bottom: 0.5em;
    }

    h2 {
      font-size: 1em;
    }

    ${mqMaxWidth.small} {
      align-self: flex-start;
      padding: 0 11%;
    }

    ${mqMaxWidth.extraSmall} {
      padding: 1.1em;
    }

    align-self: center;
    padding: 0 11%;

    p {
      margin-bottom: 1.7em;
    }
  }

  &.front_fullscreen {
    font-size: 1.5em;
    color: white;
  }

  transition: color 0s;
`;

export const ActionButtonsWrapper = styled(motion.div)`
  max-width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-basis: 10%;
  align-items: center;
  padding-bottom: 4em;
  z-index: 1;

  &.front_fullscreen {
    visibility: hidden;
    z-index: -1;
  }

  ${mqMaxWidth.small} {
    padding-bottom: 0;
  }
`;

export const LeaveFullscreenButton = styled.button`
  color: black;
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  background: rgba(235, 235, 245, 0.6);
  border: 1px solid #dedede52;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
`;
