import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { backendUrl } from '~/config';
import { getFilePreviewFilePropType } from '~/utils/types';

export const FilePreview = ({ file, cover: isCover, greyscale }) => {
  if (!file || file === 'UPLOADING') {
    return (
      <div className="player-file-preview__container">
        <div className="player-file-preview__no-file">no file provided.</div>
      </div>
    );
  }
  const fileUrl = `${backendUrl}${file.url}`;
  return (
    <div className="player-file-preview__container">
      {(file && file.type === 'image' && (
        <div
          className={classNames({
            'player-file-preview__image': true,
            'player-file-preview__image--cover': isCover,
            'player-file-preview__image--greyscale': greyscale,
          })}
          style={{ backgroundImage: `url(${fileUrl})` }}
        />
      )) ||
        (file &&
          file.type === 'video' &&
          (isCover ? (
            <div className="player-file-preview__cover-video-wrapper">
              <video className="player-file-preview__cover-video" autoPlay muted loop>
                <source src={fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <video className="player-file-preview__video" controls>
              <source src={fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )))}
    </div>
  );
};

FilePreview.propTypes = {
  file: getFilePreviewFilePropType(),
  cover: PropTypes.bool,
  greyscale: PropTypes.bool,
};

FilePreview.defaultProps = {
  file: undefined,
  cover: false,
  greyscale: false,
};
