import React from 'react';
import PropTypes from 'prop-types';
import { backendUrl } from '~/config';
import { getFilePreviewFilePropType } from '~/utils/types';

export const Picture = ({ data: { file } }) =>
  file && file.url ? (
    <div className="player-picture__container">
      <img className="player-picture__image" src={`${backendUrl}${file.url}`} alt="slide" />
    </div>
  ) : (
    ''
  );

Picture.propTypes = {
  data: PropTypes.shape({
    file: getFilePreviewFilePropType(),
  }).isRequired,
};
