import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextElement } from '~/components/ContentEditable/TextElement';

export const Paragraph = ({ onValueUpdate, data }) => {
  const onParagraphChange = useCallback(
    paragraphValue => {
      onValueUpdate({
        ...data,
        paragraphValue,
      });
    },
    [data, onValueUpdate],
  );

  const onParagraphShowHide = () => {
    onValueUpdate({
      ...data,
      isVisible: false,
    });
  };

  const getDataField = useCallback(
    ({ field: { name } }) => (data.paragraphData ? data.paragraphData[name] : undefined),
    [data],
  );

  const setDataField = useCallback(
    (value, { field: { name } }) =>
      onValueUpdate({ ...data, paragraphData: { ...data.paragraphData, [name]: value } }),
    [onValueUpdate, data],
  );

  if (data.isVisible === false) {
    return null;
  }
  return (
    <div className="text-section-editor__container">
      <TextElement
        className="ingress"
        content={data.paragraphValue || 'Skriv inn paragraph'}
        onChange={onParagraphChange}
        onRemove={onParagraphShowHide}
        caretPosition="end"
        editable
        multiline
        canDelete
        useToolbar={[]}
        data={data.paragraphData}
        getValue={getDataField}
        setValue={setDataField}
      />
    </div>
  );
};

Paragraph.propTypes = {
  data: PropTypes.shape({
    paragraphValue: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string.isRequired,
    paragraphData: PropTypes.shape({}),
  }).isRequired,
  onValueUpdate: PropTypes.func.isRequired,
};
