import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { toolbarButtonStyle } from '../styles';
import { Tooltip } from '../Tooltip';
import { faToggleOn, faToggleOff } from '@fortawesome/pro-solid-svg-icons';

import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ToolbarToggle = ({
  className,
  label,
  hideLabel,
  icon,
  onClick: onButtonClick,
  value,
  setValue,
}) => {
  const [changed, setChanged] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (!changed) {
      return;
    }
    if (setValue) {
      setValue(internalValue);
    }
    setChanged(false);
  }, [changed, internalValue, setValue]);

  useLayoutEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [value]);

  const onClick = useCallback(
    e => {
      if (onButtonClick) {
        onButtonClick(e);
      }
      setInternalValue(toggle => !toggle);
      setChanged(true);
    },
    [onButtonClick],
  );

  const ref = useRef(null);

  return (
    <div
      className={className}
      ref={ref}
      role="button"
      tabIndex="0"
      onClick={onClick}
      css={css(
        toolbarButtonStyle,
        internalValue &&
          css`
            background: rgba(255, 255, 255, 0.2);
            :hover {
              background: rgba(255, 255, 255, 0.25);
            }
          `,
      )}>
      <div
        css={css(
          !hideLabel &&
            css`
              margin-right: 8px;
            `,
        )}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
      </div>
      {!hideLabel && (
        <div
          css={css`
            margin-left: auto;
          `}>
          <FontAwesomeIcon icon={internalValue ? faToggleOn : faToggleOff} />
        </div>
      )}
    </div>
  );
};
