import { useContext, useEffect } from 'react';
import { SceneContext } from './SceneContext';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { addAssets } from '../../stores/editor/actions/course.actions';
import { createItem } from './ItemScene';
import { optimizeSvg } from '../../utils/optimize-svg';
import { createSvgElement } from '../../utils/create-svg-element';

const dataMiddleware = {
  svgCompose: {
    process: async ({ items }) => {
      let svgElement;
      await items.forEach(async ({ svg }) => {
        const svgData = await optimizeSvg.optimize(svg);
        if (!svgData?.data) {
          console.error('Invalid SVG data', svg);
          return;
        }
        const element = createSvgElement(svgData.data);
        if (!element) {
          console.error('<svg> tag missing', svg);
          return;
        }
        if (!svgElement) {
          svgElement = element;
        } else {
          element.childNodes.forEach(node => {
            svgElement.append(node);
          });
        }
      });
      if (svgElement) {
        const optimized = await optimizeSvg.optimize(svgElement.outerHTML);
        return optimized.data;
      }
      return null;
    },
  },
};

export const BundleLoader = ({ bundle, onLoaded }) => {
  const { addItem, setActionSequences } = useContext(SceneContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const { name, assets, items } = bundle;

    const parseItem = async (itemData, parentId) => {
      if (!itemData?.type) {
        console.error('Invalid item data, or missing type', itemData);
        return;
      }
      if (itemData?.target || itemData?.parent) {
        console.error(
          'Items can not use target or parent. Use nested children instead.',
          'Error at item',
          itemData,
        );
      }
      const { animation, type, events, children, data, ...rest } = itemData;
      const item = createItem(type, rest);
      if (events) {
        item.events = Object.entries(events).reduce((acc, [eventType, { actions }]) => {
          if (!actions) {
            console.error('Event actions not specified for eventType', eventType, 'for item', item);
          }

          const actionSequence = nanoid();
          setActionSequences(sequences => ({
            ...sequences,
            [actionSequence]: {
              actions: actions.map(action => ({
                ...action,
                target: item.id,
              })),
            },
          }));

          acc[eventType] = { actionSequence };
          return acc;
        }, {});
      }
      if (parentId) {
        item.parent = parentId;
      }
      if (data) {
        await Object.entries(data).forEach(async ([key, value]) => {
          const middleware = dataMiddleware[value?.middleware];
          if (middleware?.process) {
            item.data[key] = await middleware.process(value);
          } else {
            item.data[key] = value;
          }
        });
      }

      if (animation) {
        const actionSequenceId = nanoid();

        setActionSequences(sequences => ({
          ...sequences,
          [actionSequenceId]: {
            actions: [
              {
                ...animation,
                target: item.id,
              },
            ],
            target: item.id,
            autorun: true,
          },
        }));
      }

      addItem(item);

      if (children) {
        children.forEach(childItem => parseItem(childItem, item.id));
      }
    };

    items.forEach(item => parseItem(item));

    if (assets) {
      dispatch(
        addAssets({
          name,
          assets,
          date: Date.now(),
        }),
      );
    }

    onLoaded();
  }, [addItem, bundle, dispatch, onLoaded, setActionSequences]);

  return null;
};
