import { nanoid } from 'nanoid';
import API from '~/utils/request';
import { optimizeSvg } from '../../../utils/optimize-svg';

const readAsText = (file, setValue, filename) => {
  const reader = new FileReader();
  const onload = ({ target: { result } }) => {
    setValue(result, filename);
  };
  reader.addEventListener('load', onload);
  reader.readAsText(file);
};

const readJson = (file, setValue, filename) => {
  readAsText(file, data => {
    const value = JSON.parse(data);
    setValue(value, filename);
  });
};
const getOptimizedSvg = (file, setValue, filename) => {
  readAsText(file, async data => {
    const optimized = await optimizeSvg.optimize(data);
    setValue(optimized.data, filename);
  });
};
const getSvgPath = (file, setValue, filename) => {
  getOptimizedSvg(file, data => {
    const element = document.createElement('div');
    element.innerHTML = data;
    const path = element.querySelector('path');
    setValue(path?.getAttribute('d'), filename);
  });
};

// const readAsDataURL = (file, setValue, filename) => {
//   const reader = new FileReader();
//   const onload = ({ target: { result } }) => {
//     setValue(result, filename);
//   };
//   reader.addEventListener('load', onload);
//   reader.readAsDataURL(file);
// };

const sendToServer = (file, setValue) => {
  API.uploadFile(file).then(data => {
    setValue(data.data);
  });
};

export const assetDefinitions = {
  svg: {
    accept: 'image/svg+xml',
    onUpload: getOptimizedSvg,
  },
  path: {
    accept: 'image/svg+xml',
    onUpload: getSvgPath,
    defaultAssets: [
      {
        id: nanoid(),
        name: 'Bue 1',
        value: 'M 10 80 Q 95 10 180 80',
      },
      {
        id: nanoid(),
        name: 'Bue 2',
        value: 'M 10 60 C 20 80, 40 80, 50 60',
      },
      {
        id: nanoid(),
        name: 'Bue 3',
        value: 'M 130 110 C 120 140, 180 140, 170 110',
      },
      {
        id: nanoid(),
        name: 'Bue 4',
        value: 'M600,200 C675,100 975,100 900,200',
      },
      {
        id: nanoid(),
        name: 'S 1',
        value: 'M 10 80 Q 52.5 10, 95 80 T 180 80',
      },
      {
        id: nanoid(),
        name: 'S 2',
        value: 'M600,800 C625,700 725,700 750,800 S875,900 900,800',
      },
      {
        id: nanoid(),
        name: 'S 3',
        value: 'M600,500 C600,350 900,650 900,500',
      },
      {
        id: nanoid(),
        name: 'Sikksakk',
        value: 'M200,300 L400,50 L600,300 L800,550 L1000,300',
      },
      {
        id: nanoid(),
        name: 'Sprett',
        value:
          'M188.083,116.117c0-0.691,1.533,0,1.533,0l1.534,0.463l1.533,0.459 l1.533,0.694l1.534,0.921l1.534,1.154l1.533,1.385l1.533,1.614l1.533,1.848l1.534,1.845l0.44,0.662l1.093,1.645l1.534,2.308 l1.533,2.77l1.532,2.767l1.534,3.002l1.534,3.229l1.538,3.46l1.534,3.693l1.533,3.921l1.533,4.155l1.533,4.153l1.534,4.615 l1.533,4.615l1.533,4.844l1.533,5.307l1.534,5.309l1.533,5.536l1.533,5.768l1.533,6.001l1.534,5.999l1.533,6.462l1.534,6.69 l1.534,6.69l1.531,7.154l1.534,7.154l1.533,7.382l1.534,0.462l1.533-3.694l1.532-3.23l1.534-3.228l1.534-3.001l1.533-2.767 l1.534-2.54l1.533-2.307l1.537-2.076l1.533-1.848l1.533-1.614l1.534-1.615l1.531-1.154l1.533-1.153l1.533-0.691l1.533-0.695 l1.534-0.459l1.531-0.231h1.534l1.532,0.231l1.534,0.459l1.534,0.462l1.532,0.924l1.534,0.921l1.533,1.384l1.533,1.386l1.534,1.616 l1.531,2.078l1.535,2.076l1.533,2.307l1.533,2.308l1.533,2.769l1.533,2.999l1.533,2.999l1.534,3.462l1.532,3.461l1.533,2.306 l1.534-1.844l1.533-1.385l1.533-1.386l1.534-1.153l1.532-0.922l1.531-0.691l1.532-0.462l1.534-0.233h1.534h1.532l1.532,0.463 l1.534,0.461l1.533,0.691l1.533,1.154l1.532,1.155l1.533,1.387l1.534,1.615l1.533,1.614l1.533-0.921l1.534-0.693l1.53-0.231 l1.533-0.232h1.534l1.533,0.232l1.534,0.458l1.53,0.462l1.533,0.922',
      },
      {
        id: nanoid(),
        name: 'Åtte-tall loop',
        value: 'M20,50 C20,-50 180,150 180,50 C180-50 20,150 20,50 z',
      },
      {
        id: nanoid(),
        name: 'Bølger',
        value:
          'M28.47,44.453c62.265,12.101 100.868,-49.272 152.866,-29.015c60.281,23.483 83.235,35.498 114.168,17.565c25.149,-14.58 3.99,-50.978 -57.829,-19.643c-61.818,31.335 -72.96,37.393 -120.874,19.643c-36.76,-13.618 -64.357,-37.227 -97.021,-28.08c-18.606,5.209 -34.182,31.197 8.69,39.53Z',
      },
    ],
  },
  image: {
    accept: 'image/jpeg,image/png,image/gif',
    onUpload: sendToServer,
  },
  bundle: {
    accept: 'application/json,.bundle',
    onUpload: readJson,
  },
};
