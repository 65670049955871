import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

import { getIdForNextElement } from '~/utils/common';

import {
  PAGE_ID_DESCRIPTION,
  PAGE_ID_FINISHED,
  PAGE_ID_LOADING,
  PAGE_ID_NO_PAGES,
  PAGE_ID_RESULTS,
  PAGE_ID_RUNTIME_ERROR,
  PAGE_ID_SPEED_COURSE,
  SAVE_STATUS_FAILED,
  SAVE_STATUS_SAVED,
  SAVE_STATUS_UPLOADING,
  SAVE_STATUS_DIRTY,
  getPageInitialValues,
} from '~/utils/course';

import { getFilePreviewFilePropType, getCoursePagePropType } from '~/utils/types';

import * as courseEditorActions from '~/stores/editor/actions/course.actions';

import { CourseHeader } from '~/modules/Editor/components/CourseHeader';
import { CourseTemplateSelectionModal } from '~/modules/Editor/components/SelectPageTypeModal';
import { PlayerPreviewModal } from '~/modules/Editor/components/PlayerPreviewModal';

import { CourseDetailsPage } from '~/modules/Editor/containers/CourseDetails';
import { CoursePage } from '~/modules/Editor/containers/CoursePage';
import { SpeedCourseSettings } from '~/modules/Editor/components/SpeedCourseSettings/SpeedCourseSettings';
import { css } from '@emotion/core';
import { TopSideBar } from '~/modules/Editor/components/TopSidebar/Topsidebar';

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlayerPreviewModalOpen: false,
    };

    this.closePlayerPreviewModal = this.closePlayerPreviewModal.bind(this);
    this.onAddPageClick = this.onAddPageClick.bind(this);
    this.onAddPageModalCloseClick = this.onAddPageModalCloseClick.bind(this);
    this.onDeletePageClick = this.onDeletePageClick.bind(this);
    this.onPageDataChange = this.onPageDataChange.bind(this);
    this.onPlayerPreviewMessage = this.onPlayerPreviewMessage.bind(this);
    this.onPlayerPreviewClick = this.onPlayerPreviewClick.bind(this);
    this.onSaveCourseClick = this.onSaveCourseClick.bind(this);
    this.onSectionDataChange = this.onSectionDataChange.bind(this);
    this.onTemplateTypeSelect = this.onTemplateTypeSelect.bind(this);
    this.deleteCoverFile = this.deleteCoverFile.bind(this);
    this.uploadCoverFile = this.uploadCoverFile.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.showPageSettings = this.showPageSettings.bind(this);
    this.moveToPage = this.moveToPage.bind(this);
  }

  showPageSettings() {
    const {
      course: { showSettings },
      toggleSettings,
    } = this.props;
    toggleSettings(!showSettings);
  }

  moveToPage(id) {
    const {
      course: { showPage },
      gotoPage,
    } = this.props;
    if (id === 'right') {
      if (showPage === 3) {
        gotoPage(1);
      } else {
        gotoPage(showPage + 1);
      }
    } else if (id === 'left') {
      if (showPage === 1) {
        gotoPage(3);
      } else {
        gotoPage(showPage - 1);
      }
    } else {
      gotoPage(id);
    }
  }

  handleKeyDown(event) {
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey && charCode === 's') || (event.metaKey && charCode === 's')) {
      event.preventDefault();
      this.onSaveCourseClick();
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidMount() {
    const {
      course: { id: courseId },
      fetchCourseData,
    } = this.props;

    window.Mousetrap.addKeycodes({
      222: 'æ',
      186: 'ø',
    });
    window.Mousetrap.bind(['command+æ', 'ctrl+æ'], () => this.onAddPageClick());
    window.Mousetrap.bind(['command+i', 'ctrl+i'], () => this.showPageSettings());
    // eslint-disable-next-line no-console
    window.Mousetrap.bind(['command+ø', 'ctrl+ø'], () => console.log('ø'));
    window.Mousetrap.bind(['command+right', 'ctrl+right'], () => this.moveToPage('right'));
    window.Mousetrap.bind(['command+left', 'ctrl+left'], e => {
      e.preventDefault();
      this.moveToPage('left');
    });
    fetchCourseData(courseId);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown, false);
    window.removeEventListener('message', this.onPlayerPreviewMessage);
    window.Mousetrap.unbind(['command+æ', 'ctrl+æ']);
    window.Mousetrap.unbind(['command+i', 'ctrl+i']);
    window.Mousetrap.unbind(['command+ø', 'ctrl+ø']);
    window.Mousetrap.unbind(['command+right', 'ctrl+right']);
    window.Mousetrap.unbind(['command+left', 'ctrl+left']);
  }

  onAddPageClick() {
    const { setAddPageModalOpen } = this.props;
    setAddPageModalOpen(true);
  }

  onAddPageModalCloseClick() {
    const { setAddPageModalOpen } = this.props;

    setAddPageModalOpen(false);
  }

  onDeletePageClick(page) {
    this.props.deletePage(page.id);
  }

  onPlayerPreviewClick() {
    [...document.querySelectorAll('video')].forEach(video => video.pause && video.pause());
    window.addEventListener('message', this.onPlayerPreviewMessage);
    this.setState({
      isPlayerPreviewModalOpen: true,
    });
    // window.open(`player-index.html?id=${this.props.course.id}`, '_blank');
  }

  onPlayerPreviewMessage({ data }) {
    if (data === 'IFRAME_COURSE_FINISHED') {
      this.closePlayerPreviewModal();
    } else if (data === 'TAB_COURSE_FINISHED') {
      // eslint-disable-next-line no-console
      console.log('Tab course finished successfully.');
    }
  }

  onPageDataChange(pageData) {
    this.props.updatePageData(pageData);
  }

  onSaveCourseClick() {
    this.props.saveCourseData();
  }

  onSectionDataChange(sectionData, sectionDataKey) {
    // console.log('sectionData', sectionData);
    // console.log('sectionDataKey', sectionDataKey);
    this.props.updatePageData({
      [sectionDataKey]: sectionData,
    });
  }

  onTemplateTypeSelect(templateTypeId) {
    const {
      addPage,
      setActivePageId,
      setActiveSlideId,
      setAddPageModalOpen,
      course: {
        data: { pages },
      },
    } = this.props;

    setAddPageModalOpen(false);

    const newPageId = getIdForNextElement(pages);
    const pageData = getPageInitialValues({
      id: newPageId,
      templateTypeId,
    });
    addPage(pageData);
    setActivePageId(newPageId);
    if (templateTypeId === 'PICTURESLIDES-TEXT') {
      setActiveSlideId(pageData.pictureslides?.slides[0].id);
    }
  }

  closePlayerPreviewModal() {
    this.setState({
      isPlayerPreviewModalOpen: false,
    });
  }

  deleteCoverFile() {
    this.props.deleteCoverFile();
  }

  uploadCoverFile(file) {
    this.props.uploadCoverFile(file);
  }

  render() {
    const { data: courseData, isFetching } = this.props.course;
    if (!courseData) {
      return (
        <div className="editor__status-label">
          {isFetching ? 'Loading...' : 'Failed to load course data.'}
        </div>
      );
    }
    const {
      isAddPageModalOpen,
      course: {
        data: {
          activePageId: courseActivePageId,
          activeSlideId,
          pages: coursePages,
          descriptionPage,
          resultsPage,
          speedCourseSettings,
          settings,
        },
        saveStatus,
        showSettings,
        showPage,
      },
      reorderPages,
      setActivePageId,
      setActiveElementId,
      setDescriptionPageData,
      setSettingsPageData,
      setResultsPageData,
      patchSpeedCourseSettings,
      switchSpeedCourse,
      scale,
    } = this.props;
    const page = coursePages.find(page => page.id === courseActivePageId);

    return (
      <>
        {courseActivePageId != null && courseActivePageId !== -1 && (
          <div
            css={css`
              justify-items: center;
              text-align: center;
              display: flex;
              position: absolute;
              top: -36px;
              left: 0;
              width: 100%;
            `}>
            <TopSideBar
              showSettings={showSettings}
              active={showPage}
              toggleSettings={() => this.showPageSettings()}
              page={coursePages.find(page => page.id === courseActivePageId)}
              setPage={id => this.moveToPage(id)}
            />
          </div>
        )}
        <div className="editor__container">
          {(courseActivePageId != null && courseActivePageId !== -1 && page && (
            <CoursePage
              deletePicture={this.props.deletePicture}
              deleteQuestionnaireAnswerImage={this.props.deleteQuestionnaireAnswerImage}
              setActiveElement={setActiveElementId}
              showSettings={showSettings}
              showPage={showPage}
              activeSlideId={activeSlideId}
              deleteSlideImage={this.props.deleteSlideImage}
              deleteVideoPoster={this.props.deleteVideoPoster}
              deleteVideoSource={this.props.deleteVideoSource}
              onPageDataChange={this.onPageDataChange}
              onSectionDataChange={this.onSectionDataChange}
              page={page}
              pages={coursePages}
              pageNumber={coursePages.findIndex(page => page.id === courseActivePageId) + 1}
              uploadPicture={this.props.uploadPicture}
              uploadQuestionnaireAnswerImage={this.props.uploadQuestionnaireAnswerImage}
              uploadSlideImage={this.props.uploadSlideImage}
              uploadVideoPoster={this.props.uploadVideoPoster}
              uploadVideoSource={this.props.uploadVideoSource}
              uploadContentFile={this.props.uploadContentFile}
              scale={scale}
            />
          )) ||
            (courseActivePageId === PAGE_ID_SPEED_COURSE && (
              <SpeedCourseSettings
                speedCourseSettings={speedCourseSettings}
                patchSpeedCourseSettings={patchSpeedCourseSettings}
                uploadCoverFile={this.props.uploadSpeedCourseCoverFile}
                deleteCoverFile={this.props.deleteSpeedCourseCoverFile}
              />
            )) || (
              <>
                {false === true && (
                  <div className="editor__section">
                    <CourseHeader
                      courseName={descriptionPage.title}
                      onPlayerPreviewClick={this.onPlayerPreviewClick}
                      onSaveCourseClick={this.onSaveCourseClick}
                      saveStatus={saveStatus}
                    />
                  </div>
                )}
                <CourseDetailsPage
                  courseId={courseData.id}
                  deleteCoverFile={this.deleteCoverFile}
                  descriptionPage={descriptionPage}
                  onAddPageClick={this.onAddPageClick}
                  onDeletePageClick={this.onDeletePageClick}
                  pages={coursePages}
                  reorderPages={reorderPages}
                  resultsPage={resultsPage}
                  settings={settings}
                  onSectionDataChange={this.onSectionDataChange}
                  setActivePageId={setActivePageId}
                  setSettingsPageData={setSettingsPageData}
                  setDescriptionPageData={setDescriptionPageData}
                  setResultsPageData={setResultsPageData}
                  switchSpeedCourse={switchSpeedCourse}
                  uploadCoverFile={this.uploadCoverFile}
                />
              </>
            )}
        </div>
        {/* {courseActivePageId != null && (
          <div className="settings__container">
            <CourseSettings
              uploadSlideImage={(slideId, file) =>
                this.props.uploadSlideImage({
                  pageId: courseActivePageId,
                  componentDataKey: 'pictureslides',
                  slideId,
                  file,
                })
              }
              deleteSlideImage={slideId =>
                this.props.deleteSlideImage({
                  pageId: courseActivePageId,
                  componentDataKey: 'pictureslides',
                  slideId,
                })
              }
              onValueUpdate={this.onSectionDataChange}
              page={coursePages.find(page => page.id === courseActivePageId)}
              element={activeElement}
            />
          </div>
        )} */}
        <CourseTemplateSelectionModal
          isAddPageModalOpen={isAddPageModalOpen}
          onAddPageModalCloseClick={this.onAddPageModalCloseClick}
          onTemplateTypeSelect={this.onTemplateTypeSelect}
        />
        {this.state.isPlayerPreviewModalOpen && (
          <PlayerPreviewModal
            courseId={courseData.id}
            onPlayerPreviewModalCloseClick={this.closePlayerPreviewModal}
          />
        )}
        <ToastContainer />
      </>
    );
  }
}

Editor.propTypes = {
  addPage: PropTypes.func.isRequired,
  course: PropTypes.shape({
    data: PropTypes.shape({
      activePageId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([
          PAGE_ID_DESCRIPTION,
          PAGE_ID_FINISHED,
          PAGE_ID_LOADING,
          PAGE_ID_NO_PAGES,
          PAGE_ID_RESULTS,
          PAGE_ID_RUNTIME_ERROR,
          PAGE_ID_SPEED_COURSE,
        ]),
      ]),
      activeElement: PropTypes.shape({
        id: PropTypes.number,
        element: PropTypes.string,
      }),
      descriptionPage: PropTypes.shape({
        disabled: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
      }),
      resultsPage: PropTypes.shape({
        disabled: PropTypes.bool,
        textWhenPassed: PropTypes.string,
        textWhenFailed: PropTypes.string,
      }),
      speedCourseSettings: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
        pointsMaxScore: PropTypes.number,
        pointsPerAnswer: PropTypes.number,
        timeToReadQuestionSec: PropTypes.number,
        timeToAnswerQuestionSec: PropTypes.number,
      }),
      name: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(getCoursePagePropType()),
    }),
    id: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    saveStatus: PropTypes.oneOf([
      SAVE_STATUS_UPLOADING,
      SAVE_STATUS_SAVED,
      SAVE_STATUS_FAILED,
      SAVE_STATUS_DIRTY,
    ]),
  }).isRequired,
  deleteCoverFile: PropTypes.func.isRequired,
  deletePage: PropTypes.func.isRequired,
  deletePicture: PropTypes.func.isRequired,
  deleteQuestionnaireAnswerImage: PropTypes.func.isRequired,
  deleteSlideImage: PropTypes.func.isRequired,
  deleteVideoPoster: PropTypes.func.isRequired,
  deleteVideoSource: PropTypes.func.isRequired,
  fetchCourseData: PropTypes.func.isRequired,
  reorderPages: PropTypes.func.isRequired,
  saveCourseData: PropTypes.func.isRequired,
  showSettings: PropTypes.bool,
  setActivePageId: PropTypes.func.isRequired,
  setActiveSlideId: PropTypes.func.isRequired,
  setActiveElementId: PropTypes.func.isRequired,
  setDescriptionPageData: PropTypes.func.isRequired,
  setSettingsPageData: PropTypes.func.isRequired,
  setResultsPageData: PropTypes.func.isRequired,
  patchSpeedCourseSettings: PropTypes.func.isRequired,
  switchSpeedCourse: PropTypes.func.isRequired,
  updatePageData: PropTypes.func.isRequired,
  uploadCoverFile: PropTypes.func.isRequired,
  uploadContentFile: PropTypes.func.isRequired,
  uploadSpeedCourseCoverFile: PropTypes.func.isRequired,
  deleteSpeedCourseCoverFile: PropTypes.func.isRequired,
  uploadPicture: PropTypes.func.isRequired,
  uploadQuestionnaireAnswerImage: PropTypes.func.isRequired,
  uploadSlideImage: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  uploadVideoPoster: PropTypes.func.isRequired,
  uploadVideoSource: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  course: state.course,
});

const mapDispatchToProps = dispatch => bindActionCreators(courseEditorActions, dispatch);

export const EditorContainer = connect(mapStateToProps, mapDispatchToProps)(Editor);
