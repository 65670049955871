import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DropdownList from 'react-widgets/lib/DropdownList';

import './FormSelect.scss';

export const FormSelect = ({
  className,
  label,
  input: { name, value, onBlur, onChange },
  meta,
  ...props
}) => (
  <div className="form-select">
    {label && (
      <label className="form-select__label" htmlFor={name}>
        {label}
      </label>
    )}
    <DropdownList
      {...props}
      containerClassName={classnames(className, 'form-select__input', {
        'form-select__input--error': meta.touched && meta.error,
      })}
      value={value}
      defaltValue={[]}
      onChange={onChange}
      onBlur={() => onBlur()}
    />
    {meta.touched && meta.error && <div className="form-select__error">{meta.error}</div>}
  </div>
);

FormSelect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormSelect.defaultProps = {
  className: '',
  label: '',
};
