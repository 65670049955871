import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { ToolbarButton } from './ToolbarButton';
import {
  faArrowToBottom,
  faArrowToTop,
  faAlignRight,
  faAlignCenter,
  faAlignLeft,
} from '@fortawesome/pro-solid-svg-icons';

export const ToolbarAlign = ({ type, value, setValue }) => {
  const ALIGN_ITEMS = {
    'left': {value:'left', 'icon': faAlignLeft},
    'center':{value:'center', 'icon': faAlignCenter},
    'right':{value:'right', 'icon': faAlignRight}
  };
  const [changed, setChanged] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (!changed) {
      return;
    }
    setValue(internalValue);
    setChanged(false);
  }, [changed, internalValue, setValue]);

  useLayoutEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [value]);

  const onClickToggle = useCallback((myValue) => {
    let indexValue = Object.keys(ALIGN_ITEMS).indexOf(myValue) + 1;
    if (indexValue >= Object.keys(ALIGN_ITEMS).length ){
      indexValue = 0;
    }
    setInternalValue(value => Object.keys(ALIGN_ITEMS)[indexValue]);
    setChanged(true);
  }, [type]);

  const onClickLeft = useCallback(() => {
    const newValue = type === 'verticalAlign' ? 'top' : 'left';
    setInternalValue(value => (value === newValue ? 'center' : newValue));
    setChanged(true);
  }, [type]);

  const onClickRight = useCallback(() => {
    const newValue = type === 'verticalAlign' ? 'bottom' : 'right';
    setInternalValue(value => (value === newValue ? 'center' : newValue));
    setChanged(true);
  }, [type]);

  return type === 'verticalAlign' ? (
    <>
      <ToolbarButton
        icon={faArrowToTop}
        type="boolean"
        value={internalValue === 'top'}
        onClick={onClickLeft}
      />
      <ToolbarButton
        icon={faArrowToBottom}
        type="boolean"
        value={internalValue === 'bottom'}
        onClick={onClickRight}
      />

    </>
  ) : (
    <ToolbarButton
      icon={ALIGN_ITEMS[internalValue].icon}
      type="boolean"
      value={ALIGN_ITEMS[internalValue].value}
      onClick={() => onClickToggle(ALIGN_ITEMS[internalValue].value)}
    />
  );
};
