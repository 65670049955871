export const fadeOptions = [
  {
    text: 'Ingen fade',
    value: null,
  },
  {
    text: 'Fade inn',
    value: 'fadeIn',
  },
  {
    text: 'Fade ut',
    value: 'fadeOut',
  },
];
