import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import debounce from 'debounce';

import { FormInput } from '~/components/FormInput';
import { FormSwitch } from '~/components/FormSwitch';

import { number, positiveNumber, required } from '~/utils/validators';
import { formatNumberInput, parseNumberInput } from '~/utils/helpers';

class QuestionPoolSection extends Component {
  constructor(props) {
    super(props);

    this.debounce = debounce;
  }

  pointsValidator(value, allValues) {
    return allValues.pointsEnabled ? positiveNumber(value) : undefined;
  }

  render() {
    const { handleSubmit, initialValues } = this.props;
    return (
      <form className="questionnaire-section-editor__settings-container" autoComplete="off">
        <div className="questionnaire-section-editor__settings-subsection">
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormSwitch}
              label="Use question pool"
              name="useQuestionPool"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              type="boolean"
            />
          </div>
          {initialValues.useQuestionPool && (
            <div className="questionnaire-section-editor__settings-row">
              <Field
                component={FormInput}
                format={formatNumberInput}
                label="Number of questions in pool"
                name="questionPoolSize"
                onChange={this.debounce(() => {
                  handleSubmit();
                }, 700)}
                parse={parseNumberInput}
                type="number"
                validate={[required, number, this.pointsValidator]}
              />
            </div>
          )}
        </div>
      </form>
    );
  }
}

QuestionPoolSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const QuestionPoolSectionForm = reduxForm({
  form: 'speedCourseQuestionPoolSectionForm',
})(QuestionPoolSection);
