import React from 'react';
import { css } from '@emotion/core';

export const ToolbarSeparator = () => (
  <div
    css={css`
      flex: 1 0 auto;
      width: 1px;
      max-width: 1px;
      margin: 4px 0;
      background: #7a7c83;
    `}
  />
);
