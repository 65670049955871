import React, { useRef, useState, useContext, useCallback } from 'react';
import { toolbarButtonStyle, dropdownBgStyle, dropdownContainerStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { SceneContext } from '../SceneContext';
import { Tooltip } from '../Tooltip';
import { AssetSelect } from './AssetSelect';
import { ToolbarButton } from './ToolbarButton';
import { FieldContext } from '../FieldContext';
import { faEraser, faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';

export const ToolbarAssetSelect = ({ icon, hideLabel, value, label, setValue, type }) => {
  const fieldContext = useContext(FieldContext);
  const { itemSelectMode, setPathEditMode } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onClear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const onAssetSelect = useCallback(
    value => {
      setValue(value);
      setOpen(false);
      if (type === 'path') {
        const {
          fields: {
            target: { value },
          },
          actionSequence,
          actionSequenceIndex,
        } = fieldContext;
        setPathEditMode({
          id: value,
          actionSequence,
          actionSequenceIndex,
        });
      }
    },
    [fieldContext, setPathEditMode, setValue, type],
  );

  return (
    <div
      css={css`
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
      `}>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(
          toolbarButtonStyle,
          css`
            flex: 1 1 auto;
          `,
        )}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && !!label && !hideLabel && <>&nbsp;</>}
        {!hideLabel && label}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <div
            css={css(
              dropdownContainerStyle,
              css`
                width: 100vw;
                max-width: 400px;
              `,
            )}>
            <AssetSelect setValue={onAssetSelect} type={type} />
          </div>
        </Dropdown>
      )}
      {value && <ToolbarButton label="Fjern valg" hideLabel icon={faEraser} onClick={onClear} />}
    </div>
  );
};
