import React, { useEffect, useRef } from 'react';
import { useMotionValue, useTransform } from 'framer-motion';

import {
  PopupBackdrop,
  PopupWrapper,
  DragHandle,
  ButtonsWrapper,
  Button,
  ContentWrapper,
} from './styles';

const bottomYPosToClose = 100;

// Displays a floating card which is dismissable by swiping in down
// A semi-dark transparent backdrop is displayed and will fade out as the
// card is swiped

// Content is provided as children, and action-buttons is provided as an array of:
// {txt: 'Button Text', onClick: onClickHandler}
export const CardPopup = ({
  children,
  onDismissed,
  withTopDragHandle,
  actionButtons,
  ...props
}) => {
  const y = useMotionValue(0);

  const wrapperRef = useRef();
  const opacity = useTransform(
    y,
    y => 1 - (y / (wrapperRef.current && wrapperRef.current.offsetHeight + 300) || y),
  );

  // Detects if the card has beeen swiped down
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    y.onChange(latest => {
      if (
        wrapperRef.current &&
        wrapperRef.current.getBoundingClientRect().y > window.innerHeight - bottomYPosToClose
      ) {
        onDismissed();
      }
    });
  });

  return (
    <>
      <PopupBackdrop style={{ opacity }}></PopupBackdrop>

      <PopupWrapper
        className="card-popup"
        ref={wrapperRef}
        open
        {...props}
        drag="y"
        dragConstraints={{ top: 0 }}
        style={{ y }}>
        {withTopDragHandle && <DragHandle />}
        <ContentWrapper className="content">{children}</ContentWrapper>

        {actionButtons && (
          <ButtonsWrapper className="button-wrapper">
            {actionButtons.map((btn, i) => (
              <Button tabIndex={0} type="button" key={i} onClick={btn.onClick} className="button">
                {btn.txt}
              </Button>
            ))}
          </ButtonsWrapper>
        )}
      </PopupWrapper>
    </>
  );
};
