import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import * as styles from './styles';
import { FormSelect } from '~/components/FormSelect';
import { SlideBackground } from '~/modules/Editor/components/CourseSettings/components/Background';
import { css } from '@emotion/core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toolbarButtonWarnStyle } from '~/components/scene/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePageData,
  setActiveSlideId,
  setActivePageId,
  deletePage,
} from '../../../../stores/editor/actions/course.actions';
import { getAllPages, getSlide } from '../../../../stores/editor/selectors/course.selectors';

const justifyContents = { Top: 'flex-start', Center: 'center', Bottom: 'flex-end' };

export const CourseSettings = ({
  page,
  courseType,
  onValueUpdate,
  activeSlideId,
  deletePicture,
  uploadPicture,
  uploadSlideImage,
  deleteSlideImage,
  onPageDataChange,
}) => {
  const setSectionValue = useCallback(
    (prop, settings) => {
      let activeSlide = null;
      if (page.templateTypeId === 'PICTURESLIDES-TEXT') {
        activeSlide = page.pictureslides.slides.filter(ps => ps.id === activeSlideId)[0];
      } else {
        activeSlide = page;
      }
      const data = page;
      activeSlide[prop] = settings;
      onValueUpdate(
        {
          ...data.pictureslides,
        },
        'pictureslides',
      );
    },
    [activeSlideId, onValueUpdate, page],
  );

  const setSettings = useCallback(
    (prop, settings) => {
      onPageDataChange({
        ...page,
        [prop]: settings,
      });
    },
    [onPageDataChange, page],
  );

  const dispatch = useDispatch();

  const pages = useSelector(getAllPages);
  const slide = useSelector(getSlide);

  const activeSlide = useMemo(() => (page.templateTypeId === 'PICTURESLIDES-TEXT' ? slide : page), [
    page,
    slide,
  ]);

  const deleteSlide = useCallback(() => {
    let shouldDeletePage = page.templateTypeId !== 'PICTURESLIDES-TEXT';

    if (!shouldDeletePage && slide && slide.id != null) {
      const slideIndex = page.pictureslides.slides.findIndex(({ id }) => id === slide.id);

      if (slideIndex < page.pictureslides.slides.length - 1) {
        dispatch(setActiveSlideId(page.pictureslides.slides[slideIndex + 1]?.id));
      } else if (slideIndex > 0) {
        dispatch(setActiveSlideId(page.pictureslides.slides[slideIndex - 1]?.id));
      } else {
        shouldDeletePage = true;
      }

      if (!shouldDeletePage) {
        dispatch(
          updatePageData({
            pictureslides: {
              ...page.pictureslides,
              slides: page.pictureslides.slides.filter(({ id }) => id !== slide.id),
            },
          }),
        );
      }
    }
    if (shouldDeletePage) {
      const currentPageIndex = pages.findIndex(({ id }) => id === page.id);

      if (currentPageIndex < pages.length - 1) {
        dispatch(setActivePageId(pages[currentPageIndex + 1]?.id));
      } else if (currentPageIndex > 0) {
        dispatch(setActivePageId(pages[currentPageIndex - 1]?.id));
      } else {
        dispatch(setActivePageId(-1));
      }
      dispatch(deletePage(page.id));
    }
  }, [page.templateTypeId, page.pictureslides, page.id, slide, dispatch, pages]);

  return (
    <div css={styles.settingsWrapper}>
      <h2>Settings</h2>
      <div css={styles.section}>
        {(page.templateTypeId === 'PICTURESLIDES-TEXT' && slide && (
          <>
            <h3>BACKGROUND</h3>
            <SlideBackground
              activeSlide={slide}
              openSections={[]}
              deleteImage={deleteSlideImage}
              value={page.bgColor}
              handleChange={settings => setSectionValue('bgColor', settings)}
              uploadPicture={uploadSlideImage}
              acceptedImageFormats={['jpg', 'png']}
            />
            <h3>LAYOUT</h3>
            <div className="content">
              <div className="item last">
                <div className="label">Style</div>
                <div className="field">
                  <FormSelect
                    name="backgroundType"
                    defaultValue="Flow"
                    input={{
                      value: slide?.backgroundType ?? 'Free',
                      onBlur: () => null,
                      onChange: value => {
                        setSectionValue('backgroundType', value);
                      },
                    }}
                    meta={{ touched: false, error: false }}
                    data={['Flow', 'Free']}
                  />
                </div>
              </div>
              {slide?.backgroundType === 'Flow' && (
                <div className="item last">
                  <div className="label">Align content</div>
                  <div className="field">
                    <FormSelect
                      name="backgroundType"
                      defaultValue="Center"
                      input={{
                        value: Object.keys(justifyContents).find(
                          jc => justifyContents[jc] === slide?.justifyContent,
                        ),
                        onBlur: () => null,
                        onChange: value => {
                          setSectionValue('justifyContent', justifyContents[value]);
                        },
                      }}
                      meta={{ touched: false, error: false }}
                      data={Object.keys(justifyContents)}
                    />
                  </div>
                </div>
              )}
            </div>
            {courseType}...
            {courseType === '1' && (
              <>
                <h3>REDIRECTIONS</h3>
                <div className="content">
                  <div className="item last">Redirect</div>
                </div>
              </>
             )}
          </>
        )) ||
          (page.templateTypeId === 'TEXT-DILEMMA' && (
            <>
              <h3>BACKGROUND</h3>
              <SlideBackground
                activeSlide={activeSlide}
                openSections={[]}
                deleteImage={deletePicture}
                value={page.bgColor}
                handleChange={settings => setSettings('bgColor', settings)}
                uploadPicture={uploadPicture}
                acceptedImageFormats={['jpg', 'png']}
              />
              <h3>LAYOUT</h3>
              <div className="content">
                <div className="item last">
                  <div className="label">Align text</div>
                  <div className="field">
                    <FormSelect
                      name="backgroundType"
                      defaultValue={
                        activeSlide.justifyContent
                          ? Object.keys(justifyContents).filter(
                              jc => justifyContents[jc] === activeSlide.justifyContent,
                            )
                          : 'Center'
                      }
                      input={{
                        value: justifyContents[activeSlide.justifyContent],
                        onBlur: () => null,
                        onChange: value => {
                          setSectionValue('justifyContent', justifyContents[value]);
                        },
                      }}
                      meta={{ touched: false, error: false }}
                      data={Object.keys(justifyContents)}
                    />
                  </div>
                </div>
              </div>
            </>
          )) || (
            <>
              <h3>REDIRECTIONS</h3>
              {page.templateTypeId}
              <div className="content">
                <div className="item first">On success</div>
                <div className="item last">On failure</div>
              </div>
              <h3>POINTS</h3>
              <div className="content">
                <div className="item first last">Use points</div>
              </div>
            </>
          )}
      </div>
      <div
        css={css`
          margin-top: auto;
        `}>
        <div
          css={css(
            toolbarButtonWarnStyle,
            css`
              flex: 0 0 auto;
              margin-top: 1em;
              margin-right: auto;
              width: min-content;
            `,
          )}
          role="button"
          tabIndex="0"
          onClick={deleteSlide}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;
          {page.templateTypeId === 'PICTURESLIDES-TEXT' && slide ? 'Slett slide' : 'Slett side'}
        </div>
      </div>
    </div>
  );
};

CourseSettings.propTypes = {
  page: PropTypes.any.isRequired,
  element: PropTypes.any,
};
