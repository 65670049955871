import {
  faItalic,
  faBold,
  faFont,
  faPalette,
  faTextSize,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { sizeOptions } from '../scene/fields/size-options';

export const textElementFields = [
  {
    name: 'align',
    type: 'align',
    defaultValue: 'left',
  },
  { type: 'separator' },
  {
    name: 'bold',
    icon: faBold,
    label: 'Fet',
    hideLabel: true,
    type: 'boolean',
  },
  {
    name: 'italic',
    icon: faItalic,
    label: 'Kursiv',
    hideLabel: true,
    type: 'boolean',
  },
  { type: 'separator' },
  {
    name: 'size',
    icon: faTextSize,
    label: 'Fontstørrelse',
    hideLabel: true,
    type: 'range',
    step: 1,
    minValue: 0,
    maxValue: sizeOptions.length - 1,
    defaultValue: 'm',
    getValue: value => sizeOptions.findIndex(({ value: sizeValue }) => sizeValue === value),
    setValue: index => sizeOptions[index].value,
  },
  { type: 'separator' },
  {
    icon: faFont,
    name: 'color',
    defaultValue: '#000',
    type: 'color',
  },
  { type: 'separator' },
  {
    name: 'bgColor',
    icon: faPalette,
    type: 'color',
  },
];

export const deleteElement = [
  {
    icon: faTrashAlt,
    type: 'button',
    label: 'Slett element',
    hideLabel: true,
    hotkey: 'delete',
    onClick: ({ id, updateItem }) => {
      updateItem(id, { hide: true });
    },
  },
];

export const textElementFieldsTitle = [
  {
    name: 'align',
    type: 'align',
    defaultValue: 'left',
  },
  { type: 'separator' },
  { type: 'separator' },
  {
    name: 'bold',
    icon: faBold,
    label: 'Fet',
    hideLabel: true,
    type: 'boolean',
    defaultValue: true,
  },
  {
    name: 'italic',
    icon: faItalic,
    label: 'Kursiv',
    hideLabel: true,
    type: 'boolean',
  },
];
