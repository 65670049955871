import React from 'react';
import PropTypes from 'prop-types';
import { getFilePreviewFilePropType } from '~/utils/types';
import { Box } from './styles';
import { css } from '@emotion/core';

/**
 * Alows the user to select either one, or multiple items
 * Selected is the selected id for single-select and an array of selected ids for
 * multi-select mode.
 */
export const Select = ({ disabled, groupName, onChange, options, selected, multiple }) => {
  const getIsOptionChecked = option => {
    if (multiple) {
      return selected.has(option.id);
    }
    return selected === option.id;
  };

  return options.map(option => (
    <Box
      margin="12px 0"
      key={option.id}
      className={`${getIsOptionChecked(option) ? 'selected' : ''} ${option.answerStatus}
      `}>
      <input
        checked={getIsOptionChecked(option)}
        disabled={disabled}
        id={`${groupName}-${option.id}`}
        name={groupName}
        onChange={() => {
          onChange(option.id);
        }}
        type={multiple ? 'checkbox' : 'radio'}
      />
      <label
        css={css`
          display: block;
          width: 100%;
          padding: 0.9em 0em;
          padding-left: 12px;
          padding-right: 12px;
          line-height: 1.5em;
        `}
        htmlFor={`${groupName}-${option.id}`}>
        {option.label}
      </label>
    </Box>
  ));
};

Select.propTypes = {
  disabled: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: getFilePreviewFilePropType(),
      }),
    ]),
  ).isRequired,
  selectedOptionId: PropTypes.number,
  selectingImages: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  selectedOptionId: undefined,
};
