import React, { useContext } from 'react';
import { css } from '@emotion/core';
import { useActions } from '../hooks/use-actions';
import { usePosition } from '../hooks/use-position';
import { SceneContext } from '../SceneContext';

export const ImageElement = ({
  className,
  id,
  item: {
    data: { align = 'center', source, fill, file, padding, stretch, rotation },
  },
}) => {
  const { itemRefs, dynamicLayout } = useContext(SceneContext);

  const { style } = usePosition(id);
  const { onMouseEnter, onMouseLeave, onClick, onMouseDown, onDoubleClick, clickable } = useActions(
    id,
  );

  return (
    <div
      className={className}
      ref={current => {
        itemRefs[id] = current;
      }}
      css={css(
        style,
        css`
          background: url(${file ? file.url : source});
          background-repeat: no-repeat;
          background-position: ${align};
          background-size: ${(stretch && '100% 100%') || (fill && 'cover') || 'contain'};
        `,
        !dynamicLayout &&
          css`
            transform: rotate(${rotation}deg);
          `,
        padding &&
          css`
            margin: ${padding.map(p => p + 'px ')};
          `,
        clickable &&
          css`
            cursor: pointer;
          `,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
      role={clickable ? 'button' : 'image'}
      tabIndex={clickable ? '1' : '-1'}
    />
  );
};
