import React, { useRef, useCallback, useLayoutEffect, useState, useEffect } from 'react';
import { SliderBackground, Knob } from './styles';

const round = (number, increment = 1) => Math.round(number / increment) * increment;

export const Slider = ({
  value,
  onChange,
  minValue = 0,
  maxValue = 1,
  step,
  vertical = false,
  length = 160,
  width = 24,
}) => {
  const slideRef = useRef(null);
  const knobRef = useRef(null);
  const [moving, setMoving] = useState(false);

  const [internalValue, setInternalValue] = useState(value);

  const [changed, setChanged] = useState(false);

  const setKnobPosition = useCallback(
    pos => {
      const knob = knobRef.current;
      const { style } = knob;
      const range = length - width;

      if (vertical) {
        style.left = 0;
        style.bottom = `${pos * range}px`;
      } else {
        style.left = `${pos * range}px`;
        style.bottom = 0;
      }
    },
    [length, vertical, width],
  );

  const onMouseDown = useCallback(
    e => {
      setMoving(true);

      const knob = knobRef.current;
      const range = length - width;
      const valueRange = maxValue - minValue;

      if (knob.setCapture) {
        knob.setCapture();
      }

      e.preventDefault();

      const initialPos = vertical ? e.pageY : e.pageX;

      const initialSliderPosition = (internalValue - minValue) / valueRange;
      let newValue;

      const onMouseMove = e => {
        e.preventDefault();

        const pos = vertical ? e.pageY : e.pageX;

        const sliderPosition = Math.min(
          Math.max(
            initialSliderPosition + ((pos - initialPos) * (vertical ? -1 : 1)) / range || 0,
            0,
          ),
          1,
        );
        newValue = step
          ? minValue + round(sliderPosition * valueRange, step)
          : minValue + sliderPosition * valueRange;

        setKnobPosition(sliderPosition);

        setInternalValue(newValue);
        setChanged(true);

        return false;
      };
      const onMouseUp = () => {
        if (knob.releaseCapture) {
          knob.releaseCapture();
        }

        setKnobPosition((newValue - minValue) / valueRange);

        setMoving(false);

        window.removeEventListener('mousemove', onMouseMove, false);
        window.removeEventListener('mouseup', onMouseUp, false);
      };
      window.addEventListener('mousemove', onMouseMove, false);
      window.addEventListener('mouseup', onMouseUp, false);

      return false;
    },
    [length, width, maxValue, minValue, vertical, internalValue, step, setKnobPosition],
  );

  useLayoutEffect(() => {
    if (!moving) {
      setInternalValue(value);
      setKnobPosition((value - minValue) / (maxValue - minValue));
      setChanged(false);
    }
  }, [maxValue, minValue, moving, setKnobPosition, value]);

  useEffect(() => {
    if (changed && onChange) {
      onChange(internalValue);
      setChanged(false);
    }
  }, [changed, internalValue, onChange]);

  return (
    <SliderBackground ref={slideRef} length={length} width={width} vertical={vertical}>
      <Knob ref={knobRef} width={width} onMouseDown={onMouseDown} />
    </SliderBackground>
  );
};
