import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

export const FormSwitch = ({ input: { value = false, onChange }, label }) => (
  <div className="form-switch__container">
    <div className="form-switch__label">{label}</div>
    <div className="form-switch__switch">
      <Checkbox
        checked={value}
        id={`checkbox-${Math.floor(Math.random() * 10000000)}`}
        onChange={onChange}
        toggle
      />
    </div>
  </div>
);

FormSwitch.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

FormSwitch.defaultProps = {
  label: '',
};
