import React from 'react';
import { Route, Switch } from 'wouter';
import { Editor } from './Editor';
import { PlayerContainer } from './modules/Player';
import { hot } from 'react-hot-loader/root';

export const App = hot(() => (
  <Switch>
    <Route path="/editor">
      <Editor />
    </Route>
    <Route path="/player">
      <PlayerContainer />
    </Route>
  </Switch>
));
