import { PAGE_ID_LOADING, PAGE_ID_FINISHED } from '~/utils/course';

const PREFIX = '[Course]';

export const INITIALIZE_COURSE_PLAYER = `${PREFIX} INITIALIZE COURSE PLAYER`;

export const initializeCoursePlayer = payload => ({
  type: INITIALIZE_COURSE_PLAYER,
  payload,
});

export const FETCH_COURSE_DATA_REQUEST = `${PREFIX} FETCH COURSE DATA REQUEST`;
export const FETCH_COURSE_DATA_SUCCESS = `${PREFIX} FETCH COURSE DATA SUCCESS`;
export const FETCH_COURSE_DATA_FAILURE = `${PREFIX} FETCH COURSE DATA FAILURE`;

export const fetchCourseDataRequest = () => ({
  type: FETCH_COURSE_DATA_REQUEST,
});

export const fetchCourseDataSuccess = payload => ({
  type: FETCH_COURSE_DATA_SUCCESS,
  payload,
});

export const fetchCourseDataFailure = () => ({
  type: FETCH_COURSE_DATA_FAILURE,
});

export const SET_ACTIVE_PAGE_ID = `${PREFIX} SET ACTIVE PAGE ID`;

export const enterLoadingPage = () => ({
  type: SET_ACTIVE_PAGE_ID,
  payload: PAGE_ID_LOADING,
});

export const courseEnterFinishedPage = () => ({
  type: SET_ACTIVE_PAGE_ID,
  payload: PAGE_ID_FINISHED,
});

export const COURSE_SET_PAGE_COMPONENT_COLLECTED_DATA = `${PREFIX} PATCH COLLECTED DATA`;

export const courseSetPageComponentCollectedData = payload => ({
  type: COURSE_SET_PAGE_COMPONENT_COLLECTED_DATA,
  payload,
});

export const ENTER_SPEED_COURSE_PAGE = `${PREFIX} ENTER SPEED COURSE PAGE`;

export const enterSpeedCoursePage = payload => ({
  type: ENTER_SPEED_COURSE_PAGE,
  payload,
});

export const COURSE_ENTER_PAGE = `${PREFIX} ENTER PAGE`;
export const COURSE_ENTER_PAGE_REQUEST = `${PREFIX} ENTER PAGE REQUEST`;
export const COURSE_ENTER_PAGE_SUCCESS = `${PREFIX} ENTER PAGE SUCCESS`;
export const COURSE_ENTER_PAGE_FAILURE = `${PREFIX} ENTER PAGE FAILURE`;

export const courseEnterPage = payload => ({
  type: COURSE_ENTER_PAGE,
  payload,
});

export const courseEnterPageRequest = payload => ({
  type: COURSE_ENTER_PAGE_REQUEST,
  payload,
});

export const courseEnterPageSuccess = payload => ({
  type: COURSE_ENTER_PAGE_SUCCESS,
  payload,
});

export const courseEnterPageFailure = () => ({
  type: COURSE_ENTER_PAGE_FAILURE,
});

export const COURSE_LEAVE_PAGE = `${PREFIX} LEAVE PAGE`;
export const COURSE_LEAVE_PAGE_REQUEST = `${PREFIX} LEAVE PAGE REQUEST`;
export const COURSE_LEAVE_PAGE_SUCCESS = `${PREFIX} LEAVE PAGE SUCCESS`;
export const COURSE_LEAVE_PAGE_FAILURE = `${PREFIX} LEAVE PAGE FAILURE`;

export const courseLeavePage = payload => ({
  type: COURSE_LEAVE_PAGE,
  payload,
});

export const courseLeavePageRequest = () => ({
  type: COURSE_LEAVE_PAGE_REQUEST,
});

export const courseLeavePageSuccess = payload => ({
  type: COURSE_LEAVE_PAGE_SUCCESS,
  payload,
});

export const courseLeavePageFailure = () => ({
  type: COURSE_LEAVE_PAGE_FAILURE,
});

export const RUNTIME_ERROR_CATCH = `${PREFIX} RUNTIME ERROR CATCH`;

export const runtimeErrorCatch = err => ({
  type: RUNTIME_ERROR_CATCH,
  payload: err,
});

export const COURSE_ON_CONTINUE_CLICK = `${PREFIX} ON CONTINUE CLICK`;

export const courseOnContinueClick = payload => ({
  type: COURSE_ON_CONTINUE_CLICK,
  payload,
});

export const COURSE_ON_GO_BACK_CLICK = `${PREFIX} ON GO BACK CLICK`;

export const courseOnGoBackClick = payload => ({
  type: COURSE_ON_GO_BACK_CLICK,
  payload,
});

export const COURSE_ON_EXIT_CLICK = `${PREFIX} ON EXIT CLICK`;

export const courseOnExitClick = payload => ({
  type: COURSE_ON_EXIT_CLICK,
  payload,
});

export const COURSE_ON_CONTINUE_SLIDE_CLICK = `${PREFIX} ON CONTINUE SLIDE CLICK`;

export const courseOnContinueSlideClick = payload => ({
  type: COURSE_ON_CONTINUE_SLIDE_CLICK,
  payload,
});

export const COURSE_ON_GO_BACK_SLIDE_CLICK = `${PREFIX} ON GO_BACK SLIDE CLICK`;

export const courseOnGoBackSlideClick = payload => ({
  type: COURSE_ON_GO_BACK_SLIDE_CLICK,
  payload,
});

export const SET_ACTIVE_SLIDE_ID = `${PREFIX} SET ACTIVE SLIDE ID`;

export const setActiveSlideId = payload => ({
  type: SET_ACTIVE_SLIDE_ID,
  payload,
});

export const COURSE_INCREMENT_WINNING_STREAK = `${PREFIX} INCREMENT WINNING STREAK`;
export const COURSE_DROP_WINNING_STREAK = `${PREFIX} DROP WINNING STREAK`;

export const courseIncrementWinningStreak = () => ({
  type: COURSE_INCREMENT_WINNING_STREAK,
});

export const courseDropWinningStreak = () => ({
  type: COURSE_DROP_WINNING_STREAK,
});
