import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'focus-visible';

import {
  PAGE_ID_FINISHED,
  PAGE_ID_LOADING,
  PAGE_ID_NO_PAGES,
  PAGE_ID_RESULTS,
  PAGE_ID_RUNTIME_ERROR,
  PAGE_ID_SPEED_COURSE,
  PAGE_ID_DESCRIPTION,
} from '~/utils/course';

import * as coursePlayerActions from '~/stores/player/actions/course.actions';

import {
  getActivePage,
  getActivePageIndex,
  getPageQuestionnairesResults,
  getActivePageInitTimestamp,
  getSpeedCourseIndex,
  getWinningStreak,
  getActivePageTimeToReadQuestionSec,
  getActivePageTimeToAnswerQuestionSec,
  getPages,
  getPrevPageId,
  getActiveSlideIndex,
} from '~/stores/player/selectors/course.selectors';

import { CourseCover, CoursePage, CourseResults } from '~/modules/Player/containers';

import { CardPopup } from '~/modules/Player/components/CardPopup/CardPopup';
import { getConfigObject } from '~/stores/player/selectors/config.selectors';
import { getFilePreviewFilePropType, getCoursePagePropType } from '~/utils/types';

class Player extends Component {
  constructor(props) {
    super(props);
    this.onSetComponentCollectedData = this.onSetComponentCollectedData.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onGoBackClick = this.onGoBackClick.bind(this);
    this.onExitClick = this.onExitClick.bind(this);
    this.onDismissedPopupCard = this.onDismissedPopupCard.bind(this);
    this.onNextSlideClick = this.onNextSlideClick.bind(this);
    this.onGoBackSlideClick = this.onGoBackSlideClick.bind(this);
  }

  state = {
    courseDescriptionPopupDismissed: false,
  };

  componentDidMount() {
    const {
      course: { id: courseId },
      initializeCoursePlayer,
    } = this.props;
    initializeCoursePlayer(courseId);
  }

  onSetComponentCollectedData({ componentDataKey, collectedData }) {
    const { courseSetPageComponentCollectedData, course } = this.props;
    courseSetPageComponentCollectedData({
      pageId: course.data.activePageId,
      componentDataKey,
      collectedData,
    });
  }

  onContinueClick() {
    const { courseOnContinueClick } = this.props;
    courseOnContinueClick();
  }

  onGoBackClick() {
    const { courseOnGoBackClick } = this.props;
    courseOnGoBackClick();
  }

  onNextSlideClick() {
    const { courseOnContinueSlideClick } = this.props;
    courseOnContinueSlideClick();
  }

  onGoBackSlideClick() {
    const { courseOnGoBackSlideClick } = this.props;
    courseOnGoBackSlideClick();
  }

  onExitClick() {
    const { courseOnExitClick } = this.props;
    courseOnExitClick();
  }

  componentDidCatch(err) {
    const { runtimeErrorCatch } = this.props;
    runtimeErrorCatch(err);
  }

  onDismissedPopupCard() {
    const { courseLeavePage } = this.props;
    courseLeavePage({ id: -1, collectedData: {} });
    this.setState({ courseDescriptionPopupDismissed: true });
  }

  getPagesAndSubpagesCount() {
    const { pages } = this.props;

    let pagesAndSubpagesCnt = 0;
    pages.map(page => {
      // for pictureslides, also count the slides of each pictureslide
      if (page.templateTypeId === 'PICTURESLIDES-TEXT') {
        pagesAndSubpagesCnt += page.pictureslides?.slides.length;
      } else if (page.templateTypeId !== 'SPEED_COURSE') {
        pagesAndSubpagesCnt += 1;
      }
    });

    return pagesAndSubpagesCnt;
  }

  render() {
    const {
      course: { data: courseData, isFetching },
    } = this.props;

    if (!courseData) {
      return (
        <div className="player-page__wrapper">
          <div className="player-page__init-status">
            {isFetching ? 'loading...' : 'Failed to load course data.'}
          </div>
        </div>
      );
    }

    const {
      course: {
        data: { activePageId, resultsPage, pagesCount, speedCourseSettings },
      },
      activePage,
      activePageIndex,
      activePageQuestionnairesResults,
      speedCourseIndex,
      configObject,
      pages,
      prevPageId,
      activeSlideIndex,
    } = this.props;
    const { courseDescriptionPopupDismissed } = this.state;

    const isFirstPage = activePageId === this.props.course.data.pages[0].id;
    const showDescriptionPage = !courseData.descriptionPage.disabled;
    return (
      configObject.data && (
        <div className="player-page__wrapper">
          {/* The description-page is shown as a popup*/}
          {isFirstPage && showDescriptionPage && !courseDescriptionPopupDismissed && (
            <CardPopup
              withTopDragHandle
              actionButtons={[{ txt: 'Start kurs', onClick: this.onDismissedPopupCard }]}
              onDismissed={this.onDismissedPopupCard}
              css={css`
                background-color: #00cb9c;
              `}>
              <h2>{courseData.descriptionPage.title}</h2>
              <p>{courseData.descriptionPage.description}</p>
            </CardPopup>
          )}

          {(activePageId === PAGE_ID_RESULTS && (
            <CourseResults
              {...resultsPage}
              onFinishClick={this.onContinueClick}
              result={courseData.result}
            />
          )) ||
            (activePageId === PAGE_ID_RUNTIME_ERROR && (
              <div className="player-page__init-status">Runtime Error</div>
            )) ||
            (activePageId === PAGE_ID_LOADING && (
              <div className="player-page__init-status">loading...</div>
            )) ||
            (activePageId === PAGE_ID_FINISHED && (
              <div className="player-page__init-status">
                This is the end. You can close the tab.
              </div>
            )) ||
            (activePageId === PAGE_ID_NO_PAGES && (
              <div className="player-page__init-status">
                No pages added to the course.
                <br />
                <button onClick={() => this.onContinueClick()}>OK</button>
              </div>
            )) ||
            (speedCourseIndex !== -1 && activePageIndex === speedCourseIndex && (
              <CourseCover
                {...speedCourseSettings}
                title={speedCourseSettings.title || 'Er du klar for hutigquiz?'}
                description={speedCourseSettings.description}
                cover={speedCourseSettings.cover}
                onContinueClick={this.onContinueClick}
                isSpeedCourseCover
              />
            )) ||
            (activePage && (
              <CoursePage
                page={activePage}
                pages={pages}
                courseType={courseData.settings.pageType}
                resultsPage={resultsPage}
                prevPageId={prevPageId}
                activePageIndex={activePageIndex}
                pageNumber={activePageIndex + 1}
                slideNumber={activeSlideIndex + 1}
                course={courseData}
                informAboutAnswerCorrectness={
                  courseData && courseData.settings && courseData.settings.informAnswer
                }
                onContinueSlideClick={this.onNextSlideClick}
                onGoBackSlideClick={this.onGoBackSlideClick}
                pagesCount={pagesCount}
                pagesAndSubpagesCount={this.getPagesAndSubpagesCount()}
                pageQuestionnairesResults={activePageQuestionnairesResults}
                setSectionCollectedData={this.onSetComponentCollectedData}
                onContinueClick={this.onContinueClick}
                onGoBackClick={this.onGoBackClick}
                onExitClick={this.onExitClick}
              />
            ))}

          <ToastContainer />
        </div>
      )
    );
  }
}

Player.propTypes = {
  course: PropTypes.shape({
    data: PropTypes.shape({
      activePageId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([
          PAGE_ID_DESCRIPTION,
          PAGE_ID_FINISHED,
          PAGE_ID_LOADING,
          PAGE_ID_NO_PAGES,
          PAGE_ID_RESULTS,
          PAGE_ID_RUNTIME_ERROR,
          PAGE_ID_SPEED_COURSE,
        ]),
      ]),
      descriptionPage: PropTypes.shape({
        disabled: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
      }),
      pages: PropTypes.arrayOf(getCoursePagePropType()),
      resultsPage: PropTypes.shape({
        disabled: PropTypes.bool,
        textWhenPassed: PropTypes.string,
        textWhenFailed: PropTypes.string,
      }),
      speedCourseSettings: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        cover: getFilePreviewFilePropType(),
        pointsMaxScore: PropTypes.number,
        pointsPerAnswer: PropTypes.number,
        timeToReadQuestionSec: PropTypes.number,
        timeToAnswerQuestionSec: PropTypes.number,
      }),
    }),
    id: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }).isRequired,
  activePage: getCoursePagePropType(),
  activePageIndex: PropTypes.number,
  activeSlideIndex: PropTypes.number,
  activePageQuestionnairesResults: PropTypes.shape({}),
  activePageInitTimestamp: PropTypes.number,
  activePageTimeToReadQuestionSec: PropTypes.number,
  activePageTimeToAnswerQuestionSec: PropTypes.number,
  speedCourseIndex: PropTypes.number,
  winningStreak: PropTypes.number.isRequired,
  initializeCoursePlayer: PropTypes.func.isRequired,
  courseSetPageComponentCollectedData: PropTypes.func.isRequired,
  runtimeErrorCatch: PropTypes.func.isRequired,
  courseOnContinueClick: PropTypes.func.isRequired,
  courseOnGoBackClick: PropTypes.func.isRequired,
  configObject: PropTypes.shape({}).isRequired,
  configGetConfig: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prevPageId: PropTypes.number.isRequired,
};

Player.defaultProps = {
  activePage: null,
  activePageIndex: null,
  activePageQuestionnairesResults: {},
  activePageInitTimestamp: null,
  activePageTimeToReadQuestionSec: null,
  activePageTimeToAnswerQuestionSec: null,
  speedCourseIndex: null,
};

const mapStateToProps = state => ({
  course: state.course,
  activePage: getActivePage(state),
  activePageIndex: getActivePageIndex(state),
  activeSlideIndex: getActiveSlideIndex(state),
  activePageQuestionnairesResults: getPageQuestionnairesResults(state),
  activePageInitTimestamp: getActivePageInitTimestamp(state),
  speedCourseIndex: getSpeedCourseIndex(state),
  winningStreak: getWinningStreak(state),
  activePageTimeToReadQuestionSec: getActivePageTimeToReadQuestionSec(state),
  activePageTimeToAnswerQuestionSec: getActivePageTimeToAnswerQuestionSec(state),
  configObject: getConfigObject(state),
  pages: getPages(state),
  prevPageId: getPrevPageId(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(coursePlayerActions, dispatch);

export const PlayerContainer = connect(mapStateToProps, mapDispatchToProps)(Player);
