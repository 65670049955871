import { css } from '@emotion/core';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

import { mqMaxWidth, mq, nanoPlayerMaxSize } from '~/modules/Player/styles/facepaint';
import { fontSizes } from '~/components/scene/fields/size-options';

export const TextElementStyle = props => css`
  color: ${props.color};
  background: ${props.background};
  font-style: ${props.italic ? 'italic' : 'normal'};
  font-size: ${fontSizes[props.size]};
  text-align: ${props.align};
  font-weight: ${props.bold ? 'bold' : 'normal'};
  user-select: none;
`;

export const TextElement = styled.p`
  ${TextElementStyle};
  line-height: 120%;
`;

export const Header = styled.h1`
  ${TextElementStyle};
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  word-break: break-word;
  hyphens: auto;

  /*Ensure the height matches the displayed content also when the text is wrapped*/
  min-height: auto;

  ${mqMaxWidth.small} {
    font-size: ${props => (props.onlyItem ? '2.3em' : '2.1em')};
  }

  font-size: ${props => (props.onlyItem ? '2.8em' : '2.3em')};
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

export const Wrapper = styled.div`
  height: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.horizontalAlignItems};
  margin: 3em 0;
  :last-child {
    margin-bottom: 0;
  }
`;

export const Slide = styled(motion.div)`
  padding-top: ${props => (props.verticalAlignContent === 'center' ? '0em' : '8.5em')};
  height: 100%;
  justify-content: ${props => props.verticalAlignContent};
  display: flex;
  flex-direction: column;
  max-width: ${nanoPlayerMaxSize};
  font-size: 1.1em;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;

  ${mq.nanoPlayerMax} {
    font-size: 1.3em;
  }
  ${mq.medium} {
    font-size: 1.2em;
  }
`;

export const ImageElementStyled = styled.img`
  transform: ${props => (props.rotation ? `rotateZ(${props.rotation}deg)` : 'unset')};
  object-fit: ${props => (props.stretch && 'unset') || (props.fill ? 'cover' : 'unset')};
  object-position: ${props => props.objectPosition};
  ${props =>
    props.displayMode === 'FILL'
      ? css`
          width: 100%;
          object-fit: cover;
        `
      : css`
          width: 100%;
          height: auto;
          max-width: ${nanoPlayerMaxSize};
        `}
  ${props =>
    props.stretch &&
    css`
      width: 100%;
    `}


    ${mq.nanoPlayerMax}{
      padding-left: 1.5em;
      padding-right: 1.5em;
      max-width: 800px;
      width: auto;
    }
`;

export const UnorderedList = styled.ul`
  li {
    padding-left: 0.5em;
    margin-bottom: 1.5em;
  }
`;

export const SvgWrapper = styled.div`
  width: 100%;
  svg {
    width: 100%;
    height: ${props => props.height};
    transform: ${props => (props.rotation ? `rotateZ(${props.rotation}deg)` : 'unset')};
  }
`;

export const SlideWrapper = styled.section`
  background: ${props => props.background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const ClickZone = styled.div`
  background: transparent;
  position: fixed;
  left: ${props => (props.where === 'leftPartOfSlide' ? '0' : '50%')};
  width: 50%;
  top: 0;
  height: 100%;
  z-index: -1;
`;

export const NavigationButtonsWrapper = styled.div`
  z-index: 2;
  display: flex;
  position: fixed;
  justify-content: center;
  width: 40%;
  align-self: center;
  display: flex;
  justify-content: space-around;
  width: 80%;
  font-size: 1.3em;
  bottom: 3em;
  background: #b5b5b570;
  border-radius: 10px;
  max-width: ${nanoPlayerMaxSize};
`;

export const NavigationButton = styled.button`
  border: none;
  background: none;
  white-space: nowrap;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};

  padding: 1em 1.5em;
  border-radius: 25px;
  font-size: 1.2em;

  :disabled {
    color: #ffffffcf;
  }

  :disabled:focus,
  :disabled:active:focus {
    outline: 1px solid gray;
  }

  :disabled {
    color: #6d6d6d;
  }

  :hover,
  :focus,
  :focus:active {
    background-color: #ebebf54d;
    outline: none;

    :disabled {
      background: none;
      cursor: not-allowed;
    }
  }
`;
