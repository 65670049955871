import React from 'react';
import PropTypes from 'prop-types';

export const PointsColumn = ({ winningStreak, progress }) => (
  <div className="player-points-column__container">
    {winningStreak > 2 && (
      <div className="player-points-column__winning-streak">{winningStreak}</div>
    )}
    <div className="player-points-column__progress-container">
      <div className="player-points-column__progress" style={{ height: `${progress}%` }} />
    </div>
  </div>
);

PointsColumn.propTypes = {
  winningStreak: PropTypes.number,
  progress: PropTypes.number,
};

PointsColumn.defaultProps = {
  winningStreak: 0,
  progress: 0,
};
