import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getCoursePagePropType } from '~/utils/types';
import { getEditorSectionComponent, getPageSections } from '~/utils/course';
import { CourseSettings } from '~/modules/Editor/components/CourseSettings/CourseSettings';
import { Questionnaire } from '~/modules/Editor/components/Questionnaire/Questionnaire';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ScreenSizes } from '../../../views/EditorMain';
import {
  getPageSize,
  getPageType,
  getShouldUseDynamicSize,
} from '../../../stores/editor/selectors/course.selectors';
import { TextElement } from '../../../components/ContentEditable/TextElement';
import { css } from '@emotion/core';

const ConditionalWrapper = ({ children, enabled, ...props }) =>
  enabled ? <div {...props}>{children}</div> : children;

export const CoursePage = ({
  deletePicture,
  deleteQuestionnaireAnswerImage,
  deleteSlideImage,
  deleteVideoPoster,
  setActiveElement,
  activeSlideId,
  deleteVideoSource,
  onPageDataChange,
  onSectionDataChange,
  page,
  pages,
  uploadPicture,
  showSettings,
  showPage,
  uploadQuestionnaireAnswerImage,
  uploadSlideImage,
  uploadContentFile,
  uploadVideoPoster,
  uploadVideoSource,
  size,
  scale,
}) => {
  const pageSize = useSelector(getPageSize);
  const pageType = useSelector(getPageType);
  const selectedScreenSize = ScreenSizes[pageSize];

  const isDynamicSize = useSelector(getShouldUseDynamicSize);
  const customCss = {};
  if (['PICTURESLIDES-TEXT'].includes(page.templateTypeId) && activeSlideId) {
    if (page.pictureslides.slides.length > 0) {
      const activeSlide = page.pictureslides.slides.filter(ps => ps.id === activeSlideId)[0];
      if (activeSlide) {
        customCss.backgroundColor = activeSlide.bgColor;
        if (activeSlide.bgImage && showPage === 1) {
          customCss.backgroundImage = `url('${activeSlide.bgImage.url}')`;
          customCss.backgroundSize = 'cover';
        }
      }
    }
  }

  if (page.bgImage && showPage === 1) {
    customCss.backgroundImage = `url('${page.bgImage.url}')`;
    customCss.backgroundSize = 'cover';
  }
  if (page.bgColor) {
    customCss.backgroundColor = page.bgColor;
  }

  if (page.justifyContent) {
    customCss.justifyContent = page.justifyContent;
  }

  const getTitleDataField = useCallback(
    ({ field: { name } }) => (page.titleData ? page.titleData[name] : undefined),
    [page.titleData],
  );
  const setTitleDataField = useCallback(
    (value, { field: { name } }) =>
      onPageDataChange({ titleData: { ...page.titleData, [name]: value } }),
    [onPageDataChange, page.titleData],
  );

  return (
    <>
      <ConditionalWrapper
        enabled={page.templateTypeId !== 'PICTURESLIDES-TEXT'}
        css={css(
          css`
            top: 0;
            left: 0;
          `,
          !isDynamicSize &&
            css`
              position: relative;
              transform: scale(${scale});
              transform-origin: top left;
              width: ${selectedScreenSize.w}px;
              height: ${selectedScreenSize.h}px;
            `,
        )}>
        <div
          className={classNames([
            'course-sections-editors__container',
            `page-${
              page &&
              page.templateTypeId &&
              page.templateTypeId &&
              page.templateTypeId.toLowerCase()
            }`,
            showPage === 2 ? 'answers right' : null,
            showPage === 3 ? 'answers wrong' : null,
          ])}
          css={
            (css(
              page.templateTypeId === 'PICTURESLIDES-TEXT' &&
                css`
                  overflow: visible;
                `,
            ),
            css(customCss))
          }>
          {(['TEXT-QUESTIONNAIRE', 'TEXT-PICTURESQUESTIONNAIRE'].includes(page.templateTypeId) &&
            showPage !== 1 && (
              <div className="content">
                <Questionnaire
                  key={`page_${page.id}-section_questionnaire`}
                  data={
                    page[
                      page.templateTypeId === 'TEXT-QUESTIONNAIRE'
                        ? 'questionnaire'
                        : 'picturesQuestionnaire'
                    ]
                  }
                  page={page}
                  pages={pages}
                  activeSection={showPage}
                  activeSlideId={activeSlideId}
                  setActiveElement={setActiveElement}
                  onPageDataChange={onPageDataChange}
                  onValueUpdate={data =>
                    onSectionDataChange(
                      data,
                      page.templateTypeId === 'TEXT-QUESTIONNAIRE'
                        ? 'questionnaire'
                        : 'picturesQuestionnaire',
                    )
                  }
                />
              </div>
            )) || (
            <ConditionalWrapper
              enabled={page.templateTypeId !== 'PICTURESLIDES-TEXT'}
              className="content">
              {!['PICTURESLIDES-TEXT', 'TEXT-DILEMMA'].includes(page.templateTypeId) && (
                <div className="course-sections-editors__header">
                  <TextElement
                    title
                    tagName="h1"
                    className="title"
                    content={page.title}
                    editable={true}
                    onChange={value => onPageDataChange({ title: value })}
                    multiline={false}
                    data={page.titleData}
                    getValue={getTitleDataField}
                    setValue={setTitleDataField}
                  />
                </div>
              )}
              {getPageSections(page.templateTypeId).map(({ componentName, dataKey }) => {
                const {
                  component: SectionComponent,
                  defaultParams: defaultComponentParams,
                } = getEditorSectionComponent(componentName);
                return (
                  <SectionComponent
                    {...defaultComponentParams}
                    key={`page_${page.id}-section_${dataKey}`}
                    data={page[dataKey]}
                    page={page}
                    pages={pages}
                    activeSection={showPage}
                    activeSlideId={activeSlideId}
                    setActiveElement={setActiveElement}
                    onPageDataChange={onPageDataChange}
                    onValueUpdate={data => onSectionDataChange(data, dataKey)}
                    deleteQuestionnaireAnswerImage={answerId =>
                      deleteQuestionnaireAnswerImage({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        answerId,
                      })
                    }
                    uploadQuestionnaireAnswerImage={(answerId, file) =>
                      uploadQuestionnaireAnswerImage({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        answerId,
                        file,
                      })
                    }
                    deleteSlideImage={slideId =>
                      deleteSlideImage({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        slideId,
                      })
                    }
                    uploadSlideImage={(slideId, file) =>
                      uploadSlideImage({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        slideId,
                        file,
                      })
                    }
                    deleteVideoPoster={() =>
                      deleteVideoPoster({
                        pageId: page.id,
                        componentDataKey: dataKey,
                      })
                    }
                    uploadVideoPoster={file =>
                      uploadVideoPoster({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        file,
                      })
                    }
                    uploadContentFile={file =>
                      uploadContentFile({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        file,
                      })
                    }
                    deleteVideoSource={() =>
                      deleteVideoSource({
                        pageId: page.id,
                        componentDataKey: dataKey,
                      })
                    }
                    uploadVideoSource={file =>
                      uploadVideoSource({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        file,
                      })
                    }
                    deletePicture={() =>
                      deletePicture({
                        pageId: page.id,
                        componentDataKey: dataKey,
                      })
                    }
                    uploadPicture={file =>
                      uploadPicture({
                        pageId: page.id,
                        componentDataKey: dataKey,
                        file,
                      })
                    }
                    size={size}
                    scale={scale}
                    editor
                  />
                );
              })}
            </ConditionalWrapper>
          )}
        </div>
      </ConditionalWrapper>
      {showSettings && (
        <CourseSettings
          page={page}
          courseType={pageType}
          onPageDataChange={onPageDataChange}
          deletePicture={() =>
            deletePicture({
              pageId: page.id,
              componentDataKey: null,
            })
          }
          uploadPicture={file =>
            uploadPicture({
              pageId: page.id,
              componentDataKey: null,
              file,
            })
          }
          deleteSlideImage={ () =>
            deleteSlideImage({
              pageId: page.id,
              bg: true,
              componentDataKey: 'pictureslides',
              slideId: activeSlideId,
            })
          }
          uploadSlideImage={file =>
            uploadSlideImage({
              pageId: page.id,
              bg: true,
              componentDataKey: 'pictureslides',
              slideId: activeSlideId,
              file,
            })
          }
          onValueUpdate={(data, dataKey) => onSectionDataChange(data, dataKey)}
          activeSlideId={activeSlideId}
        />
      )}
    </>
  );
};

CoursePage.propTypes = {
  deletePicture: PropTypes.func.isRequired,
  deleteQuestionnaireAnswerImage: PropTypes.func.isRequired,
  deleteSlideImage: PropTypes.func.isRequired,
  deleteVideoPoster: PropTypes.func.isRequired,
  deleteVideoSource: PropTypes.func.isRequired,
  onPageDataChange: PropTypes.func.isRequired,
  setActiveElement: PropTypes.func.isRequired,
  onSectionDataChange: PropTypes.func.isRequired,
  page: getCoursePagePropType().isRequired,
  pages: PropTypes.arrayOf(getCoursePagePropType()).isRequired,
  uploadPicture: PropTypes.func.isRequired,
  uploadQuestionnaireAnswerImage: PropTypes.func.isRequired,
  uploadSlideImage: PropTypes.func.isRequired,
  uploadContentFile: PropTypes.func.isRequired,
  uploadVideoPoster: PropTypes.func.isRequired,
  uploadVideoSource: PropTypes.func.isRequired,
};
