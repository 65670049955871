import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import { toolbarButtonStyle, dropdownBgStyle } from '../styles';
import { Dropdown } from '../Dropdown';
import { SceneContext } from '../SceneContext';
import { Tooltip } from '../Tooltip';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';

export const ToolbarSelect = ({ icon, hideLabel, value, label, options, setValue }) => {
  const { itemSelectMode } = useContext(SceneContext);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [changed, setChanged] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (!changed) {
      return;
    }
    setValue(internalValue);
    setChanged(false);
  }, [changed, internalValue, setValue]);

  useLayoutEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [value]);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        onClick={() => {
          setOpen(toggle => !toggle);
        }}
        css={css(toolbarButtonStyle)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {icon && <>&nbsp;</>}
        {!hideLabel && label && <>{label}:&nbsp;</>}
        {hideLabel && !!label && (
          <Tooltip parentRef={ref} onHover distance={12} alignCenter alignBottom>
            {label}
          </Tooltip>
        )}
        {options.find(({ value: optionValue }) => optionValue === internalValue)?.text}
        &nbsp;
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open && (
        <Dropdown
          css={dropdownBgStyle}
          parentRef={ref}
          alignTop
          onClose={() => {
            if (!itemSelectMode) {
              setOpen(false);
            }
          }}>
          <div
            css={css`
              padding: 2px;
              background: #585d6a;
              color: #fff;
              pointer-events: all;
            `}>
            {options.map(({ text, value }) => (
              <div
                key={value}
                css={css(
                  toolbarButtonStyle,
                  css`
                    height: 26px;
                    border-radius: 10px;
                    padding: 0 10px;
                    margin: 2px;
                  `,
                )}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setInternalValue(value);
                  setChanged(true);
                  setOpen(false);
                }}>
                {text}
              </div>
            ))}
          </div>
        </Dropdown>
      )}
    </>
  );
};
