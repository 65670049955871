import React from 'react';
import ReactDOM from 'react-dom';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Provider } from 'react-redux';

import API from '~/utils/request';
import { getUrlParam } from '~/utils/common';

import { configureStore as configureEditorStore } from '~/stores/editor/editor.store';
import { configureStore as configurePlayerStore } from '~/stores/player/player.store';
import { initialState as courseInitialEditorState } from '~/stores/editor/reducers/course.reducer';
import { initialState as courseInitialPlayerState } from '~/stores/player/reducers/course.reducer';

import '~/styles/main.scss';
import './index.css';
import { App } from './App';

const root = document.querySelector('#root');
const courseId = Number.parseInt(root.getAttribute('courseid') || getUrlParam('id'), 10);
console.log('courseId', courseId);
API.configureRequests({ courseId });
const store = window.location.href.includes('/editor')
  ? configureEditorStore({
      course: {
        ...courseInitialEditorState,
        id: courseId,
      },
    })
  : configurePlayerStore({
      course: {
        ...courseInitialPlayerState,
        id: courseId,
      },
    });

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  console.error = (function () {
    // https://github.com/facebook/prop-types/pull/245#issuecomment-517915855
    const { error } = console;
    return function (...args) {
      if (args[0] && args[0].match && args[0].match(/^warn(ing)?:/i)) {
        console.warn(...args);
      } else {
        error.apply(console, args);
      }
    };
  })();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  root,
);
