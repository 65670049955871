import React, { useState, useEffect } from 'react';
import { Dropdown } from './Dropdown';
import { css } from '@emotion/core';

export const Tooltip = ({
  className,
  children,
  parentRef,
  onHover,
  distance,
  alignTop,
  alignBottom,
  alignRight,
  alignCenter,
  positionRight,
  positionLeft,
}) => {
  const [visible, setVisible] = useState(!onHover);

  useEffect(() => {
    const element = parentRef.current;

    if (!onHover || !element) {
      return undefined;
    }

    const onMouseEnter = () => {
      setVisible(true);
    };
    const onMouseLeave = () => {
      setVisible(false);
    };

    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  });

  return (
    visible && (
      <Dropdown
        className={className}
        parentRef={parentRef}
        alignTop={alignTop}
        alignCenter={alignCenter}
        alignRight={alignRight}
        positionRight={positionRight}
        positionLeft={positionLeft}
        alignBottom={alignBottom}
        distance={distance}
        css={css`
          pointer-events: none;
        `}>
        <div
          css={css`
            line-height: 1.5;
            white-space: nowrap;
            height: 1.5em;
            border-radius: 0.75em;
            background: #5b57d9;
            color: #fff;
            text-align: center;
            padding: 0 0.75em;
          `}>
          {children}
        </div>
      </Dropdown>
    )
  );
};
