import React from 'react';
import {
  Wrapper,
  ProgressBar,
  ProgressItemGrowShrink,
  ProgressItemWrapper,
  CloseButton,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { css } from '@emotion/core';

const range = length => Array.from({ length }, (x, i) => i + 1);

/* Shows a progress-indicator and a exit button.

Has two modes of usage:
a: Provide pagesNumber and pagesCount and the progress-indicator will show the progress
b: When the finishedMode-prop is given, the progress-indicator will indicate 100% progress
*/
export const TopStatusBar = ({
  pageNumber,
  pagesCount,
  finishedMode,
  handleExit,
  colorScheme,
  noCloseButton,
  ...props
}) => (
  <Wrapper {...props}>
    <ProgressBar aria-live="polite" colorScheme={colorScheme}>
      <p
        css={css`
          width: 0;
          height: 0;
          overflow: hidden;
        `}>
        {(finishedMode && 'Ferdig') || `Side ${pageNumber} av ${pagesCount}`}
      </p>
      {!finishedMode &&
        range(pagesCount).map(pageNo => (
          <ProgressItemWrapper key={pageNo} aria-hidden="true" colorscheme={colorScheme}>
            <ProgressItemGrowShrink
              key={pageNo + colorScheme}
              variants={{
                hidden: { scaleX: 0 },
                visible: {
                  scaleX: 1,
                  backgroundColor: colorScheme === 'light' ? 'white' : '#a4a4a4',
                },
              }}
              animate={pageNo > pageNumber ? 'hidden' : 'visible'}
              transition={{ type: 'tween', easing: 'easeOut' }}
              notYetEntered={pageNo > pageNumber}
            />
          </ProgressItemWrapper>
        ))}

      {finishedMode && (
        <ProgressItemWrapper
          key="finished"
          aria-hidden="true"
          colorscheme="light"
          css={css`
            background: black;
          `}>
          <ProgressItemGrowShrink
            css={css`
              background-color: #21ce6c;
              z-index: 100;
            `}
            notYetEntered={false}
          />
        </ProgressItemWrapper>
      )}
    </ProgressBar>

    {!noCloseButton && (
      <CloseButton type="button" title="Lukk kurs" aria-label="Lukk kurs" onClick={handleExit}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
    )}
  </Wrapper>
);
