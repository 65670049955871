import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import debounce from 'debounce';

import { FormInput } from '~/components/FormInput';
import { FormSwitch } from '~/components/FormSwitch';

import { number, positiveNumber, required } from '~/utils/validators';
import { formatNumberInput, parseNumberInput } from '~/utils/helpers';

class SettingsTab extends Component {
  constructor(props) {
    super(props);

    this.debounce = debounce;
    this.normalizeOnPointsEnabledChange = this.normalizeOnPointsEnabledChange.bind(this);
  }

  normalizeOnPointsEnabledChange(value) {
    const { change } = this.props;
    if (value) {
      change('pointsPerAnswer', 1);
      change('pointsMaxScore', 1);
    } else {
      change('pointsPerAnswer', 0);
      change('pointsMaxScore', 0);
    }
    return value;
  }

  pointsValidator(value, allValues) {
    return allValues.pointsEnabled ? positiveNumber(value) : undefined;
  }

  render() {
    const { initialValues, isPicturesQuestionnaireSettings, handleSubmit } = this.props;
    return (
      <form className="questionnaire-section-editor__settings-container" autoComplete="off">
        {isPicturesQuestionnaireSettings && (
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormSwitch}
              label="Drag & drop"
              name="dndSelect"
              onChange={() => {
                setTimeout(() => {
                  handleSubmit();
                });
              }}
            />
          </div>
        )}
        <div className="questionnaire-section-editor__settings-row">
          <Field
            component={FormSwitch}
            label="Points"
            name="pointsEnabled"
            normalize={this.normalizeOnPointsEnabledChange}
            onChange={() => {
              setTimeout(() => {
                handleSubmit();
              });
            }}
          />
        </div>
        <div className="questionnaire-section-editor__settings-subsection">
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormInput}
              disabled={!initialValues.pointsEnabled}
              format={formatNumberInput}
              label="Points per correct answer"
              name="pointsPerAnswer"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              parse={parseNumberInput}
              type="number"
              validate={[required, number, this.pointsValidator]}
            />
          </div>
          <div className="questionnaire-section-editor__settings-row">
            <Field
              component={FormInput}
              disabled={!initialValues.pointsEnabled}
              format={formatNumberInput}
              label="Max score in points"
              name="pointsMaxScore"
              onChange={this.debounce(() => {
                handleSubmit();
              }, 700)}
              parse={parseNumberInput}
              type="number"
              validate={[required, number, this.pointsValidator]}
            />
          </div>
        </div>
      </form>
    );
  }
}

SettingsTab.propTypes = {
  initialValues: PropTypes.shape({
    pointsEnabled: PropTypes.bool.isRequired,
    pointsMaxScore: PropTypes.number.isRequired,
    pointsPerAnswer: PropTypes.number.isRequired,
  }).isRequired,
  isPicturesQuestionnaireSettings: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const SettingsTabForm = reduxForm({
  form: 'questionareSettingsForm',
})(SettingsTab);
