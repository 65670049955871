import React, { forwardRef } from 'react';
import { resizeHandleStyle, anchorHandleStyle } from './styles';
import { css } from '@emotion/core';

const EditorSelectionHandles = React.memo(
  ({
    onMouseDownEvents,
    anchor,
    resizeHorizontal,
    resizeVertical,
    move,
    point,
    resizeCornersOnly,
    color,
    hasSize,
  }) => (
    <>
      {!point && !anchor && (
        <div
          css={css(
            css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: none;
              :focus {
                outline: 0;
              }
            `,
            css`
              pointer-events: ${move ? 'all' : 'none'};
              box-shadow: 0 0 0 2px ${color};
            `,
          )}
          onMouseDown={move ? onMouseDownEvents.move : undefined}
          role="button"
          tabIndex="-1"
        />
      )}
      {!point && (resizeVertical || resizeHorizontal) && (
        <>
          <div
            css={css(
              anchor ? anchorHandleStyle : resizeHandleStyle,
              css`
                cursor: ${(resizeHorizontal && ((resizeVertical && 'nw-resize') || 'w-resize')) ||
                'n-resize' ||
                (!resizeVertical && 'move')};
              `,
              anchor
                ? css`
                    left: -8px;
                    top: -8px;
                    border-bottom: 8px solid ${color};
                    border-left: 8px solid transparent;
                  `
                : css`
                    left: -4px;
                    top: -4px;
                    background: ${color};
                  `,
            )}
            role="button"
            tabIndex="-1"
            onMouseDown={
              (resizeHorizontal &&
                ((resizeVertical && onMouseDownEvents.nwResize) || onMouseDownEvents.wResize)) ||
              onMouseDownEvents.nResize ||
              (!resizeVertical && onMouseDownEvents.move)
            }
          />
          <div
            css={css(
              anchor ? anchorHandleStyle : resizeHandleStyle,
              css`
                cursor: ${(resizeHorizontal && ((resizeVertical && 'ne-resize') || 'e-resize')) ||
                'n-resize' ||
                (!resizeVertical && 'move')};
              `,
              anchor
                ? css`
                    right: -8px;
                    top: -8px;
                    border-bottom: 8px solid ${color};
                    border-right: 8px solid transparent;
                  `
                : css`
                    right: -4px;
                    top: -4px;
                    background: ${color};
                  `,
            )}
            role="button"
            tabIndex="-1"
            onMouseDown={
              (resizeHorizontal &&
                ((resizeVertical && onMouseDownEvents.neResize) || onMouseDownEvents.eResize)) ||
              onMouseDownEvents.nResize ||
              (!resizeVertical && onMouseDownEvents.move)
            }
          />
          <div
            css={css(
              anchor ? anchorHandleStyle : resizeHandleStyle,
              css`
                cursor: ${(resizeHorizontal && ((resizeVertical && 'sw-resize') || 'w-resize')) ||
                's-resize' ||
                (!resizeVertical && 'move')};
              `,
              anchor
                ? css`
                    left: -8px;
                    bottom: -8px;
                    border-top: 8px solid ${color};
                    border-left: 8px solid transparent;
                  `
                : css`
                    left: -4px;
                    bottom: -4px;
                    background: ${color};
                  `,
            )}
            role="button"
            tabIndex="-1"
            onMouseDown={
              (resizeHorizontal &&
                ((resizeVertical && onMouseDownEvents.swResize) || onMouseDownEvents.wResize)) ||
              onMouseDownEvents.sResize ||
              (!resizeVertical && onMouseDownEvents.move)
            }
          />
          <div
            css={css(
              anchor ? anchorHandleStyle : resizeHandleStyle,
              css`
                cursor: ${(resizeHorizontal && ((resizeVertical && 'se-resize') || 'e-resize')) ||
                's-resize' ||
                (!resizeVertical && 'move')};
              `,
              anchor
                ? css`
                    right: -8px;
                    bottom: -8px;
                    border-top: 8px solid ${color};
                    border-right: 8px solid transparent;
                  `
                : css`
                    right: -4px;
                    bottom: -4px;
                    background: ${color};
                  `,
            )}
            role="button"
            tabIndex="-1"
            onMouseDown={
              (resizeHorizontal &&
                ((resizeVertical && onMouseDownEvents.seResize) || onMouseDownEvents.eResize)) ||
              onMouseDownEvents.sResize ||
              (!resizeVertical && onMouseDownEvents.move)
            }
          />
        </>
      )}
      {!!(point || (anchor && hasSize)) && (
        <div
          css={css(
            resizeHandleStyle,
            css`
              background: ${color};
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              cursor: move;
            `,
          )}
          role="button"
          tabIndex="-1"
          onMouseDown={onMouseDownEvents.move}
        />
      )}
      {!anchor && !point && (resizeVertical || resizeHorizontal) && !resizeCornersOnly && (
        <>
          {resizeVertical && (
            <>
              <div
                css={css(
                  resizeHandleStyle,
                  css`
                    background: ${color};
                    left: 50%;
                    top: -4px;
                    transform: translateX(-50%);
                    cursor: n-resize;
                  `,
                )}
                role="button"
                tabIndex="-1"
                onMouseDown={onMouseDownEvents.nResize}
              />
              <div
                css={css(
                  resizeHandleStyle,
                  css`
                    background: ${color};
                    left: 50%;
                    bottom: -4px;
                    transform: translateX(-50%);
                    cursor: s-resize;
                  `,
                )}
                role="button"
                tabIndex="-1"
                onMouseDown={onMouseDownEvents.sResize}
              />
            </>
          )}
          {resizeHorizontal && (
            <>
              <div
                css={css(
                  resizeHandleStyle,
                  css`
                    background: ${color};
                    left: -4px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: w-resize;
                  `,
                )}
                role="button"
                tabIndex="-1"
                onMouseDown={onMouseDownEvents.wResize}
              />
              <div
                css={css(
                  resizeHandleStyle,
                  css`
                    background: ${color};
                    right: -4px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: e-resize;
                  `,
                )}
                role="button"
                tabIndex="-1"
                onMouseDown={onMouseDownEvents.eResize}
              />
            </>
          )}
        </>
      )}
    </>
  ),
);

export const EditorSelection = forwardRef(
  (
    {
      x,
      y,
      width,
      height,
      color,
      onMouseDownEvents,
      anchor,
      resizeHorizontal,
      resizeVertical,
      move,
      point,
      resizeCornersOnly,
    },
    ref,
  ) => (
    <div
      ref={ref}
      css={css(
        css`
          position: absolute;
          pointer-events: none;
          user-select: none;
        `,
        css`
          left: ${x}px;
          top: ${y}px;
          width: ${width}px;
          height: ${height}px;
        `,
      )}>
      <EditorSelectionHandles
        onMouseDownEvents={onMouseDownEvents}
        anchor={anchor}
        resizeHorizontal={resizeHorizontal}
        resizeVertical={resizeVertical}
        move={move}
        point={point}
        resizeCornersOnly={resizeCornersOnly}
        color={color}
        hasSize={width || height}
      />
    </div>
  ),
);
