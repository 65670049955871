import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import { acceptedImageFormats, acceptedVideoFormats } from '~/config';
import { PAGE_ID_SPEED_COURSE, getTemplateTypeName } from '~/utils/course';

import { getFilePreviewFilePropType, getCoursePagePropType } from '~/utils/types';
import { FormSwitch } from '~/components/FormSwitch';
import { FormSelect } from '~/components/FormSelect';
import { FilePreview } from '~/modules/Editor/components/FilePreview';
import { FormInput } from '~/components/FormInput';

const LIST_TAB = 'LIST TAB';
const SETTINGS_TAB = 'SETTINGS TAB';

const DragHandle = SortableHandle(() => <i className="icon-reorder small" />);

const SortableItem = SortableElement(({ onDeletePageClick, page, setActivePageId }) => (
  <div className="wide-pages-list__row">
    <div className="wide-pages-list__page-drag-handle">
      <DragHandle />
    </div>
    <div className="wide-pages-list__page-title-container">
      <div className="wide-pages-list__page-title-main">
        {page.id === PAGE_ID_SPEED_COURSE ? 'Speed Course' : page.title}
      </div>
      <div className="wide-pages-list__page-title-secondary">
        {getTemplateTypeName(page.templateTypeId)}
      </div>
    </div>
    <div className="wide-pages-list__page-action-buttons">
      <Button
        color="blue"
        onClick={() => {
          setActivePageId(page.id);
        }}>
        Edit
      </Button>
      <Button
        color="red"
        onClick={() => {
          onDeletePageClick(page);
        }}>
        <i className="icon-trash small" />
      </Button>
    </div>
  </div>
));

const SortableList = SortableContainer(({ onDeletePageClick, pages, setActivePageId }) => (
  <div className="wide-pages-list__container">
    {pages.map((page, index) => (
      <SortableItem
        index={index}
        key={page.id}
        onDeletePageClick={onDeletePageClick}
        page={page}
        pageNumber={index + 1}
        setActivePageId={setActivePageId}
      />
    ))}
  </div>
));

const pageTypes = {
  '1': 'Course',
  '2': 'Infographics',
};

const pageSizes = {
  '1': 'Responsive',
  '2': 'Responsive - Flex',
  '3': 'Mobile',
};

export class CourseDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: LIST_TAB,
    };

    this.uploadCoverFile = this.uploadCoverFile.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { pages, reorderPages } = this.props;
    const reorderedPages = arrayMove(pages, oldIndex, newIndex);
    reorderPages(reorderedPages);
  }

  setActiveTab(tabId) {
    this.setState({
      activeTab: tabId,
    });
  }

  uploadCoverFile([file]) {
    this.props.uploadCoverFile({ file });
  }

  render() {
    const { activeTab } = this.state;

    const {
      descriptionPage,
      pages,
      resultsPage,
      setActivePageId,
      settings,
      setSettingsPageData,
      setDescriptionPageData,
      setResultsPageData,
      switchSpeedCourse,
    } = this.props;

    const isSpeedCourseEnabled = pages.findIndex(page => page.id === PAGE_ID_SPEED_COURSE) !== -1;

    return (
      <div className="course-details-page__container">
        <div className="course-details-page__content">
          <div className="course-details-page__section">
            <div className="course-details-page__input-wrapper">
              <div className="material-input">
                <input
                  id="course-name-input"
                  onChange={({ target: { value: title } }) => {
                    setDescriptionPageData({ title });
                  }}
                  required
                  type="text"
                  value={descriptionPage.title}
                />
                <label htmlFor="course-name-input">Course Name</label>
              </div>
            </div>
            <div className="course-details-page__input-wrapper">
              <div className="material-input">
                <TextareaAutosize
                  id="course-description-input"
                  onChange={({ target: { value: description } }) => {
                    setDescriptionPageData({ description });
                  }}
                  required
                  rows={1}
                  type="textarea"
                  value={descriptionPage.description}
                />
                <label htmlFor="course-description-input">Description</label>
              </div>
            </div>

            <div className="course-details-page__section-header">Cover</div>

            {(typeof descriptionPage.cover === 'object' && (
              <div className="course-details-page__dropzone-container">
                <div className="course-details-page__dropzone-image">
                  <FilePreview file={descriptionPage.cover} asElement height={240} />
                </div>
                <div className="course-details-page__dropzone-buttons">
                  <Button color="red" onClick={() => this.props.deleteCoverFile()}>
                    Remove Cover
                  </Button>
                </div>
              </div>
            )) ||
              (descriptionPage.cover === 'UPLOADING' && (
                <div className="course-details-page__dropzone-container">
                  <div className="course-details-page__cover-file-status">
                    <p>Uploading...</p>
                  </div>
                </div>
              )) || (
                <div className="course-details-page__dropzone-container">
                  <Dropzone
                    onDrop={this.uploadCoverFile}
                    accept={[
                      ...acceptedImageFormats.map(ext => `image/${ext}`),
                      ...acceptedVideoFormats.map(ext => `video/${ext}`),
                    ].join(', ')}
                    className="course-details-page__cover-file-status">
                    {() => <p>Drop your files or click here to upload</p>}
                  </Dropzone>
                </div>
              )}
          </div>

          <div className="material-tabs__container">
            <div className="material-tabs__header">
              <div
                className={classNames('material-tabs__tab-title', {
                  active: activeTab === LIST_TAB,
                })}
                onClick={() => this.setActiveTab(LIST_TAB)}
                role="button"
                tabIndex={0}>
                Pages
              </div>

              <div
                className={classNames('material-tabs__tab-title', {
                  active: activeTab === SETTINGS_TAB,
                })}
                onClick={() => this.setActiveTab(SETTINGS_TAB)}
                role="button"
                tabIndex={0}>
                Settings
              </div>
            </div>
            <div className="course-details-page__section">
              {
                {
                  [LIST_TAB]: (
                    <>
                      <SortableList
                        pages={pages}
                        onDeletePageClick={this.props.onDeletePageClick}
                        onSortEnd={this.onSortEnd}
                        setActivePageId={setActivePageId}
                        useDragHandle
                      />
                      <div className="wide-pages-list__action-buttons">
                        <button className="btn" onClick={this.props.onAddPageClick}>
                          Add Page
                        </button>
                      </div>
                    </>
                  ),
                  [SETTINGS_TAB]: (
                    <>
                      <div
                        className="course-details-page__input-wrapper"
                        style={{marginTop: 20}}
                      >
                        <FormSelect
                          name="pageType"
                          input={{
                            value: pageTypes[settings.pageType],
                            onBlur: () => false,
                            onChange: value => {
                              setSettingsPageData({
                                pageType: Object.keys(pageTypes).filter(
                                  key => pageTypes[key] === value,
                                )[0],
                              });
                            },
                          }}
                          meta={{ touched: false, error: false }}
                          data={Object.keys(pageTypes).map(ps => pageTypes[ps])}
                          label="Page type"
                        />
                      </div>
                      <div className="course-details-page__input-wrapper">
                        <FormSelect
                          name="pageSizing"
                          input={{
                            value: pageSizes[settings.pageSizing],
                            onBlur: () => false,
                            onChange: value => {
                              setSettingsPageData({
                                pageSizing: Object.keys(pageSizes).filter(
                                  key => pageSizes[key] === value,
                                )[0],
                              });
                            },
                          }}
                          meta={{ touched: false, error: false }}
                          data={Object.keys(pageSizes).map(ps => pageSizes[ps])}
                          label="Page sizing"
                        />
                      </div>
                      {settings.pageType === '1' && (
                        <>
                      <div className="course-details-page__input-wrapper">
                        <FormSwitch
                          input={{
                            value: !descriptionPage.disabled,
                            onChange: () => {
                              setDescriptionPageData({ disabled: !descriptionPage.disabled });
                            },
                          }}
                          label="Description page"
                        />
                      </div>
                      <div className="course-details-page__input-wrapper">
                        <FormSwitch
                          input={{
                            value: settings.informAnswer,
                            onChange: () => {
                              setSettingsPageData({ informAnswer: !settings.informAnswer });
                            },
                          }}
                          label="Inform about correct answer"
                        />
                      </div>

                      <div className="course-details-page__input-wrapper">
                        <FormSwitch
                          input={{
                            value: settings.winstreak,
                            onChange: () => {
                              setSettingsPageData({ winstreak: !settings.winstreak });
                            },
                          }}
                          label="Winstreak"
                        />
                      </div>

                      <div className="course-details-page__input-wrapper">
                        <FormSwitch
                          input={{
                            value: !resultsPage.disabled,
                            onChange: () => {
                              setResultsPageData({ disabled: !resultsPage.disabled });
                            },
                          }}
                          label="Results page"
                        />
                      </div>

                      <div className="course-details-page__input-wrapper">
                        <FormSwitch
                          input={{
                            value: isSpeedCourseEnabled,
                            onChange: () => {
                              switchSpeedCourse();
                            },
                          }}
                          label="Speed Course"
                        />
                      </div>

                      <div className="course-details-page__input-wrapper">
                        <div className="material-input">
                          <TextareaAutosize
                            id="text-when-passed"
                            rows={1}
                            type="textarea"
                            onChange={({ target: { value: textWhenPassed } }) => {
                              setResultsPageData({ textWhenPassed });
                            }}
                            required
                            value={resultsPage.textWhenPassed}
                          />
                          <label htmlFor="text-when-passed">Text when passed</label>
                        </div>
                      </div>

                      <div className="course-details-page__input-wrapper">
                        <FormInput
                          type="number"
                          input={{
                            value: settings.passingScore,
                            onChange: ({ target: { value: passingScore } }) => {
                              setSettingsPageData({ passingScore });
                            },
                          }}
                          label="Passing procesent % (0 always pass)"
                        />
                      </div>

                      <div className="course-details-page__section-header">Result texts</div>
                      <div className="course-details-page__points-section">
                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <FormInput
                              id="course-points-input"
                              type="number"
                              input={{
                                value: resultsPage.points1,
                                onChange: ({ target: { value: points1 } }) => {
                                  setResultsPageData({ points1 });
                                },
                              }}
                              label="Show text when user answers more than % correct"
                            />
                          </div>
                        </div>

                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <TextareaAutosize
                              id="text-when-1"
                              rows={1}
                              type="textarea"
                              onChange={({ target: { value: textWhen1 } }) => {
                                setResultsPageData({ textWhen1 });
                              }}
                              required
                              value={resultsPage.textWhen1}
                            />
                            <label htmlFor="text-when-1">Text when..</label>
                          </div>
                        </div>
                      </div>

                      <div className="course-details-page__points-section">
                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <FormInput
                              id="points-2"
                              type="number"
                              input={{
                                value: resultsPage.points2,
                                onChange: ({ target: { value: points2 } }) => {
                                  setResultsPageData({ points2 });
                                },
                              }}
                              label="Show text when user answers more than % correct"
                            />
                          </div>
                        </div>

                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <TextareaAutosize
                              id="text-when-2"
                              rows={1}
                              type="textarea"
                              onChange={({ target: { value: textWhen2 } }) => {
                                setResultsPageData({ textWhen2 });
                              }}
                              required
                              value={resultsPage.textWhen2}
                            />
                            <label htmlFor="text-when-2">Text when..</label>
                          </div>
                        </div>
                      </div>

                      <div className="course-details-page__points-section">
                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <FormInput
                              id="points-3"
                              type="number"
                              input={{
                                value: resultsPage.points3,
                                onChange: ({ target: { value: points3 } }) => {
                                  setResultsPageData({ points3 });
                                },
                              }}
                              label="Show text when user answers more than % correct"
                            />
                          </div>
                        </div>

                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <TextareaAutosize
                              id="text-when-3"
                              rows={1}
                              type="textarea"
                              onChange={({ target: { value: textWhen3 } }) => {
                                setResultsPageData({ textWhen3 });
                              }}
                              required
                              value={resultsPage.textWhen3}
                            />
                            <label htmlFor="text-when-3">Text when..</label>
                          </div>
                        </div>
                      </div>

                      <div className="course-details-page__points-section">
                        <div className="course-details-page__input-wrapper">
                          <div className="material-input">
                            <TextareaAutosize
                              id="text-when-else"
                              rows={1}
                              type="textarea"
                              onChange={({ target: { value: textWhenElse } }) => {
                                setResultsPageData({ textWhenElse });
                              }}
                              required
                              value={resultsPage.textWhenElse}
                            />
                            <label htmlFor="text-when-else">Text else...</label>
                          </div>
                        </div>
                      </div>
                          </>
                      )}
                    </>
                  ),
                }[activeTab]
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CourseDetailsPage.propTypes = {
  deleteCoverFile: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    winstreak: PropTypes.bool,
    informAnswer: PropTypes.bool,
    pageSizing: PropTypes.string,
  }),
  descriptionPage: PropTypes.shape({
    disabled: PropTypes.bool,
    info: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    cover: getFilePreviewFilePropType(),
  }).isRequired,
  onAddPageClick: PropTypes.func.isRequired,
  onDeletePageClick: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(getCoursePagePropType()).isRequired,
  reorderPages: PropTypes.func.isRequired,
  resultsPage: PropTypes.shape({
    disabled: PropTypes.bool,
    points: PropTypes.bool,
    points1: PropTypes.number,
    points2: PropTypes.number,
    points3: PropTypes.number,
    textWhen1: PropTypes.string,
    textWhen2: PropTypes.string,
    textWhen3: PropTypes.string,
  }).isRequired,
  setActivePageId: PropTypes.func.isRequired,
  setDescriptionPageData: PropTypes.func.isRequired,
  setSettingsPageData: PropTypes.func.isRequired,
  setResultsPageData: PropTypes.func.isRequired,
  switchSpeedCourse: PropTypes.func.isRequired,
  uploadCoverFile: PropTypes.func.isRequired,
};

CourseDetailsPage.defaultProps = {
  courseDescription: '',
  courseName: '',
  settings: {
    winstreak: false,
    pageSizing: 'Responsive',
  },
};
