import { css } from '@emotion/core';
const width = 24;
export const btn = css(`
  width: ${width / 2.5}px;
  height: ${width / 2.5}px;
  border-radius: ${width / 2.5}px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid rgba(88,86,214,.5);
  background-color: rgba(88,86,214,.5);
`);
export const btnActive = css(`
  border: 1px solid rgba(88,86,214,1);
  background-color: rgba(88,86,214,1);
`);

export const pagesSection = css(`
  background-color: rgba(88,86,214,.5);
  border: 1px solid #5856D6;
  svg{color:#5856D6};
  min-width: ${width}px;
  border-radius: ${width}px;
  height: ${width}px;
  display:flex;

  justify-content: center;
  align-content: center;
  align-items: center;
`);

export const wrapper = css(`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`);

export const settingsBtn = css(`
  position:absolute;
  cursor: pointer;
  right: 0;
  padding-top:0;
  line-height: 1.2;
  top: 0;
  width: ${width}px;
  height: ${width}px;
  border-radius: ${width}px;
  background-color: rgba(88,86,214,.5);
  border: 1px solid #5856D6;
  svg{color:#5856D6}
  :hover {
    background-color: rgba(88,86,214,.7);
  }
`);

export const settingsBtnActive = css(`
  background-color: rgba(88,86,214,1);
  border: 1px solid rgba(88,86,214,.5);
  svg{color:white}
`);
