import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenSizes } from '../../../views/EditorMain';
import { getPageSize } from '../../../stores/editor/selectors/course.selectors';
import { setScreenSize } from '~/stores/editor/actions/course.actions';
import { Slider } from './Slider';

export const PageScaleInput = ({ viewportScale }) => {
  const pageSize = useSelector(getPageSize);
  const maxValue = Object.keys(ScreenSizes).length - 1;
  const selectedScreenSize = ScreenSizes[pageSize];
  const [value, setValue] = useState(pageSize);

  const onChange = useCallback(value => {
    setValue(value);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (pageSize !== Math.round(value)) {
      dispatch(setScreenSize(Math.round(value)));
    }
  }, [value, pageSize, dispatch]);

  return (
    <>
      <Slider
        value={value}
        minValue={0}
        maxValue={maxValue}
        step={1}
        length={160}
        onChange={onChange}
      />
      <Label>
        {selectedScreenSize.w} x {selectedScreenSize.h} ({(viewportScale * 100).toFixed(0)}%)
      </Label>
    </>
  );
};

const Label = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  line-height: 1.5;
  white-space: nowrap;
  color: #ccc;
  overflow: hidden;
  user-select: none;
`;
