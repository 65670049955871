import PropTypes from 'prop-types';
import { createSlide } from '../modules/Editor/components/create-slide';
import { Content } from '../modules/Editor/components/Content/Content';

import * as publicationStatus from '~/utils/publication-status';
import { getIdForNextElement } from '~/utils/common';

import { PictureSlide } from '~/modules/Editor/components/PictureSlides/PictureSlide';
import { Questionnaire as QuestionnaireEditor } from '~/modules/Editor/components/Questionnaire/Questionnaire';
import { QuestionnaireInput as QuestionnaireInputEditor } from '~/modules/Editor/components/QuestionnaireInput/QuestionnaireInput';
import { Paragraph as ParagraphEditor } from '~/modules/Editor/components/Paragraph/Paragraph';
import { Dilemma as DilemmaEditor } from '~/modules/Editor/components/Dilemma/Dilemma';
import { Video as VideoEditor } from '~/modules/Editor/components/Video/Video';
import { Picture as PictureEditor } from '~/modules/Editor/components/Picture/Picture';

import { PictureSlides as PictureSlidesPlayer } from '~/modules/Player/components/PictureSlides/PictureSlides';
import { PicturesQuestionnaire as PicturesQuestionnairePlayer } from '~/modules/Player/components/PicturesQuestionnaire';
import { Questionnaire as QuestionnairePlayer } from '~/modules/Player/components/Questionnaire';
import { QuestionnaireInput as QuestionnaireInputPlayer } from '~/modules/Player/components/QuestionnaireInput';
import { Paragraph as ParagraphPlayer } from '~/modules/Player/components/Paragraph';
import { Video as VideoPlayer } from '~/modules/Player/components/Video';
import { Picture as PicturePlayer } from '~/modules/Player/components/Picture';
import { Dilemma as DilemmaPlayer } from '~/modules/Player/components/Dilemma';

export const PAGE_ID_DESCRIPTION = -1;
export const PAGE_ID_LOADING = 'LOADING_PAGE';
export const PAGE_ID_SPEED_COURSE = 'SPEED_COURSE';
export const PAGE_ID_RESULTS = 'RESULTS_PAGE';
export const PAGE_ID_FINISHED = 'FINISHED_PAGE';
export const PAGE_ID_RUNTIME_ERROR = 'RUNTIME_ERROR_PAGE';
export const PAGE_ID_NO_PAGES = 'NO_PAGES_PAGE';

export const PAGE_STATUS_INACTIVE = 'INACTIVE';
export const PAGE_STATUS_ENTERING = 'ENTERING';
export const PAGE_STATUS_ACTIVE = 'ACTIVE';
export const PAGE_STATUS_LEAVING = 'LEAVING';

export const SAVE_STATUS_UPLOADING = 'UPLOADING';
export const SAVE_STATUS_SAVED = 'SAVED';
export const SAVE_STATUS_FAILED = 'FAILED';
export const SAVE_STATUS_DIRTY = 'DIRTY';

export const PICTURESLIDES = 'PICTURESLIDES COMPONENT';
export const PICTURESQUESTIONNAIRE = 'PICTURESQUESTIONNAIRE COMPONENT';
export const QUESTIONNAIRE = 'QUESTIONNAIRE COMPONENT';
export const QUESTIONNAIREINPUT = 'QUESTIONNAIRE INPUT COMPONENT';

export const CONTENT = 'CONTENT COMPONENT';
export const TEXT = 'TEXT COMPONENT';
export const VIDEO = 'VIDEO COMPONENT';
export const DILEMMA = 'DILEMMA COMPONENT';
export const PICTURE = 'PICTURE COMPONENT';

export const TEMPLATE_DILEMMA = 'TEXT-DILEMMA';
export const TEMPLATE_CONTENT = 'TEXT-CONTENT';
export const TEMPLATE_QUESTIONNAIRE = 'TEXT-QUESTIONNAIRE';
export const TEMPLATE_PICTURESLIDES = 'PICTURESLIDES-TEXT';
export const TEMPLATE_VIDEO = 'TEXT-VIDEO';
export const TEMPLATE_PICTUREQUESTIONNAIRE = 'TEXT-PICTURESQUESTIONNAIRE';

let pageTypes;

export const getPagesTypes = () => pageTypes;

export const setPagesTypes = newPageTypes => {
  pageTypes = newPageTypes;
  return pageTypes;
};

export const getEmptyCourseData = () => ({
  activePageId: PAGE_ID_DESCRIPTION,
  description: '',
  name: '',
  pages: [],
  publicationStatus: publicationStatus.PUBLICATION_STATUS_NOT_PUBLISHED,
});

export const getPageSections = pageTypeId => {
  const pageType = getPagesTypes().find(ipageType => ipageType.id === pageTypeId);
  if (!pageType || !pageType.sections) {
    return [];
  }
  return pageType.sections;
};

export const getParsedCourseData = data => {
  const { pages, descriptionPage, speedCourseSettings = {} } = data;

  return {
    ...data,
    descriptionPage: {
      ...descriptionPage,
      cover: descriptionPage.cover === 'UPLOADING' ? undefined : descriptionPage.cover,
      title: descriptionPage.title || '',
      description: descriptionPage.description || '',
    },
    speedCourseSettings: {
      ...descriptionPage,
      cover: speedCourseSettings.cover === 'UPLOADING' ? undefined : speedCourseSettings.cover,
      title: speedCourseSettings.title || '',
      description: speedCourseSettings.description || '',
      useRandomize:
        typeof speedCourseSettings.useRandomize === 'boolean'
          ? speedCourseSettings.useRandomize
          : null,
      useQuestionPool:
        typeof speedCourseSettings.useQuestionPool === 'boolean'
          ? speedCourseSettings.useQuestionPool
          : null,
      questionPoolSize:
        typeof speedCourseSettings.questionPoolSize === 'number'
          ? speedCourseSettings.questionPoolSize
          : null,
      timeToReadQuestionSec:
        typeof speedCourseSettings.timeToReadQuestionSec === 'number'
          ? speedCourseSettings.timeToReadQuestionSec
          : null,
      timeToAnswerQuestionSec:
        typeof speedCourseSettings.timeToAnswerQuestionSec === 'number'
          ? speedCourseSettings.timeToAnswerQuestionSec
          : null,
      pointsMaxScore:
        typeof speedCourseSettings.pointsMaxScore === 'number'
          ? speedCourseSettings.pointsMaxScore
          : null,
      pointsPerAnswer:
        typeof speedCourseSettings.pointsPerAnswer === 'number'
          ? speedCourseSettings.pointsPerAnswer
          : null,
    },
    pages: pages.map(page =>
      page.id === PAGE_ID_SPEED_COURSE
        ? page
        : getPageSections(page.templateTypeId).reduce(
            (parsedPage, { dataKey, componentName }) => {
              switch (componentName) {
                case PICTURESLIDES:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      slides: parsedPage[dataKey].slides.map(slide =>
                        slide.image !== 'UPLOADING'
                          ? slide
                          : {
                              ...slide,
                              image: undefined,
                            },
                      ),
                    },
                  };
                case CONTENT:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      files: parsedPage[dataKey].files || [],
                      redirections: parsedPage[dataKey].redirections || {},
                      timeBounds: parsedPage[dataKey].timeBounds || { customTimeBounds: false },
                    },
                  };
                case QUESTIONNAIRE:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      redirections: parsedPage[dataKey].redirections || {},
                      timeBounds: parsedPage[dataKey].timeBounds || { customTimeBounds: false },
                    },
                  };
                case QUESTIONNAIREINPUT:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      redirections: parsedPage[dataKey].redirections || {},
                      timeBounds: parsedPage[dataKey].timeBounds || { customTimeBounds: false },
                    },
                  };
                case PICTURESQUESTIONNAIRE:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      answers: parsedPage[dataKey].answers.map(answer =>
                        answer.image !== 'UPLOADING'
                          ? answer
                          : {
                              ...answer,
                              image: undefined,
                            },
                      ),
                      redirections: parsedPage[dataKey].redirections || {},
                      timeBounds: parsedPage[dataKey].timeBounds || { customTimeBounds: false },
                    },
                  };
                case VIDEO:
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      video: {
                        ...parsedPage[dataKey].video,
                        file:
                          parsedPage[dataKey].video.file === 'UPLOADING'
                            ? undefined
                            : parsedPage[dataKey].video.file,
                        poster:
                          parsedPage[dataKey].video.poster === 'UPLOADING'
                            ? undefined
                            : parsedPage[dataKey].video.poster,
                      },
                    },
                  };
                case PICTURE: {
                  let file;
                  if (parsedPage[dataKey]) {
                    file =
                      parsedPage[dataKey].file === 'UPLOADING'
                        ? undefined
                        : parsedPage[dataKey].file;
                  }
                  return {
                    ...parsedPage,
                    [dataKey]: {
                      ...parsedPage[dataKey],
                      file,
                    },
                  };
                }
                default:
                  return parsedPage;
              }
            },
            { ...page },
          ),
    ),
  };
};

export const getTemplateTypeName = templateTypeId => {
  if (!templateTypeId) {
    return '';
  }

  const pagesTypesNames = {
    'PICTURESLIDES-TEXT': 'Content slides',
    'TEXT-QUESTIONNAIRE': 'Questionnaire',
    'INPUT-QUESTIONNAIRE': 'User input',
    'TEXT-DILEMMA': 'Dilemma',
    'TEXT-CONTENT': 'Content',
    'TEXT-VIDEO': 'Video',
    'TEXT-PICTURESQUESTIONNAIRE': 'Questionnaire, Pictures',
  };

  return pagesTypesNames[templateTypeId];
};

export const getTemplateTypeMiniatureUrl = templateTypeId => {
  if (!templateTypeId) {
    return '';
  }
  const templateType = getPagesTypes().find(pageType => pageType.id === templateTypeId);
  if (!templateType?.miniatureFileName) {
    return '';
  }
  return `${process.env.REACT_APP_STATICS_ROOT}static/images/nano/templates-miniatures/${templateType.miniatureFileName}`;
};

export const getEditorSectionComponent = componentName => {
  switch (componentName) {
    case PICTURESLIDES:
      return {
        component: PictureSlide,
        defaultParams: {},
      };
    case PICTURESQUESTIONNAIRE:
      return {
        component: QuestionnaireEditor,
        defaultParams: {
          isPicturesQuestionnaire: true,
        },
      };
    case QUESTIONNAIRE:
      return {
        component: QuestionnaireEditor,
        defaultParams: {
          isPicturesQuestionnaire: false,
        },
      };
    case QUESTIONNAIREINPUT:
      return {
        component: QuestionnaireInputEditor,
        defaultParams: {},
      };
    case CONTENT:
      return {
        component: Content,
        defaultParams: {},
      };
    case DILEMMA:
      return {
        component: DilemmaEditor,
        defaultParams: {},
      };
    case TEXT:
      return {
        component: ParagraphEditor,
        defaultParams: {},
      };
    case VIDEO:
      return {
        component: VideoEditor,
        defaultParams: {},
      };
    case PICTURE:
      return {
        component: PictureEditor,
        defaultParams: {},
      };
    default:
      return 'No section of this type';
  }
};

export const getPlayerSectionComponent = componentName => {
  switch (componentName) {
    case PICTURESLIDES:
      return PictureSlidesPlayer;
    case PICTURESQUESTIONNAIRE:
      return PicturesQuestionnairePlayer;
    case QUESTIONNAIRE:
      return QuestionnairePlayer;
    case QUESTIONNAIREINPUT:
      return QuestionnaireInputPlayer;
    case TEXT:
      return ParagraphPlayer;
    case VIDEO:
      return VideoPlayer;
    case PICTURE:
      return PicturePlayer;
    case DILEMMA:
      return DilemmaPlayer;
    case CONTENT:
      return Content;
    default:
      return 'No section of this type';
  }
};

export const getPlayerShouldDisplayBottomStatusBar = pageTemplateName => {
  switch (pageTemplateName) {
    case TEMPLATE_CONTENT:
      return true;
    case TEMPLATE_QUESTIONNAIRE:
      return false;
    case TEMPLATE_PICTUREQUESTIONNAIRE:
      return true;
    case TEMPLATE_PICTURESLIDES:
      return false;
    case TEMPLATE_VIDEO:
      return true;
    case TEMPLATE_DILEMMA:
      return false;
    default:
      return true;
  }
};

export const getPlayerShouldDisplayTopStatusBar = pageTemplateName => {
  switch (pageTemplateName) {
    case TEMPLATE_CONTENT:
      return true;
    case TEMPLATE_QUESTIONNAIRE:
      return true;
    case TEMPLATE_PICTUREQUESTIONNAIRE:
      return true;
    case TEMPLATE_PICTURESLIDES:
      return true;
    case TEMPLATE_VIDEO:
      return true;
    case TEMPLATE_DILEMMA:
      return true;
    default:
      return true;
  }
};

export const getTopStatusBarVariant = pageTemplateName => {
  switch (pageTemplateName) {
    case TEMPLATE_QUESTIONNAIRE:
      return 'FULLSCREEN';
    default:
      return 'CENTERED';
  }
};

export const getSectionComponentInitialValues = componentName => {
  switch (componentName) {
    case PICTURESLIDES:
      return {
        slides: [createSlide()],
      };
    case PICTURESQUESTIONNAIRE:
      return {
        question: '',
        answers: [
          {
            id: getIdForNextElement([]),
            isCorrect: true,
          },
        ],
        settings: {
          dndSelect: false,
          pointsEnabled: true,
          pointsPerAnswer: 1,
          pointsMaxScore: 1,
        },
        texts: {
          textWhenGood: '',
          textWhenWrong: '',
        },
        redirections: {},
        timeBounds: { customTimeBounds: false },
      };
    case DILEMMA:
      return {
        answer: {
          text: 'Write a long topic',
        },
        question: {
          text: 'Write your dilemma',
          attr: {},
        },
      };
    case QUESTIONNAIRE:
      return {
        question: '',
        answers: [
          {
            id: getIdForNextElement([]),
            isCorrect: true,
            text: '',
          },
        ],
        settings: {
          pointsEnabled: true,
          pointsPerAnswer: 1,
          pointsMaxScore: 1,
        },
        texts: {
          textWhenGood: '',
          textWhenWrong: '',
        },
        redirections: {},
        timeBounds: { customTimeBounds: false },
      };
    case QUESTIONNAIREINPUT:
      return {
        question: '',
        answers: [
          {
            id: getIdForNextElement([]),
            isCorrect: true,
            text: '',
          },
        ],
        input: [
          {
            type: 0,
            range: [0, 5],
          },
        ],
        texts: {
          textWhenGood: '',
          textWhenWrong: '',
        },
        redirections: {},
      };
    case CONTENT:
      return {
        htmlValue: '',
        files: [],
      };

    case TEXT:
      return {
        isVisible: false,
        defaultValue: 'Skriv inn tekst',
        paragraphValue: '',
      };
    case VIDEO:
      return {
        video: {
          autoPlay: false,
          file: undefined,
          meta: {
            title: '',
            subtitle: '',
          },
        },
      };
    case PICTURE:
      return {
        file: undefined,
      };
    default:
      return {};
  }
};

export const getPageInitialValues = ({ id, templateTypeId }) => {
  const pageType = getPagesTypes().find(i => i.id === templateTypeId);
  if (!pageType) {
    return {};
  }
  const newPage = {
    id,
    templateTypeId,
    title: `${getTemplateTypeName(templateTypeId)} Page`,
    ...pageType.sections.reduce(
      (sectionsComponentsInitialData, section) => ({
        ...sectionsComponentsInitialData,
        [section.dataKey]: getSectionComponentInitialValues(section.componentName),
      }),
      {},
    ),
  };
  return newPage;
};

export const getTemplateTypeShape = () =>
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        componentName: PropTypes.oneOf([
          PICTURESLIDES,
          PICTURESQUESTIONNAIRE,
          QUESTIONNAIRE,
          CONTENT,
          TEXT,
          VIDEO,
        ]).isRequired,
        dataKey: PropTypes.string.isRequired,
      }),
    ),
  });

export const getRedirectPages = (currentPage, allPages) => [
  { id: null },
  ...allPages.filter(p => p.id !== currentPage.id),
];

export const getRedirectPageId = (currentPage, allPages) =>
  allPages.find(p => p.id === currentPage.redirectPageId);
