import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FilePreview } from '~/modules/Player/components/FilePreview';
import { getFilePreviewFilePropType } from '~/utils/types';

export const RadioSelect = ({
  disabled,
  groupName,
  selectingImages,
  onChange,
  options,
  selectedOptionId,
}) => (
  <div className={classNames({ 'radio-images-container': selectingImages })}>
    {options.map(option => (
      <div
        className={classNames({
          'radio-image': selectingImages,
          'md-radio': !selectingImages,
        })}
        key={option.id}>
        <input
          checked={selectedOptionId === option.id}
          disabled={disabled}
          id={`${groupName}-${option.id}`}
          name={groupName}
          onChange={() => onChange(option.id)}
          type="radio"
        />
        <label htmlFor={`${groupName}-${option.id}`}>
          {selectingImages ? (
            <div className="radio-image__file-preview-container">
              <FilePreview file={option.image} cover />
            </div>
          ) : (
            option.label
          )}
        </label>
      </div>
    ))}
  </div>
);

RadioSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: getFilePreviewFilePropType(),
      }),
    ]),
  ).isRequired,
  selectedOptionId: PropTypes.number,
  selectingImages: PropTypes.bool,
};

RadioSelect.defaultProps = {
  disabled: false,
  selectedOptionId: undefined,
  selectingImages: false,
};
