const prefix = 'config';

export const CONFIG_GET_CONFIG = `${prefix} get learning portal config`;
export const CONFIG_GET_CONFIG_REQUEST = `${prefix} get learning portal config request`;
export const CONFIG_GET_CONFIG_SUCCESS = `${prefix} get learning portal config success`;
export const CONFIG_GET_CONFIG_FAILURE = `${prefix} get learning portal config failure`;

export const configGetConfig = () => ({
  type: CONFIG_GET_CONFIG,
});

export const configGetConfigRequest = () => ({
  type: CONFIG_GET_CONFIG_REQUEST,
});

export const configGetConfigSuccess = payload => ({
  type: CONFIG_GET_CONFIG_SUCCESS,
  payload,
});

export const configGetConfigFailure = payload => ({
  type: CONFIG_GET_CONFIG_FAILURE,
  payload,
});
