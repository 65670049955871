import React from 'react';
import PropTypes from 'prop-types';
import { Header, Image, Modal } from 'semantic-ui-react';

import { getTemplateTypeName, getTemplateTypeMiniatureUrl, getPagesTypes } from '~/utils/course';

const courseMiniature = ({ miniatureIndex, onTemplateTypeSelect, pageType }) => {
  const title = getTemplateTypeName(pageType.id);
  const imageUrl = getTemplateTypeMiniatureUrl(pageType.id);
  const onClick = () => {
    onTemplateTypeSelect(pageType.id);
  };

  return (
    <div
      className="course-miniature__container"
      key={pageType.id}
      onClick={onClick}
      role="button"
      tabIndex={miniatureIndex}>
      <div className="course-miniature__image">
        <Image src={imageUrl} />
      </div>
      <div className="course-miniature__title">
        <span>{title}</span>
      </div>
    </div>
  );
};

courseMiniature.propTypes = {
  miniatureIndex: PropTypes.number.isRequired,
  onTemplateTypeSelect: PropTypes.func.isRequired,
  pageType: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
};

export const CourseTemplateSelectionModal = ({
  isAddPageModalOpen,
  onAddPageModalCloseClick,
  onTemplateTypeSelect,
}) => (
  <Modal
    basic
    mountNode={document.querySelector('#modal-root')}
    onClose={onAddPageModalCloseClick}
    open={isAddPageModalOpen}
    size="small">
    <Header content="Select Template" />
    <Modal.Content className="course-miniatures__wrapper">
      <div className="course-miniatures__container">
        {getPagesTypes().map((pageType, index) =>
          courseMiniature({
            miniatureIndex: index,
            onTemplateTypeSelect,
            pageType,
          }),
        )}
      </div>
    </Modal.Content>
    <Modal.Actions className="course-miniatures__action-buttons-container">
      <button className="btn btn-blue" onClick={onAddPageModalCloseClick}>
        Cancel
      </button>
    </Modal.Actions>
  </Modal>
);

CourseTemplateSelectionModal.propTypes = {
  isAddPageModalOpen: PropTypes.bool.isRequired,
  onAddPageModalCloseClick: PropTypes.func.isRequired,
  onTemplateTypeSelect: PropTypes.func.isRequired,
};
