import { assetDefinitions } from './definitions/asset-definitions';

let inputElement;
let currentCleanupTimeout;

export const onAssetUpload = (type, setValueCallback) => () => {
  if (!assetDefinitions[type]) {
    console.error('No asset definition for', type);
    console.error('No asset definition for', type);
    return;
  }
  const { accept, onUpload } = assetDefinitions[type];

  if (inputElement) {
    inputElement.remove();
  }

  const element = document.createElement('input');
  inputElement = element;

  element.type = 'file';
  element.accept = accept;

  if (currentCleanupTimeout) {
    clearTimeout(currentCleanupTimeout);
  }

  // Passive cleanup function so the input element doesn't stay with us forever
  const cleanupTimeout = setTimeout(() => {
    const onClick = () => {
      element.remove();
      document.removeEventListener('click', onClick, true);
    };

    document.addEventListener('click', onClick, true);
  }, 10000);
  currentCleanupTimeout = cleanupTimeout;

  const onChange = e => {
    if (e.target.files.length && e.target.value) {
      const filename = e.target.value.replace(/^.*?([^/\\]*)$/, '$1');
      onUpload(e.target.files[0], setValueCallback, filename);
      element.remove();
      clearTimeout(cleanupTimeout);
    }
  };
  element.addEventListener('change', onChange, false);

  element.dataset.modal = true;
  const { style } = element;
  style.display = 'none';

  document.body.append(element);

  element.click();
};
