import React, { useContext, useCallback, useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import { useActions } from '../hooks/use-actions';
import { usePosition } from '../hooks/use-position';
import { SceneContext } from '../SceneContext';
import { ContentEditable } from '../../../components/ContentEditable';
import { getLuminance } from 'polished';
import { sizeOptions } from '../fields/size-options';

export const ActionDot = ({
  className,
  id,
  item: {
    editing,
    events,
    data: { text, color, type, borderRadius, size },
  },
}) => {
  const { editor, itemRefs, updateItem } = useContext(SceneContext);
  const { style, width, height } = usePosition(id);
  const { onClick, onMouseEnter, onMouseLeave, onMouseDown, onDoubleClick } = useActions(id);
  const init = useRef(false);

  const onChange = useCallback(
    value => {
      updateItem(id, {
        name: type === 'button' ? `Knapp: ${value}` : `Punkt ${value}`,
        data: { text: value },
      });
    },
    [id, type, updateItem],
  );

  useEffect(() => {
    if (editor) {
      onChange(text);
    }
  }, [editor, onChange, text]);

  useEffect(() => {
    if (editor && type !== 'button' && width !== height) {
      updateItem(id, {
        position: {
          width: 0,
          height: 0,
          widthDelta: height,
          heightDelta: height,
        },
      });
    }
  }, [editor, onChange, width, height, updateItem, id, type]);

  useEffect(() => {
    if (itemRefs[id] && editing) {
      itemRefs[id].focus();
      if (!init.current) {
        // Select all text when created with {editing: true}.
        // Remove if-statement to always select all text when editing.
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(itemRefs[id]);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
    init.current = true;
  }, [editing, id, init, itemRefs]);

  return (
    <ContentEditable
      className={className}
      editable={editing}
      multiline={true}
      css={css(
        style,
        css`
          white-space: pre;
          line-height: 0;
          font-size: ${type === 'button'
            ? (sizeOptions.find(({ value }) => value === size) ?? sizeOptions[1])?.size
            : width / Math.pow((text?.length || 1) * 1.4, 0.7)}px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: ${type === 'button' ? `${borderRadius}px` : '50%'};
          background-color: ${color};
          color: ${getLuminance(color) < 0.6 ? '#fff' : '#000'};
          cursor: pointer;

          > div {
            width: 100%;
            height: 100%;
          }
        `,
      )}
      ref={current => {
        if (current) {
          itemRefs[id] = current;
        }
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
      onChange={onChange}
      content={text}
      role={editor ? 'button' : undefined}
      tabIndex={editor ? '0' : '-1'}
    />
  );
};
