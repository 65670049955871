import { getIdForNextElement } from '../../../utils/common';

export const createSlide = () => ({
  backgroundType: 'Flow',
  justifyContent: 'Center',
  title: '',
  subtitle: '',
  subtitle_properties: {
    align: 'left',
    textColor: 'black',
    font: {
      size: 14,
      family: '',
      attrib: '',
    },
    animation: { type: '', delay: 0, outType: '', outDelay: 0 },
    background: {
      color: '',
      alpha: 100,
      type: '',
      border: {
        color: '',
        radius: 0,
        width: 0,
      },
    },
  },
  title_properties: {
    align: 'left',
    textColor: 'black',
    font: {
      size: 24,
      family: 'Default',
      attrib: 'Regular',
    },
    background: {
      color: '',
      alpha: 100,
      type: '',
      border: {
        color: '',
        radius: 0,
        width: 0,
      },
    },
  },
  id: getIdForNextElement(),
  image: undefined,
});
