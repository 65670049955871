import React, { useContext, useCallback, useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import { useActions } from '../hooks/use-actions';
import { usePosition } from '../hooks/use-position';
import { SceneContext } from '../SceneContext';
import { ContentEditable } from '../../../components/ContentEditable';
import { maxStringLength } from '../../../utils/max-string-length';
import { sizeOptions } from '../fields/size-options';

export const flexAlignOptions = {
  left: 'flex-start',
  top: 'flex-start',
  center: 'center',
  right: 'flex-end',
  bottom: 'flex-end',
};

export const TextElement = ({
  className,
  id,
  item: {
    editing,
    data: {
      text,
      bold,
      italic,
      size,
      padding,
      align = 'left',
      verticalAlign = 'top',
      color,
      bgColor,
      unorderedList,
    },
  },
}) => {
  const { editor, itemRefs, updateItem, dynamicLayout } = useContext(SceneContext);
  const { style } = usePosition(id);
  const { onClick, onMouseEnter, onMouseLeave, onMouseDown, onDoubleClick } = useActions(id);
  const init = useRef(false);

  const onChange = useCallback(
    value => {
      const data = {
        name: maxStringLength(16, value.split('\n')[0]),
        data: { text: value },
      };
      if (dynamicLayout && itemRefs[id]) {
        data.position = {
          heightDelta: itemRefs[id].clientHeight,
        };
      }
      updateItem(id, data);
    },
    [dynamicLayout, id, itemRefs, updateItem],
  );

  useEffect(() => {
    // Reset text if it gets deselected with no text so we don't lose it
    //   alternatively we could just remove the element from the scene
    if (editor && !editing && !text) {
      const text = 'Sample Text';
      updateItem(id, {
        name: text,
        data: {
          text,
        },
      });
    }
  }, [editing, editor, id, text, updateItem]);

  useEffect(() => {
    if (editing && itemRefs[id]) {
      itemRefs[id].focus();
      if (!init.current) {
        // Select all text when created with {editing: true}.
        // Remove if-statement to always select all text when editing.
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(itemRefs[id]);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
    init.current = true;
  }, [editing, id, itemRefs]);

  return (
    <ContentEditable
      className={className}
      editable={editing}
      editing={editing}
      multiline
      css={css(
        style,
        css`
          line-height: 1;
          font-size: ${(sizeOptions.find(({ value }) => value === size) ?? sizeOptions[1])?.size};
          text-align: ${align};
        `,
        !dynamicLayout
          ? css`
              display: flex;
              overflow: visible;
              justify-content: ${flexAlignOptions[align]};
              align-items: ${flexAlignOptions[verticalAlign]};
            `
          : css`
              height: auto;
            `,
        padding &&
          css`
            padding: ${padding.map(p => p + 'px ')};
          `,
        color &&
          css`
            color: ${color};
          `,
        bgColor &&
          css`
            background: ${bgColor};
          `,
        bold &&
          css`
            font-weight: bold;
          `,
        italic &&
          css`
            font-style: italic;
          `,
      )}
      ref={current => {
        if (current) {
          itemRefs[id] = current;
        }
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
      onChange={onChange}
      unorderedList={unorderedList}
      content={text}
      tabIndex={editor ? '0' : '-1'}
      hover={false}
    />
  );
};
