import { useContext, useLayoutEffect } from 'react';
import { SceneContext } from './SceneContext';

export const SceneSizeUpdater = ({ width, height }) => {
  const { setSceneSize, scale } = useContext(SceneContext);

  useLayoutEffect(() => {
    if (width && height && scale) {
      setSceneSize(sceneSize => {
        if (
          !sceneSize ||
          sceneSize.width !== width / scale ||
          sceneSize.height !== height / scale
        ) {
          return { width: width / scale, height: height / scale };
        }
        return sceneSize;
      });
    }
  }, [height, scale, setSceneSize, width]);

  return null;
};
