import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { EditorContainer } from '~/modules/Editor';
import Sidebar from '~/modules/Editor/components/Sidebar';
import LeftSidebar from '~/modules/Editor/components/LeftSidebar/LeftSidebar';
import { PageScaleInput } from '~/modules/Editor/components/PageScaleInput';
import { AutoSizer } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { getPageSize, getShouldUseDynamicSize } from '../stores/editor/selectors/course.selectors';
import { EditorContext } from '../modules/Editor/EditorContext';
import { getConfigObject } from '../stores/editor/selectors/config.selectors';
import { configGetConfig } from '../stores/editor/actions/config.actions';

const SceneEditor = ({
  verticalToolbarsPadding,
  horizontalToolbarsPadding,
  width,
  height,
  resolutionWidth,
  resolutionHeight,
  isAddPageModalOpen,
  setAddPageModalOpen,
}) => {
  const pageSizing = useSelector(state => state.course.data?.settings?.pageSizing);
  const isDynamicSize = useSelector(getShouldUseDynamicSize);

  let viewportHeight = Math.min(height - verticalToolbarsPadding, resolutionHeight);
  let viewportWidth = Math.min(width - horizontalToolbarsPadding, resolutionWidth);

  if (!isDynamicSize) {
    const resolutionAspectRatio = resolutionWidth / resolutionHeight;
    const viewportAspectRatio = viewportWidth / viewportHeight;

    if (viewportAspectRatio > resolutionAspectRatio) {
      viewportWidth = (resolutionWidth * viewportHeight) / resolutionHeight;
    } else {
      viewportHeight = (resolutionHeight * viewportWidth) / resolutionWidth;
    }
  }

  const viewportScale = !isDynamicSize
    ? Math.min(viewportWidth / resolutionWidth, viewportHeight / resolutionHeight)
    : 1;

  return (
    <>
      <SceneViewport
        css={css`
          padding: 0;
          margin: auto;
        `}>
        <SceneViewportCanvas
          css={css(
            css`
              height: ${resolutionHeight * viewportScale}px;
              width: ${resolutionWidth * viewportScale}px;
              border: 1px solid #dddddd;
              border-radius: 10px;
            `,
            isDynamicSize &&
              css`
                height: ${viewportHeight * viewportScale}px;
                width: ${viewportWidth * viewportScale}px;
              `,
          )}>
          <EditorContainer
            isAddPageModalOpen={isAddPageModalOpen}
            setAddPageModalOpen={setAddPageModalOpen}
            scale={viewportScale}
          />
        </SceneViewportCanvas>
      </SceneViewport>
      {pageSizing === '1' && (
        <BottomToolbar>
          <PageScaleInput viewportScale={viewportScale} />
        </BottomToolbar>
      )}
    </>
  );
};

export const ScreenSizes = {
  0: { w: 380, h: 900 },
  1: { w: 680, h: 1200 },
  2: { w: 920, h: 1200 },
  3: { w: 1200, h: 1200 },
  4: { w: 1280, h: 1200 },
};

export const EditorMain = ({ ...props }) => {
  const [isAddPageModalOpen, setAddPageModalOpen] = useState(false);
  const [isAddItemModalOpen, setAddItemModalOpen] = useState(false);
  const [isToolbarOpen, setToolbarOpen] = useState(false);

  const configObject = useSelector(getConfigObject);

  const pageSize = useSelector(getPageSize);
  const selectedScreenSize = ScreenSizes[pageSize];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!configObject.isFetching && !configObject.data && !configObject.error) {
      dispatch(configGetConfig());
    }
  });

  return (
    configObject.data && (
      <EditorContext.Provider value={{ isAddItemModalOpen, setAddItemModalOpen, setToolbarOpen }}>
        <div
          css={css`
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
            flex-direction: row;
          `}
          {...props}>
          <LeftSidebar
            setToolbarOpen={setToolbarOpen}
            setAddItemModalOpen={setAddItemModalOpen}
            isToolbarOpen={isToolbarOpen}
          />
          <Sidebar setAddPageModalOpen={setAddPageModalOpen} />
          <AutoSizer>
            {({ width, height }) => (
              <div
                css={css`
                  width: ${width}px;
                  height: ${height}px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  pointer-events: none;
                  overflow: hidden;

                  > * {
                    pointer-events: auto;
                  }
                `}>
                <SceneEditor
                  verticalToolbarsPadding={140} // Height of the scale-slider on the bottom or any other toolbars
                  horizontalToolbarsPadding={400}
                  width={width}
                  height={height}
                  resolutionWidth={selectedScreenSize.w}
                  resolutionHeight={selectedScreenSize.h}
                  isAddPageModalOpen={isAddPageModalOpen}
                  setAddPageModalOpen={setAddPageModalOpen}
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </EditorContext.Provider>
    )
  );
};

const BottomToolbar = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const SceneViewport = styled.div`
  flex: 0 1 auto;
  margin: auto;
  border-radius: 5px;
`;

const SceneViewportCanvas = styled.div`
  flex: 1 1 auto;
  margin: 0;
  background: #fff;
  overflow: visible;
  position: relative;
`;
