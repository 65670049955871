import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormTextarea } from '~/components/FormTextarea';

const CoverSection = props => {
  const { handleSubmit } = props;
  return (
    <form
      className="questionnaire-section-editor__settings-container"
      autoComplete="off"
      onSubmit={handleSubmit}>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormTextarea}
          name="title"
          type="text"
          label="Speed Course Title"
          defaultValue={''}
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
      <div className="questionnaire-section-editor__settings-row">
        <Field
          component={FormTextarea}
          name="description"
          type="text"
          label="Speed Course Description"
          defaultValue={''}
          onChange={() => {
            setTimeout(() => handleSubmit());
          }}
        />
      </div>
    </form>
  );
};

CoverSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const CoverSectionForm = reduxForm({
  form: 'speedCourseCoverSectionForm',
})(CoverSection);
